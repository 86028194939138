import { useState } from 'react'
import FormSignup from 'forms/FormSignup'
import FormLogin from 'forms/FormLogin'
import Button from 'components/Button'
import Container from 'components/Container'
import LogoIcon from 'assets/login-icon.png'
import FormForgotPassword from 'forms/FormForgotPassword'
import Title from 'components/Title'

type Screen = 'login' | 'signup' | 'forgot-password' | 'request-sent'

type Props = {
  initialScreen?: Screen
}

const LoginSignup = ({ initialScreen }: Props) => {
  const [screen, setScreen] = useState<Screen>(initialScreen || 'login')

  return (
    <div className="flex">
      <Container topAndBottom className="flex flex-col">
        <div className="mx-auto p-2">
          <img src={LogoIcon} alt="Logo" className="w-52" />
        </div>

        {screen === 'login' && (
          <>
            <FormLogin />

            {/* Links */}
            <p className="text-gray-600 text-center">
              Don't have an account yet?{' '}
              <Button
                type="button"
                $type="link"
                onClick={() => {
                  setScreen('signup')
                }}
              >
                Sign up
              </Button>
            </p>

            <p className="text-center">
              <Button
                type="button"
                $type="link"
                onClick={() => {
                  setScreen('forgot-password')
                }}
              >
                Forgot password?
              </Button>
            </p>
          </>
        )}
        {screen === 'signup' && (
          <>
            <div className="text-center pb-6 pt-12">
              <h1 className="text-3xl font-bold text-black">
                Save time, save money and fix your credit like a Pro.
              </h1>
              <p className="text-xl pt-4 text-gray-700">
                Myself Credit is the Easiest, Fastest, and most Innovative
                dispute creator software. Take action now!
              </p>
            </div>

            <FormSignup />

            {/* Links */}
            <p className="text-gray-600 text-center">
              I already have an account{' '}
              <Button
                type="button"
                $type="link"
                onClick={() => {
                  setScreen('login')
                }}
              >
                Login
              </Button>
            </p>
          </>
        )}
        {screen === 'forgot-password' && (
          <>
            <FormForgotPassword
              onRequestSent={() => setScreen('request-sent')}
            />

            {/* Links */}
            <p className="text-gray-600 text-center">
              I already have an account{' '}
              <Button
                type="button"
                $type="link"
                onClick={() => {
                  setScreen('login')
                }}
              >
                Login
              </Button>
            </p>
          </>
        )}
        {screen === 'request-sent' && (
          <>
            <div className="text-center py-10">
              <Title
                title="Request sent!"
                subtitle="Check your email to reset your password."
              />
            </div>
            {/* Links */}
            <p className="text-gray-600 text-center">
              I already have an account{' '}
              <Button
                type="button"
                $type="link"
                onClick={() => {
                  setScreen('login')
                }}
              >
                Login
              </Button>
            </p>
          </>
        )}

        <div className="pt-4">
          <Button
            $fluid
            $type="link"
            $size="sm"
            to="https://myselfcredit.com"
            $external={false}
          >
            Back to homepage
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default LoginSignup
