import ProgressBar from 'components/ProgressBar'
import {
  createContext,
  useState,
  Children,
  isValidElement,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react'
import Button from './components/Button'
import cc from 'classcat'

type ContextType = {
  state: any
  back: any
  next: any
  getDefault: any
}

const Context = createContext<ContextType>({
  state: null,
  getDefault: () => undefined,
  back: () => undefined,
  next: () => undefined,
})

export const useStepper = () => useContext(Context)

/**
 * Stepper is a simple form carousel that collects
 * data from each Step
 */
const Stepper = ({
  children,
  debug,
  startFrom,
  initialData,
  $progressBar,
  $progressBarFrom,
}: {
  children: any
  startFrom: number
  debug: boolean
  initialData: any
  $progressBar: boolean
  $progressBarFrom: number
}) => {
  Children.forEach(children, (child, index) => {
    if (!isValidElement<{ id: string; timeToComplete: number }>(child)) {
      throw new Error(`The step ${index + 1} is not a valid Element`)
    }
    if (!child.props.id) {
      throw new Error(`The step ${index + 1} is missing its 'id'`)
    }
  })

  const [state, setState] = useState({
    index: process.env.NODE_ENV === 'production' ? 0 : startFrom || 0,
    data: initialData || {},
  })

  const getDefault = (key: string) =>
    key.split('.').reduce((value, k) => value?.[k], state.data) || null

  const childrenArr = useMemo(
    () => Children.toArray(children),
    [children],
  ) as any[]

  const getCurrentStepName = useCallback(() => {
    const child = childrenArr[state.index]

    return child?.props.id
  }, [childrenArr, state.index])

  const findNewIndex = (move: string, index: number): number => {
    const newIndex = move === 'backwards' ? index - 1 : index + 1

    if (
      childrenArr[newIndex]?.props[
        move === 'backwards' ? 'omitBack' : 'omitNext'
      ]
    ) {
      return findNewIndex(move, newIndex)
    } else {
      return newIndex
    }
  }

  const back = (data: any) => {
    if (state.index > 0) {
      const stepName = getCurrentStepName()

      setState({
        ...state,
        index: findNewIndex('backwards', state.index),
        data: data
          ? {
              ...state.data,
              [stepName]: data,
            }
          : state.data,
      })

      setTimeout(() => {
        window.scroll({ top: 0 })
      })
    }
  }

  const next = (data = {}) => {
    if (state.index < children.length) {
      const stepName = getCurrentStepName()

      setState({
        ...state,
        index: findNewIndex('forward', state.index),
        data: {
          ...state.data,
          [stepName]: data,
        },
      })

      setTimeout(() => {
        window.scroll({ top: 0 })
      })
    }
  }

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') {
      setState((state) => ({
        ...state,
        index: startFrom,
      }))
    }
  }, [startFrom])

  const progress = useCallback(() => {
    const times = childrenArr
      .map((child) =>
        child.props.timeToComplete != null ? child.props.timeToComplete : 10,
      )
      .slice($progressBarFrom)
    const totalTime = times.reduce((sum, n) => sum + n, 0)

    const consumedTime = times
      .filter((_t, i) => i >= $progressBarFrom && i < state.index)
      .reduce((sum, n) => sum + n, 0)

    return +((consumedTime * 100) / totalTime).toFixed(0)
  }, [childrenArr, state, $progressBarFrom])

  return (
    <>
      {$progressBar && (
        <div
          className="fixed h-1 w-full top-0 left-0 z-9"
          style={{
            marginBottom: 20,
            marginLeft: 'calc(50% - 50vw)',
            marginRight: 'calc(50% - 50vw)',
          }}
        >
          <ProgressBar value={progress()} />
        </div>
      )}

      <div
        style={{
          maxWidth: '600px',
          margin: 'auto',
        }}
      >
        <Context.Provider
          value={{
            state,
            back,
            next,
            getDefault,
          }}
        >
          {children[state.index]}
        </Context.Provider>

        {debug && (
          <div style={{ paddingTop: 30, marginBottom: 1000 }}>
            <div>
              <strong style={{ textTransform: 'uppercase' }}>Debugger</strong>
            </div>
            <pre>
              <code>{JSON.stringify(state, null, 2)}</code>
            </pre>
          </div>
        )}
      </div>
    </>
  )
}

Stepper.defaultProps = {
  startFrom: 0,
  $progressBarFrom: 0,
}

export default Stepper

export const Step = ({
  children,
}: {
  children: any
  timeToComplete: number
  id: string
  omitBack?: boolean
}) => {
  return <div>{children}</div>
}

export const Navigator = ({
  saveDataHandler,
  $type,
  $disableContinue,
  $hideContinue,
  $continueText,
  $layout,
}: {
  saveDataHandler: any
  $type?: string
  $disableContinue?: boolean
  $hideContinue?: boolean
  $continueText: string
  $layout?: any
}) => {
  const { state, back, next } = useStepper()
  const canGoBack = state.index > 0

  if (!canGoBack) {
    return (
      <div className="text-center py-8 w-1/2 m-auto">
        <Button
          disabled={$disableContinue}
          type={$type === 'form' ? 'submit' : 'button'}
          $fluid
          onClick={() => {
            if ($type !== 'form') {
              next(saveDataHandler?.())
            }
          }}
        >
          {$continueText}
        </Button>
      </div>
    )
  }

  if ($hideContinue) {
    return (
      <div className="text-center py-8">
        <Button
          disabled={$disableContinue}
          $type="secondary"
          className="m-0"
          onClick={() => {
            back(saveDataHandler?.())
          }}
        >
          Back
        </Button>
      </div>
    )
  }

  return (
    <div
      className={cc([
        'gap-4 py-7 grid',
        { 'grid-cols-1': $layout === 'stack' },
        { 'grid-cols-2': $layout !== 'stack' },
      ])}
    >
      {$layout !== 'stack' && (
        <Button
          $fluid
          $type="secondary"
          onClick={() => {
            back(saveDataHandler?.())
          }}
        >
          Back
        </Button>
      )}
      {!$hideContinue && (
        <Button
          $fluid
          disabled={$disableContinue}
          type={$type === 'form' ? 'submit' : 'button'}
          onClick={() => {
            if ($type !== 'form') {
              next(saveDataHandler?.())
            }
          }}
        >
          {$continueText}
        </Button>
      )}
      {$layout === 'stack' && (
        <li>
          <Button
            $fluid
            $type="tertiary"
            onClick={() => {
              back(saveDataHandler?.())
            }}
          >
            Go back
          </Button>
        </li>
      )}
    </div>
  )
}

Navigator.defaultProps = {
  $continueText: 'Continue',
}
