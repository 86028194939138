import { FC, useCallback } from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from 'pages/HomePage'
import Logout from 'pages/Logout'
import RequiresLogin from 'components/RequiresLogin'
import CreditItems from 'pages/CreditItems'
import Notifications from 'pages/Notifications'
import PersonalDocuments from 'pages/PersonalDocuments'
import MyAccount from 'pages/SettingsAccount'
import PageNotFound from 'pages/PageNotFound'
import TipsPage from 'pages/TipsPage'
import DisputeLetters from 'pages/DisputeLetters'
import TutorialsPage from 'pages/TutorialsPage'
import CreditScorePage from 'pages/CreditScorePage'
import LetterPage from 'pages/LetterPage'
import NewLetterPage from 'pages/NewLetterPage'
import CreditSimulator from 'pages/CreditSimulatorPage'
import CreditItem from 'pages/CreditItem'
import ResetPassword from 'pages/ResetPassword'
import PaymentCheck from 'pages/PaymentCheck'
import Signup from 'pages/Signup'
import Simulator from 'pages/Simulator'

export type RouteType = {
  path: string
  Component: FC
  private?: boolean
  withoutActiveSubscription?: boolean
}

const PageRoutes = () => {
  const pageRoutes = useCallback(
    (): RouteType[] =>
      [
        { path: '/', Component: HomePage, private: true },
        {
          path: '/logout',
          Component: Logout,
          private: true,
          withoutActiveSubscription: true,
        },
        {
          path: '/signup',
          Component: Signup,
        },
        { path: '/letters', Component: DisputeLetters, private: true },
        { path: '/credit-items', Component: CreditItems, private: true },
        {
          path: '/credit-items/:itemID',
          Component: CreditItem,
          private: true,
        },
        { path: '/notifications', Component: Notifications, private: true },
        { path: '/my-documents', Component: PersonalDocuments, private: true },
        { path: '/my-account', Component: MyAccount, private: true },
        { path: '/credit-score', Component: CreditScorePage, private: true },
        {
          path: '/credit-score/simulator',
          Component: CreditSimulator,
          private: true,
        },
        { path: '/tips', Component: TipsPage },
        { path: '/tutorials', Component: TutorialsPage },
        { path: '/letters/new-letter/:itemID', Component: NewLetterPage },
        { path: '/letters/:itemID/:letterID', Component: LetterPage },
        { path: '/change-password', Component: ResetPassword },
        {
          path: '/payment/check',
          Component: PaymentCheck,
          private: true,
          withoutActiveSubscription: true,
        },
        {
          path: '/simulator',
          Component: Simulator,
        },
        // 404
        { path: '*', Component: PageNotFound },
      ].filter(Boolean) as RouteType[],
    [],
  )

  return (
    <Routes>
      {pageRoutes().map(
        ({
          path,
          Component,
          private: isPrivate,
          withoutActiveSubscription,
        }) => (
          <Route
            path={path}
            element={
              isPrivate ? (
                <RequiresLogin
                  Component={Component}
                  withoutActiveSubscription={withoutActiveSubscription}
                />
              ) : (
                <Component />
              )
            }
            key={`dynamic-route-${path}`}
          />
        ),
      )}
    </Routes>
  )
}

export default PageRoutes
