import React, { useEffect, useState } from 'react'
import ScoreWheel from 'components/ScoreWheel'
import { useStepper } from 'Stepper'
import roundDownToNearest10 from 'utils/roundDownToNearest10'
import config from 'config'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'
import Container from 'components/Container'
import cc from 'classcat'
import styles from './index.module.scss'

function GettingStarted() {
  const { next, getDefault } = useStepper()
  const currentScore = getDefault('currentScore')
  const { simulatedScore, selectedOptions } = getDefault('recommendations')
  const [displayedScore, setDisplayedScore] = useState(currentScore)
  const [displayedOptions, setDisplayedOptions] = useState<any[]>([])
  const [status, setStatus] = useState('Processing...')
  const increaseBy = roundDownToNearest10(
    (simulatedScore - currentScore) / selectedOptions.length,
  )

  useEffect(() => {
    selectedOptions.forEach((opt: string, i: number) => {
      const isLast = i === selectedOptions.length - 1
      setTimeout(() => {
        setDisplayedScore((current: number) =>
          isLast ? simulatedScore : current + increaseBy,
        )
        setDisplayedOptions((opts) => [
          ...opts,
          config.recommendations.find((rec) => rec.id === opt),
        ])

        if (isLast) {
          setStatus('Processing...')

          setTimeout(() => {
            next()
          }, 1000 * 2)
        }
      }, 1000 * (i + 1))
    })
  }, [])

  return (
    <Container topAndBottom>
      <div className="max-w-2xl mx-auto text-center">
        <div style={{ paddingBottom: 20 }}>
          <ScoreWheel
            $size="extra_large"
            score={displayedScore}
            disabled
            label={status}
          />
        </div>
        <ul className="inline-block">
          {displayedOptions.map((opt) => (
            <div
              className={cc([
                'flex text-left p-2.5 gap-3 items-center text-lg',
                styles.item,
              ])}
              key={opt.id}
            >
              <div>
                <IconCheck width="15" className="fill-green-600" />
              </div>
              {opt.bodyFuture}
            </div>
          ))}
        </ul>
      </div>
    </Container>
  )
}

export default GettingStarted
