import { yupResolver } from '@hookform/resolvers/yup'
import {
  AccountStatus_Enum,
  Bureaus_Enum,
  Categories_Enum,
  CreditItemStatus_Enum,
  useCreateNewCreditItemMutation,
  useDeleteCreditItemMutation,
  useUpdateBureausItemsMutation,
  useUpdateCreditItemMutation,
} from 'generated/graphql'
import {
  DISPUTE_REASONS,
  equifaxValidator,
  experianValidator,
  transUnionValidator,
} from 'globalConstants'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { useToast } from 'components/ToastMessage'
import FormRadioGroup from 'forms/FormRadioGroup/FormRadioGroup'
import FormRadio from 'forms/FormRadio'
import FormGroup from 'components/FormGroup'
import FormField from 'components/FormField'
import FormDropDown from 'forms/FormDropDown'
import { capitalize } from 'lodash'
import FormCheckbox from 'forms/FormCheckbox'
import Button from 'components/Button'
import friendlyStringFormatter from 'utils/friendlyStringFormatter'

const validationSchema = yup.object({
  accountStatus: yup.string().required(),
  accountName: yup.string().required().min(3).max(300),
  accountNumber: yup.string().optional(),
  balance: yup
    .number()
    .transform((value) => (isNaN(value) ? 0 : value))
    .optional(),
  category: yup.string().required(),
  comment: yup.string(),
  dispute_reason: yup.string().required(),
  trans_union: transUnionValidator,
  equifax: equifaxValidator,
  experian: experianValidator,
  status: yup.string().required(),
})

type initialValues = {
  id: string
  accountStatus: string
  accountName: string
  accountNumber: string
  balance: number
  category: string
  experian?: string
  trans_union?: string
  equifax?: string
  status: string
  dispute_reason?: string
}

type bureausObject = { item_id: string; value: Bureaus_Enum }

const CreditItemForm = ({
  initialValues,
  onCancel,
}: {
  initialValues?: initialValues
  onCancel?: () => void
}) => {
  const navigation = useNavigate()
  const ACCOUNT_STATUSES = [AccountStatus_Enum.Open, AccountStatus_Enum.Closed]
  const CATEGORIES = [
    Categories_Enum.UnknownAccounts,
    Categories_Enum.LatePayments,
    Categories_Enum.ChargeOff,
    Categories_Enum.Collections,
    Categories_Enum.Repossession,
    Categories_Enum.Foreclosure,
    Categories_Enum.MixedReasons,
  ]
  const [createItemState, createItemMutation] = useCreateNewCreditItemMutation()
  const [updateBureausState, updateBureausMutation] =
    useUpdateBureausItemsMutation()
  const [deleteItemState, deleteItem] = useDeleteCreditItemMutation()

  const {
    register,
    control,
    formState,
    handleSubmit,
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      accountStatus: ACCOUNT_STATUSES.find(
        (status) => status === initialValues?.accountStatus,
      ),
      accountName: initialValues?.accountName,
      accountNumber: initialValues?.accountNumber,
      balance: initialValues?.balance,
      category: CATEGORIES.find(
        (category) => initialValues?.category === category,
      ),
      experian: initialValues?.experian === Bureaus_Enum.Experian,
      trans_union: initialValues?.trans_union === Bureaus_Enum.TransUnion,
      equifax: initialValues?.equifax === Bureaus_Enum.Equifax,
      status: initialValues?.status || CreditItemStatus_Enum.InProgress || '',
      dispute_reason: initialValues?.dispute_reason || '',
    },
  })
  const toast = useToast()
  const [updateItemState, updateItemMutation] = useUpdateCreditItemMutation()
  const { errors, isDirty, isSubmitting, isSubmitSuccessful } = formState
  const watchCategory = watch('category')
  const REASONS = watchCategory ? DISPUTE_REASONS[watchCategory].reasons : []

  const STATUSES = [
    CreditItemStatus_Enum.InProgress,
    CreditItemStatus_Enum.AccountFixed,
    CreditItemStatus_Enum.AccountDeleted,
    CreditItemStatus_Enum.Canceled,
  ]

  const formHasErrors =
    createItemState.error ||
    updateBureausState.error ||
    updateItemState.error ||
    deleteItemState.error

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (values) => {
          console.log('--values', values)
          let itemId: string
          let hasErrors = false
          let operationMessage: string = ''
          if (!initialValues?.id) {
            const result = await createItemMutation(
              {
                object: {
                  account_name: values.accountName,
                  account_number: values.accountNumber,
                  account_status: values.accountStatus,
                  category: values.category,
                  dispute_reason: values.dispute_reason,
                  total: values.balance,
                  status: STATUSES.find((status) => values.status === status),
                },
              },
              { requestPolicy: 'network-only' },
            )
            itemId = result.data?.insert_CreditItem_one?.id
            if (result.error) {
              hasErrors = true
            } else {
              operationMessage = 'The Credit Item has been created!'
            }
          } else {
            itemId = initialValues.id

            const updateItemResponse = await updateItemMutation({
              id: initialValues.id,
              set: {
                account_name: values.accountName,
                account_number: values.accountNumber,
                account_status: values.accountStatus,
                category: values.category,
                total: values.balance,
                dispute_reason: values.dispute_reason,
                status: STATUSES.find((status) => values.status === status),
              },
            })

            if (updateItemResponse.error) {
              hasErrors = true
            } else {
              operationMessage = 'The Credit Item has been updated!'
            }
          }
          const bureaus = [
            values.equifax && { item_id: itemId, value: Bureaus_Enum.Equifax },
            values.experian && {
              item_id: itemId,
              value: Bureaus_Enum.Experian,
            },
            values.trans_union && {
              item_id: itemId,
              value: Bureaus_Enum.TransUnion,
            },
          ].filter(Boolean) as bureausObject[]

          const bureausResult = await updateBureausMutation({
            object: bureaus,
            itemId,
          })
          if (!hasErrors && !bureausResult.error) {
            toast.notify({
              type: 'success',
              message: operationMessage,
              onClose() {
                navigation(0)
              },
            })
          } else
            toast.notify({
              type: 'failure',
              message: 'There was an error creating the item, please try again',
            })
        })}
      >
        <div className="space-y-3 my-3">
          <div className="text-gray-600">Account Status</div>
          <FormRadioGroup
            name="accountStatus"
            control={control}
            render={({ onChange, value }) => (
              <div className="flex gap-5">
                <FormRadio
                  label="Open"
                  name="accountStatus"
                  id="open"
                  value="open"
                  onChange={onChange}
                  checked={value === 'open'}
                />
                <FormRadio
                  label="Closed"
                  name="isClosed"
                  id="closed"
                  value="closed"
                  onChange={onChange}
                  checked={value === 'closed'}
                />
              </div>
            )}
          />
          {errors.accountStatus?.message && (
            <ErrorMessage
              message={errors.accountStatus?.message.toString() || ''}
            />
          )}
        </div>
        <FormGroup>
          <FormField
            placeholder="Account Name"
            error={errors.accountName?.message}
            {...register('accountName')}
          />
          <FormField
            placeholder="Account Number"
            error={errors.accountNumber?.message}
            {...register('accountNumber')}
          />
          <FormField
            placeholder="Balance/Total ($)"
            error={errors.balance?.message}
            $maskType="money"
            defaultValue={getValues('balance')}
            onValueChange={(value: number) => {
              setValue('balance', value)
            }}
            {...register('balance')}
          />
          <FormDropDown
            {...register('category')}
            placeholder="Category"
            error={errors.category?.message}
          >
            {CATEGORIES.map((category, i) => (
              <option key={i} value={category}>
                {capitalize(
                  category === Categories_Enum.ChargeOff
                    ? "Charge off's"
                    : category.replace('_', ' '),
                )}
              </option>
            ))}
          </FormDropDown>
          <FormDropDown
            {...register('dispute_reason')}
            placeholder="Dispute Reason"
            error={errors.dispute_reason?.message}
            disabled={!watchCategory}
          >
            {REASONS.map((reason, i) => (
              <option key={`item-dispute_reason-${i}`}>{reason}</option>
            ))}
          </FormDropDown>
          <div className="space-y-3">
            <div className="text-gray-600">Where to dispute?</div>
            <div className="flex gap-4 flex-wrap">
              <FormCheckbox
                {...register('experian')}
                label="Experian"
                defaultChecked={getValues('experian')}
              />
              <FormCheckbox
                {...register('trans_union')}
                label="TransUnion"
                defaultChecked={getValues('trans_union')}
              />
            </div>
            <FormCheckbox
              {...register('equifax')}
              label="Equifax"
              defaultChecked={getValues('equifax')}
            />
          </div>
          {(errors.experian || errors.trans_union || errors.equifax) && (
            <ErrorMessage
              message={
                errors.experian?.message ||
                errors.trans_union?.message ||
                errors.equifax?.message ||
                ''
              }
            />
          )}
          <FormDropDown
            {...register('status')}
            placeholder="Dispute Status"
            error={errors.status?.message}
          >
            {STATUSES.map((value, i) => (
              <option value={value} key={`item-status-${i}`}>
                {value
                  .split('_')
                  .map((value) => friendlyStringFormatter(value))
                  .join(' ')}
              </option>
            ))}
          </FormDropDown>
          {formHasErrors && (
            <ErrorMessage
              message={
                createItemState.error?.message ||
                updateBureausState.error?.message ||
                updateItemState.error?.message ||
                deleteItemState.error?.message ||
                ''
              }
            />
          )}

          <div className="flex gap-4">
            <Button
              $fluid
              disabled={!isDirty || (isSubmitSuccessful && !formHasErrors)}
              loading={isSubmitting}
            >
              Save
            </Button>
            {onCancel && (
              <Button
                $fluid
                $type="danger"
                type="button"
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            )}
          </div>
          {initialValues && (
            <Button
              $fluid
              $type="tertiary"
              type="button"
              onClick={async () => {
                const result = await deleteItem({
                  id: initialValues.id,
                })
                if (!result.error) {
                  toast.notify({
                    type: 'success',
                    message: 'The item has been deleted!',
                    onClose() {
                      navigation(0)
                    },
                  })
                } else
                  toast.notify({
                    type: 'failure',
                    message:
                      'There was an error delete the item, please try again',
                  })
              }}
            >
              Delete Item
            </Button>
          )}
        </FormGroup>
      </form>
    </div>
  )
}

const ErrorMessage = ({ message }: { message: string }) => {
  if (!message) {
    return null
  }
  return <div className="text-action-fail text-sm pt-1">{message}</div>
}

export default CreditItemForm
