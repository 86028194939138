import { useAuth } from 'clients/auth.client'
import { useCallback } from 'react'

type Type = 'primary' | 'secondary' | 'logout'

type MenuLink = {
  to: string
  onClick?: () => void
  text: string
  $type?: Type
  initialOpen?: boolean
}

type MenuCustomLink = {
  Component: React.FC
}

const useMenu = () => {
  const { account } = useAuth()

  const LINKS = useCallback(
    () =>
      [
        {
          to: '/my-account',
          text: 'Account Settings',
        },
        {
          to: '/my-documents',
          text: 'Personal Documents',
        },
        {
          text: 'Credit Items',
          to: '/credit-items',
        },
        {
          to: '/letters',
          text: 'Dispute letters',
        },
        { to: '/credit-score', text: 'Credit Score' },
        { to: '/credit-score/simulator', text: 'Credit Simulator' },
        {
          Component: () => (
            <div
              className="w-full py-3 hover:text-gray-700"
              onClick={(e) => e.stopPropagation()}
            >
              <a
                href="http://myselfcredit.com/tutorials/"
                target="_blank"
                rel="noreferrer"
              >
                Tutorials
              </a>
            </div>
          ),
        },
        {
          to: '/tips',
          text: 'Credit Secrets',
        },
        {
          text: 'Documentation',
          to: [
            {
              to: 'https://myselfcredit.com/tutorials/',
              text: 'Tutorials',
            },
            {
              to: 'https://app.myselfcredit.com/tips',
              text: 'Credit Secrets',
            },
            {
              to: 'https://myselfcredit.com/laws/',
              text: 'Laws',
            },
            {
              to: 'https://myselfcredit.com/how-is-my-credit-score-calculated/',
              text: 'How my credit score is calculated',
            },
          ],
          initialOpen: true,
        },
      ].filter(Boolean) as (MenuLink | MenuCustomLink)[],
    [account],
  )
  return LINKS
}

export default useMenu
