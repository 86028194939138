export const colors = {
  white: "white",
  black: "#555",
  blue: "#01C1CF",
  darkBlue: "#049ba5",
  lighterGray: "#F7F8FB",
  lightGray: "#DFDFDF",
  gray: "#ccc",
  darkGray: "#999",
  primary: '#ec2cad',

  // status
  red: "#eb6762",
  yellow: "#f0bd50",
  lightGreen: "#60ab8a",
  green: "#387e57",
};
