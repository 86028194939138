import React from 'react'
import Container from 'components/Container'
import FormGroup from 'components/FormGroup'
import Title from 'components/Title'
import FormDropDown from 'forms/FormDropDown'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import Button from 'components/Button'
import { yupResolver } from '@hookform/resolvers/yup'
import {
  Bureaus_Enum,
  UpdateCreditScoreDocument,
  GetCreditScoresQuery,
  useGetCreditScoresQuery,
} from 'generated/graphql'
import { useClient } from 'urql'
import { useToast } from 'components/ToastMessage'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import withAsideMenu from 'hocs/withAsideMenu'
import withContainer from 'hocs/withContainer'
import { useNavigate } from 'react-router-dom'

const MIN_VALUE = 350
const MAX_VALUE = 850

const bureauValidation = yup.string()

const validationSchema = yup.object({
  trans_union: bureauValidation,
  equifax: bureauValidation,
  experian: bureauValidation,
})

type CreditScoreItem = {
  value: number
  bureau: Bureaus_Enum
}

function CreditScorePage() {
  const [{ data, fetching, error }] = useGetCreditScoresQuery()

  if (fetching) {
    return <Loading />
  }

  if (error?.message) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return null
  }

  return (
    <Container topAndBottom>
      <div className="max-w-2xl mx-auto">
        <div>
          <Title
            $align="left"
            title="Credit Score"
            subtitle="Add your recent credit score, update it and keep track of your progress."
          />
        </div>
        <Form initialData={data.CreditScore} />
      </div>
    </Container>
  )
}

const Form = ({
  initialData,
}: {
  initialData: GetCreditScoresQuery['CreditScore']
}) => {
  const client = useClient()
  const toast = useToast()
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      trans_union: initialData.find(
        (score) => score.bureau === Bureaus_Enum.TransUnion,
      )?.value,
      equifax: initialData.find(
        (score) => score.bureau === Bureaus_Enum.Equifax,
      )?.value,
      experian: initialData.find(
        (score) => score.bureau === Bureaus_Enum.Experian,
      )?.value,
    },
  })
  const navigate = useNavigate()
  const { isDirty, isSubmitting } = formState

  const OPTIONS = Array.from(
    { length: (MAX_VALUE - MIN_VALUE) / 10 + 1 },
    (_, i) => MIN_VALUE + i * 10,
  )

  return (
    <form
      className="py-4"
      onSubmit={handleSubmit(async (values) => {
        const objects = [
          values.trans_union && {
            value: +values.trans_union,
            bureau: Bureaus_Enum.TransUnion,
          },
          values.equifax && {
            value: +values.equifax,
            bureau: Bureaus_Enum.Equifax,
          },
          values.experian && {
            value: +values.experian,
            bureau: Bureaus_Enum.Experian,
          },
        ].filter(Boolean) as CreditScoreItem[]

        const whereToDelete = initialData.map((score) => ({
          id: { _eq: score.id },
        }))

        const result = await client
          .mutation(UpdateCreditScoreDocument, { objects, whereToDelete })
          .toPromise()

        if (!result.error) {
          toast.notify({
            type: 'success',
            message: 'Your scores were updated!',
            onClose() {
              navigate('/')
            },
          })
        } else {
          toast.notify({
            type: 'failure',
            message:
              'There was a problem updating your scores please try again.',
          })
        }
      })}
    >
      <FormGroup>
        <FormDropDown {...register('trans_union')} placeholder="TransUnion">
          {OPTIONS.map((option, i) => (
            <option key={`trans-union-option-${i}`}>{option}</option>
          ))}
        </FormDropDown>
        <FormDropDown {...register('equifax')} placeholder="Equifax">
          {OPTIONS.map((option, i) => (
            <option key={`equifax-option-${i}`}>{option}</option>
          ))}
        </FormDropDown>
        <FormDropDown {...register('experian')} placeholder="Experian">
          {OPTIONS.map((option, i) => (
            <option key={`experian-option-${i}`}>{option}</option>
          ))}
        </FormDropDown>

        <Button $fluid disabled={!isDirty} loading={isSubmitting}>
          Update
        </Button>
      </FormGroup>
    </form>
  )
}

export default withContainer(withAsideMenu(CreditScorePage))
