import { Bureaus_Enum, Categories_Enum } from 'generated/graphql'
import * as yup from 'yup'

export const GENERIC_ERROR_MESSAGE =
  'Something went wrong. Please try again. If the error persists, please contact support.'

export enum DEFAULT_HTML_VARIABLES {
  'CustomerFirstName',
  'CustomerLastName',
  'CustomerSSN',
  'CustomerAddress',
  'CustomerDOB',
  'ItemNameList',
  'ItemNumberList',
  'ItemBalance',
  'RecipientName',
  'RecipientAddressLine1',
  'RecipientCityStatePostalCode',
  'TodaysDate',
}

export const CUSTOM_FIELDS_VARIABLES = ['AdditionalComments']

export const FILTEROUT_VARS = ['[15 USC &sect; 1681j]']

export const bureausVariables = {
  [Bureaus_Enum.Equifax]: {
    RecipientName: 'Equifax',
    RecipientAddressLine1: 'P.O. Box 740256',
    RecipientCityStatePostalCode: 'Atlanta GA 30374-0256',
  },
  [Bureaus_Enum.TransUnion]: {
    RecipientName: 'Transunion',
    RecipientAddressLine1: 'P.O. Box 2000',
    RecipientCityStatePostalCode: 'Chester, PA 19016-2000',
  },
  [Bureaus_Enum.Experian]: {
    RecipientName: 'Experian',
    RecipientAddressLine1: 'P.O. Box 4500',
    RecipientCityStatePostalCode: 'Allen, TX 75013',
  },
}

export type UserInformationType = {
  firstName: string
  lastName: string
  city: string
  ssn: string
  state: string
  address1: string
  address2: string
  zip_code: string
  ssn_picture: string
  license_picture: string
  address_picture: string
  birthdate: string
}

export type AccountInformationType = {
  accountNumber: string
  accountName: string
  accountBalance: number
}

export type AccountInstructionType = {
  accountName: string
  accountNumber: string
  accountReason: string
}

export const getVariableRegex = /\[(.*?)\]/g

export const getVariableNameRegex = /[\w\d]+/g

type DisputeReasonsType = { [key in Categories_Enum]: { reasons: string[] } }

export const transUnionValidator = yup.boolean().test({
  name: 'select-one',
  message: 'Please select at least one bureau',
  exclusive: false,
  test: (value, context) =>
    value === false ? context.parent.equifax || context.parent.experian : true,
})

export const experianValidator = yup.boolean().test({
  name: 'select-one',
  message: 'Please select at least one bureau',
  exclusive: false,
  test: (value, context) =>
    value === false
      ? context.parent.equifax || context.parent.trans_union
      : true,
})

export const equifaxValidator = yup
  .boolean()
  .required()
  .test({
    name: 'select-one',
    message: 'Please select at least one bureau',
    exclusive: false,
    test: (value, context) =>
      value === false
        ? context.parent.trans_union || context.parent.experian
        : true,
  })

export const DISPUTE_REASONS: DisputeReasonsType = {
  [Categories_Enum.UnknownAccounts]: {
    reasons: [
      'I have no knowledge of this account at all. Delete it.',
      'I am not responsible for this account. Remove immediately.',
      'This is not my account; it belongs to a relative or person with similar name or address. Delete my credit please.',
      'This account was opened fraudulently. You must delete it.',
      'This account does not belong to me. Please delete it.',
      'This account is not mine. Please delete it.',
      'This account is obsolete. Please delete it.',
      'I have absolutely no record of this alleged account. Stop reporting this and delete it.',
      'I do not recognize this creditor. This is erroneous reporting, delete it.',
      'My credit is mixed up with someone else and my credit is the one suffering. Delete this incorrect information.',
      'Unknown account, please delete',
    ],
  },
  [Categories_Enum.LatePayments]: {
    reasons: [
      'This account is incorrect. Never late, fix it accordingly.',
      'The payment history is incorrect. Fix it please.',
      'The payment history on this account is incorrect. Needs correction please.',
      'This acocunt has been always paid on time. Please correct.',
      'Never late. Please correct.',
      'I was never 30 days late. Please change to a positive rating. Correction action needed.',
      'Late payments on this account are being reported incorrectly. Change it to a positive rating.',
      'I was never late on this account at all. Please delete it.',
      'I was never late during the month it shows on the report. Correction action needed.',
      'Why is this account still showing late payments? It’s completely inaccurate, fix it or remove it.',
      'The number of late payments is incorrect. Change to positive rating please.',
    ],
  },
  [Categories_Enum.ChargeOff]: {
    reasons: [
      'This charge off account does not belong to me. Please delete it.',
      'This charged off account belongs to someone elses with the same or similar name. Please delete it',
      'This charged off account was reported in error and should not be shown on my credit report. Please delete it.',
      'I do not have any financial obligation for this alleged charged off. Delete it.',
      'This charge off account is not mine. Delete it now.',
      'This charged off account is not mine and therefore does not have any financial obligation with it. Delete it.',
      'There is no connection between this account and me. Delete this error.',
      'I contacted the creditor and confirmed this is not mine and was told to dispute it again with you. Delete it.',
      'How did you validate this alleged charge off account? Not under FCRA guidelines. Again, the account is not mine, Delete it.',
      'I have absolutely no record of such charge off account with his accreditor. Erroneous reporting, delete it.',
    ],
  },
  [Categories_Enum.Collections]: {
    reasons: [
      'This collection account is being surreptitiously and unlawfully placed on my credit. Delete it.',
      'This collection account does not belong to me. Please delete it at once.',
      'This collection account is false and depredatory. Please delete it',
      'This account was reported to collections in error and should not be shown on my credit report. Please delete it',
      'I do not have any financial obligation with this alleged collection account, remove permanently.',
      'This collection account belongs to another person with the same or similar name, delete it.',
      'I contacted the agency and they explained to me that they don’t have records of this account. Please delete it.',
      'I never received any evidence nor notification that this alleged collection account in question is mine. Delete it.',
      'Not my account, delete it please',
      'This collection account is not mine, remove it at once.',
      'I’m unaware of this collection account. Please delete it.',
    ],
  },
  [Categories_Enum.Repossession]: {
    reasons: [
      'Unknown repossession record, please delete it.',
      'This repossession account does not belong to me. Please delete it.',
      'This repossession account belongs to another person. Delete it at once.',
      'This repossession account was reported in error and should not be shown on my credit report. Delete it.',
      'This repossession account is being surreptitiously and unlawfully placed on my credit reports. Delete it.',
      'I do not have any financial obligation to you. Delete this now.',
      'This repossession account is false and depredatory. Delete it.',
      'This repossession account is not mine therefore I do not have any financial obligation with you. Delete',
      'Why is this repossession account still reported on my credit? I confirmed is not mine and you failed to validate it. Delete it.',
      'Produce reasonably validation for this alleged account, otherwise you must delete it immediately.',
    ],
  },
  [Categories_Enum.Foreclosure]: {
    reasons: [
      'This foreclosure account is not mine, delete it.',
      'This foreclosure does not belong to me. Delete it.',
      'My report must be mixed with someone else’s because this foreclosure is not mine. Delete it.',
      'How did you validate this foreclosure? Not under FCRA because is not related to me at all. Delete it.',
      'This foreclosure is misleading and false. Delete it',
      'Why is this foreclosure on my credit? Stop reporting it because does not belong to me.',
      'I am not liable for this alleged foreclosure. Remove at once.',
      'Provide documentation bearing my signature as ownership for this alleged account. Otherwise delete it.',
      'The agency was supposed to delete this from my report. I called and confirmed is not mine and was told to dispute it again with you.',
      'This foreclosure was reported in error and should not show up on my credit. Delete it.',
    ],
  },
  [Categories_Enum.MixedReasons]: {
    reasons: [
      'I do not have any financial obligation to you. Please delete this reference from all credit reports.',
      'I have no idea what this account is about. Delete it.',
      'I never received any loan or credit from this creditor. Delete it.',
      'Provide documentation about this alleged account. Otherwise delete',
      'Current status is incorrect. Delete it.',
      'The date of the last activity was more than 7 years ago. Delete this outdated account.',
      'The date reported for the account opening is incorrect. Delete it.',
      'The date of my last payment is incorrect. Delete it.',
      'There is no past due amount on this acocunt. Delete it.',
      'This account is reported as open, there is no open account. Delete it now.',
      'This account is not a collection or charge off. Delete it.',
      'This account is missing or incorrect. Delete it.',
      'This account was paid by insurance. Delete it.',
      'This account is involved in litigation. Please delete it.',
      'The creditor agreed to change account information to positive. Correct or delete.',
      'I am on active military duty. Delete it.',
    ],
  },
}

export type loanTypes = 'home' | 'car' | 'credit-card' | 'loan'

export type statusType = 'poor' | 'decent' | 'good' | 'excellent'
export enum Videos {
  ACCOUNT_SETTINGS = 'https://www.youtube.com/watch?v=6dpjK-gimbg',
  DOCUMENTS = 'https://youtu.be/QaqWddHZw2I',
  ITEMS = 'https://youtu.be/GlwkvMTBn8o',
  LETTERS = 'https://youtu.be/3DzpApuToIE',
}
