import React from 'react'
import NoResultTile from 'components/NoResultTile'
import { useGetMissingStepsQuery } from 'generated/graphql'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'

function ActionsList() {
  const [{ data, error, fetching }] = useGetMissingStepsQuery()

  if (fetching) {
    return <Loading />
  }

  if (error?.message) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data || !data.checkMissingSteps || !data.checkMissingSteps.length) {
    return null
  }

  return (
    <div>
      {data.checkMissingSteps.map((step, i) => {
        return (
          <div
            className="my-8"
            data-testid={`action-${step?.key}`}
            key={`action-list-${step?.key}`}
          >
            <div className="bg-white shadow-sm rounded-lg">
              <NoResultTile
                title={step?.title || ''}
                subtitle={step?.subtitle || ''}
                buttonText={step?.buttonText || ''}
                buttonLink={step?.buttonLink || ''}
                className="rounded-lg px-4"
                name={step?.key}
                stepName={`Step #${i + 1}`}
                $isCompleted={step?.completed || false}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default ActionsList
