import Button from 'components/Button'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function HeroButtons({
  $size,
  $fluid,
}: {
  $size?: 'md' | 'sm'
  $fluid?: boolean
}) {
  const navigate = useNavigate()

  return (
    <>
      <Button
        $size={$size}
        $fluid={$fluid}
        onClick={() => navigate('/credit-items')}
      >
        Add credit item
      </Button>
      <Button
        $type="secondary"
        $size={$size}
        $fluid={$fluid}
        onClick={() => navigate('/letters')}
      >
        New letter
      </Button>
    </>
  )
}

export default HeroButtons
