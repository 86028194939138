import { FC } from 'react'
import { Controller } from 'react-hook-form'

type RenderProps = {
  onChange: (...event: any[]) => void
  value: string
}

type Props = {
  render: FC<RenderProps>
  name: any //type any because react-hook-form is throwing exception
  control: any
}

function FormRadioGroup({ render: Render, name, control }: Props) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value } }) => (
        <Render onChange={onChange} value={value} />
      )}
    />
  )
}

export default FormRadioGroup
