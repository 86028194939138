import React from 'react'
import playIcon from 'assets/icons/play-icon.png'
import VideoPlayer from 'components/VideoPlayer'
import { useToast } from 'components/ToastMessage'

function PlayVideoButton({ video }: { video: string }) {
  const toast = useToast()
  return (
    <button
      className="relative flex items-center flex-col"
      type="button"
      onClick={() => {
        toast.notify({
          type: 'video',
          preventAutoClose: true,
          message: () => {
            return (
              <div className="">
                <VideoPlayer url={video} playing />
              </div>
            )
          },
        })
      }}
    >
      <span className="animate-ping absolute inline-flex w-2/3 h-2/3 rounded-full bg-primary opacity-10 top-3" />
      <img src={playIcon} alt="Play Icon" className="w-10" />
      <div
        className="text-primary text-center"
        style={{
          minWidth: 90,
        }}
      >
        See Tutorial
      </div>
    </button>
  )
}

export default PlayVideoButton
