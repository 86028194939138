import CreditItemStatus from 'components/CreditItemStatus'
import {
  AccountStatus_Enum,
  Bureaus_Enum,
  Categories_Enum,
  CreditItemStatus_Enum,
} from 'generated/graphql'
import { capitalize } from 'lodash'
import friendlyStringFormatter from 'utils/friendlyStringFormatter'

type CreditItemInformationType = {
  id: string
  accountName: string
  accountNumber: string
  accountStatus: AccountStatus_Enum
  bureaus: Bureaus_Enum[]
  total: number
  category: Categories_Enum
  status: CreditItemStatus_Enum
  dispute_reason?: string
}

const CreditItemInformation = ({
  data,
}: {
  data: CreditItemInformationType
}) => {
  const {
    accountNumber,
    accountStatus,
    bureaus,
    category,
    total,
    status,
    dispute_reason,
  } = data

  return (
    <div className="my-8 space-y-3">
      <div>
        <div className="text-gray-500">Account #:</div>
        <div>{accountNumber}</div>
      </div>

      <div className="flex justify-between">
        <div>
          <div className="text-gray-500">Balance:</div>
          <div>${total == null ? '--' : total.toLocaleString()}</div>
        </div>
        <div>
          <div className="text-gray-500">Category:</div>
          <div>{capitalize(category.replace('_', ' '))}</div>
        </div>
        <div>
          <div className="text-gray-500">Account Status:</div>
          <div>{capitalize(accountStatus)}</div>
        </div>
      </div>

      <div>
        <div className="text-gray-500">Dispute Reason:</div>
        <div>{dispute_reason}</div>
      </div>
      <div>
        <div className="text-gray-500">Where to Dispute:</div>
        <div>
          {bureaus
            .map((bureau) =>
              bureau
                .split('_')
                .map((value) => friendlyStringFormatter(value))
                .join(''),
            )
            .join(', ')}
        </div>
      </div>
      <div>
        <div className="text-gray-500">Dispute Status:</div>
        <CreditItemStatus status={status} />
      </div>
    </div>
  )
}

export default CreditItemInformation
