import cc from 'classcat'
import React, { useState, useEffect, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

type ID = string

type Item = {
  id: ID
  text: string | React.FC
  Component: React.FC
}

type Props = {
  items: Item[]
  $style?: 'text-base' | 'even'
  $hideSingleTab?: boolean
  activeTab?: string
  className?: string
  rootClass?: string
  onChange?: (id: ID) => void
}

const Tabs = ({
  items,
  $style,
  $hideSingleTab,
  activeTab,
  className,
  rootClass,
  onChange,
}: Props) => {
  const location = useLocation()
  const [current, setCurrent] = useState<ID>(
    activeTab || location.hash.replace('#', '') || items[0].id.toString(),
  )
  const item = items.find((i) => i.id.toString() === current) || items[0]
  const navigation = useNavigate()
  const hasRendered = useRef(false)

  useEffect(() => {
    if (location.hash) {
      setCurrent(location.hash.replace('#', ''))
    }
  }, [location.hash])

  useEffect(() => {
    if (activeTab) {
      setCurrent(activeTab)
    }
  }, [activeTab])

  useEffect(() => {
    if (onChange && hasRendered.current) {
      onChange(current)
    } else {
      hasRendered.current = true
    }
  }, [current])

  return (
    <div className={rootClass}>
      {(!$hideSingleTab || items.length > 1) && (
        <ul
          className={cc([
            'flex bg-gray-100 gap-3',
            {
              'justify-between': $style === 'text-base',
            },
            className,
          ])}
        >
          {items.map(({ id, text }) => {
            const isActive = id.toString() === current
            const TextComponent = typeof text === 'function' ? text : null
            const textString = typeof text === 'string' ? text.toString() : null

            return (
              <li
                className={cc([
                  '',
                  {
                    'w-full': $style === 'even',
                  },
                ])}
                key={`tabs-items-${id}`}
              >
                <button
                  className={cc([
                    'py-2 md:text-sm',
                    {
                      'font-medium border-b-2 border-primary md:font-bold white bg-white rounded-t-md shadow':
                        isActive,
                      'text-gray-700': !isActive,
                      'w-full': $style === 'even',
                    },
                  ])}
                  type="button"
                  onClick={() => {
                    navigation(`#${id}`)
                  }}
                >
                  {TextComponent ? <TextComponent /> : textString}
                </button>
              </li>
            )
          })}
        </ul>
      )}

      {items && <item.Component />}
    </div>
  )
}

Tabs.defaultProps = {
  $style: 'text-base',
}

export default Tabs
