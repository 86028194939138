import cc from 'classcat'
import { Link } from 'react-router-dom'
import { LoadingSpinner } from 'components/Loading'

export type Type =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'danger'
  | 'info'
  | 'confirm'

export interface Props {
  children: any
  $type?: Type
  $fluid?: boolean
  $inverted?: boolean
  disabled?: boolean
  loading?: boolean
  $loadingColor?: 'primary' | 'white'
  onClick?: () => void
  className?: string
  form?: string
  type?: string
  to?: string
  $size: 'sm' | 'md'
  $rounded?: boolean
  $external?: boolean
}

const Button = ({
  children,
  $type,
  $fluid,
  $inverted,
  className,
  disabled,
  loading,
  $size,
  $loadingColor,
  $rounded,
  $external,
  ...rest
}: Props) => {
  const isExternalLink =
    $external != null ? $external : rest.to?.startsWith('http')

  const Render = (rest.to ? (isExternalLink ? 'a' : Link) : 'button') as any
  const classMap: {
    [key: string]: {
      normal: {
        enabled: string
        disabled: string
      }
      inverted: {
        enabled: string
        disabled: string
      }
    }
  } = {
    primary: {
      normal: {
        enabled: 'text-white bg-primary ',
        disabled: 'text-white bg-gray-600',
      },
      inverted: {
        enabled: 'text-green bg-white',
        disabled: 'text-green bg-gray-300 opacity-75',
      },
    },
    secondary: {
      normal: {
        enabled: 'bg-white text-primary border-2 border-primary',
        disabled: 'bg-white text-gray-600 border-2 border-gray-600',
      },
      inverted: {
        enabled: 'bg-white text-white border-2 border-white',
        disabled: 'bg-white text-gray-300 border-2 border-gray-300 opacity-75',
      },
    },
    link: {
      normal: {
        enabled: 'text-primary',
        disabled: 'text-gray-600',
      },
      inverted: {
        enabled: 'text-white',
        disabled: 'text-white text-opacity-75',
      },
    },

    confirm: {
      normal: {
        enabled: 'text-white bg-action-success',
        disabled: 'text-white bg-gray-600',
      },
      inverted: {
        enabled: 'text-white bg-gray-500',
        disabled: 'text-bg-action-success bg-gray-300 opacity-75',
      },
    },

    danger: {
      normal: {
        enabled: 'bg-action-fail text-white',
        disabled: 'text-action-fail bg-gray-400',
      },
      inverted: {
        enabled: 'bg-white text-action-fail border-2 border-action-fail',
        disabled: 'bg-white text-action-fail opacity-75',
      },
    },

    tertiary: {
      normal: {
        enabled: 'bg-transparent text-gray-600',
        disabled: 'bg-transparent text-gray-600',
      },
      inverted: {
        enabled: 'bg-transparent text-white',
        disabled: 'text-gray-300 text-opacity-75',
      },
    },
    // *** WIP ***

    // info: {
    //   normal: {
    //     enabled: 'bg-primary text-white',
    //     disabled: 'text-gray-700 bg-gray-500',
    //   },
    //   inverted: {
    //     enabled: 'bg-white text-primary',
    //     disabled: 'bg-white text-primary opacity-75',
    //   },
    // },
  }

  if (isExternalLink) {
    ;(rest as any).href = rest.to
    ;(rest as any).target = '_blank'
  }

  return (
    <Render
      className={cc([
        'inline-block tracking-wide leading-none font-bold uppercase text-center',
        {
          'w-full': $fluid,
          'p-3 text-xs': !loading && $size === 'sm',
          'p-4': !loading && $size === 'md',
          'px-2 py-2.5': loading && $size === 'sm',
          'px-5 py-4': loading && $size === 'md',
          'rounded-md': $rounded,
        },
        {
          'bg-white text-primary border-2 border-primary':
            $type === 'secondary' && !$inverted && !disabled,
          'bg-white text-gray-600 border-2 border-gray-600':
            $type === 'secondary' && !$inverted && disabled,
          'bg-white text-white border-2 border-white':
            $type === 'secondary' && $inverted && !disabled,
          'bg-white text-gray-300 border-2 border-gray-300 opacity-75':
            $type === 'secondary' && $inverted && disabled,
        },
        $type &&
          classMap[$type][$inverted ? 'inverted' : 'normal'][
            disabled ? 'disabled' : 'enabled'
          ],

        // custom classes
        className,
      ])}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <LoadingSpinner
          $size={$size === 'sm' ? 'xs' : 'sm'}
          $color={$loadingColor || 'white'}
          $type="dots"
        />
      ) : (
        children
      )}
    </Render>
  )
}

Button.defaultProps = {
  $type: 'primary',
  $size: 'md',
  $rounded: true,
}

export default Button
