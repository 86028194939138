import { MAX_REPAIRABLE_SCORE, SCORE_STATUS } from 'config'

const getStatus = (n: number) => {
  if (n >= MAX_REPAIRABLE_SCORE) {
    return SCORE_STATUS.excellent
  } else if (n >= 690) {
    return SCORE_STATUS.good
  } else if (n >= 630) {
    return SCORE_STATUS.decent
  } else {
    return SCORE_STATUS.poor
  }
}

export default getStatus
