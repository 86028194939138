import Container from 'components/Container'
import ActionsList from 'components/Container/ActionsList'
import DataCard from 'components/DataCard'
import ItemsBox from 'components/ItemsBox'
import ScoreWheel from 'components/ScoreWheel'
import Title from 'components/Title'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'
import DashBoardHero from 'components/DashboardHero'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useClient } from 'urql'
import { WithAside } from 'hocs/withAside'
import Media from 'react-media'
import VideoPlayer from 'components/VideoPlayer'
import {
  CountCreditItemsDocument,
  Bureaus_Enum,
  GetHomePageInformationQuery,
  PersonalDocumentType_Enum,
  useGetHomePageInformationQuery,
} from 'generated/graphql'
import Loading from 'components/Loading'
import getFullName from 'utils/getFullName'
import { useNavigate } from 'react-router-dom'
import ErrorMessage from 'components/ErrorMessage'
import HeroButtons from 'components/HeroButtons'
import { MIN_SCORE } from 'config'
import CalendarComponent from 'components/CalendarComponent'
import Button from 'components/Button'

function HomePage() {
  const [{ data, fetching, error }] = useGetHomePageInformationQuery()
  const [margin, setMargin] = useState(0)
  const MARGIN_MAX_DESKTOP = 470
  const MARGIN_MIN_DESKTOP = 100
  const MIN_DESKTOP_WIDTH = 767
  const MARGIN_MAX_MOBILE = 500
  const MARGIN_MIN_MOBILE = 0

  useLayoutEffect(() => {
    const updateMargin = () => {
      let newMargin: number
      if (window.innerWidth > MIN_DESKTOP_WIDTH) {
        newMargin = window.innerWidth * 0.29
        newMargin <= MARGIN_MAX_DESKTOP
          ? newMargin >= MARGIN_MIN_DESKTOP
            ? setMargin(newMargin)
            : setMargin(MARGIN_MIN_DESKTOP)
          : setMargin(MARGIN_MAX_DESKTOP)
      } else {
        newMargin = window.innerWidth * 0.65
        newMargin <= MARGIN_MAX_MOBILE
          ? newMargin >= MARGIN_MIN_MOBILE
            ? setMargin(newMargin)
            : setMargin(MARGIN_MIN_MOBILE)
          : setMargin(MARGIN_MAX_MOBILE)
      }
    }
    window.addEventListener('resize', updateMargin)
    updateMargin()
    return () => window.removeEventListener('resize', updateMargin)
  })

  if (fetching) {
    return <Loading />
  }

  if (error?.message) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data || !data.UserProfile || !data.UserProfile) {
    return null
  }

  return (
    <div className="flex flex-col max-w-screen-2xl mx-auto w-full">
      <div className="relative w-full ">
        <div className="absolute z-0 w-full mx-auto">
          <DashBoardHero />
        </div>
      </div>
      <div style={{ marginTop: margin }}>
        <Container className="relative">
          <WithAside
            Aside={() => (
              <div className="mt-8 py-5 space-y-4 w-full">
                <CreditScore
                  data={data.CreditScore}
                  avg={data.CreditScore_aggregate.aggregate?.avg?.value || 0}
                />
                <div className="mx-auto">
                  <CalendarComponent items={data.CreditItem} />
                </div>
              </div>
            )}
            position="right"
          >
            <div className="py-5">
              <div className="bg-white py-4 px-4 mt-4  rounded-lg shadow-md flex justify-center flex-col gap-8 relative md:hidden">
                <div className="flex justify-evenly gap-4">
                  <Media query={{ maxWidth: 442 }}>
                    {(matches) => (
                      <>
                        <HeroButtons $fluid $size={matches ? 'sm' : 'md'} />
                      </>
                    )}
                  </Media>
                </div>
              </div>
              {/* Credit Score  */}
              {/* 
                Note can't use tailwind nor css style directly. When using any of those
                causes the bar in the aside CreditScore component to dissapear     
            */}
              <div>
                <Media
                  query={{
                    maxWidth: 767,
                  }}
                >
                  {(matches) => (
                    <>
                      {matches && (
                        <div className="my-8 md:hidden">
                          <CreditScore
                            size={'large'}
                            data={data.CreditScore}
                            avg={
                              data.CreditScore_aggregate.aggregate?.avg
                                ?.value || 0
                            }
                          />
                        </div>
                      )}
                    </>
                  )}
                </Media>
              </div>

              {/* Actions List */}
              <div className="my-8">
                <div className="bg-white shadow-sm rounded-lg p-2">
                  <Title title="Introducing MyselfCredit.com" />
                  <div className="overflow-hidden rounded-md">
                    <VideoPlayer url={`https://youtu.be/Ru_pmPktzo0`} />
                  </div>
                </div>
              </div>

              <ActionsList />

              {/* Credit Tracker */}
              <div className="my-5">
                <CreditTracker />
              </div>

              {/* Items */}
              {data.CreditItem.length > 0 && (
                <div className="pt-5">
                  <Title title="Dispute Tracker" />
                  <div className="mt-3">
                    <ItemsBox items={data.CreditItem} />
                  </div>
                </div>
              )}

              {/* User Profile */}
              <div className="mt-8">
                <MyProfile
                  userProfile={data.UserProfile}
                  documents={data.PersonalDocument}
                />
              </div>
            </div>
          </WithAside>
        </Container>
      </div>
    </div>
  )
}

const CreditItem = ({ value, title }: { value: number; title: string }) => {
  return (
    <div className="text-center">
      <h1 className="text-gray-600 text-4xl">{value || '000'}</h1>
      <h2 className="text-gray-500 text-sm">{title}</h2>
    </div>
  )
}

const CreditScore = ({
  data,
  avg,
  size,
}: {
  data: GetHomePageInformationQuery['CreditScore']
  avg: number
  size?: 'medium' | 'large' | 'small' | 'extra_large'
}) => {
  const navigation = useNavigate()
  const experian = data.find(
    (score) => score.bureau === Bureaus_Enum.Experian,
  )?.value
  const transUnion = data.find(
    (score) => score.bureau === Bureaus_Enum.TransUnion,
  )?.value
  const equifax = data.find(
    (score) => score.bureau === Bureaus_Enum.Equifax,
  )?.value

  return (
    <div className="bg-white py-8 px-4 rounded-lg shadow-md flex justify-center flex-col gap-8 relative ">
      <div className="absolute top-3 right-3">
        <AddButton
          onCLick={() => {
            navigation('/credit-score')
          }}
        />
      </div>
      <div className="mx-auto">
        <ScoreWheel
          $size={size || 'large'}
          score={Math.floor(avg / 10) * 10 || MIN_SCORE}
          disabled
          $trackColor={'#fff'}
        />
      </div>
      <div className="flex justify-evenly">
        <CreditItem value={experian} title="Experian" />
        <CreditItem value={transUnion} title="TransUnion" />
        <CreditItem value={equifax} title="Equifax" />
      </div>

      <Button $fluid to="/credit-score/simulator">
        Credit Score Simulator
      </Button>
    </div>
  )
}

const CreditTracker = () => {
  const [unsolvedItems, setUnsolvedItems] = useState(0)
  const [solvedItems, setSolvedItems] = useState(0)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const client = useClient()

  const countItems = async () => {
    const creditItems = await client.query(CountCreditItemsDocument).toPromise()

    if (creditItems.error) {
      setError(creditItems.error?.message || '')
      return
    }

    setUnsolvedItems(creditItems.data.unsolvedItems.aggregate?.count)
    setSolvedItems(creditItems.data.sovledItems.aggregate?.count)
  }

  useEffect(() => {
    setLoading(true)
    countItems()
    setLoading(false)
  }, [])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage>{error}</ErrorMessage>
  }

  return (
    <div>
      <Title title="Credit Tracker" />
      <div className="flex gap-3 mt-3">
        <DataCard data={unsolvedItems} name="Items In Progress" />
        <DataCard data={solvedItems} name="Items Fixed" />
      </div>
    </div>
  )
}

const ProfileItem = ({
  value,
  title,
  to,
}: {
  value: string
  title: string
  to: string
}) => {
  const navigation = useNavigate()
  return (
    <div className="flex items-center">
      <div className={!value ? 'text-gray-600' : ''}>{title}</div>
      <div className="ml-auto">
        {value ? (
          <div className="bg-green-500 p-2 rounded-full ">
            <IconCheck className="fill-white" width={15} />
          </div>
        ) : (
          <AddButton onCLick={() => navigation(to)} />
        )}
      </div>
    </div>
  )
}

const MyProfile = ({
  userProfile,
  documents,
}: {
  userProfile: GetHomePageInformationQuery['UserProfile']
  documents: GetHomePageInformationQuery['PersonalDocument']
}) => {
  // profile information
  const profile = userProfile[0]

  // personal documents information
  const socialSecurityCard = documents.find(
    (document) => document.type === PersonalDocumentType_Enum.Ssc,
  )?.id
  const driverLicense = documents.find(
    (document) => document.type === PersonalDocumentType_Enum.DriverLicense,
  )?.id
  const proofAddress = documents.find(
    (document) => document.type === PersonalDocumentType_Enum.Address,
  )?.id

  return (
    <div>
      <Title title="My Profile" />
      <div className="bg-white border border-gray-400 rounded-lg overflow-hidden w-full mt-3">
        <div className="p-4 ">
          <ProfileItem
            title="Full Name"
            value={getFullName(profile.first_name, profile.last_name)}
            to={'/my-account'}
          />
          <hr className="border-gray-500 my-3" />
          <ProfileItem
            title="Email"
            value={profile.User.email}
            to={'/my-account'}
          />
          <hr className="border-gray-500 my-3" />
          <ProfileItem
            title="Address"
            value={profile.UserAddress?.id || ''}
            to={'/my-account'}
          />
          <hr className="border-gray-500 my-3" />
          <ProfileItem
            title="Driver Liceense of ID"
            value={driverLicense || ''}
            to={'/my-documents'}
          />
          <hr className="border-gray-500 my-3" />
          <ProfileItem
            title="Social Security Card"
            value={socialSecurityCard || ''}
            to={'/my-documents'}
          />
          <hr className="border-gray-500 my-3" />
          <ProfileItem
            title="Proof of Address"
            value={proofAddress || ''}
            to={'/my-documents'}
          />
        </div>
        <div className="bg-primary to-secondary h-1" />
      </div>
    </div>
  )
}

const AddButton = ({ onCLick }: { onCLick?: () => void }) => {
  return (
    <button
      className="border-gray-600 border w-8 h-8 rounded-full text-xl text-gray-600 font-bold"
      onClick={(e) => {
        onCLick && onCLick()
      }}
    >
      +
    </button>
  )
}

export default HomePage
