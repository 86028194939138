import { FC } from 'react'
import Container from 'components/Container'

type Options = {
  leftAndRight?: boolean
  topAndBottom?: boolean
  containerClass?: string
}

function withContainer<Props = unknown>(Component: FC<any>, opts?: Options) {
  return (props: Props) => {
    return (
      <Container
        leftAndRight={opts?.leftAndRight || false}
        topAndBottom={opts?.topAndBottom}
        className={opts?.containerClass}
      >
        <Component {...props} />
      </Container>
    )
  }
}

export default withContainer
