import cc from 'classcat'
import Title from 'components/Title'
import withContainer from 'hocs/withContainer'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import { useState } from 'react'
import BackgroundImage from 'components/BackgroundImage'
import PlaceholderAvatar from 'assets/avatar-profile.svg'
import {
  MarkNotificationAsSeenDocument,
  MarkNotificationAsSeenMutation,
  useGetNotificationsSubscription,
} from 'generated/graphql'
import { LoadingSpinner } from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { useClient } from 'urql'
import NoResultMessage from 'components/NoResultMessage'
import { useAuth } from 'clients/auth.client'

dayjs.extend(calendar)

const Notifications = () => {
  return (
    <div className="max-w-2xl mx-auto">
      <div className="pb-5 px-4 pt-5">
        <Title
          title="Notifications"
          subtitle="See your notifiactions and keep up to date"
          $align="left"
        />
      </div>
      <NotificationsList />
    </div>
  )
}

export default withContainer(Notifications)

const NotificationsList = () => {
  const [{ fetching, error, data }] = useGetNotificationsSubscription({
    variables: {
      offset: 0,
      limit: 20,
    },
  })

  if (fetching) {
    return (
      <div className="text-center p-4">
        <LoadingSpinner $size="md" $color="primary" $type="logo" />
      </div>
    )
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return (
      <ErrorMessage>
        Something went wrong. Please try again. If the error persists, please
        contact support.
      </ErrorMessage>
    )
  }

  if (!data.Notification || data.Notification.length === 0) {
    return (
      <div className="py-20 bg-white">
        <NoResultMessage message="No notifications yet" />
      </div>
    )
  }

  const { Notification } = data

  return (
    <ul className="bg-white">
      {Notification.map((notification) => (
        <NotificationItem
          key={`tab-notifications-item-${notification.id}`}
          data={notification}
          $showAvatar
        />
      ))}
    </ul>
  )
}

type NotificationItemProps = any

const NotificationItem = ({
  data: { id, seen, title, message, created_at, metadata },
  $showAvatar,
}: NotificationItemProps) => {
  const [seenState, setSeenState] = useState(seen)
  const client = useClient()

  return (
    <li
      key={`tab-notifications-item-${id}`}
      className={cc([
        {
          'bg-primary bg-opacity-5': !seenState,
        },
      ])}
    >
      <button
        className="flex border-b border-gray-400 text-left w-full p-5"
        onClick={() => {
          console.log('--id', id)
          console.log('--title', title)
          if (!seenState) {
            client
              .mutation<MarkNotificationAsSeenMutation>(
                MarkNotificationAsSeenDocument,
                {
                  id,
                  seen: new Date().toISOString(),
                },
              )
              .toPromise()
              .then()
            setSeenState(true)
          }

          // TODO: Use an ID instead
          if (metadata?.id === 'firstRound' || title === 'First Round') {
            window.location.href = '/credit-items'
          }
        }}
      >
        {$showAvatar && (
          <div className="min-w-11">
            <NotificationPhoto />
          </div>
        )}
        <div className="space-y-1 pl-4 w-full">
          {title && <div className="font-bold">{title}</div>}
          <p className="text-gray-800">{message}</p>
          <p className="text-xs text-gray-600">
            {dayjs(created_at).calendar()}
          </p>
        </div>
      </button>
    </li>
  )
}

const NotificationPhoto = () => {
  const { account } = useAuth()

  return (
    <BackgroundImage
      image={account?.picture || PlaceholderAvatar}
      className="rounded-full mr-4 "
      width={44}
    />
  )
}
