import { useAuth } from 'clients/auth.client'
import Container from 'components/Container'
import { LoadingSpinner } from 'components/Loading'
import SimpleQuery from 'components/SimpleQuery'
import { PaymentStatusDocument, PaymentStatusQuery } from 'generated/graphql'
import { useEffect } from 'react'

const PaymentCheck = () => {
  return (
    <Container topAndBottom>
      <SimpleQuery query={PaymentStatusDocument} Success={ShowPaymentStatus} />
    </Container>
  )
}

export default PaymentCheck

const ShowPaymentStatus = ({ data }: { data: PaymentStatusQuery }) => {
  const { isAuthenticated, account, updateAccount } = useAuth()

  useEffect(() => {
    if (
      isAuthenticated &&
      !account.active &&
      data?.paymentStatus?.status === 'paid'
    ) {
      updateAccount({
        active: true,
      })
      window.location.href = '/'
    }
  }, [])

  if (data?.paymentStatus?.status === 'paid') {
    return (
      <Container topAndBottom>
        <div className="text-center">
          <LoadingSpinner $size="lg" $type="dots" $color="primary" />
        </div>
      </Container>
    )
  }

  return (
    <Container topAndBottom>
      <div className="text-center">
        Payment status: {data?.paymentStatus?.status || 'N/A'}
      </div>
    </Container>
  )
}
