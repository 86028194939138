import Container from 'components/Container'
import Title from 'components/Title'
import VideoPlayer from 'components/VideoPlayer'
import withAsideMenu from 'hocs/withAsideMenu'
import withContainer from 'hocs/withContainer'
import React from 'react'

type TipType = {
  id: number
  title: string
  body: string
  videoUrl?: string
  list?: string[]
}

const tips: TipType[] = [
  {
    id: 1,
    title: 'Request a limit increase on your credit cards',
    body: 'You can request that your credit limit be increased every six months. This helps you lower your utilization rate and increase your credit score.',
    videoUrl: 'https://www.youtube.com/watch?v=Ky_btjTQmWE',
  },
  {
    id: 2,
    title: 'Keep your credit card utilization rate below 30%.',
    body: 'For example, if you have $1,000 in credit cards, your account should reflect a balance of less than $300 at the end of the month. If you have a higher balance, your credit score will be seriously affected.',
    videoUrl: 'https://youtu.be/uekj1HhmHy0',
  },
  {
    id: 3,
    title: 'Do not close old lines of credit that report positively.',
    body: 'A secured credit card or secured personal loans are those that you open with your own money. These are helpful because they do not require credit verification to request them. They report positively every month, and it increases your score. Note: This is not a debit card.',
    videoUrl: 'https://www.youtube.com/watch?v=AZsECJRxsGw',
  },
  {
    id: 4,
    title: 'Do not close old lines of credit that report positively.',
    body: 'Even if you are not using the cards, do not close the accounts that register positively in your credit; these increase your credit history and credit capacity.',
    videoUrl: 'https://youtu.be/afzJIjJ2oG8',
  },
  {
    id: 5,
    title:
      'Ask a family member or friend to add you as an authorized user on their credit cards.',
    body: 'By being added as an authorized user under someone who has a good credit history, all that good history will be reflected in your credit report and will increase your score significantly.',
    videoUrl: 'https://youtu.be/ISldzSn_Xx0',
  },
  {
    id: 6,
    title: 'You must keep your credit free of negative accounts.',
    body: 'It is very important and of great impact on your credit that it is clean. That is, it does not have derogatory marks, such as:',
    list: [
      'Accounts in collection',
      'Late payments',
      'Medical bills',
      'Car repossessions',
      'Multiple inquiries',
    ],
    videoUrl: 'https://youtu.be/VtD9Oy3xho0',
  },
]
function TipsPage() {
  return (
    <Container>
      <div className="max-w-2xl mx-auto">
        <div className="pb-5 pt-5">
          <Title
            $align="left"
            title="Credit Secrets"
            subtitle="To increase your credit score quickly, you must follow these recommendations."
          />
        </div>
        <div className="space-y-4">
          {tips.map((tip, i) => {
            return (
              <div className="pb-4" key={tip.id}>
                {tip.videoUrl && (
                  <div className="bg-dark">
                    <VideoPlayer url={tip.videoUrl} />
                  </div>
                )}
                <div>{`Secret #${i + 1}`}</div>
                <div className="font-bold mt-1 mb-4 ">{tip.title}</div>
                <div className="text-gray-600 my-4">{tip.body}</div>
                {tip.list && (
                  <ul className="list-disc list-inside mb-4">
                    {tip.list.map((item) => (
                      <li>{item}</li>
                    ))}
                  </ul>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default withContainer(withAsideMenu(TipsPage))
