import cc from 'classcat'
import { FC, useState, useLayoutEffect } from 'react'

type Options = {
  position: 'left' | 'right'
  asideClass?: string
  staticPosition?: boolean //This when is set to true will prevent the ImageViewer bug
}

export const WithAside = ({
  Aside,
  children,
  position,
  asideClass,
  staticPosition,
}: Options & {
  Aside: FC<any>
  children: any
}) => {
  const [hasHeader, setHasHeader] = useState(false)
  const asideElement = (
    <div className={'hidden md:block md:w-1/3'}>
      <div
        className={cc([
          asideClass,
          { sticky: !staticPosition },
          {
            'top-0': !hasHeader,
            'top-14': hasHeader,
          },
        ])}
      >
        <Aside />
      </div>
    </div>
  )

  useLayoutEffect(() => {
    if (document.querySelector('[data-testid="page-header"]')) {
      setHasHeader(true)
    }
  }, [])

  return (
    <>
      <div className="flex flex-row flex-grow w-full justify-center">
        {position === 'left' && asideElement}

        <div className="w-full md:w-2/3 md:px-4">{children}</div>

        {position === 'right' && asideElement}
      </div>
    </>
  )
}

WithAside.defaultProps = {
  position: 'left',
}

function withAside<Props = unknown>(
  Page: FC<any>,
  Aside: FC<any>,
  opts?: Options,
) {
  return (props: Props) => {
    return (
      <WithAside Aside={Aside} {...opts}>
        <Page {...props} />
      </WithAside>
    )
  }
}

export default withAside
