import PlaceholderAvatar from 'assets/avatar-profile.svg'
import BackgroundImage from 'components/BackgroundImage'
import { Link } from 'react-router-dom'

type Props = {
  name: string
  username?: string
  picture?: string
}

const UserCard = ({ picture, name, username }: Props) => {
  return (
    <Link to={'/my-account'} className="flex items-center">
      <BackgroundImage
        width={55}
        height={55}
        image={picture || PlaceholderAvatar}
        className="rounded-full"
      />

      <div className="ml-3">
        <div className="font-bold text-lg leading-none">{name}</div>
        <div className="text-gray-500 text-sm">{username}</div>
      </div>
    </Link>
  )
}

export default UserCard
