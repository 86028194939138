import cc from 'classcat'
import { ReactComponent as IconArrow } from 'assets/icons/arrow-payment.svg'
import {
  ReactNode,
  forwardRef,
  useEffect,
  useState,
  DetailedHTMLProps,
  SelectHTMLAttributes,
} from 'react'
import { LoadingSpinner } from 'components/Loading'

type Props = DetailedHTMLProps<
  SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  children: ReactNode
  subtext?: string
  subtextAlign?: 'left' | 'center' | 'right'
  error?: string
  label?: string
  $disableLabel?: boolean
  placeholder?: string
  $type?: 'field' | 'normal'
  loading: boolean
}

const FormDropDown = forwardRef<any, any>(
  (
    {
      children,
      placeholder,
      subtext,
      subtextAlign,
      error,
      label,
      $disableLabel,
      $type,
      loading,
      ...nativeAttrs
    }: Props,
    ref,
  ) => {
    const [element, setElement] = useState<any>(null)
    const [hasValue, setHasValue] = useState(false)

    useEffect(() => {
      setHasValue(!!element?.value)
    }, [element])

    return (
      <>
        <div className="relative">
          {!$disableLabel && hasValue && (
            <label
              htmlFor={nativeAttrs.name}
              className="text-gray-700 text-xs absolute bottom-full"
            >
              {label || placeholder}
            </label>
          )}
          <select
            {...nativeAttrs}
            className={cc([
              'w-full rounded-md border py-2 px-3 appearance-none',
              {
                'bg-white placeholder-gray-600 border-gray-600':
                  !nativeAttrs.disabled && $type === 'field' && !loading,
                'bg-gray-400 text-gray-700 border-gray-500':
                  (nativeAttrs.disabled && $type === 'field') || loading,
                'bg-gray-100 border-none ': $type === 'normal',
              },
            ])}
            ref={(el) => {
              setElement(el)
              return (ref as any)?.(el)
            }}
            onChange={(e) => {
              nativeAttrs.onChange?.(e)
              setHasValue(!!e.target.value)
            }}
            disabled={nativeAttrs.disabled || loading}
          >
            {placeholder && (
              <>
                <option value="">{placeholder}</option>
                <option value="">--------------</option>
              </>
            )}
            {children}
          </select>

          <div>
            <IconArrow
              width={8}
              className={cc([
                'transform rotate-90 absolute right-4 top-3 mt-1',
                $type === 'field' ? 'fill-gray-500' : undefined,
              ])}
            />
          </div>
          {loading && (
            <div className="absolute top-2 w-full flex items-center w-full justify-center">
              <div className="">
                <LoadingSpinner $size={'sm'} $color={'white'} $type="dots" />
              </div>
            </div>
          )}
        </div>
        {subtext && (
          <div
            className={cc([
              'text-gray-500 text-xs pt-1',
              {
                'text-left': subtextAlign === 'left',
                'text-center': subtextAlign === 'center',
                'text-right': subtextAlign === 'right',
              },
            ])}
          >
            {subtext}
          </div>
        )}
        {error && <div className="text-action-fail text-sm pt-1">{error}</div>}
      </>
    )
  },
)

FormDropDown.defaultProps = {
  $type: 'field',
}

export default FormDropDown
