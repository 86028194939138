import { useAuth } from 'clients/auth.client'
import Container from 'components/Container'
import LoginSignup from 'components/LoginSignup'
import Redirect from 'components/Redirect'

const Signup = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <Container leftAndRight={false}>
      <div className="max-w-xl mx-auto py-10 ">
        <LoginSignup initialScreen="signup" />
      </div>
    </Container>
  )
}

export default Signup
