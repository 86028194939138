import Button from 'components/Button'
import Container from 'components/Container'
import ScoreWheel from 'components/ScoreWheel'
import Title from 'components/Title'
import Media from 'react-media'
import { useStepper } from 'Stepper'
import cc from 'classcat'

function FinalStep() {
  const { getDefault, back } = useStepper()
  const currentScore = getDefault('currentScore')
  const { simulatedScore } = getDefault('recommendations')

  return (
    <Container>
      <div>
        <Title title="By applying these strategies, you can reach your credit goals." />

        <Media query={{ maxWidth: 442 }}>
          {(matches) => (
            <>
              <div
                className={cc([
                  'flex gap-4 md:gap-20 justify-center',
                  { 'flex-col': matches },
                ])}
              >
                <div className="text-center space-y-4 flex flex-col">
                  <div>
                    <ScoreWheel disabled score={currentScore} $size="large" />
                  </div>
                  <div
                    className={cc([
                      'text-xl font-bold',
                      { 'order-first pb-4': matches },
                    ])}
                  >
                    Before
                  </div>
                </div>
                <div className="text-center space-y-4 flex flex-col">
                  <div>
                    <ScoreWheel disabled score={simulatedScore} $size="large" />
                  </div>

                  <div className="text-xl font-bold">After</div>
                </div>
              </div>

              <div className="gap-4 py-7 grid grid-cols-2">
                <Button $fluid onClick={() => back()} $type="secondary">
                  Back
                </Button>
                <Button $fluid to="/credit-items">
                  Create credit items
                </Button>
              </div>
            </>
          )}
        </Media>
      </div>
    </Container>
  )
}

export default FinalStep
