import React, { useLayoutEffect, useRef, useState } from 'react'
import cc from 'classcat'
import heroBannerDesktop from 'assets/cr-heroe-desktop.jpg'
import heroBannerMobile from 'assets/cr-heroe-mobile.jpg'
import styles from './index.module.scss'
import { useAuth } from 'clients/auth.client'
import Media from 'react-media'
import HeroButtons from 'components/HeroButtons'

function DashBoardHero() {
  const [textTop, setTextTop] = useState(0)
  const { account } = useAuth()

  const imageRef = useRef<HTMLImageElement>(null)
  const getNewTopPosition = (imageHeight: number) => {
    let newTop: number
    if (window.innerWidth >= 768) {
      newTop = imageHeight * 0.3
      return newTop
    } else {
      newTop =
        imageHeight *
        (window.innerWidth >= 449 ? 0.6 : window.innerWidth >= 354 ? 0.4 : 0.2)
      return newTop
    }
  }

  const updateTextTop = () => {
    if (imageRef.current) {
      const imageHeight = imageRef.current.clientHeight
      setTextTop(getNewTopPosition(imageHeight))
    }
  }

  const onImageLoaded = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setTextTop(getNewTopPosition(e.currentTarget.clientHeight))
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateTextTop)
    updateTextTop()
    return () => window.removeEventListener('resize', updateTextTop)
  })

  return (
    <div className="max-w-hero mx-auto relative">
      {/* Image */}
      <Media query={{ maxWidth: 768 }}>
        {(matches) => (
          <>
            {matches && (
              <div
                className={cc([
                  styles.imageDesktop,
                  'block md:hidden overflow-hidden',
                ])}
              >
                <img
                  src={heroBannerMobile}
                  alt="placeholder"
                  ref={imageRef}
                  onLoad={onImageLoaded}
                />
              </div>
            )}
            {!matches && (
              <div
                className={cc([
                  styles.imageDesktop,
                  'hidden md:block overflow-hidden overflow-hidden',
                ])}
              >
                <img
                  src={heroBannerDesktop}
                  alt="placeholder"
                  ref={imageRef}
                  onLoad={onImageLoaded}
                />
              </div>
            )}
          </>
        )}
      </Media>

      {/* Text */}
      <div className="w-full h-full bg-gradient-to-t from-black to-transparent absolute opacity-20 top-0"></div>
      <div
        className="absolute pl-5 pr-2 md:pl-20 text-white"
        style={{ top: textTop }}
      >
        <h1 className="text-3xl font-bold">
          Hi, {account?.firstName},<br /> Welcome to your future credit
        </h1>
        <h2 className="mt-2 mb-4">
          Now you have the tools to improve your credit.
        </h2>
        <div className="gap-4 md:flex hidden">
          <HeroButtons />
        </div>
      </div>
    </div>
  )
}

export default DashBoardHero
