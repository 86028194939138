import Container from 'components/Container'
import ScoreWheel from 'components/ScoreWheel'
import Title from 'components/Title'
import { DEFAULT_SCORE } from 'config'
import React, { useState } from 'react'
import { Navigator, useStepper } from 'Stepper'

function CurrentScore() {
  const { getDefault } = useStepper()
  const [score, setScore] = useState(
    getDefault('currentScore') || DEFAULT_SCORE,
  )

  return (
    <Container>
      <div className="max-w-2xl mx-auto text-center">
        <Title
          title={'What is your credit score?'}
          subtitle="Move the circle to select your score and receive personalized
            recommendations."
        />
        <div className="text-center py-5">
          <ScoreWheel
            score={score}
            onChange={setScore}
            $size="extra_large"
            $trackColor={'#fff'}
          />
        </div>
        <div className="text-center text-xs text-gray-500">
          Move the circle to adjust the credit score.
        </div>
        <Navigator saveDataHandler={() => score} />
      </div>
    </Container>
  )
}

export default CurrentScore
