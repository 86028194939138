export const getEnv = (key: string, safe?: boolean) => {
  const value =
    process.env[key.toUpperCase()] ||
    process.env[`REACT_APP_${key.toUpperCase()}`]

  if (!safe && value == null) {
    throw new Error(`Missing required environment variable ${key}`)
  }

  return value
}

export const getAllEnvs = () => process.env
