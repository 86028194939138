import { ReactComponent as IconArrow } from 'assets/icons/arrow-payment.svg'
import {
  format,
  parse,
  startOfToday,
  eachDayOfInterval,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  isToday,
  isSameMonth,
  isEqual,
  add,
  isSameDay,
  parseISO,
} from 'date-fns'
import React, { Fragment, useState } from 'react'
import cc from 'classcat'
import {
  CreditItemStatus_Enum,
  GetHomePageInformationQuery,
  LetterType_Enum,
} from 'generated/graphql'
import { useToast } from 'components/ToastMessage'
import dayjs from 'dayjs'
import CreditItemStatus from 'components/CreditItemStatus'
import { useNavigate } from 'react-router-dom'
import Button from 'components/Button'

type Props = {
  items: GetHomePageInformationQuery['CreditItem']
}

function CalendarComponent({ items }: Props) {
  const toast = useToast()
  let today = startOfToday()
  const [selectedDay, setSelectedDay] = useState(today)
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))
  let firstDayCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date())
  let days = eachDayOfInterval({
    start: startOfWeek(startOfMonth(firstDayCurrentMonth)),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth)),
  })

  const previousMonth = () => {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  const nextMonth = () => {
    const firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, 'MMM-yyyy'))
  }

  return (
    <div className="">
      <div className="max-w-md p-4 mx-auto sm:px-7 md:max-w-4xl md:px-6 bg-white rounded-md border border-gray-400">
        <div className="">
          <div className="">
            <div className="flex items-center">
              <button
                type="button"
                onClick={previousMonth}
                className="-my-1.5 flex flex-none items-center justify-center p-1.5 bg-gray-100 rounded-full"
              >
                <IconArrow className="w-2 transform fill-gray-600 rotate-180" />
              </button>
              <h2 className="text-center font-semibold text-gray-900 mx-auto">
                {format(firstDayCurrentMonth, 'MMMM yyyy')}
              </h2>
              <button
                onClick={nextMonth}
                type="button"
                className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 bg-gray-100 rounded-full"
              >
                <IconArrow className="w-2 transform fill-gray-600 " />
              </button>
            </div>
            <div className="grid grid-cols-7 mt-4 text-xs leading-6 text-center text-gray-800 font-semibold">
              <div>Su</div>
              <div>Mo</div>
              <div>TU</div>
              <div>WE</div>
              <div>TH</div>
              <div>FR</div>
              <div>SA</div>
            </div>
            <div className="grid grid-cols-7 mt-2 text-sm">
              {days.map((day) => {
                const requiresActionItems = items.filter((item) => {
                  const letter = item.Letters[0]
                  const expiresAt = letter?.waiting_expired_at
                  return (
                    isSameDay(parseISO(expiresAt), day) &&
                    letter.type === LetterType_Enum.Letter &&
                    item.status !== CreditItemStatus_Enum.AccountFixed
                  )
                })

                return (
                  <div key={day.toString()} className="py-1.5">
                    <button
                      type="button"
                      onClick={() => {
                        setSelectedDay(day)
                        requiresActionItems.length > 0 &&
                          toast.notify({
                            type: 'warning',
                            preventAutoClose: true,
                            message: () => {
                              return (
                                <div className="flex items-center flex-col gap-6">
                                  <strong className="text-xl">
                                    Next Round: ready to create letters
                                  </strong>
                                  <p className="px-4">
                                    You must have received the Credit Bureaus.
                                    If so, see if the previous round fixed the
                                    accounts you are disputing. If the credit
                                    item is not repaired, you must send the next
                                    round of disputes. See the tutorial page to
                                    get the next steps.
                                  </p>

                                  <RequriesActionItemsList
                                    items={requiresActionItems}
                                  />

                                  <div className="pt-2 flex gap-2">
                                    <Button
                                      to={`/letters`}
                                      $type="primary"
                                      $size="sm"
                                      onClick={() => {
                                        toast.dismiss(0)
                                      }}
                                    >
                                      Create Letters
                                    </Button>
                                    <Button
                                      to={`https://myselfcredit.com/tutorials`}
                                      $type="secondary"
                                      $size="sm"
                                      onClick={() => {
                                        toast.dismiss(0)
                                      }}
                                    >
                                      Watch Tutorials
                                    </Button>
                                  </div>
                                </div>
                              )
                            },
                          })
                      }}
                      className={cc([
                        'mx-auto flex h-8 w-8 items-center justify-center rounded-md',
                        {
                          'hover:bg-gray-500 hover:text-white bg-gray-100':
                            !isEqual(day, selectedDay) &&
                            requiresActionItems.length === 0,
                        },
                        {
                          'text-primary':
                            !isEqual(day, selectedDay) &&
                            isToday(day) &&
                            requiresActionItems.length === 0,
                        },
                        {
                          'text-gray-900':
                            !isEqual(day, selectedDay) &&
                            isSameMonth(day, firstDayCurrentMonth) &&
                            requiresActionItems.length === 0,
                        },
                        {
                          'text-gray-400':
                            !isEqual(day, selectedDay) &&
                            !isSameMonth(day, firstDayCurrentMonth) &&
                            requiresActionItems.length === 0,
                        },
                        {
                          'bg-primary text-white': isEqual(day, selectedDay),
                        },
                        {
                          'bg-green-500 text-white hover:bg-green-700':
                            requiresActionItems.length > 0 &&
                            !isEqual(day, selectedDay),
                        },
                      ])}
                    >
                      <time dateTime={format(day, 'yyyy-MM-dd')}>
                        {format(day, 'd')}
                      </time>
                    </button>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const RequriesActionItemsList = ({
  items,
}: {
  items: GetHomePageInformationQuery['CreditItem']
}) => {
  const toast = useToast()
  const navigation = useNavigate()

  if (!items || items.length === 0) {
    return null
  }

  return (
    <div className="bg-white w-full text-center">
      <div className="px-8">
        {items.map((item, index) => {
          const letter = item.Letters[0]
          const today = dayjs(new Date().toUTCString())
          const expiresAt = letter?.waiting_expired_at
          const difference = expiresAt
            ? today.diff(dayjs(expiresAt), 'days')
            : null

          return (
            <Fragment key={`requires-action-item-card-${index}`}>
              <div
                className="flex space-evenly font-bold hover:cursor-pointer"
                onClick={() => {
                  navigation(`/letters`)
                  toast.dismiss(0)
                }}
              >
                <div className="flex gap-3">
                  <div>{item.account_name}</div>
                  <div className="text-gray-600">|</div>
                  <CreditItemStatus status={item.status} />
                </div>

                {difference !== null && (
                  <div
                    className={cc([
                      'ml-auto text-primary',
                      { 'text-green-600': difference >= 0 },
                      { 'text-red-600': difference < 0 },
                      ``,
                    ])}
                  >{`${difference} ${
                    +difference.toString().replace('-', '') > 1 ? 'days' : 'day'
                  }`}</div>
                )}
              </div>

              {!!item.dispute_round &&
                item.status !== CreditItemStatus_Enum.AccountFixed &&
                item.status !== CreditItemStatus_Enum.AccountDeleted && (
                  <>
                    <div className="text-left text-orange-600 font-bold">{`Round #${item.dispute_round}`}</div>
                  </>
                )}

              {index < items.length - 1 && (
                <hr className="border-gray-500 my-3" />
              )}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default React.memo(CalendarComponent)
