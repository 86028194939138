import React from 'react'
import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import { useAuth } from 'clients/auth.client'
import { useLoginMutation } from 'generated/graphql'
import ErrorMessage from 'components/ErrorMessage'

const validationSchema = yup.object({
  email: yup.string().required().email(),
  password: yup.string().required().min(5),
})

const FormLogin = () => {
  const [loginState, loginMutation] = useLoginMutation()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { errors } = formState
  const { login } = useAuth()

  return (
    <div>
      <div>
        {/* Email & Password */}
        <form
          onSubmit={handleSubmit(async (values) => {
            const { email, password } = values
            const result = await loginMutation({
              email,
              password,
            })

            if (result.data?.login) {
              const {
                id,
                active,
                email,
                firstName,
                lastName,
                token,
                picture,
                address1,
                address2,
                city,
                ssn,
                state,
                zip_code,
                ssn_picture,
                address_picture1,
                address_picture2,
                license_picture,
                birth_date,
              } = result.data.login
              const account = {
                id,
                active,
                email,
                firstName,
                lastName,
                token,
                address1,
                address2,
                city,
                ssn,
                state,
                zip_code,
                ssn_picture,
                address_picture1,
                address_picture2,
                license_picture,
                picture: picture || '',
                birth_date: birth_date || '',
              }

              login(account)
            }
          })}
        >
          <FormGroup>
            <FormField
              type="email"
              placeholder="Email"
              error={errors.email?.message}
              {...register('email')}
            />
            <FormField
              type="password"
              placeholder="Password"
              error={errors.password?.message}
              {...register('password')}
            />
            {loginState.error && (
              <ErrorMessage>
                {loginState.error.message.replace('[GraphQL]', '')}
              </ErrorMessage>
            )}
            <Button
              $fluid
              loading={formState.isSubmitting}
              data-testid="button-login"
            >
              Login
            </Button>
          </FormGroup>
        </form>
      </div>
    </div>
  )
}

export default FormLogin
