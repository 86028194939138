import Header from 'components/Header'
import PageRoutes from 'PageRoutes'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useAuth } from 'clients/auth.client'
import LoginBackground from 'assets/login-background.png'

import 'react-phone-input-2/lib/material.css'
import BackgroundImage from 'components/BackgroundImage'

function App() {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return (
      <div className="h-full w-full">
        <div className="flex flex-col min-h-full w-full grow">
          <Header />
          <main className="grow flex flex-col h-full">
            <PageRoutes />
          </main>
          <Footer />
        </div>
      </div>
    )
  }

  return (
    <div className="fixed inset-0 flex h-full">
      <div className="hidden lg:block max-h-full">
        <BackgroundImage image={LoginBackground} className="w-full h-full" />
      </div>
      <div className="flex flex-col overflow-auto grow w-full h-full sm:px-6">
        <main className="flex grow justify-center items-center">
          <PageRoutes />
        </main>
        <Footer />
      </div>
    </div>
  )
}

const Footer = () => {
  return (
    <footer className="p-4 text-gray-600 text-sm text-center">
      <div>
        2022 All Rights Reserved |{' '}
        <span className="text-primary">myselfcredit.com</span>
      </div>
    </footer>
  )
}

export default App
