export const removeAllEmptySpaces = (value: string) => {
  return value.replace(/ /g, '')
}

export const removeSpecialCharacters = (value: string) => {
  return value.replace(/[^\wÀ-ú\s]/gi, '')
}

export const removeRepeatedEmptySpaces = (value: string) => {
  return value.replace(/\s\s+/g, ' ')
}

export const sanitizeHashtags = (value: string) => {
  return value
    .split(/[ #]+/)
    .filter(Boolean)
    .map((hashtag: string) =>
      hashtag.charAt(0) !== '#' ? `#${hashtag}` : hashtag,
    )
    .join(' ')
}

export const sanitizeTaggedUser = (value: string) => {
  return value
    .split(/[ @]+/)
    .filter(Boolean)
    .map((profile: string) =>
      profile.charAt(0) !== '@' ? `@${profile}` : profile,
    )
    .join(' ')
}

//applies almost every method to the string
const sanitizeData = (value: string) => {
  return removeRepeatedEmptySpaces(removeSpecialCharacters(value)).trim()
}

export default sanitizeData
// À-ú
