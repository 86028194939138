import { useFieldArray, useForm } from 'react-hook-form'
import FormCheckbox from 'forms/FormCheckbox'
import FormDropDown from 'forms/FormDropDown'
import FormGroup from 'components/FormGroup'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Button from 'components/Button'
import {
  Bureaus_Enum,
  Categories_Enum,
  LetterStatus_Enum,
  LetterType_Enum,
  useCreateNewLetterMutation,
  useDeleteLetterMutation,
  useUpdateBureausLettersMutation,
  useUpdateCreditItemMutation,
  useUpdateLetterMutation,
} from 'generated/graphql'
import { useEffect, useState } from 'react'
import { useToast } from 'components/ToastMessage'
import ErrorMessage from 'components/ErrorMessage'
import {
  CUSTOM_FIELDS_VARIABLES,
  equifaxValidator,
  experianValidator,
  FILTEROUT_VARS,
  GENERIC_ERROR_MESSAGE,
  getVariableNameRegex,
  getVariableRegex,
  transUnionValidator,
} from 'globalConstants'
// import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import letters, { header, footer } from 'letters'
import { pdf } from '@react-pdf/renderer'
import PDFDocument from 'components/PDFDocument'
import { useAuth } from 'clients/auth.client'
import { saveAs as saveFileAs } from 'file-saver'
import insertInformationToHtml from 'utils/insertInformationToHtml'
import PDFPreview from 'components/PDFPreview'
import FormField from 'components/FormField'
import { arrayToObject } from 'utils/arrayToObject'
import STATES from 'states.json'
import { useNavigate } from 'react-router-dom'
import patternMatching from 'utils/patternMatching'

type bureausObject = { letter_id: string; value: Bureaus_Enum }

const validationSchema = yup.object({
  name: yup.string().required(),
  rawHtml: yup.string(),
  experian: experianValidator,
  trans_union: transUnionValidator,
  equifax: equifaxValidator,
  extraVariables: yup.array(
    yup.object({ name: yup.string(), value: yup.string() }),
  ),
})

type extraFieldsType = {
  name: string
  value: string
}

type initialValues = {
  name: string
  rawHtml: string
  experian?: string
  trans_union?: string
  equifax?: string
  savedAs: string
  extraFields?: extraFieldsType[]
}

const LetterForm = ({
  initialValues,
  id,
  itemID,
  allowNewLetter,
  accountName,
  accountNumber,
  accountBalance,
  accountCategory,
}: {
  initialValues?: initialValues
  id?: string
  itemID: string
  allowNewLetter?: boolean
  accountName: string
  accountNumber: string
  accountBalance: number
  accountCategory: Categories_Enum
}) => {
  const { account } = useAuth()
  const [createLetterState, createLetter] = useCreateNewLetterMutation()
  const [updateLetterState, updateLetter] = useUpdateLetterMutation()
  const [updateBureausState, updateBureaus] = useUpdateBureausLettersMutation()
  const [updateItemState, updateItem] = useUpdateCreditItemMutation()
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState,
    watch,
    control,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: initialValues?.name || '',
      rawHtml: initialValues?.rawHtml || '',
      experian: initialValues?.experian === Bureaus_Enum.Experian,
      trans_union: initialValues?.trans_union === Bureaus_Enum.TransUnion,
      equifax: initialValues?.equifax === Bureaus_Enum.Equifax,
      extraVariables: [{ name: '', value: '' }],
    },
  })
  const navigation = useNavigate()
  const [saveAs, setSaveAs] = useState<LetterType_Enum>(LetterType_Enum.Letter)
  const { isDirty, errors, isSubmitting } = formState
  const [deleteLetterState, deleteLetter] = useDeleteLetterMutation()
  const toast = useToast()
  // const navigation = useNavigate()
  const watchHtml = watch('rawHtml')
  const watchExperian = watch('experian')
  const watchEquifax = watch('equifax')
  const watchTransUnion = watch('trans_union')
  const watchExtraVariables = watch('extraVariables')

  const userInformation = {
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    city: account?.city || '',
    address1: account?.address1 || '',
    address2: account?.address2 || '',
    ssn: account?.ssn || '',
    zip_code: account?.zip_code || '',
    ssn_picture: account?.ssn_picture || '',
    license_picture: account?.license_picture || '',
    address_picture: account?.address_picture1 || '',
    birthdate: account?.birth_date || '',
    state:
      STATES.states.find((state) => state.stateCode === account?.state)?.name ||
      '',
  }

  const accountInformation = {
    accountName,
    accountNumber,
    accountBalance,
  }

  const selectedBureaus = [
    watchEquifax && Bureaus_Enum.Equifax,
    watchExperian && Bureaus_Enum.Experian,
    watchTransUnion && Bureaus_Enum.TransUnion,
  ].filter(Boolean) as string[]

  const { fields, append } = useFieldArray({ control, name: 'extraVariables' })

  useEffect(() => {
    setValue('extraVariables', [])

    watchHtml
      .match(getVariableRegex)
      ?.filter((variable) => {
        return Object.values(CUSTOM_FIELDS_VARIABLES).includes(
          variable.match(getVariableNameRegex)?.join('') || '',
        )
      })
      .forEach((variable) => {
        const variableName = variable.match(getVariableNameRegex)?.join('')
        const variableValue = initialValues?.extraFields?.find(
          (initialVar) => initialVar.name === variableName,
        )?.value

        if (!FILTEROUT_VARS.find((filter_out) => filter_out === variable)) {
          append({
            name: variableName || '',
            value:
              (getValues('name') === initialValues?.name && variableValue) ||
              '',
          })
        }
      })

    setTimeout(() => {
      window.scroll({ top: 0 })
    })
  }, [watchHtml])

  const deleteLetterHandler = async () => {
    const response = await deleteLetter({ id })
    if (!response.error) {
      toast.notify({
        type: 'success',
        message: 'Your letter was deleted correctly!',
        onClose() {
          navigation('/letters#single-letters')
        },
      })
    } else {
      toast.notify({ type: 'failure', message: GENERIC_ERROR_MESSAGE })
    }
  }

  return (
    <>
      <form
        //TODO: Clean this submit code
        onSubmit={handleSubmit(async (values) => {
          const letterName = values.name.split('-')[0]
          const documents = selectedBureaus.map((bureau) =>
            insertInformationToHtml(
              values.rawHtml,
              userInformation,
              bureau,
              arrayToObject(values.extraVariables),
              accountInformation,
            ),
          )

          if (
            allowNewLetter ||
            (!allowNewLetter && saveAs === LetterType_Enum.Draft) ||
            (!allowNewLetter &&
              initialValues?.savedAs === LetterType_Enum.Letter)
          ) {
            let hasErrors = false
            let letter_id: string

            if (!id && itemID) {
              const result = await createLetter({
                object: {
                  name: letterName,
                  raw_html: values.rawHtml,
                  type: saveAs,
                  item_id: itemID,
                  status:
                    saveAs === LetterType_Enum.Letter
                      ? LetterStatus_Enum.SavedDownloaded
                      : LetterStatus_Enum.SavedDraft,
                  waiting_expired_at:
                    saveAs === LetterType_Enum.Letter
                      ? dayjs(new Date().toISOString()).add(45, 'day')
                      : new Date().toISOString(),
                  extra_fields: values.extraVariables,
                },
              })

              if (result.error) {
                hasErrors = true
              }
              letter_id = result.data?.insert_Letter_one?.id
            } else {
              letter_id = id || ''
              const result = await updateLetter({
                id,
                set: {
                  raw_html: values.rawHtml,
                  name: letterName,
                  status:
                    saveAs === LetterType_Enum.Letter
                      ? LetterStatus_Enum.SavedDownloaded
                      : LetterStatus_Enum.SavedDraft,
                  type: saveAs,
                  extra_fields: values.extraVariables,
                  waiting_expired_at:
                    saveAs === LetterType_Enum.Letter
                      ? dayjs(new Date().toISOString()).add(45, 'day')
                      : new Date().toISOString(),
                },
              })
              if (result.error) {
                hasErrors = true
                console.log('--error', result.error)
              }
            }
            const bureaus = [
              values.equifax && { letter_id, value: Bureaus_Enum.Equifax },
              values.experian && {
                letter_id,
                value: Bureaus_Enum.Experian,
              },
              values.trans_union && {
                letter_id,
                value: Bureaus_Enum.TransUnion,
              },
            ].filter(Boolean) as bureausObject[]

            const bureausResult = await updateBureaus({
              object: bureaus,
              letterId: letter_id,
            })
            if (!hasErrors && saveAs === LetterType_Enum.Letter) {
              const result = await updateItem({
                id: itemID,
                set: {
                  round_expired_at: dayjs(new Date().toISOString()).add(
                    45,
                    'day',
                  ),
                },
              })
              if (result.error) {
                hasErrors = true
              }
            }
            if (!hasErrors && !bureausResult.error) {
              try {
                if (saveAs === LetterType_Enum.Letter) {
                  const blob = await pdf(
                    <PDFDocument
                      htmlDocuments={documents}
                      userInformation={userInformation}
                      selectedBureaus={selectedBureaus}
                    />,
                  ).toBlob()
                  saveAs === LetterType_Enum.Letter &&
                    saveFileAs(blob, `${values.name}-${Date.now().toString()}`)
                }

                toast.notify({
                  type: 'success',
                  message: 'Your letter was sent correctly!',
                  onClose() {
                    navigation('/letters#single-letters')
                  },
                })
              } catch (error) {
                toast.notify({
                  type: 'failure',
                  message: 'Error downloading PDF',
                })
                console.error(error)
              }
            } else {
              toast.notify({ type: 'failure', message: GENERIC_ERROR_MESSAGE })
            }
          } else {
            toast.notify({
              type: 'warning',
              title: "You can't create a new letter right now!",
              message:
                "You can't create a new letter while having an active letter",
            })
          }
        })}
      >
        <FormGroup>
          <div>
            <div className="text-gray-500 mb-4">Letter #1</div>
            <FormDropDown
              {...register('name')}
              placeholder="Choose a letter from the library"
              error={errors.name?.message}
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                const selectedIndex = e.target.selectedIndex - 2
                if (selectedIndex >= 0) {
                  const selectedHtml =
                    header +
                    letters.filter(
                      ({ categories }) =>
                        categories.includes(Categories_Enum.MixedReasons) ||
                        categories.includes(accountCategory),
                    )[selectedIndex].rawHtml +
                    footer
                  setValue('rawHtml', selectedHtml, { shouldDirty: true })
                } else {
                  setValue('rawHtml', '', { shouldDirty: true })
                }
              }}
            >
              {letters
                .filter(
                  ({ categories }) =>
                    categories.includes(Categories_Enum.MixedReasons) ||
                    categories.includes(accountCategory),
                )
                .map((letter, i) => (
                  <option key={`letter-name-option-${i}`}>
                    {[
                      letter.round,
                      letter.categories
                        .map(
                          patternMatching<Categories_Enum, string>([
                            [Categories_Enum.ChargeOff, 'Charge Off'],
                            [Categories_Enum.Collections, 'Collections'],
                            [Categories_Enum.Foreclosure, 'Foreclosure'],
                            [Categories_Enum.LatePayments, 'Late Payments'],
                            [Categories_Enum.Repossession, 'Repossession'],
                            [
                              Categories_Enum.UnknownAccounts,
                              'Unknown Accounts',
                            ],
                            [Categories_Enum.MixedReasons, 'Misc'],
                          ]),
                        )
                        .join(' & '),
                      letter.name,
                    ]
                      .filter(Boolean)
                      .join(' - ')}
                  </option>
                ))}
            </FormDropDown>
          </div>

          {watchHtml && (
            <div className="-m-14">
              <PDFPreview
                htmlString={watchHtml}
                userInformation={userInformation}
                accountInformation={accountInformation}
                extraVariables={arrayToObject(watchExtraVariables)}
              />
            </div>
          )}

          <div className="space-y-3">
            <div className="text-gray-600">Where to dispute?</div>
            <div className="flex gap-4 flex-wrap">
              <FormCheckbox
                {...register('experian')}
                label="Experian"
                defaultChecked={getValues('experian')}
              />
              <FormCheckbox
                {...register('trans_union')}
                label="TransUnion"
                defaultChecked={getValues('trans_union')}
              />
            </div>
            <FormCheckbox
              {...register('equifax')}
              label="Equifax"
              defaultChecked={getValues('equifax')}
            />
          </div>
          {(errors.experian || errors.trans_union || errors.equifax) && (
            <ErrorMessage>
              {errors.experian?.message ||
                errors.trans_union?.message ||
                errors.equifax?.message ||
                ''}
            </ErrorMessage>
          )}
          <div>
            {fields.length > 0 && (
              <>
                <div className="text-sm text-gray-600 my-2">Extra Values</div>
                {fields.map((field, index) => (
                  <div key={field.id} className="py-3.5">
                    <FormField
                      {...register(`extraVariables.${index}.value` as const)}
                      placeholder={`${field.name
                        .match(/[A-Z][a-z]+/g)
                        ?.join(' ')} (Optional)`}
                      error={errors.extraVariables?.[index]?.value?.message}
                      defaultValue={field.value!}
                    />
                  </div>
                ))}
              </>
            )}
          </div>

          {(createLetterState.error ||
            updateBureausState.error ||
            updateLetterState.error ||
            updateItemState.error ||
            deleteLetterState.error) && (
            <ErrorMessage>
              {createLetterState.error?.message ||
                updateBureausState.error?.message ||
                updateLetterState.error?.message ||
                deleteLetterState.error?.message ||
                updateItemState.error?.message ||
                ''}
            </ErrorMessage>
          )}
        </FormGroup>

        <div className="space-y-3">
          <Button
            $fluid
            onClick={() => setSaveAs(LetterType_Enum.Letter)}
            disabled={!isDirty}
            loading={isSubmitting && saveAs === LetterType_Enum.Letter}
          >
            {!initialValues ? 'Create & Download' : 'Update & Download'}
          </Button>
          <Button
            $fluid
            $type="secondary"
            onClick={() => setSaveAs(LetterType_Enum.Draft)}
            disabled={!isDirty}
            loading={isSubmitting && saveAs === LetterType_Enum.Draft}
          >
            Save as draft
          </Button>
          {id && (
            <Button
              $fluid
              type="button"
              $type="tertiary"
              onClick={() => {
                deleteLetterHandler()
              }}
              loading={deleteLetterState.fetching}
            >
              Delete Letter
            </Button>
          )}
        </div>
      </form>
    </>
  )
}

export default LetterForm
