import cc from 'classcat'
import Link from 'components/Link'
import { ReactComponent as IconArrow } from 'assets/icons/arrow-payment.svg'
import useCurrentPath from 'hooks/useCurrentPath'
import useMenu from 'hooks/useMenu'
import Container from 'components/Container'
import { isOfType } from 'utils/types.util'

//Todo: think of a better name
const SettingsMenu = () => {
  const currentPath = useCurrentPath()
  const menu = useMenu()

  return (
    <Container topAndBottom>
      <ul className="h-full bg-white bg-opacity-50 overflow-hidden rounded-lg">
        {menu()
          .filter(
            (link) => !isOfType(link, 'Component') && !Array.isArray(link.to),
          )
          .map((link, index) => (
            <li key={`settings-menu-${index}`}>
              {index > 0 && <hr className="border-gray-400" />}
              {isOfType(link, 'Component') ? (
                <link.Component />
              ) : (
                <Link
                  to={link.to}
                  active={currentPath === link.to}
                  className={cc([
                    'flex items-center justify-between px-6 py-4 text-lg hover:bg-white',
                    {
                      'bg-white border-primary border-l-2 shadow-lg':
                        currentPath.includes(link.to),
                    },
                  ])}
                >
                  <span>{link.text}</span>
                  <IconArrow className="w-2" />
                </Link>
              )}
            </li>
          ))}
      </ul>
    </Container>
  )
}

export default SettingsMenu
