import { Fragment } from 'react'
import cc from 'classcat'
import {
  CreditItemStatus_Enum,
  GetHomePageInformationQuery,
  LetterType_Enum,
} from 'generated/graphql'
import CreditItemStatus from 'components/CreditItemStatus'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

type Props = {
  items: GetHomePageInformationQuery['CreditItem']
}
function ItemsBox({ items }: Props) {
  const navigation = useNavigate()

  if (!items || items.length === 0) {
    return null
  }

  return (
    <div className="bg-white border border-gray-400 rounded-lg overflow-hidden w-full text-center">
      <div className="p-4">
        {items.map((item, index) => {
          const letter = item.Letters[0]
          const today = dayjs(new Date().toUTCString())
          const expiresAt = item.round_expired_at
          const difference = expiresAt
            ? today.diff(dayjs(expiresAt), 'days')
            : null

          return (
            <Fragment key={`item-card-${index}`}>
              <div
                className="text-left font-bold hover:cursor-pointer"
                onClick={() => {
                  switch (item.status) {
                    case CreditItemStatus_Enum.AccountFixed:
                    case CreditItemStatus_Enum.AccountDeleted: {
                      navigation('/credit-items#fixed-items')
                      break
                    }
                    default: {
                      navigation('/letters')
                    }
                  }
                }}
              >
                <div className="flex justify-between">
                  <div data-testid={`dashboard-item-${index}`}>
                    {item.account_name}
                  </div>

                  {difference !== null &&
                    letter?.type === LetterType_Enum.Letter &&
                    item.status !== CreditItemStatus_Enum.AccountFixed &&
                    item.status !== CreditItemStatus_Enum.AccountDeleted && (
                      <div
                        className={cc([
                          'text-primary',
                          { 'text-green-600': difference >= 0 },
                          { 'text-red-600': difference < 0 },
                          ``,
                        ])}
                      >{`${difference} ${
                        +difference.toString().replace('-', '') > 1
                          ? 'days'
                          : 'day'
                      }`}</div>
                    )}
                </div>

                <div className="flex gap-3">
                  {!!item.dispute_round &&
                    item.status !== CreditItemStatus_Enum.AccountFixed &&
                    item.status !== CreditItemStatus_Enum.AccountDeleted && (
                      <>
                        <div className="text-orange-600">{`Round #${item.dispute_round}`}</div>
                        <div className="text-gray-600">|</div>
                      </>
                    )}

                  <CreditItemStatus status={item.status} />

                  {letter?.type === LetterType_Enum.Draft &&
                    item.status !== CreditItemStatus_Enum.AccountFixed && (
                      <div className="text-gray-600">Draft</div>
                    )}
                  {!letter &&
                    item.status !== CreditItemStatus_Enum.AccountFixed && (
                      <button
                        className="border-gray-600 border w-8 h-8 rounded-full text-xl text-gray-600 font-bold"
                        onClick={(e) => {
                          e.stopPropagation()
                          navigation(`letters/new-letter/${item.id}`)
                        }}
                      >
                        +
                      </button>
                    )}
                </div>
              </div>

              {index < items.length - 1 && (
                <hr className="border-gray-500 my-3" />
              )}
            </Fragment>
          )
        })}
      </div>
      <div className="bg-primary to-secondary h-1" />
    </div>
  )
}

export default ItemsBox
