import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useState,
} from 'react'
import cc from 'classcat'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
}

const FormCheckbox = forwardRef(({ label, ...props }: Props, ref: any) => {
  const id = props.id || `checkbox-${props.name}-${props.value}`
  const [isChecked, setIsChecked] = useState(false)

  return (
    <>
      <input
        {...props}
        id={id}
        ref={(element) => {
          setIsChecked(element?.checked || false)
          return ref(element)
        }}
        className="hidden"
        type="checkbox"
        onChange={(e) => {
          setIsChecked(e.target.checked)
          props.onChange?.(e)
        }}
      />

      <label htmlFor={id} className="inline-flex items-center">
        <div
          className={cc([
            'relative min-w-6 block w-6 h-6 border border-gray-500 rounded-md',
            {
              'bg-white cursor-pointer': !props!.disabled,
              'bg-gray-400': props.disabled,
            },
          ])}
        >
          {isChecked && (
            <div
              className="absolute block bg-primary  rounded-sm top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              style={{
                width: '74%',
                height: '74%',
              }}
            >
              <IconCheck className="fill-white p-0.5" />
            </div>
          )}
        </div>
        {label && <div className="pl-2 text-gray-700">{label}</div>}
      </label>
    </>
  )
})

export default FormCheckbox
