import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import cc from 'classcat'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
  $size?: 'sm' | 'lg'
  onChange: (state: boolean) => void
}

const Checkbox = forwardRef(
  ({ checked, id, ref, label, $size, onChange, ...props }: Props) => {
    const [state, setState] = useState(checked || false)

    useEffect(() => {
      setState(checked || false)
    }, [checked])

    return (
      <div
        className="flex items-center cursor-pointer"
        onClick={() => {
          setState(!state)
          if (onChange) onChange(!state)
        }}
      >
        <input
          {...props}
          className="hidden"
          id={id}
          type="checkbox"
          ref={ref}
          name={id}
        />
        <div
          className={cc([
            'rounded-md transition-all  border border-2',
            { 'bg-white': !checked },
            { 'bg-primary': checked },
            { 'border-primary': checked },
            { 'border-gray-600': !checked },
            { 'w-4 h-4': $size === 'sm' },
            { 'w-8 h-8': $size === 'lg' },
          ])}
        >
          {checked && <IconCheck className="fill-white" />}
        </div>
        {label && <div className="pl-2 text-gray-700">{label}</div>}
      </div>
    )
  },
)

Checkbox.defaultProps = {
  $size: 'sm',
}

export default Checkbox
