import React, { useEffect } from 'react'
import { useAuth } from 'clients/auth.client'
import { useNavigate } from 'react-router-dom'
import withContainer from 'hocs/withContainer'

function Logout() {
  const { logout } = useAuth()
  const navigation = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      logout()
      navigation('/')
    }, 1500)
  }, [])

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-25">
      Loading...
    </div>
  )
}

export default withContainer(Logout)
