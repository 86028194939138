import { FC, useEffect } from 'react'
import { useAuth } from 'clients/auth.client'
import LoginSignup from 'components/LoginSignup'
import Container from 'components/Container'
import Payment from 'pages/Payment'

type Props = {
  Component: FC
  withoutActiveSubscription?: boolean
}

const RequiresLogin = ({ Component, withoutActiveSubscription }: Props) => {
  const { isAuthenticated, account } = useAuth()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [Component])

  if (!isAuthenticated) {
    return (
      <Container leftAndRight={false}>
        <div className="max-w-xl mx-auto py-10 ">
          <LoginSignup />
        </div>
      </Container>
    )
  }

  if (!account.active && !withoutActiveSubscription) {
    return <Payment />
  }

  return <Component />
}

export default RequiresLogin
