import {
  createClient,
  Provider,
  dedupExchange,
  fetchExchange,
  errorExchange,
  subscriptionExchange,
} from 'urql'
import { cacheExchange } from '@urql/exchange-graphcache'
import {
  DeletePersonalDocumentMutation,
  GetCreditScoresDocument,
  GetPersonalDocumentsDocument,
  UpdateCreditScoreMutation,
  UploadPersonalDocumentMutation,
} from 'generated/graphql'

import { SubscriptionClient } from 'subscriptions-transport-ws'
import { useAuth } from './auth.client'

const GraphqlClientProvider = ({ children }: any) => {
  const { isAuthenticated, account, logout } = useAuth()

  const cache = cacheExchange({
    keys: {
      CreditItem_aggregate: () => null,
      CreditItem_aggregate_fields: () => null,
    },
    updates: {
      Mutation: {
        delete_PersonalDocument(
          result: DeletePersonalDocumentMutation,
          _args: any,
          cache,
        ) {
          cache.updateQuery({ query: GetPersonalDocumentsDocument }, (data) => {
            if (data && _args.where && Array.isArray(data.PersonalDocument)) {
              return {
                ...data,
                PersonalDocument: data.PersonalDocument.filter(
                  (document: any) =>
                    document.id !==
                    result.delete_PersonalDocument?.returning[0].id,
                ),
              }
            } else {
              return data
            }
          })
        },
        UploadPersonalDocument(
          result: UploadPersonalDocumentMutation,
          _args,
          cache,
        ) {
          cache.updateQuery({ query: GetPersonalDocumentsDocument }, (data) => {
            if (data && Array.isArray(data.PersonalDocument)) {
              let newDocument = result.UploadPersonalDocument
              return {
                ...data,
                PersonalDocument: [
                  {
                    id: newDocument?.documentId,
                    File: {
                      file_type: newDocument?.fileType,
                      id: newDocument?.fileId,
                      url: newDocument?.imageUrl,
                      __typename: 'File',
                    },
                    type: newDocument?.documentType,
                    __typename: 'PersonalDocument',
                  },
                  ...data.PersonalDocument,
                ],
              }
            }
            return data
          })
        },
        insert_CreditScore(
          result: UpdateCreditScoreMutation,
          _args: any,
          cache,
        ) {
          cache.updateQuery({ query: GetCreditScoresDocument }, (data) => {
            if (data && result.insert_CreditScore?.returning) {
              return {
                ...data,
                CreditScore: result.insert_CreditScore?.returning,
              }
            } else {
              return data
            }
          })
        },
      },
    },
  })

  const errorHandler = errorExchange({
    onError: async (error) => {
      if (error.message.includes('JWTExpired')) {
        logout()
      } else {
        console.error('error', `GRAPHQL ERROR "${error.message}"`)
      }
    },
  })

  const subscriptionClient = new SubscriptionClient(
    process.env.REACT_APP_GRAPHQL_WS_URL as string,
    {
      reconnect: true,
      connectionParams: () => {
        if (isAuthenticated) {
          return {
            headers: {
              authorization: `Bearer ${account.token}`,
            },
          }
        }
        return {}
      },
    },
  )

  const client = createClient({
    url: process.env.REACT_APP_GRAPHQL_URL as string,
    requestPolicy: 'cache-and-network', // TODO: Temporary until we work on manual cache updates
    fetchOptions: () => {
      if (isAuthenticated) {
        return {
          headers: {
            authorization: `Bearer ${account.token}`,
          },
        }
      }

      return {}
    },
    exchanges: [
      dedupExchange,
      errorHandler,
      cache as any,
      fetchExchange,
      subscriptionExchange({
        forwardSubscription: (operation) =>
          subscriptionClient.request(operation) as any,
      }),
    ],
  })

  // TODO: Handle errors + jwt expiration

  return <Provider value={client}>{children}</Provider>
}

export default GraphqlClientProvider
