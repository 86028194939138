import { Categories_Enum } from 'generated/graphql'

const letters = [
  {
    round: '1st Round',
    categories: [Categories_Enum.Repossession],
    name: 'Option 1',
    rawHtml: `<p class="c2">
    <span class="c1"
      >I am writing to request the investigation of a repossession that appears on
      my credit report. </span></p>
  <C></C>
  <p class="c2"><span class="c1"></span></p>
  <C></C>
  <p class="c2">
    <span class="c1"
      >This repossession is false. I am requesting that the item be permanently
      removed to correct the information on my credit file.</span
    >
  </p>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <C></C>
  <p class="c2"><span class="c1"></span></p>
  <C></C>
  <p class="c3"><span class="c13">[AdditionalComments]</span></p>
  <C></C>
  <p class="c2"><span class="c1"></span></p>
  <C></C>

  <p class="c2">
    <span class="c1"
      >Please reinvestigate this repossession and delete it as soon as possible. I
      expect you to delete these negative accounts and provided me with an updated
      report by postal mail within 30 calendar days in accordance with the FCRA.
    </span>
  </p>
  <C></C>
  <p class="c2"><span class="c1"></span></p>
  <C></C>
  <p class="c2">
    <span class="c1">Thank you for your attention to this matter.</span>
  </p>`,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.Repossession],
    name: 'Option 2',
    rawHtml: `<p class="c1">
    <span class="c0"
      >This letter is a formal statement notifying the necessary parties that the
      above referenced account is hereby disputed. The laws of the state of
      [CustomerState] and UCC 9.506 § along with [CustomerState] Sales Agreements
      and Retail Installment Sales Agreements clearly state that a deficiency
      balance cannot be claimed if the required notices were not properly and
      timely given. The consumer must be permitted a certain time to cure and/or
      redeem which is not available if the proper notices are not submitted.
    </span>
  </p>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1">
    <span class="c0">[AdditionalComments] </span>
  </p>
  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1">
    <span class="c0"
      >Please provide copies of the legal notices sent to me along with proof of
      the resale of the vehicle was completed in a commercially reasonable manner
      as required by law.
    </span>
  </p>
  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1">
    <span class="c0"
      >If you are unable to provide the required proof within 15 days of receipt
      of this letter, the alleged claim of deficiency will be considered null and
      void. After the 15 days have passed, any activity including but not limited
      to credit reporting of this invalid claim will be considered an infraction
      of the Fair Credit Reporting Act, and the Fair Debt Collection Practices
      Act.
    </span>
  </p>
  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1">
    <span class="c0"
      >I hereby reserve the right to pursue damages for your failure to comply
      with the above referenced request and demand. I will. Seek any and all
      damages including and not limited to defamation, willful negligence, and all
      FCRA and FDCPA violations as well.
    </span>
  </p>
  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1">
    <span class="c0">I look forward to a prompt response. </span>
  </p>`,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.LatePayments],
    name: 'Option 1',
    rawHtml: `<p class="c1">
    <span class="c2">
    My credit report lists the following accounts reflecting late payments.
      These accounts are in error because they were paid punctually. Please
      reinvestigate the following accounts and update my credit report and credit
      score to accurately reflect my credit history. Pursuant to the Fair Credit
      Reporting Act, I will expect you to complete the verification within 30
      (thirty) calendar
      days.
      </span>
  </p>

  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>

  <p class="c2"><span class="c3"> </span></p>
  <C></C>
  <p class="c1"><span class="c10">[AdditionalComments]</span></p>
  <C></C>
  <p class="c2"><span class="c3"> </span></p>
  <C></C>
  <p class="c1">
    <span class="c2"
      > I would appreciate you sending me an updated copy of my file as soon
      as you have completed the investigation and expected corrections.</span
    >
  </p>
  <C></C>
  <p class="c1">
    <span class="c2"
      >Please send me my updated report by postal mail as soon as your
      investigation is completed.</span
    >
  </p>`,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.LatePayments],
    name: 'Option 2',
    rawHtml: `<p class="c0">
    <span class="c1"
      >After checking my credit report with your company, I am notifying you that
      you need to update the accounts showing derogatory status
      because the furnished information is completely erroneous</span
    >
  </p>
  
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c1">
    <span class="c0">[AdditionalComments] </span>
  </p>
  <C></C>
  <p class="c1 c2"><span class="c0"></span></p>
  <C></C>
  <p class="c0">
    <span class="c1"
      >I ask that you contact the creditors to have you verify and
      update the payment history accordingly.  </span
    >
  </p>
  <C></C>
  <p class="c0 c5"><span class="c1"></span></p>
  <C></C>
  <p class="c0">
    <span class="c1"
      >Send me an updated credit report by postal mail when correction has been
      applied. Remember by law you have 30 calendar days to complete and comply
      with the law.
    </span>
  </p>`,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.UnknownAccounts],
    name: `1st Round - Invalid Account`,
    rawHtml: `<p class="c2">
      This letter in reference to account [ItemNumberList], my name is
      [CustomerFirstName] [CustomerLastName], my social security number is
      [CustomerSSN], my date of birth is [CustomerDOB].
    </p>
    <C></C>
    <p class="c0"><span class="c3"></span></p>
    <C></C>
    <p class="c2">
      <span class="c3"
        >The above account is illegally being reported on my credit report. I have
        formally disputed it with "[ItemNameList]" there is no account that I owe any
        money to from the above-mentioned creditor for that amount.
      </span>
    </p>
    <C></C>
    <p class="c0"><span class="c3"></span></p>
    <C></C>

    <p class="c2"><span class="c1">Account Information:</span></p>
    <C></C>
    <p class="c2 c4"><span class="c1"></span></p>
    <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
    <C></C>
    <p class="c2 c4"><span class="c1"></span></p>
    <C></C>
    <p class="c2 c4"><span class="c1"></span></p>
    <C></C>

    <p class="c2">
      <span class="c3"
        >[AdditionalComments]
      </span>
    </p>
    <C></C>
    <p class="c0"><span class="c3"></span></p>
    <C></C>
    <p class="c2">
      <span class="c3"
        >If either "[ItemNameList]" verify this illegal entry, please advise me of the
        manner that they verified this illegal account along with the name and
        address of the verifying party. I will need this information to pursue legal
        action, including but not limited to a complaint with the [CustomerState]
        Attorney General, Better Business Bureau, and the federal Trade Commission.
         As I will be taking appropriate legal action and filing complaints
        with the FTC and [CustomerState] Attorney General&rsquo;s Office.
      </span>
    </p>`,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.ChargeOff, Categories_Enum.Collections],
    name: 'Option 1',
    rawHtml: `<p class="c1">
    <span class="c2">I formally request that the following</span
    ><span class="c6"> </span
    ><span class="c2">be immediately investigated</span>
  </p>
  <C></C>
  <p class="c0"><span class="c2"></span></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p class="c4"><span class="c9">[AdditionalComments]</span></p>
  <C></C>
  <p class="c0"><span class="c2"></span></p>
  <C></C>
  <p class="c1">
    <span class="c2"
      >They must be removed in order to show my true credit history, as these
      items should not be on my report. Pursuant to the Fair Credit Reporting Act,
      I will expect you to complete the verification within 30 (thirty) calendar
      days.</span
    >
  </p>
  <C></C>
  <p class="c0"><span class="c2"></span></p>
  <C></C>
  <p class="c1">
    <span class="c2"
      >Please provide me with my updated report after completing the deletion
      process by postal mail.
    </span>
  </p>
  `,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.ChargeOff, Categories_Enum.Collections],
    name: 'Option 2',
    rawHtml: `<p class="c2">
    <span class="c1"
      >According to the Fair Credit Reporting Act Section 609(a)(1)(A), by federal
      law you are required to verify trough the physical verification of the
      original signed document contracts, any and all accounts you post on my
      credit report.</span
    >
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c11">
    <span class="c1"
      >Anyone paying for your reporting services could fax, mail or email you a
      fraudulent account, I expect to see an original proof of the accounts that I
      am listing below.
    </span>
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c2">
    <span class="c1"
      >Your failure to verify these accounts may hurt my ability to obtain credit,
      under the FCRA, unverified accounts must be deleted and if you are unable to
      provide me a copy of verifiable proof, you must delete the accounts listed
      below.</span
    >
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c2">
    <span class="c1">Please verify and delete the following immediately:</span>
  </p>
  <C></C>
  <p class="c0"><span class="c3"></span></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

    <C></C>
    <p class="c2">
      <span class="c3"
        >[AdditionalComments]
      </span>
    </p>
    <C></C>
    <p class="c0"><span class="c3"></span></p>
    <C></C>
  <p class="c11">
    <span class="c1"
      >Please note that I have opted out in writing to your forced arbitration
      terms and a willing to seek legal relief.
    </span>
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c2" id="h.gjdgxs">
    <span class="c1"
      >I expect you to delete these negative accounts and provided me with an
      updated report by postal mail within 30 calendar days in accordance with the
      FCRA.
    </span>
  </p>
  `,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.ChargeOff, Categories_Enum.Collections],
    name: 'Medical',
    rawHtml: `<p>
    This is a written request that you investigate specific items that I believe
    to be reported in error. These items are not correct and are causing me
    financial and emotional distress because of their derogative nature. The
    following items must be deleted because Under HIPAA, Medical Privacy Statutes
    and the Privacy Rule prohibits you from using or disclosing my individual
    protected health information "PHI" unless I specifically authorize the use,
    which I have not. You are required to delete these accounts due to serious
    violations of my federal rights NOW!
  </p>
  <C></C>
  <p class="c8"><span class="c1"> </span></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p>[AdditionalComments]</p>
  <C></C>
  <p></p>
  <C></C>
  <p>
    It is my understanding of the law that you are required to complete your
    investigation within thirty days. I am awaiting your response.
  </p>
  `,
  },
  {
    round: '1st Round',
    categories: [Categories_Enum.MixedReasons],
    name: 'Personal Information Correction',
    rawHtml: `<p>
    I just received my credit report of your company. After research of the personal information reporting, I see that it is not accurate and is a concern. I need your help to have this inaccurate information deleted.
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p>
    Any other information different from the following must be deleted immediately:
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p>Name: [CustomerFirstName] [CustomerLastName]</p>
  <C></C>
  <p>Address: [CustomerAddress]</p>
  <C></C>
  <p>Social Security Number: [CustomerSSN]</p>
  <C></C>
  <p>DOB: [CustomerDOB]</p>
  <C></C>
  <p></p>
  <C></C>
  <p>
    This is the only information that should be reporting as this is accurate and up to date. My creditors have this accurate information and so should you, in order for it to be law abiding.
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p>
    I ask that you correct all personal information reporting. When finished, please mail a new report to my address to the information above.
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p>Thank you for all of your help and assistance.</p>
  <C></C>
  <p></p>
  <C></C>
  <p>Have a terrific day.</p>
  `,
  },
  {
    round: '2nd Round',
    categories: [Categories_Enum.ChargeOff],
    name: 'Follow Up Charged offs with balances',
    rawHtml: `<p>
    In accordance with the law, I have sent a letter to the following creditors in
    order to verify the debts you allege that I owe. Since these creditors cannot
    verify debts to me, even though I am the alleged debtor, how can your bureau
    claim to have verified them.
  </p>
  <C></C>
  <p></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p>
    Since these debts have not been verified according to the law, I demand that
    you remove all reference to these accounts from my credit report within the
    next 30 days.
  </p>
  `,
  },
  {
    round: '2nd Round',
    categories: [Categories_Enum.MixedReasons],
    name: 'Follow Up Letter',
    rawHtml: `<p>
    Thank you for deleting some of the accounts which incorrectly appeared on my
    credit report. However, I still vigorously dispute the following remaining
    items. These accounts are still being reported inaccurately and are extremely
    damaging to me. In accordance to the Fair Credit Reporting Act, Section 1681i,
    ask you to reinvestigate these items and delete them from my report.
  </p>
  <C></C>
  <p></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p>[AdditionalComments]</p>
  <C></C>
  <p></p>
  <C></C>
  <p>
    Furthermore, I would appreciate the name of the individuals you contacted for
    verification, along with their addresses and phone numbers so I may follow up.
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p>
    Please send me my updated report as soon as you investigation is completed.
  </p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Cease And Desist',
    rawHtml: `<p class="c1">
    <span class="c0"
      >Since last month, I have received several phone
      calls and letters from you concerning and overdue account with
      you. </span
    >
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p class="c1">
    <span class="c0"
      >This is to give you notice to cease all contact with me or anyone else
      except the creditor about this claimed debt. Accordingly, under 15 U.S.C
      Sec. 1692c, this is my formal notice to you to cease all further
      communications with me. If you must contact me, please do so inwriting and
      not by telephone.</span
    >
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p class="c3">
    <span class="c0"
      >I look forward to your acknowledgement that you have received this notice.</span
    >
  </p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Failure to Respond',
    rawHtml: `<p class="c2">
    <span class="c0"
      >I sent a letter requesting that you reinvestigate or delete disputed items
      from my credit report as well as place temporarily remove these items from
      my report during the investigation period.  As of this date, you have
      failed to respond to my request. A copy of my original letter is attached
      for your review.</span
    >
  </p>
  <C></C>
  <p class="c1"><span class="c0"></span></p>
  <C></C>
  <p class="c2">
    <span class="c0"
      >The law stipulates that you must investigate within 30 days of receiving my
      letter and respond within 5 days of completing your investigation. You have
      not followed the stipulations of the law.  </span
    >
  </p>
  <C></C>
  <p class="c1"><span class="c0"></span></p>
  <C></C>
  <p class="c2">
    <span class="c0"
      >I may suffer damages because I need to rely on an accurate and complete
      statement of my credit record and demand that you remove the disputed items
      from my report immediately as you failed to comply with the law. Otherwise,
      I will contact the Federal Trade Commission and advise them of your apparent
      disregard for consumer protection laws.</span
    >
  </p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Fraud Alert 1 year',
    rawHtml: `<p class="c0">
    <span class="c4"
      >It appears I have been a victim of identity theft and am writing that an
      initial fraud alert be immediate placed in my Credit file so that no new
      Credit will be approved or issued until my identity is first verified by the
      lender.</span
    >
  </p>
  <C></C>
  <p></p>
  <C></C>
  <p class="c0">
    <span class="c4"
      >I am aware that this alert will expire in 365 days if I do not provide
      another written request asking for it to be extended.</span
    >
  </p>
  <C></C>
  <p class="c0">
    <span class="c4"
      >Please provide evidence that you processed such alert on my
      credit report. </span
    >
  </p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Fraud Alert 7 years - Extended',
    rawHtml: `<p class="c3">
    <span class="c1"
      >I am a recent victim of identity theft. I previously placed a fraud alert
      on my credit file but would like to extend the alert for seven more years. I
      am aware this means that no new credit will be approved or issued unless my
      identity has been first verified by the lender.
    </span>
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c3">
    <span class="c1"
      >My Social Security number is [CustomerSSN]. My birth date is [CustomerDOB].
    </span>
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c3">
    <span class="c1"
      >I have included proof of my identity and proof of address.
    </span>
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c3">
    <span class="c1"
      >Please contact me if you need any further information.
    </span>
  </p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Unauthorized Inquiry',
    rawHtml: `<p class="c3">
    <span class="c1"
      >I reviewed a copy of my credit report, and some companies ran an
      unauthorized credit inquiry on me.
    </span>
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c3">
    <span class="c1"
      >I never authorized such actions, and they constitute a violation of my
      rights under the Fair Credit Reporting Act &sect;604 as well as a violation
      of my rights to privacy.  Please contact the following agencies and
      investigate such occurrences:</span
    >
  </p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>

  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p class="c3"><span class="c2">[AdditionalComments]</span></p>
  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  <p class="c3">
    <span class="c1"
      >I am requesting an updated copy of my credit report, which should be sent
      to the address listed below. According to the provisions of &sect; 612 [15
      USC &sect; 1681j], there should be no charge for this report. In addition,
      as part of your investigation, please send the names of the representative
      you spoke with, business address and phone numbers of those who made
      unauthorized credit inquiries so I may contact them directly.</span
    >
  </p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Unauthorized Inquiry - Alternative',
    rawHtml: `<p class="c1">
    <span class="c2">
      I am writing to request the removal of unauthorized credit inquiries on my [RecipientName] credit report. My latest credit report shows credit inquiries that I did not authorize.
    </span>
    </p>

  <C></C>
  <p class="c0"><span class="c1"></span></p>
    <C></C>
  <p class="c1">
    <span class="c2"
      >I writing to dispute the following inquiries and asking for their removal
      from my credit report:</span
    >
  </p>
  <C></C>
  
  <p class="c2"><span class="c1">Account Information:</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <p class="c2"><span class="c1">[ItemNameList] | [ItemNumberList] | $[ItemBalance]</span></p>
  <C></C>
  <p class="c2 c4"><span class="c1"></span></p>
  <C></C>

  <p class="c2"><span class="c3">[AdditionalComments]</span></p>
  <C></C>
  <p class="c1">
    <span class="c2"
      >Please have these inquiries removed from my credit report within 30 days,
      as it is harming my ability to obtain new credit. I would appreciate a copy
      of my credit report once this issue is resolved.</span
    >
  </p>

  <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
  
  <p class="c2"><span class="c3">Thank you for your assistance.</span></p>
  `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Credit Repair Services Accusation',
    rawHtml: `
    <p class="c1">
      <span class="c2">
      I am very dismayed at your suggestion that I may be using a third party to fix the horrors on my credit made by you. I am appalled that a major international firm of your stature would commit such an obvious infraction of the Deceptive Trade Practices Act and Fair Credit Reporting Act.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      I did send the letters and they are 100% by me. Attached is the proof that I am who I say I am. How dare you accuse me of not being an educated consumer by not knowing my rights under the Fair Credit Reporting Act. I will ask you politely for the last time to conduct a federal investigation in regard to the items included in my previous dispute which is attached to this communication as well. 
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      By law, I have the right to have a formal investigation performed as outlined in the Fair Credit Reporting Act. If you will not perform such an investigation, I will have no other remedy than to file a lawsuit against you. This is what the law says, and this is what you are doing. Clearly you are in violation and the law says that I can get up to $1,000.00 per item plus damages for this inaccurate reporting from your company and I will see you in court.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      Thank you so much when you said that I can count on you, but you are clearly not working for my best interest because if you do you will go ahead since my first dispute and investigate the items the items that I asked you. This is just a tactic to stall me out, not doing your job and continue to use artificial intelligence to completely disregard any source of inaccuracies or fraud information that is listed on my consumer report. 
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      The constant judgments awarded against you certainly demonstrate that courts will not tolerate your bullying tactics. I have made a lawful request to you to verify a report about me in my credit files.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      Please be advised that your failure to complete your investigation and compliance with the law may result in legal action against you.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      I'll be waiting for my results on my mail.
      </span>
    </p>
    `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Frivolous Letter',
    rawHtml: `
    <p class="c1">
      <span class="c2">
      I am writing in response to your illegal letter claiming that my dispute is "frivolous". Please note that you have failed to provide me with a copy of the evidence submitted to you by these banks, financial institutions, or collectors.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      I have forwarded a copy of this illegal letter to the Federal Trade Commission and the subcommittee on Banking Credit and Insurance.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      The information listed on these accounts is not accurate. As it now stands, the information you have presented to me continues to be inaccurate. It continues to represent serious errors in your reporting and a violation of the Fair Credit Reporting Act.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      I have written to you a few times before and I do not see any movements of you doing any re-investigation. All you say is "this information has been previously verified" In my dispute I requested a complete investigation and that includes copies of any documents by these institutions which contain my signature to validate their claim.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      I fully expect that you will fulfill your legal obligation and reinvestigate the items contained in my previous  letter . I also expect you send this information to me within fifteen (15) days of the completion of your re-investigation.
      </span>
    </p>
    <C></C>
  <p class="c0"><span class="c1"></span></p>
  <C></C>
    <p class="c1">
      <span class="c2">
      Please send me my updated report as soon as you investigation is completed.
      </span>
    </p>
    `,
  },
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Validation Letter',
    rawHtml: `
    <p class="c1">
      <span class="c2">
      This letter is a formal complaint that you are reporting inaccurate credit information.
      </span>
    </p>
    <C></C>
    <p class="c1">
      <span class="c2">
      I am very distressed you have included the below information in my credit profile, due to its damaging effects on my good credit standing. Because of the mistakes on my credit report, I have been wrongfully denied credit recently.
      </span>
    </p>
      <p class="c0"><span class="c1"></span></p>
    <p class="c1">
      <span class="c2">
      I hereby demand that you produce for my inspection an original contract that establishes the existence of a debt or legal establishment of an account to which I am obligated to pay to the alleged creditor or were I resign to my privacy rights under the Fair Credit Reporting Act for the account to be reported on my credit profile. Please notify me of a time and place convenient to both you and myself for the above referenced inspection.
      </span>
    </p>
      <C></C>
    <p class="c1">
      <span class="c2">
      In as much as this letter is in response to a pro-active claim on your part, any failure to satisfy this request within 30 days will be construed as your absolute obligation to permanently delete this from my credit, and your tacit agreement to compensate me for costs and attorney fees.
      </span>
    </p>
    `,
  },
]

export default letters

export const groupLetters = [
  {
    categories: [Categories_Enum.MixedReasons],
    name: 'Dispute Letter',
    rawHtml: `<html> <body class="c4 doc-content"> <p><strong>[TodaysDate] </strong></p> <C></C> <p></p> <C></C> <p><strong>[RecipientName]</strong></p> <C></C> <p><strong>[RecipientAddressLine1]</strong></p> <C></C> <p><strong>[RecipientCityStatePostalCode]</strong></p> <C></C> <p></p> <C></C> <p></p> <C></C> <p class="c1"> <span class="c0" >This letter is to be taken as my formal complaint that you are reporting inaccurate credit information in my credit file. I am quite miffed that you have included the following information on my credit report:</span > </p> <C></C> <p></p> <C></C> <p class="c1">[ItemsNameAccountsInstructions]</p> <C></C> <p></p> <C></C> <p class="c1"> <span class="c0" >These inaccurate item(s) are not only embarrassing, is preventing me from obtaining the credit I deserve. As you and I are both aware, there are laws that ensure that credit bureaus report only accurate credit information. It is evident that the inclusion of this inaccurate information is a mistake on either your part or the creditor&rsquo;s part.</span > </p> <C></C> <p></p> <C></C> <p class="c1"> <span class="c0" >It is with great concern that I insist you verify that this information is incorrect and delete it from my profile. Due to the damaging effects of such negative inaccurate information, I must insist that this is done swiftly.</span > </p> <C></C> <p></p> <C></C> <p>Regards,</p> <C></C> <C></C> <p><strong>[CustomerFirstName] [CustomerLastName]</strong></p> <C></C> <p><strong>[CustomerAddress]</strong></p> <C></C> <p><strong>[CustomerSSN]</strong></p> <C></C> <p><strong>[CustomerDOB]</strong></p> <C></C> </body></html>`,
  },
]

export const header = `<p class="c2"><span class="c3">[TodaysDate]</span></p>
<C></C>
<p class="c2"><span class="c3"> </span></p>
<C></C>
<p class="c2"><span class="c3">[RecipientName]</span></p>
<C></C>
<p class="c2"><span class="c3">[RecipientAddressLine1]</span></p>
<C></C>
<p class="c2"><span class="c3">[RecipientCityStatePostalCode]</span></p>
<C></C><C></C>
<p class="c2"><span class="c3"> </span></p>
<C></C>
<p class="c2"><span class="c3">To whom it may Concern:</span></p>
<C></C>
<p class="c2"><span class="c1"></span></p>
<C></C>`

export const footer = `<C></C>
<p class="c2"><span class="c1"></span></p>
<C></C>

<p class="c2"><span class="c1"></span></p>
<C></C>

<p class="c2"><span class="c1">Sincerely,</span></p>
<C></C>
<p class="c2"><span class="c1"></span></p>
<C></C>
<p class="c2"><span class="c1">[CustomerFirstName] [CustomerLastName]</span></p>
<C></C>
<p class="c2"><span class="c1">[CustomerAddress]</span></p>
<C></C>
<p class="c2"><span class="c1">[CustomerSSN]</span></p>
<C></C>
<p class="c2"><span class="c1">[CustomerDOB]</span></p>
<C></C><C></C>
<p class="c3"><span class="c8"></span></p>
<C></C>`
