import cc from 'classcat'

type Props = {
  title: string
  subtitle?: string
  $size: 'sm' | 'md' | 'lg' | 'xs'
  $align: 'left' | 'center' | 'right'
}

const Title = ({ title, subtitle, $size, $align }: Props) => {
  return (
    <div
      className={cc([
        'mt-4 mb-6',
        {
          'text-left': $align === 'left',
          'text-center': $align === 'center',
          'text-right': $align === 'right',
        },
      ])}
    >
      <h1
        className={cc([
          'font-bold',
          {
            'text-md': $size === 'xs',
            'text-lg': $size === 'sm',
            'text-xl': $size === 'md',
            'text-2xl': $size === 'lg',
          },
        ])}
      >
        {title}
      </h1>
      {subtitle && (
        <h2
          className={cc([
            'text-gray-600',
            {
              'text-xs': $size === 'sm',
            },
          ])}
        >
          {subtitle}
        </h2>
      )}
    </div>
  )
}

Title.defaultProps = {
  $size: 'md',
  $align: 'center',
}

export default Title
