import { pdf } from '@react-pdf/renderer'
import { useAuth } from 'clients/auth.client'
import Button from 'components/Button'
import Collapsable from 'components/Collapsable'
import Container from 'components/Container'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import PlayVideoButton from 'components/PlayVideoButton'
import PDFDocument from 'components/PDFDocument'
import SectionLocked from 'components/SectionLocked'

import Title from 'components/Title'

import {
  AccountStatus_Enum,
  Bureaus_Enum,
  Categories_Enum,
  CreditItemStatus_Enum,
  GetallCreditItemsQuery,
  GetCreditItemsQuery,
  GetLetterGroupsQuery,
  LetterStatus_Enum,
  LetterType_Enum,
  useCreateLetterGroupMutation,
  useGetallCreditItemsQuery,
  useGetCreditItemsQuery,
  useGetLetterGroupsQuery,
  useUpdateLetterGroupMutation,
} from 'generated/graphql'
import withAsideMenu from 'hocs/withAsideMenu'
import {
  Videos,
  equifaxValidator,
  experianValidator,
  GENERIC_ERROR_MESSAGE,
  transUnionValidator,
  AccountInstructionType,
} from 'globalConstants'
import withContainer from 'hocs/withContainer'
import { useNavigate } from 'react-router-dom'
import { saveAs as saveFileAs } from 'file-saver'
import insertInformationToHtml from 'utils/insertInformationToHtml'
import patternMatching from 'utils/patternMatching'
import { useToast } from 'components/ToastMessage'
import { useEffect, useState } from 'react'
import Tabs from 'components/Tabs'
import NoResultMessage from 'components/NoResultMessage'
import { useFieldArray, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import FormCheckbox from 'forms/FormCheckbox'
import { groupLetters } from 'letters'
import { capitalize, every } from 'lodash'
import friendlyStringFormatter from 'utils/friendlyStringFormatter'
import CreditItemStatus from 'components/CreditItemStatus'

function DisputeLetters() {
  return (
    <SectionLocked>
      <Container topAndBottom>
        <div className="max-w-2xl mx-auto">
          <div className="pb-5 flex items-center">
            <div>
              <Title
                $align="left"
                title="Dispute Letters"
                subtitle="Create dispute letters based on your credit needs."
              />
            </div>

            <div className="ml-auto">
              <PlayVideoButton video={Videos.LETTERS} />
            </div>
          </div>
          <Tabs
            items={[
              {
                id: 'single-letters',
                Component: SingleLettersTab,
                text: 'Single Letters',
              },
              {
                id: 'group-letters',
                Component: GroupLettersTab,
                text: 'Group Letter Creation',
              },
            ]}
            $style="even"
          />
        </div>
      </Container>
    </SectionLocked>
  )
}

const LIMIT_PER_PAGE = 3

const GroupLettersTab = () => {
  const [createNewLetter, setCreateNewLetter] = useState(false)
  const [{ data: groupsData, error: groupsError, fetching: fetchingGroups }] =
    useGetLetterGroupsQuery()
  const [{ data, error, fetching }] = useGetCreditItemsQuery({
    variables: {
      where: {
        _and: [
          { status: { _neq: CreditItemStatus_Enum.AccountFixed } },
          { status: { _neq: CreditItemStatus_Enum.AccountDeleted } },
        ],
      },
    },
    requestPolicy: 'network-only',
  })

  if (fetching || fetchingGroups) return <Loading />

  if (error?.message || groupsError?.message) {
    return (
      <ErrorMessage>
        {error?.message || groupsError?.message || ''}
      </ErrorMessage>
    )
  }

  if (!data || !data.CreditItem || !groupsData || !groupsData.Letter) {
    return null
  }

  if (groupsData.Letter.length === 0)
    return (
      <div className="mt-12 space-y-8">
        <Button $fluid onClick={() => setCreateNewLetter(true)}>
          Create New Group Letter
        </Button>

        {createNewLetter ? (
          <div className="bg-white w-full p-4 border-gray-600 border rounded-md">
            <Collapsable
              trigger={() => (
                <strong className="text-lg">CREATE NEW GROUP LETTER</strong>
              )}
              initialValue={true}
            >
              <LetterGroupForm
                items={data.CreditItem}
                onCancel={() => setCreateNewLetter(false)}
              />
            </Collapsable>
          </div>
        ) : (
          <div className="py-20">
            <NoResultMessage message="No items found" />
          </div>
        )}
      </div>
    )

  return (
    <div className="mt-12 space-y-8">
      <Button $fluid onClick={() => setCreateNewLetter(true)}>
        Create New Group Letter
      </Button>
      {createNewLetter && (
        <div className="bg-white w-full p-4 border-gray-600 border rounded-md">
          <Collapsable
            trigger={() => (
              <strong className="text-lg">CREATE NEW GROUP LETTER</strong>
            )}
            initialValue={true}
          >
            <LetterGroupForm
              items={data.CreditItem}
              onCancel={() => setCreateNewLetter(false)}
            />
          </Collapsable>
        </div>
      )}

      <LettersGroupList data={groupsData.Letter} items={data.CreditItem} />
    </div>
  )
}

const easyLetterFormatter = (value: string) =>
  patternMatching<string, string>([
    [LetterStatus_Enum.SavedDownloaded, 'Save & Downloaded'],
    [LetterStatus_Enum.SavedDraft, 'Saved as draft'],
    [Bureaus_Enum.Equifax, 'Equifax'],
    [Bureaus_Enum.Experian, 'Experian'],
    [Bureaus_Enum.TransUnion, 'TransUnion'],
  ])(value)

const LettersGroupList = ({
  data,
  items,
}: {
  data: GetLetterGroupsQuery['Letter']
  items: GetCreditItemsQuery['CreditItem']
}) => {
  const { account } = useAuth()
  const [isEditing, setIsEditing] = useState(-1)
  const [dowloading, setDowloading] = useState(false)
  const userInformation = {
    address1: account?.address1 || '',
    address2: account?.address2 || '',
    address_picture: account?.address_picture1 || '',
    birthdate: account?.birth_date || '',
    city: account?.city || '',
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    license_picture: account?.license_picture || '',
    ssn: account?.ssn || '',
    ssn_picture: account?.ssn_picture || '',
    state: account?.state || '',
    zip_code: account?.zip_code || '',
  }
  const toast = useToast()

  const downloadLetterGroupHandler = async (
    bureaus: string[],
    rawHtml: string,
    name: string,
    accountInstructions: AccountInstructionType[],
  ) => {
    setDowloading(true)

    if (!userInformation.address_picture) {
      toast.notify({
        type: 'failure',
        message:
          'Missing proof of address. Please go to "Personal Documents" and upload a picture.',
      })
    } else if (!userInformation.license_picture) {
      toast.notify({
        type: 'failure',
        message:
          'Missing ID / Driver License. Please go to "Personal Documents" and upload a picture.',
      })
    } else if (!userInformation.ssn_picture) {
      toast.notify({
        type: 'failure',
        message:
          'Missing Social Security Card. Please go to "Personal Documents" and upload a picture.',
      })
    } else {
      const documents = bureaus.map((bureau) =>
        insertInformationToHtml(
          rawHtml,
          userInformation,
          bureau,
          {},
          {
            accountName: '',
            accountNumber: '',
            accountBalance: 0,
          },
          accountInstructions,
        ),
      )

      try {
        const blob = await pdf(
          <PDFDocument
            htmlDocuments={documents}
            userInformation={userInformation}
            selectedBureaus={bureaus}
          />,
        ).toBlob()

        saveFileAs(blob, `${name}-${Date.now().toString()}`)
      } catch (error) {
        toast.notify({
          type: 'failure',
          message: 'Error downloading PDF',
        })
        console.error(error)
      }
    }

    setDowloading(false)
  }

  return (
    <div className="space-y-8">
      {data.map((letter, index) => {
        const LetterGroupAccounts = letter.LetterGroups.map(
          (group) => group.CreditItem,
        )
        if (isEditing === index) {
          return (
            <div
              className="bg-white w-full p-4 border-gray-600 border rounded-md"
              key={`letter-group-list-${letter.id}`}
            >
              <LetterGroupForm
                group_id={letter.id}
                items={items}
                initialValues={{
                  selectedAccounts: LetterGroupAccounts,
                  equifax: letter.LetterBureaus.find(
                    (bureau) => bureau.value === Bureaus_Enum.Equifax,
                  )?.value,
                  trans_union: letter.LetterBureaus.find(
                    (bureau) => bureau.value === Bureaus_Enum.TransUnion,
                  )?.value,
                  experian: letter.LetterBureaus.find(
                    (bureau) => bureau.value === Bureaus_Enum.Experian,
                  )?.value,
                }}
                onCancel={() => setIsEditing(-1)}
                groupNumber={0}
                letterId={letter.id}
              />
            </div>
          )
        }
        const bureaus = letter.LetterBureaus.map((bureau) => bureau.value)

        return (
          <div
            className="bg-white w-full p-4 border-gray-600 border rounded-md"
            key={`letter-group-list-${letter.id}`}
          >
            <Collapsable
              trigger={() => <strong className="text-lg">{letter.name}</strong>}
              initialValue={index === 0}
            >
              <div className="space-y-4 mt-4">
                {LetterGroupAccounts.map((account) => {
                  const {
                    id,
                    account_name,
                    account_number,
                    account_status,
                    category,
                    ItemBureaus,
                    total,
                    status,
                    dispute_reason,
                    dispute_round,
                  } = account
                  return (
                    <div
                      className="bg-white w-full p-4 border-gray-400 border rounded-md"
                      key={`account-group-info-${id}`}
                    >
                      <Collapsable
                        trigger={() => (
                          <div className="flex items-center gap-4">
                            <strong>{account_name}</strong>
                          </div>
                        )}
                      >
                        <AccountElement
                          account={{
                            id: id,
                            accountName: account_name,
                            accountNumber: account_number,
                            accountStatus: account_status,
                            bureaus: ItemBureaus.map((bureau) => bureau.value),
                            total: total,
                            category: category,
                            status: status,
                            dispute_reason: dispute_reason || '',
                            dispute_round: dispute_round,
                          }}
                        />
                      </Collapsable>
                    </div>
                  )
                })}
                <div className="text-left">
                  <div className="text-sm text-gray-500">{`Letter #${
                    letter.group_number || 0
                  }`}</div>
                  <div>{letter.name}</div>
                </div>
                <div className="text-left">
                  <div className="text-sm text-gray-500">Status:</div>
                  <div>{easyLetterFormatter(letter.status)}</div>
                </div>
                <div className="text-left">
                  <div className="text-sm text-gray-500">Letter For:</div>
                  <div>
                    {letter.LetterBureaus.map(
                      (bureau, i) =>
                        `${easyLetterFormatter(bureau.value)}${
                          i === letter.LetterBureaus.length - 1 ? '' : ', '
                        }`,
                    )}
                  </div>
                </div>
                <div className="space-y-4">
                  <Button
                    $fluid
                    $type="secondary"
                    loading={dowloading}
                    onClick={() =>
                      downloadLetterGroupHandler(
                        bureaus,
                        letter.raw_html,
                        `Letter-${letter.group_number || 0}`,
                        LetterGroupAccounts.map((groupAccouunt) => ({
                          accountName: groupAccouunt.account_name,
                          accountNumber: groupAccouunt.account_number,
                          accountReason: groupAccouunt.dispute_reason || '',
                        })),
                      )
                    }
                  >
                    Download Letter
                  </Button>
                  <Button
                    $fluid
                    $type="secondary"
                    onClick={() => setIsEditing(index)}
                  >
                    Edit Group
                  </Button>
                </div>
              </div>
            </Collapsable>
          </div>
        )
      })}
    </div>
  )
}

const AccountElement = ({
  account,
}: {
  account: {
    id: string
    accountName: string
    accountNumber: string
    accountStatus: AccountStatus_Enum
    bureaus: Bureaus_Enum[]
    total: number
    category: Categories_Enum
    status: CreditItemStatus_Enum
    dispute_reason?: string
    dispute_round: number
  }
}) => {
  const {
    accountNumber,
    accountStatus,
    bureaus,
    category,
    total,
    status,
    dispute_reason,
  } = account

  return (
    <div className="my-8 space-y-3">
      <div>
        <div className="text-gray-500">Account #:</div>
        <div>{accountNumber}</div>
      </div>

      <div className="flex justify-between">
        <div>
          <div className="text-gray-500">Balance:</div>
          <div>{total}</div>
        </div>
        <div>
          <div className="text-gray-500">Category:</div>
          <div>{capitalize(category.replace('_', ' '))}</div>
        </div>
        <div>
          <div className="text-gray-500">Account Status:</div>
          <div>{capitalize(accountStatus)}</div>
        </div>
      </div>

      <div>
        <div className="text-gray-500">Dispute Reason:</div>
        <div>{dispute_reason}</div>
      </div>
      <div>
        <div className="text-gray-500">Where to Dispute:</div>
        <div>
          {bureaus
            .map((bureau) =>
              bureau
                .split('_')
                .map((value) => friendlyStringFormatter(value))
                .join(''),
            )
            .join(', ')}
        </div>
      </div>
      <div>
        <div className="text-gray-500">Dispute Status:</div>
        <CreditItemStatus status={status} />
      </div>
    </div>
  )
}

const validationSchema = yup.object({
  items: yup
    .array(
      yup.object({
        item_id: yup.string().required(),
        selected: yup.boolean(),
        account_name: yup.string().required(),
      }),
    )
    .test({
      name: 'one-true',
      message: 'Select at least one account',
      test: (val) => !every(val, ['selected', false]),
    }),
  experian: experianValidator,
  trans_union: transUnionValidator,
  equifax: equifaxValidator,
})

//TODO create a type for select ed bureaus
type initialValues = {
  selectedAccounts: any[]
  experian?: string
  trans_union?: string
  equifax?: string
}
type bureausObject = { value: Bureaus_Enum }
const LetterGroupForm = ({
  group_id,
  items,
  initialValues,
  onCancel,
  groupNumber,
  letterId,
}: {
  group_id?: string
  items: GetCreditItemsQuery['CreditItem']
  initialValues?: initialValues
  groupNumber?: number
  onCancel: () => void
  letterId?: string
}) => {
  const { account } = useAuth()
  const [createGroupState, createGroup] = useCreateLetterGroupMutation()
  const [updateGroupState, updateGroup] = useUpdateLetterGroupMutation()

  const { control, register, handleSubmit, getValues, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      items: items.map((item) => ({
        item_id: item.id,
        selected: initialValues?.selectedAccounts.find(
          (selectedAccount) => selectedAccount.id === item.id,
        )
          ? true
          : false,
        account_name: item.account_name,
      })),
      experian: initialValues?.experian === Bureaus_Enum.Experian,
      trans_union: initialValues?.trans_union === Bureaus_Enum.TransUnion,
      equifax: initialValues?.equifax === Bureaus_Enum.Equifax,
    },
  })
  const { isSubmitting, errors, isDirty } = formState
  const { fields } = useFieldArray({ control, name: 'items' })
  const toast = useToast()
  const navigation = useNavigate()
  const defaultLetter = groupLetters[0]
  const userInformation = {
    address1: account?.address1 || '',
    address2: account?.address2 || '',
    address_picture: account?.address_picture1 || '',
    birthdate: account?.birth_date || '',
    city: account?.city || '',
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    license_picture: account?.license_picture || '',
    ssn: account?.ssn || '',
    ssn_picture: account?.ssn_picture || '',
    state: account?.state || '',
    zip_code: account?.zip_code || '',
  }

  return (
    <Container topAndBottom>
      <form
        className="space-y-4"
        onSubmit={handleSubmit(async (values) => {
          let hasErrors = false
          const bureaus = [
            values.equifax && { value: Bureaus_Enum.Equifax },
            values.experian && {
              value: Bureaus_Enum.Experian,
            },
            values.trans_union && {
              value: Bureaus_Enum.TransUnion,
            },
          ].filter(Boolean) as bureausObject[]

          const selectedBureaus = bureaus.map((bureau) => bureau.value)
          const selectedItems = values.items
            .filter((item) => item.selected)
            .map((item) => item.item_id)

          const accountInstructions = selectedItems
            .map((id) => {
              const creditItem = items.find((item) => item.id === id)
              return {
                accountName: creditItem?.account_name || '',
                accountNumber: creditItem?.account_number || '',
                accountReason: creditItem?.dispute_reason || '',
              }
            })
            .filter(Boolean)

          const documents = selectedBureaus.map((bureau) =>
            insertInformationToHtml(
              defaultLetter.rawHtml,
              userInformation,
              bureau,
              {},
              {
                accountName: '',
                accountNumber: '',
                accountBalance: 0,
              },
              accountInstructions,
            ),
          )

          if (!group_id && !groupNumber) {
            const response = await createGroup({
              bureaus,
              items: selectedItems,
              letterName: defaultLetter.name,
              letterType: LetterType_Enum.LetterGroup,
              rawHtml: defaultLetter.rawHtml,
              status: LetterStatus_Enum.SavedDownloaded,
            })
            if (response.error) {
              hasErrors = true
            }
          } else {
            const response = await updateGroup({
              letterId,
              groupId: group_id,
              bureaus,
              items: selectedItems,
            })
            if (response.error) {
              hasErrors = true
            }
          }

          if (!hasErrors) {
            const blob = await pdf(
              <PDFDocument
                htmlDocuments={documents}
                userInformation={userInformation}
                selectedBureaus={selectedBureaus}
              />,
            ).toBlob()
            saveFileAs(blob, `Letter-${'test'}-${Date.now().toString()}`)
            try {
              toast.notify({
                type: 'success',
                message: 'Your letter group was created correctly!',
                onClose() {
                  navigation(0)
                },
              })
            } catch (error) {
              toast.notify({
                type: 'failure',
                message: 'Error downloading PDF',
              })
              console.error(error)
            }
          } else {
            toast.notify({
              type: 'failure',
              message: GENERIC_ERROR_MESSAGE,
            })
          }
        })}
      >
        <div className="space-y-10">
          <div className="space-y-4">
            {fields.map((item, index) => {
              const accountItem = items.find(
                (account) => account.id === item.item_id,
              )
              return (
                <div
                  className="bg-white w-full p-4 border-gray-400 border rounded-md"
                  key={`account-field-${item.id}`}
                >
                  <Collapsable
                    trigger={() => (
                      <div className="flex items-center gap-4">
                        <FormCheckbox
                          {...register(`items.${index}.selected` as const)}
                          defaultChecked={getValues(`items.${index}.selected`)}
                        />
                        <strong className="text-lg">{item.account_name}</strong>
                      </div>
                    )}
                  >
                    {accountItem && (
                      <AccountElement
                        account={{
                          id: accountItem.id,
                          accountName: accountItem.account_name,
                          accountNumber: accountItem.account_number,
                          accountStatus: accountItem.account_status,
                          bureaus: accountItem.ItemBureaus.map(
                            (bureau: any) => bureau.value,
                          ),
                          total: accountItem.total,
                          category: accountItem.category,
                          status: accountItem.status,
                          dispute_reason: accountItem.dispute_reason || '',
                          dispute_round: accountItem.dispute_round,
                        }}
                      />
                    )}
                  </Collapsable>
                </div>
              )
            })}
          </div>
          <div className="space-y-3">
            <div className="text-gray-600">Where to dispute?</div>
            <div className="flex gap-4 flex-wrap">
              <FormCheckbox
                {...register('experian')}
                label="Experian"
                defaultChecked={getValues('experian')}
              />
              <FormCheckbox
                {...register('trans_union')}
                label="TransUnion"
                defaultChecked={getValues('trans_union')}
              />
            </div>
            <FormCheckbox
              {...register('equifax')}
              label="Equifax"
              defaultChecked={getValues('equifax')}
            />
          </div>
          {(errors.items ||
            errors.equifax?.message ||
            errors.experian?.message ||
            errors.trans_union?.message ||
            createGroupState.error ||
            updateGroupState.error) && (
            <div>
              {errors.items && (
                <ErrorMessage>{errors.items.message || ''}</ErrorMessage>
              )}
              {(errors.equifax?.message ||
                errors.experian?.message ||
                errors.trans_union?.message) && (
                <ErrorMessage>
                  {errors.equifax?.message ||
                    errors.trans_union?.message ||
                    errors.experian?.message ||
                    ''}
                </ErrorMessage>
              )}
              {createGroupState.error && (
                <ErrorMessage>{createGroupState.error.message}</ErrorMessage>
              )}
              {updateGroupState.error && (
                <ErrorMessage>{updateGroupState.error.message}</ErrorMessage>
              )}
            </div>
          )}
          <div className="space-y-4">
            <Button
              $fluid
              $type="secondary"
              loading={isSubmitting}
              $loadingColor="primary"
              disabled={!isDirty}
            >
              {`${initialValues ? 'update' : 'create'} group letter & Download`}
            </Button>
            <Button $fluid type="button" onClick={() => onCancel()}>
              Cancel
            </Button>
          </div>
        </div>
      </form>
    </Container>
  )
}

const SingleLettersTab = () => {
  const [page, setPage] = useState(1)
  const [{ data, error, fetching }] = useGetallCreditItemsQuery({
    variables: { limit: LIMIT_PER_PAGE, offset: (page - 1) * LIMIT_PER_PAGE },
  })

  if (fetching && !data) {
    return <Loading />
  }

  if (error?.message) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data) {
    return null
  }

  return (
    <Container>
      <div className="max-w-2xl mx-auto mt-5">
        <ItemsList items={data.CreditItem} page={page} />
        {data?.CreditItem.length !== 0 &&
          data.CreditItem.length === LIMIT_PER_PAGE && (
            <div className="py-4">
              <Button
                $type="secondary"
                $fluid
                onClick={() => setPage(page + 1)}
                loading={fetching}
              >
                Load More
              </Button>
            </div>
          )}
      </div>
    </Container>
  )
}

type ExtraFieldType = {
  name: string
  value: string
}

type LetterType = {
  id: string
  name: string
  status: string
  rawHtml: string
  for: string[]
  extraFields: ExtraFieldType[]
}

const ItemsList = ({
  items,
  page,
}: {
  items: GetallCreditItemsQuery['CreditItem']
  page: number
}) => {
  const [itemsList, setItemsList] = useState(items)

  useEffect(() => {
    if (page !== 1) {
      setItemsList((prev) => [...prev, ...items])
    }
  }, [items])

  return (
    <div className="space-y-4">
      {itemsList.map((item, i) => (
        <ItemElement
          itemId={item.id}
          key={`letter-account-${item.id}`}
          name={item.account_name}
          itemStatus={item.status}
          category={item.category}
          letters={item.Letters.map((letter) => ({
            id: letter.id,
            name: letter.name,
            status: letter.status,
            for: letter.LetterBureaus.map((bureau) => bureau.value),
            rawHtml: letter.raw_html,
            extraFields: letter.extra_fields || [],
          }))}
        />
      ))}
    </div>
  )
}

const ItemElement = ({
  itemId,
  name,
  letters,
  itemStatus,
  category,
}: {
  itemId: string
  name: string
  letters?: LetterType[]
  itemStatus: CreditItemStatus_Enum
  category: string
}) => {
  const { account } = useAuth()
  const navigation = useNavigate()

  if (!account) return <ErrorMessage>{GENERIC_ERROR_MESSAGE}</ErrorMessage>

  return (
    <div className="bg-white w-full p-4 border-gray-600 border rounded-md">
      <Collapsable
        trigger={() => <strong className="text-lg">{name}</strong>}
        initialValue={true}
      >
        <Container topAndBottom leftAndRight={false}>
          {letters?.length === 0 &&
            itemStatus !== CreditItemStatus_Enum.AccountFixed && (
              <Button
                $fluid
                onClick={() => navigation(`/letters/new-letter/${itemId}`)}
              >
                Create the first letter
              </Button>
            )}
          {letters && letters.length > 0 && (
            <div className="space-y-3">
              {letters.map((letter, i) => (
                <div
                  className="p-4 border-gray-600 border rounded-md"
                  key={`letter-element-${letter.id}`}
                >
                  <Collapsable
                    trigger={() => (
                      <div className="text-left">
                        <div className="text-sm text-gray-500">{`Letter #${
                          i + 1
                        }`}</div>
                        <div>{letter.name}</div>
                      </div>
                    )}
                    initialValue={i === 0}
                  >
                    <LetterElement
                      name={letter.name}
                      bureaus={letter.for}
                      id={letter.id}
                      itemId={itemId}
                      status={letter.status}
                      rawHtml={letter.rawHtml}
                      extraFields={letter.extraFields}
                      category={category}
                    />
                  </Collapsable>
                </div>
              ))}
              {itemStatus !== CreditItemStatus_Enum.AccountFixed && (
                <div className="pt-3">
                  <Button
                    $fluid
                    onClick={() => navigation(`/letters/new-letter/${itemId}`)}
                  >
                    New Letter for this account
                  </Button>
                </div>
              )}
            </div>
          )}
        </Container>
      </Collapsable>
    </div>
  )
}

const LetterElement = ({
  id,
  itemId,
  name,
  status,
  bureaus,
  rawHtml,
  extraFields,
  category,
}: {
  name: string
  id: string
  itemId: string
  status: string
  bureaus: string[]
  rawHtml: string
  extraFields: ExtraFieldType[]
  category: string
}) => {
  const { account } = useAuth()
  const navigation = useNavigate()
  const [dowloading, setDowloading] = useState(false)

  const userInformation = {
    address1: account?.address1 || '',
    address2: account?.address2 || '',
    address_picture: account?.address_picture1 || '',
    birthdate: account?.birth_date || '',
    city: account?.city || '',
    firstName: account?.firstName || '',
    lastName: account?.lastName || '',
    license_picture: account?.license_picture || '',
    ssn: account?.ssn || '',
    ssn_picture: account?.ssn_picture || '',
    state: account?.state || '',
    zip_code: account?.zip_code || '',
  }
  const toast = useToast()

  const downloadLetterHandler = async () => {
    setDowloading(true)

    if (!userInformation.address_picture) {
      toast.notify({
        type: 'failure',
        message:
          'Missing proof of address. Please go to "Personal Documents" and upload a picture.',
      })
    } else if (!userInformation.license_picture) {
      toast.notify({
        type: 'failure',
        message:
          'Missing ID / Driver License. Please go to "Personal Documents" and upload a picture.',
      })
    } else if (!userInformation.ssn_picture) {
      toast.notify({
        type: 'failure',
        message:
          'Missing Social Security Card. Please go to "Personal Documents" and upload a picture.',
      })
    } else {
      const documents = bureaus.map((bureau) =>
        insertInformationToHtml(rawHtml, userInformation, bureau, extraFields, {
          accountName: '',
          accountNumber: '',
          accountBalance: 0,
        }),
      )

      try {
        const blob = await pdf(
          <PDFDocument
            htmlDocuments={documents}
            userInformation={userInformation}
            selectedBureaus={bureaus}
          />,
        ).toBlob()
        saveFileAs(blob, `${name}-${Date.now().toString()}`)
      } catch (error) {
        toast.notify({
          type: 'failure',
          message: 'Error downloading PDF',
        })
        console.error(error)
      }
    }
    
    setDowloading(false)
  }

  return (
    <div className="mt-4 space-y-4">
      <div className="text-left">
        <div className="text-sm text-gray-500">Category:</div>
        <div>{capitalize(category.replace('_', ' '))}</div>
      </div>

      <div className="text-left">
        <div className="text-sm text-gray-500">Status:</div>
        <div>{easyLetterFormatter(status)}</div>
      </div>

      <div className="text-left">
        <div className="text-sm text-gray-500">Letter For:</div>
        <div>
          {bureaus.map(
            (name, i) =>
              `${easyLetterFormatter(name)}${
                i === bureaus.length - 1 ? '' : ', '
              }`,
          )}
        </div>
      </div>
      <div className="flex gap-4">
        <Button
          $fluid
          $type="secondary"
          onClick={() => navigation(`/letters/${itemId}/${id}`)}
        >
          Edit Letter
        </Button>
        {status === LetterStatus_Enum.SavedDownloaded && (
          <Button
            $fluid
            onClick={() => downloadLetterHandler()}
            loading={dowloading}
          >
            Download Letter
          </Button>
        )}
      </div>
    </div>
  )
}

export default withContainer(withAsideMenu(DisputeLetters))
