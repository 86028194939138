import { DetailedHTMLProps, InputHTMLAttributes } from 'react'
import cc from 'classcat'

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label?: string
}

const FormRadio = ({ label, ...props }: Props) => {
  const id = props.id || `radio-${props.name}-${props.value}`

  return (
    <>
      <input
        {...props}
        className="hidden"
        type="radio"
        data-testid={`input-radio-${props.name}`}
      />

      <label htmlFor={id} className="inline-flex items-center">
        <div
          className={cc([
            'relative block w-6 h-6 border border-gray-500 rounded-full',
            {
              'bg-white cursor-pointer': !props!.disabled,
              'bg-gray-400': props.disabled,
            },
          ])}
        >
          {props.checked && (
            <span
              className="absolute block bg-primary  rounded-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              style={{
                width: '74%',
                height: '74%',
              }}
            />
          )}
        </div>
        {label && <div className="pl-2 text-gray-700">{label}</div>}
      </label>
    </>
  )
}

FormRadio.defaultProps = {
  checked: false,
}

export default FormRadio
