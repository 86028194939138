import React from 'react'
import Container from 'components/Container'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import Title from 'components/Title'
import { useGetCreditItemByIdQuery } from 'generated/graphql'
import withContainer from 'hocs/withContainer'
import { useNavigate, useParams } from 'react-router-dom'
import Collapsable from 'components/Collapsable'
import LetterForm from 'components/LetterForm/index'
import Button from 'components/Button'
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-payment.svg'
import SectionLocked from 'components/SectionLocked'

const today = new Date().toISOString()

function NewLetterPage() {
  const { itemID } = useParams<{ itemID: string }>()
  const [{ data, fetching, error }] = useGetCreditItemByIdQuery({
    variables: { id: itemID, today },
  })
  const navigation = useNavigate()

  if (fetching) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data || !data.CreditItem_by_pk || !itemID) {
    return null
  }

  return (
    <SectionLocked>
      <Container>
        <div className="pb-5 pt-5 flex gap-5">
          <button
            onClick={() => {
              navigation('/letters')
            }}
          >
            <IconArrowRight className="w-4 transform rotate-180 fill-gray-600" />
          </button>
          <div>
            <Title
              $align="left"
              title="Dispute Letters"
              subtitle="Choose the letter you want to print"
            />
          </div>
        </div>
        <div className="bg-white w-full p-4 border-gray-600 border rounded-md">
          <Collapsable
            trigger={() => (
              <strong className="text-lg">
                {data.CreditItem_by_pk?.account_name}
              </strong>
            )}
            initialValue={true}
          >
            <LetterForm
              accountName={data.CreditItem_by_pk.account_name}
              accountNumber={data.CreditItem_by_pk.account_number}
              accountBalance={data.CreditItem_by_pk.total}
              accountCategory={data.CreditItem_by_pk.category}
              itemID={itemID}
              allowNewLetter={
                data.CreditItem_by_pk.Letters_aggregate.aggregate?.count === 0
              }
            />
          </Collapsable>
        </div>
        <div className="mt-5">
          <Button
            $type="tertiary"
            $fluid
            onClick={() => navigation('/letters')}
          >
            Go back to letters
          </Button>
        </div>
      </Container>
    </SectionLocked>
  )
}

export default withContainer(NewLetterPage)
