import Container from 'components/Container'
import Title from 'components/Title'
import Checkbox from 'components/Checkbox'
// import FormCheckbox from 'forms/FormCheckbox'
import React, { useState } from 'react'
import { Navigator, useStepper } from 'Stepper'

function CurrentProblems() {
  const { getDefault } = useStepper()
  const [selectedOptions, setSelectedOptions] = useState(
    getDefault('currentProblems') || [],
  )
  const canContinue = selectedOptions.length > 0
  const OPTIONS = [
    {
      id: 'collection',
      label: 'Collections',
    },
    {
      id: 'late-payments',
      label: 'Late Payments',
    },
    {
      id: 'medical',
      label: 'Medical Bills',
    },
    {
      id: 'student-loan',
      label: 'Student Loans',
    },
    {
      id: 'inquiries',
      label: 'Inquires',
    },
    {
      id: 'new-credit',
      label: 'New Credit',
    },
    {
      id: 'others',
      label: 'Other',
    },
  ]

  return (
    <Container>
      <Title
        title={'What is affecting your credit?'}
        subtitle="Let's establish a strategy based on the negative details affecting
          your credit."
      />

      <ul className="grid grid-cols-2 gap-4 py-2 max-w-full list-none">
        {' '}
        {OPTIONS.map(({ id, label }) => (
          <li key={id}>
            <div className="bg-white hover:bg-gray-200 shadow-lg rounded-lg  relative overflow-hidden flex flex-col justify-center items-center text-sm cursor-pointer">
              <div className="w-full p-4">
                <Checkbox
                  id={`option-${id}`}
                  value={id}
                  checked={selectedOptions.includes(id)}
                  onChange={() => {
                    if (selectedOptions.includes(id)) {
                      setSelectedOptions(
                        selectedOptions.filter((op: string) => op !== id),
                      )
                    } else {
                      setSelectedOptions([...selectedOptions, id])
                    }
                  }}
                  label={label}
                />
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Navigator
        saveDataHandler={() => selectedOptions}
        $disableContinue={!canContinue}
      />
    </Container>
  )
}

export default CurrentProblems
