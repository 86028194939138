import { useAuth } from 'clients/auth.client'
import Container from 'components/Container'
import Redirect from 'components/Redirect'
import { createElement } from 'react'

export default function Payment() {
  const { isAuthenticated, account } = useAuth()

  if (!isAuthenticated) {
    return <Redirect to="/" />
  }

  const table = createElement('stripe-pricing-table', {
    'pricing-table-id': 'prctbl_1Pj3IPKjq4esjnjblJeLlMNk',
    'publishable-key':
      'pk_live_51MCqUqKjq4esjnjbyQ2UgosASwrYwFeAJOK2fbyOn6kWIfUGFBmalHR8xpaHqmQLjadTrRVhlJBmdD30XxjuYGFu00hWa5Rmse',
    'client-reference-id': account.id,
  })

  return <Container>{table}</Container>
}
