import cc from 'classcat'
import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useState,
} from 'react'
import autosize from 'autosize'
import NumberFormat from 'react-number-format'
import ReactInputMask from 'react-input-mask'
import omit from 'lodash/omit'
import { ReactComponent as IconLock } from 'assets/icons/lock.svg'

const STYLES_COMMON = `w-full rounded-md border py-2 px-3 text-md`

export const STYLES_ENABLE = [
  STYLES_COMMON,
  'bg-white placeholder-gray-600 border-gray-600',
].join(' ')
export const STYLES_DISABLE = [
  STYLES_COMMON,
  'bg-gray-200 text-gray-800 border-gray-600',
].join(' ')

type CommonProps = {
  subtext?: string
  subtextAlign?: 'left' | 'center' | 'right'
  error?: string
  label?: string
  $disableLabel?: boolean
  $maskType?: 'localNumber' | 'money'
  $mask?: string
  onValueChange?: (value: string | number) => void
  $locked?: boolean
}

type Props =
  | ({
      $type: 'text'
    } & CommonProps &
      DetailedHTMLProps<
        InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >)
  | ({
      $type: 'longtext'
    } & CommonProps &
      DetailedHTMLProps<
        TextareaHTMLAttributes<HTMLTextAreaElement>,
        HTMLTextAreaElement
      >)

const FormField = forwardRef<any, any>(
  (
    {
      subtext,
      subtextAlign,
      error,
      label,
      $disableLabel,
      $type,
      $mask,
      $maskType,
      onValueChange,
      $locked,
      ...nativeAttrs
    }: Props,
    ref,
  ) => {
    // TODO: This is experimental, let's see how it works
    const [element, setElement] = useState<any>(null)
    const [hasValue, setHasValue] = useState(false)
    useEffect(() => {
      setHasValue(!!element?.value)

      if ($type === 'longtext') {
        autosize(element)
      }

      return () => {
        if ($type === 'longtext') {
          autosize.destroy(element)
        }
      }
    }, [element])

    return (
      <FormFieldWrapper>
        {!$disableLabel && hasValue && (
          <FormFieldLabel htmlFor={nativeAttrs.name!}>
            {label || nativeAttrs.placeholder}
          </FormFieldLabel>
        )}

        {$type === 'text' ? (
          $maskType ? (
            <NumberFormat
              {...(omit(nativeAttrs, ['onChange']) as any)}
              thousandSeparator
              onValueChange={({ floatValue }) => {
                if (floatValue != null) {
                  onValueChange?.(floatValue)
                }
              }}
              prefix={$maskType === 'money' ? '$' : undefined}
              customInput={(props) => {
                return (
                  <FormField
                    {...((props as any) || {})}
                    $type="text"
                    type="tel"
                  />
                )
              }}
            />
          ) : $mask ? (
            <>
              <ReactInputMask
                data-testid={`input-${nativeAttrs.name || nativeAttrs.id}`}
                {...(nativeAttrs as any)}
                mask={$mask}
                placeholder="Social Security Number"
                maskPlaceholder={' '}
                className={cc([
                  {
                    [STYLES_ENABLE]: !nativeAttrs.disabled,
                    [STYLES_DISABLE]: nativeAttrs.disabled,
                  },
                ])}
                onChange={(e) => {
                  setHasValue(!!e.target.value)
                  nativeAttrs.onChange?.(e as any)
                }}
                ref={(el) => {
                  setElement(el)
                  return (ref as any)?.(el)
                }}
              />
            </>
          ) : (
            <div className="relative">
              <input
                data-testid={`input-${nativeAttrs.name || nativeAttrs.id}`}
                {...(nativeAttrs as any)}
                className={cc([
                  {
                    [STYLES_ENABLE]: !nativeAttrs.disabled,
                    [STYLES_DISABLE]: nativeAttrs.disabled,
                  },
                ])}
                onChange={(e) => {
                  setHasValue(!!e.target.value)
                  nativeAttrs.onChange?.(e as any)
                }}
                ref={(el) => {
                  setElement(el)
                  return (ref as any)?.(el)
                }}
              />
              {$locked && nativeAttrs.disabled && (
                <span className="absolute top-0 right-0 flex items-center justify-center h-full p-2">
                  <IconLock width={20} />
                </span>
              )}
            </div>
          )
        ) : (
          <textarea
            data-testid={`input-${nativeAttrs.name || nativeAttrs.id}`}
            {...(nativeAttrs as any)}
            className={cc([
              'resize-none min-h-8',
              {
                [STYLES_ENABLE]: !nativeAttrs.disabled,
                [STYLES_DISABLE]: nativeAttrs.disabled,
              },
            ])}
            onChange={(e) => {
              setHasValue(!!e.target.value)
              nativeAttrs.onChange?.(e as any)
            }}
            ref={(el) => {
              setElement(el)
              return (ref as any)?.(el)
            }}
            rows={1}
          />
        )}

        {subtext && (
          <div
            className={cc([
              'text-gray-500 text-xs',
              {
                'text-left': subtextAlign === 'left',
                'text-center': subtextAlign === 'center',
                'text-right': subtextAlign === 'right',
              },
              { 'pt-1': $type === 'text' },
            ])}
          >
            {subtext}
          </div>
        )}
        {error && <div className="text-action-fail text-sm pt-1">{error}</div>}
      </FormFieldWrapper>
    )
  },
)

FormField.defaultProps = {
  type: 'text',
  $type: 'text',
}

export default FormField

export const FormFieldWrapper = ({ children }: { children: any }) => (
  <div className="relative">{children}</div>
)

export const FormFieldLabel = ({
  htmlFor,
  children,
}: {
  htmlFor: string
  children: any
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className="text-gray-700 text-xs absolute bottom-full pb-1"
    >
      {children}
    </label>
  )
}
