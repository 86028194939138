import Container from 'components/Container'
import FormField from 'components/FormField'
import Title from 'components/Title'
import withContainer from 'hocs/withContainer'
import * as yup from 'yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import FormGroup from 'components/FormGroup'
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg'
import BackgroundImage from 'components/BackgroundImage'
import FormDropDown from 'forms/FormDropDown'
import Button from 'components/Button'
import FilePicker from 'utils/FilePicker'
import {
  GetCitiesByStateDocument,
  UploadProfilePictureDocument,
  UploadProfilePictureMutation,
  useCreateUserAddressMutation,
  useGetUserInformationQuery,
  useUpdateUserProfileMutation,
  useUpodateUserAddressMutation,
} from 'generated/graphql'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { useClient } from 'urql'
import { useAuth } from 'clients/auth.client'
import { useToast } from 'components/ToastMessage'
import STATES from 'states.json'
import DatePicker from 'react-date-picker'
import dayjs from 'dayjs'
import withAsideMenu from 'hocs/withAsideMenu'
import { Link } from 'react-router-dom'
import { Videos } from 'globalConstants'
import { ReactComponent as IconLock } from 'assets/icons/lock.svg'
import PlayVideoButton from 'components/PlayVideoButton'
import PhoneInput from 'react-phone-input-2'
import sanitizeData from 'utils/sanitizeData'

type ProfileImageType = {
  imageBase64: string
  imageType: string
  imageRaw: string
}

function SettingsAccount() {
  const [{ data, error, fetching }] = useGetUserInformationQuery()

  if (fetching) return <Loading />

  if (!data?.UserProfile || error) {
    return (
      <ErrorMessage>
        {error?.message || 'There was an issue fetching the data'}
      </ErrorMessage>
    )
  }
  const UserProfile = data.UserProfile[0]
  const { UserAddress } = UserProfile

  return (
    <Container>
      <div className="max-w-2xl mx-auto">
        <div className="pb-5 pt-5 flex items-center">
          <div className="max-w-settingsTitle">
            <Title
              $align="left"
              title=" My Account Settings"
              subtitle="Add your personal information exactly as it appears on your social security."
            />
          </div>
          <div className="ml-auto">
            <PlayVideoButton video={Videos.ACCOUNT_SETTINGS} />
          </div>
        </div>
        {/* User Profile info */}
        <UserProfileForm
          initialValues={{
            firstName: UserProfile.first_name,
            lastName: UserProfile.last_name,
            middleName: UserProfile.middle_name || '',
            phoneNumber: UserProfile.phone || '',
            ssn: UserProfile.ssn || '',
            picture: UserProfile.ProfilePicture?.url,
            birthDate: UserProfile.birth_date || '',
          }}
        />
        {/* Adress Information */}
        <AddressForm
          initialValues={
            UserAddress
              ? {
                  address_1: UserAddress?.address_line_1,
                  address_2: UserAddress?.address_line_2 || '',
                  city: UserAddress?.city,
                  state: UserAddress?.state,
                  zip: UserAddress?.zip_code,
                }
              : undefined
          }
          profileId={UserProfile.id}
        />
        <Link to="/my-documents">
          <Button $fluid>Continue to next Step</Button>
        </Link>
      </div>
    </Container>
  )
}

const ConfirmPopup = ({
  onConfirm,
  onCancel,
  autoConfirm,
}: {
  onConfirm: () => void
  onCancel: () => void
  autoConfirm?: boolean
}) => {
  const toast = useToast()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (autoConfirm) {
      toast.dismiss(0)
      onConfirm()
    } else {
      setShow(true)
    }
  }, [])

  if (!show) return null

  return (
    <div className="px-8">
      <div className="mb-8 text-center">
        <strong className="text-lg">Careful!</strong> <br /> You can only save
        your account settings once If you want to change your settings later
        you'd have to contact support.
        <br />
        <strong>So please check your information before submit it!</strong>
      </div>
      <div className="flex gap-3">
        <Button
          onClick={() => {
            toast.dismiss(0)
            onConfirm()
          }}
          $fluid
          type="button"
        >
          Confirm
        </Button>
        <Button $fluid $type="secondary" onClick={() => onCancel()}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

type InitialValuesProfile = {
  firstName: string
  middleName?: string
  lastName: string
  phoneNumber: string
  picture?: string
  ssn: string
  birthDate: string
}

const UserProfileForm = ({
  initialValues,
}: {
  initialValues: InitialValuesProfile
}) => {
  const [pictureState, setPictureState] = useState<ProfileImageType | null>(
    null,
  )
  const [updateProfileState, updateProfileMutation] =
    useUpdateUserProfileMutation()
  const [value, onChange] = useState(
    initialValues.birthDate
      ? new Date(dayjs(initialValues.birthDate).format('MM/DD/YYYY'))
      : new Date(),
  )
  const { account, updateAccount } = useAuth()
  const toast = useToast()
  const client = useClient()

  const phoneRegex = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/
  const ssnRegex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/
  const today = new Date()
  const MIN_DATE = dayjs(today).subtract(120, 'year').toDate()
  const MAX_DATE = dayjs(today).subtract(18, 'year').toDate()

  const { register, formState, handleSubmit, setValue, watch } = useForm({
    resolver: yupResolver(
      yup.object({
        firstName: yup.string().required(),
        middleName: yup.string(),
        lastName: yup.string().required(),
        picture: yup.string(),
        phoneNumber: yup
          .string()
          .matches(phoneRegex, 'Phone Number is not valid'),
        socialNumber: yup
          .string()
          .required()
          .matches(
            ssnRegex,
            'SSN Number is not valid, enter it as XXX-XX-XXXX',
          ),
        birthDate: yup
          .date()
          .required()
          .min(MIN_DATE, 'Select a valid date')
          .max(MAX_DATE, 'You must be at least 18 years old'),
      }),
    ),
    defaultValues: {
      firstName: initialValues.firstName,
      middleName: initialValues.middleName,
      lastName: initialValues.lastName,
      picture: initialValues.picture,
      phoneNumber: initialValues.phoneNumber.replace('+1', ''),
      socialNumber: initialValues.ssn,
      birthDate: initialValues.birthDate
        ? new Date(initialValues.birthDate)
        : new Date(),
    },
  })

  const watchPhoneNumber = watch('phoneNumber')

  const addProfilePicture = async () => {
    const { base64String, mime } = await FilePicker('image/*')
    const imageRaw = `data:${mime};base64,${base64String}`
    setPictureState({ imageBase64: base64String, imageType: mime, imageRaw })
    setValue('picture', imageRaw, { shouldDirty: true })
  }

  const { errors, isDirty, isSubmitting } = formState

  type valuesSubmit = {
    firstName: string
    middleName: string | undefined
    lastName: string
    picture: string | undefined
    phoneNumber: string
    socialNumber: string
    birthDate: Date
  }

  const submitHandler = async (values: valuesSubmit) => {
    if (pictureState) {
      await client
        .mutation<UploadProfilePictureMutation>(UploadProfilePictureDocument, {
          imageBase64: pictureState.imageBase64,
          imageType: pictureState.imageType,
        })
        .toPromise()
    }
    const result = await updateProfileMutation({
      email: account?.email,
      set: {
        first_name:
          initialValues.ssn && initialValues.firstName
            ? initialValues.firstName
            : sanitizeData(values.firstName),
        last_name:
          initialValues.ssn && initialValues.lastName
            ? initialValues.lastName
            : sanitizeData(values.lastName),
        middle_name:
          initialValues.ssn && initialValues.middleName
            ? initialValues.middleName
            : sanitizeData(values.middleName || ''),
        phone: `+1${values.phoneNumber}`,
        ssn: initialValues.ssn || values.socialNumber,
        birth_date:
          initialValues.ssn && initialValues.birthDate
            ? initialValues.birthDate
            : values.birthDate,
      },
    })

    if (result.data?.update_UserProfile) {
      toast.notify({
        type: 'success',
        message: 'Your personal information was updated!',
      })
      const returningValues = result.data.update_UserProfile?.returning[0]
      updateAccount({
        firstName: returningValues?.first_name,
        lastName: returningValues?.last_name,
        picture: returningValues.ProfilePicture?.url,
        ssn: returningValues.ssn || '',
        birth_date: returningValues.birth_date,
      })
    } else {
      toast.notify({
        type: 'failure',
        message: 'There was a problem updating your information',
      })
    }
  }

  const watchMiddleName = watch('middleName') || ''

  const allRequiredInfoCompleted =
    initialValues.birthDate &&
    initialValues.firstName &&
    initialValues.lastName &&
    initialValues.ssn
      ? true
      : false

  return (
    <div className="border-b border-gray-500 py-3">
      <div className="my-4">
        <Title title="Personal Information" />
      </div>
      <form
        onSubmit={handleSubmit(async (values) => {
          const middleNameCondition = initialValues.middleName
            ? true
            : !initialValues.middleName && watchMiddleName.length === 0
            ? true
            : false

          toast.notify({
            type: 'warning',
            preventAutoClose: true,
            message: () => (
              <ConfirmPopup
                onCancel={() => {
                  toast.dismiss(0)
                }}
                onConfirm={handleSubmit(async (values) => {
                  submitHandler(values)
                })}
                autoConfirm={allRequiredInfoCompleted && middleNameCondition}
              />
            ),
          })
        })}
      >
        <FormGroup>
          <FormField
            {...register('firstName')}
            placeholder="First Name"
            error={errors.firstName?.message}
            disabled={initialValues.firstName && initialValues.ssn}
            $locked
          />
          <FormField
            {...register('middleName')}
            placeholder="Middle Name"
            error={errors.middleName?.message}
            disabled={initialValues.middleName && initialValues.ssn}
            $locked
          />
          <FormField
            {...register('lastName')}
            placeholder="Last Name"
            error={errors.lastName?.message}
            disabled={initialValues.lastName && initialValues.ssn}
            $locked
          />
          <BackgroundImage
            image={pictureState?.imageRaw || initialValues.picture || ''}
            loadHandler={false}
            className="inline-block rounded-full w-32 h-32 border-2 border-white rounded-full relative border border-gray-500"
          >
            <button
              className="absolute rounded-full bg-black bg-opacity-60 p-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              onClick={() => addProfilePicture()}
              type="button"
            >
              <IconCamera width={15} className="fill-white" />
            </button>
          </BackgroundImage>

          <div className="w-full relative mt-2">
            {watchPhoneNumber && (
              <label className="text-gray-700 text-xs absolute bottom-full pb-1">
                Enter phone number
              </label>
            )}
            <PhoneInput
              containerClass="w-full flex gap-3"
              placeholder="Enter phone number"
              {...register('phoneNumber')}
              value={watchPhoneNumber}
              onChange={(value) => {
                setValue('phoneNumber', value, { shouldDirty: true })
              }}
              disableDropdown
              showDropdown={false}
              disableCountryCode
              onlyCountries={['us']}
              country={'us'}
            />
            {errors.phoneNumber && (
              <div className="text-action-fail text-sm pt-1">
                {errors.phoneNumber.message}
              </div>
            )}
          </div>

          <FormField
            {...register('socialNumber')}
            placeholder="Social Security Number"
            error={errors.socialNumber?.message}
            disabled={initialValues.ssn}
            $mask="999-99-9999"
            $locked
          />
          <div>
            <label className="text-gray-700 text-xs  bottom-full pb-1">
              Date of birth
            </label>
            <div className="w-full">
              <div className="inline-block relative pr-8">
                <DatePicker
                  {...register('birthDate')}
                  onChange={(value: Date) => {
                    setValue('birthDate', value, {
                      shouldDirty: true,
                    })
                    onChange(value)
                  }}
                  value={value}
                  disabled={!!initialValues.birthDate && !!initialValues.ssn}
                />
                {!!initialValues.birthDate && !!initialValues.ssn && (
                  <span className="absolute top-0 right-0 flex items-center justify-center h-full p-2">
                    <IconLock width={20} />
                  </span>
                )}
              </div>
              {errors.birthDate && (
                <div className="text-action-fail text-sm pt-1">
                  {errors.birthDate.message}
                </div>
              )}
            </div>
          </div>
          {updateProfileState.error?.message && (
            <ErrorMessage>{updateProfileState.error?.message}</ErrorMessage>
          )}
          <Button $fluid disabled={!isDirty} loading={isSubmitting}>
            Save
          </Button>
        </FormGroup>
      </form>
    </div>
  )
}

type InitialValuesAddress = {
  address_1: string
  address_2?: string
  city: string
  state: string
  zip: string
}

type cityType = {
  name: string
  country_code: string
  state_code: string
}

const AddressForm = ({
  initialValues,
  profileId,
}: {
  initialValues?: InitialValuesAddress | undefined
  profileId: string
}) => {
  const [stateCode, setStateCode] = useState<string | undefined>(
    initialValues?.state,
  )
  const [citiesState, setCitiesState] = useState<cityType[]>([])
  const [fetchingCities, setFetchingCities] = useState(false)
  const [citiesError, setCitiesError] = useState('')
  const client = useClient()
  const { account, updateAccount } = useAuth()
  const { register, formState, handleSubmit, setValue } = useForm({
    resolver: yupResolver(
      yup.object({
        address_1: yup.string().required(),
        address_2: yup.string(),
        city: yup.string(),
        state: yup.string().required(),
        zip: yup.string().required(),
      }),
    ),
    defaultValues: {
      address_1: initialValues?.address_1 || '',
      address_2: initialValues?.address_2 || '',
      city: initialValues?.city || '',
      state: initialValues?.state || '',
      zip: initialValues?.zip || '',
    },
  })
  const [createAddressStae, createAddressMutation] =
    useCreateUserAddressMutation()
  const [updateAddressState, updateAddressMutation] =
    useUpodateUserAddressMutation()
  const [updateProfileState, updateProfileMutation] =
    useUpdateUserProfileMutation()
  const { errors, isDirty, isSubmitting } = formState
  const toast = useToast()

  const fetchCities = async () => {
    setFetchingCities(true)
    setCitiesState([])
    setValue('city', '')
    if (stateCode) {
      const result = await client
        .query(GetCitiesByStateDocument, { stateCode, countryCode: 'US' })
        .toPromise()
      if (result.error) {
        setCitiesError(result.error.message)
        setFetchingCities(false)
      }
      if (result.data.getCitiesByState) {
        setCitiesState(result.data.getCitiesByState)
        citiesError && setCitiesError('')
      }
    }
    setFetchingCities(false)
  }

  useEffect(() => {
    fetchCities()
  }, [stateCode])

  const completeHandler = (
    address_line_1: string,
    address_line_2: string,
    city: string,
    state: string,
    zip_code: string,
    message: string,
  ) => {
    toast.notify({
      type: 'success',
      message,
    })

    updateAccount({
      ...account,
      address1: address_line_1,
      address2: address_line_2,
      city,
      state,
      zip_code,
    })
  }

  return (
    <div className="py-3">
      <div className="my-4">
        <Title title="Actual Address" />
      </div>
      <form
        onSubmit={handleSubmit(async (values) => {
          if (!initialValues) {
            const result = await createAddressMutation({
              object: {
                address_line_1: values.address_1,
                address_line_2: values.address_2,
                city: values.city,
                profile_id: profileId,
                state: values.state,
                zip_code: values.zip,
              },
            })
            if (result.data?.insert_UserAddress_one) {
              const updateProfileResult = await updateProfileMutation({
                email: account?.email,
                set: { address_id: result.data.insert_UserAddress_one.id },
              })
              const returningValues = result.data.insert_UserAddress_one

              result.data && updateProfileResult
                ? completeHandler(
                    returningValues.address_line_1,
                    returningValues.address_line_2 || '',
                    returningValues.city,
                    returningValues.state,
                    returningValues.zip_code,
                    'Your address  was updated!',
                  )
                : toast.notify({
                    type: 'failure',
                    message: 'There was a problem updating your informatioN!',
                  })
            }
          } else {
            const result = await updateAddressMutation({
              userId: account?.id,
              set: {
                address_line_1: values.address_1,
                address_line_2: values.address_2,
                city: values.city,
                state: values.state,
                zip_code: values.zip,
              },
            })

            const returningValues =
              result.data?.update_UserAddress?.returning[0]

            result.data && returningValues
              ? completeHandler(
                  returningValues.address_line_1,
                  returningValues.address_line_2 || '',
                  returningValues.city,
                  returningValues.state,
                  returningValues.zip_code,
                  'Your address  was updated!',
                )
              : toast.notify({
                  type: 'failure',
                  message: 'There was a problem updating your informatioN!',
                })
          }
        })}
      >
        <FormGroup>
          <FormDropDown
            {...register('state')}
            placeholder="State / Province"
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setStateCode(e.target.value)
              setValue('state', e.target.value, { shouldDirty: true })
            }}
            disabled={fetchingCities}
            error={errors.state?.message}
          >
            {STATES.states.map((state, i) => (
              <option key={`state-${i}`} value={state.stateCode}>
                {state.name}
              </option>
            ))}
          </FormDropDown>
          {/* 
            DropDown not selecting initial city due to array being empty
          */}

          <FormDropDown
            {...register('city')}
            placeholder="City"
            error={errors.city?.message}
            loading={fetchingCities}
          >
            {citiesState.map((city, i) => (
              <option
                key={`state-${i}`}
                selected={initialValues?.city === city.name}
              >
                {city.name}
              </option>
            ))}
          </FormDropDown>

          <FormField
            {...register('address_1')}
            placeholder="Street"
            error={errors.address_1?.message}
          />
          <FormField
            {...register('address_2')}
            placeholder="Street 2 (Optional)"
            error={errors.address_2?.message}
          />

          <FormField
            {...register('zip')}
            placeholder="Zip / Postal Code"
            error={errors.zip?.message}
          />

          {(updateProfileState.error?.message ||
            updateAddressState.error?.message ||
            createAddressStae.error?.message) && (
            <ErrorMessage>
              {updateProfileState.error?.message ||
                updateAddressState.error?.message ||
                createAddressStae.error?.message ||
                ''}
            </ErrorMessage>
          )}
          {citiesError && <ErrorMessage>{citiesError}</ErrorMessage>}
          <Button
            $fluid
            disabled={!isDirty}
            loading={isSubmitting || fetchingCities}
          >
            Save
          </Button>
        </FormGroup>
      </form>
    </div>
  )
}

export default withContainer(withAsideMenu(SettingsAccount))
