import { Document, Page, StyleSheet } from '@react-pdf/renderer'
import ConvertHtmlToPDF from 'components/ConvertHtmlToPDF'
import { AccountInformationType, UserInformationType } from 'globalConstants'
import { useEffect, useState } from 'react'
import insertInformationToHtml from 'utils/insertInformationToHtml'

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 64,
    fontSize: 16,
  },
  section: {
    flexGrow: 1,
  },
})

const PDFPreview = ({
  htmlString,
  userInformation,
  accountInformation,
  extraVariables,
}: {
  htmlString: string
  userInformation: UserInformationType
  accountInformation: AccountInformationType
  extraVariables: any
}) => {
  const [htmlState, setHtmlState] = useState(htmlString)

  useEffect(() => {
    setHtmlState(htmlString)
  }, [htmlString])

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {insertInformationToHtml(
          htmlState,
          userInformation,
          'trans_union',
          extraVariables,
          accountInformation,
        )
          .replace(/<br>/g, '<C></C>')
          .split('<C></C>')
          .map((line) => (
            <ConvertHtmlToPDF elementString={line} />
          ))}
      </Page>
    </Document>
  )
}

export default PDFPreview
