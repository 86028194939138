import Button from 'components/Button'
import withContainer from 'hocs/withContainer'
import { useNavigate } from 'react-router-dom'

function PageNotFound() {
  const navigation = useNavigate()

  return (
    <div className="p-4 text-center">
      <h1 className="text-xl font-bold pb-2">404 Page Not Found</h1>
      <Button
        onClick={() => {
          navigation('/')
        }}
      >
        Go Back To Homepage
      </Button>
    </div>
  )
}

export default withContainer(PageNotFound)
