import { ReactComponent as Hamburger } from 'assets/icons/nav.svg'
import { ReactComponent as Bell } from 'assets/icons/notifications.svg'
import Menu from 'components/Menu'
import { Link, useLocation } from 'react-router-dom'
import { useAuth } from 'clients/auth.client'
import BackgroundImage from 'components/BackgroundImage'
import PlaceholderAvatar from 'assets/avatar-profile.svg'
import { useEffect, useState } from 'react'
import LogoIcon from 'assets/nav-icon.png'
import { useCountUnseenNotificationsSubscription } from 'generated/graphql'
import useSound from 'use-sound'
const notificationSound = require('assets/swiftly-610.mp3')

const Header = () => {
  const [menu, setMenu] = useState(false)
  const { isAuthenticated, account } = useAuth()
  const location = useLocation()

  useEffect(() => {
    setMenu(false)
  }, [location])

  // TODO: Find a more scalable solution
  if (location.pathname.split('/')[1] === 'user' && !isAuthenticated) {
    return null
  }

  const RenderUserIsLoggedInItems = () => {
    return (
      <div className="flex justify-center items-center">
        <NotificationsIcons />

        <Link to="/my-account" data-testid="header-picture">
          <BackgroundImage
            image={account?.picture || PlaceholderAvatar}
            width={30}
            height={30}
            className="rounded-full m-2"
          />
        </Link>
      </div>
    )
  }

  return (
    <>
      <header
        data-testid="page-header"
        className="sticky top-0 bg-white bg-opacity-50 z-3 h-14 backdrop-filter backdrop-blur"
      >
        <div className="max-w-screen-2xl mx-auto w-full flex justify-between items-center">
          <div className="flex items-center">
            {/* Menu */}
            <button
              onClick={() => {
                setMenu((current) => !current)
              }}
              className="py-5 px-3 sm:p-5"
              data-testid="button-menu"
            >
              <Hamburger width="20" />
            </button>

            {/* Logo */}
            <Link to="/" className="inline-block">
              <img src={LogoIcon} alt="Logo" className="w-40" />
            </Link>
          </div>
          {account && (
            <div className="ml-3 sm:ml-0 mr-4">
              <RenderUserIsLoggedInItems />
            </div>
          )}
        </div>
      </header>
      <Menu
        isOpen={menu}
        toggle={() => {
          setMenu(false)
        }}
      />
    </>
  )
}

export default Header

const NotificationsIcons = () => {
  const [notificationState] = useCountUnseenNotificationsSubscription()
  const notificationCount =
    notificationState.data?.Notification_aggregate.aggregate?.count || 0
  const [prevCountNotification, setPrevCountNotification] =
    useState(notificationCount)
  const [loaded, setLoaded] = useState(false)
  const [play] = useSound(notificationSound)
  const showDot = !!notificationCount

  useEffect(() => {
    const canPlay = loaded && notificationCount > prevCountNotification
    if (!loaded && !notificationState.fetching) {
      setLoaded(true)
    }

    if (canPlay) {
      play()
    }
    setPrevCountNotification(
      notificationState.data?.Notification_aggregate.aggregate?.count || 0,
    )
  }, [notificationState.data, notificationState.fetching])

  return (
    <>
      <Link to="/notifications">
        <div className="relative">
          <Bell height="30" className="m-2" />
          {showDot && (
            <div className="-top-0.5 left-2 ml-1.5 rounded-full absolute border-2 border-white">
              <div className="bg-primary w-2 h-2 rounded-full" />
            </div>
          )}
        </div>
      </Link>
    </>
  )
}
