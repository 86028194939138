// import logger from "./logger";

function makeStorage<Type>(
  key: string,
  initialData?: Type,
): {
  get: () => Type | null
  set: (data: Type) => Type
  update: (newData: Type) => Type
  remove: () => void
  reset: () => void
} {
  const get = (): Type | null => {
    const storedData = localStorage.getItem(key)

    if (!storedData) {
      return null
    }

    try {
      return JSON.parse(storedData)
    } catch (err) {
      //logger is causing problems
      // logger.log("error", `Malformed storage '${key}'`);
      console.log('error', `Malformed storage '${key}'`)

      return null
    }
  }

  const set = (data: Type): Type => {
    localStorage.setItem(key, JSON.stringify(data))

    return data
  }

  const update = (newData: Type): Type => {
    return set({
      ...get(),
      ...newData,
    })
  }

  const remove = () => {
    localStorage.removeItem(key)
  }

  const reset = () => {
    if (initialData) {
      return set(initialData)
    } else {
      remove()
      return null
    }
  }

  return {
    get,
    set,
    update,
    remove,
    reset,
  }
}

export default makeStorage
