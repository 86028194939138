import ScoreWheel from 'components/ScoreWheel'
import getStatus from 'utils/getStatus'
import { INTEREST_PER_STATUS, LOAN_TYPES } from 'config'
import calculateLoan from 'utils/calculateLoan'
import React from 'react'
import { loanTypes, statusType } from 'globalConstants'
import Highlight from 'components/Highlight'
import Media from 'react-media'

const CreditSimulator = ({
  score,
  loanType,
  person,
}: {
  score: number
  loanType: loanTypes
  person: string
}) => {
  const status = getStatus(score)
  const interest = INTEREST_PER_STATUS[loanType][status.id as statusType]
  const { cost, months, img } = LOAN_TYPES[loanType]
  const monthlyPayment = calculateLoan(cost, interest, months)
  const interestCost = monthlyPayment * months - cost

  return (
    <div>
      <div
        className="bg-white pt-2.5 px-2.5 pb-3.5 overflow-hidden text-center rounded-lg border-b-8"
        style={{ borderColor: status.colors[1] }}
      >
        <div className="text-sm">Custom credit simulator</div>
        {person && (
          <div className="text-lg">
            <strong>{person}</strong>
          </div>
        )}

        <div className="inline-flex justify-center items-center my-5">
          <div>
            <ScoreWheel
              $size="medium"
              score={score}
              disabled
              $trackColor={'#fff'}
            />
          </div>
          <Media query={{ maxWidth: 400 }}>
            {(matches) => {
              return (
                <img
                  src={img}
                  alt={loanType}
                  style={{
                    width: matches ? 150 : 250,
                  }}
                />
              )
            }}
          </Media>
        </div>

        <div className="w-full p-2.5">
          <div className="m-0 p-0 list-none grid grid-cols-3 text-center">
            <GridItem>
              <div className="text-xs">Value</div>
              <div className="text-sm sm:text-base">
                <Highlight score={score}>${cost.toLocaleString()}</Highlight>
              </div>
            </GridItem>
            <GridItem>
              <div className="flex gap-1 text-xs">
                <Highlight score={score}>{interest}%</Highlight> interests
              </div>
              <div className="text-sm sm:text-base">
                <Highlight score={score}>
                  -${interestCost.toLocaleString()}
                </Highlight>
              </div>
            </GridItem>
            <GridItem>
              <div className="text-xs">Monthly payment</div>
              <div className="text-sm sm:text-base">
                <Highlight score={score}>
                  ${monthlyPayment.toLocaleString()}
                </Highlight>
              </div>
            </GridItem>
          </div>
        </div>
      </div>
    </div>
  )
}

const GridItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="pr-1.5">
      <div className="inline-block">{children}</div>
    </div>
  )
}

export default CreditSimulator
