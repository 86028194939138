import { useEffect } from 'react'

const Redirect = ({ to }: { to: string }) => {
  useEffect(() => {
    window.location.href = to
  }, [])

  return null
}

export default Redirect
