import { useToast } from 'components/ToastMessage'
import { useNavigate } from 'react-router-dom'
import Container from 'components/Container'
import Button from 'components/Button'

const SectionLockedPopup = () => {
  const navigation = useNavigate()
  const toast = useToast()
  return (
    <Container>
      <div className="flex items-center flex-col gap-6">
        <div>
          <div className="font-bold text-lg">Section Locked!</div>
          <div className="text-gray-600">
            This Section is locked until you finish your profile, personal
            documents and credit items.
          </div>
        </div>
        <Button
          onClick={() => {
            toast.dismiss(0)
            navigation('/')
          }}
        >
          Check what is missing
        </Button>
      </div>
    </Container>
  )
}

export default SectionLockedPopup
