type Props = {
  children: string
}

const ErrorMessage = ({ children }: Props) => (
  <div className="text-action-fail py-2">
    {children.replace('[GraphQL]', '').trim()}
  </div>
)

export default ErrorMessage
