import { useStepper } from 'Stepper'
import FormSignup from 'forms/FormSignup'
import CreditSimulatorPage from 'pages/CreditSimulatorPage'
import confetti from 'assets/confetti.gif'
import ScoreWheel from 'components/ScoreWheel'
import { useAuth } from 'clients/auth.client'
import Redirect from 'components/Redirect'
import LogoIcon from 'assets/nav-icon.png'

const Simulator = () => {
  const { isAuthenticated } = useAuth()

  if (isAuthenticated) {
    return <Redirect to="/" />
  }

  return (
    <div>
      <div className="text-center pb-10">

      <img src={LogoIcon} alt="Logo" className="inline-block w-60" />
      </div>
      <CreditSimulatorPage LastStep={LastStep} />
    </div>
  )
}

export default Simulator

const LastStep = () => {
  const { getDefault } = useStepper()
  const currentScore = getDefault('currentScore')
  const { simulatedScore } = getDefault('recommendations')

  return (
    <div className="relative">
      <div className="absolute w-full aspect-w-16 aspect-h-9">
        {/* Confetti */}
        <div className="absolute left-0 top-0 -translate-y-1/2">
          <img src={confetti} alt="congrats" className="w-full" />
        </div>
      </div>

      <div className="relative space-y-6 p-2">
        {/* Score */}
        <div className="flex gap-4 md:gap-20 justify-center">
          <div className="text-center space-y-4 flex flex-col">
            <div>
              <ScoreWheel disabled score={currentScore} $size="medium" />
            </div>
            <div className="text-xl font-bold">Before</div>
          </div>
          <div className="text-center space-y-4 flex flex-col">
            <div>
              <ScoreWheel disabled score={simulatedScore} $size="medium" />
            </div>

            <div className="text-xl font-bold">After</div>
          </div>
        </div>

        {/* Title */}
        <h1 className="text-center font-bold text-3xl">
          Congratulations!
          <br />
          You can fix your own credit with Myself Credit.
        </h1>
      </div>

      <div className="pt-6">
        <FormSignup />
      </div>
    </div>
  )
}
