import React from 'react'
import cc from 'classcat'
import Button from 'components/Button'
import UserCard from 'components/UserCard'
import Link from 'components/Link'
import { useSpring, config, animated } from 'react-spring'
import { isOfType } from 'utils/types.util'
import { useAuth } from 'clients/auth.client'
import { ReactComponent as IconX } from 'assets/icons/x.svg'
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-payment.svg'
import useToggle from 'hooks/useToggle'
import useCurrentPath from 'hooks/useCurrentPath'
import LogoIcon from 'assets/nav-icon.png'
import useMenu from 'hooks/useMenu'

type Props = {
  isOpen: boolean
  toggle: () => void
}

type Type = 'primary' | 'secondary' | 'logout'

type MenuLink = {
  to?: string | MenuLink[]
  onClick?: () => void
  text: string
  $type?: Type
  initialOpen?: boolean
}

type MenuCustomLink = {
  Component: React.FC
}

const Menu = ({ isOpen, toggle }: Props) => {
  const menu = useMenu()
  const overlayStyle = useSpring({
    from: { display: 'none', opacity: 0 },
    to: [
      {
        display: 'block',
        opacity: isOpen ? 1 : 0,
      },
      {
        display: isOpen ? 'block' : 'none',
      },
    ],
    delay: 50,
    config: config.stiff,
  })
  const sideStyle = useSpring({
    to: { x: isOpen ? -15 : -300 },
    delay: 50,
    config: config.stiff,
  })
  const { isAuthenticated, account } = useAuth()

  return (
    <>
      {/* Overlay */}
      <animated.div
        className="z-7 fixed inset-0 bg-black bg-opacity-60 backdrop-filter backdrop-blur"
        style={overlayStyle}
        onClick={() => {
          toggle()
        }}
      />

      {/* Container */}
      <animated.div
        className="z-8 fixed top-0 left-0 flex flex-col w-72 h-full bg-white py-5 pl-8 pr-8 overflow-y-auto"
        style={sideStyle}
      >
        {/* TOP */}
        <div className="flex-grow">
          <button
            onClick={() => {
              toggle()
            }}
          >
            <IconX width="20" />
          </button>

          {/* User */}
          {isAuthenticated && (
            <div className="py-2">
              <UserCard
                name={[account?.firstName, account?.lastName]
                  .filter(Boolean)
                  .join(' ')}
                picture={account?.picture}
              />
            </div>
          )}

          {/* Logo guest */}
          {!isAuthenticated && (
            <div className="py-5">
              <Link to="/" className="inline-block mx-auto">
                <img src={LogoIcon} alt="Logo" className="max-w-full" />
              </Link>
            </div>
          )}

          {/* View Profile Button */}
          <div className="pb-3">
            {isAuthenticated ? (
              <>
                <div className="py-2">
                  <Button to={'/credit-items'} $fluid>
                    Create Items
                  </Button>
                </div>
                <div className="py-2">
                  <Button to={'/'} $fluid $type="secondary">
                    Dashboard
                  </Button>
                </div>
              </>
            ) : (
              <div className="py-2">
                <Button $fluid>Login / Register</Button>
              </div>
            )}
          </div>

          {/* Menu (Top) */}
          {isAuthenticated ? (
            <MenuLinks links={menu()} />
          ) : (
            <MenuLinks
              links={
                [
                  {
                    to: '/',
                    text: 'Home',
                  },
                  {
                    Component: () => (
                      <div
                        className="w-full py-3 hover:text-gray-700"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <a
                          href="http://myselfcredit.com/tutorials/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Tutorials
                        </a>
                      </div>
                    ),
                  },
                  {
                    to: '/tips',
                    text: 'Credit Secrets',
                  },
                ].filter(Boolean) as (MenuLink | MenuCustomLink)[]
              }
            />
          )}
        </div>

        {/* BOTTOM */}
        <div>
          {isAuthenticated && (
            <MenuLinks
              links={[
                {
                  to: 'mailto:myselfcreditapp@gmail.com',
                  text: 'Support',
                  $type: 'logout',
                },
                {
                  to: '/logout',
                  text: 'Logout',
                  $type: 'logout',
                },
              ]}
            />
          )}
        </div>
      </animated.div>
    </>
  )
}

type MenuLinksProps = {
  links: (MenuLink | MenuCustomLink)[]
  $type?: Type
}

const MenuLinks = ({ links, $type }: MenuLinksProps) => {
  const currentPath = useCurrentPath()

  return (
    <ul>
      {links.map((link, index) => (
        <li key={`menu-link-${index}`} className="border-b border-gray-400">
          {isOfType(link, 'Component') ? (
            <link.Component />
          ) : Array.isArray(link.to) ? (
            <SubMenu
              text={link.text}
              links={link.to}
              initialOpen={link.initialOpen}
            />
          ) : link.to ? (
            <Link
              to={link.to}
              className={`${index !== 0 ? 'w-full py-3' : 'py-3'}`}
              active={currentPath === link.to}
              $type={link.$type || $type}
              data-testid={`link`}
              data-value={link.to}
            >
              {link.text}
            </Link>
          ) : (
            <div />
          )}
        </li>
      ))}
    </ul>
  )
}

type SubMenuProps = {
  text: string
  links: (MenuLink | MenuCustomLink)[]
  initialOpen?: boolean
}

const SubMenu = ({ text, links, initialOpen }: SubMenuProps) => {
  const [isOpen, toggle] = useToggle(initialOpen || false)

  return (
    <>
      <button
        className="w-full py-3"
        onClick={() => {
          toggle()
        }}
        data-testid="button-settings"
      >
        <span className="flex items-center justify-between">
          <span>{text}</span>
          <IconArrowRight
            className={cc([
              'w-2 transform',
              {
                'rotate-90': !isOpen,
                '-rotate-90': isOpen,
              },
            ])}
          />
        </span>
      </button>
      {isOpen && <MenuLinks links={links} $type="secondary" />}
    </>
  )
}

export default Menu
