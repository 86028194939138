import get from 'lodash/fp/get'
import { FC } from 'react'
import { CombinedError, Query, QueryProps } from 'urql'
import { LoadingSpinner } from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'

type ErrorProps = { error: CombinedError }
type SuccessProps = { data: any }

interface Props extends Omit<QueryProps, 'children'> {
  Loading: FC
  Error: FC<ErrorProps>
  Success: FC<SuccessProps>
  dataAccess?: String
  NoResults?: FC
}

const SimpleQuery = ({
  Loading,
  Error,
  Success,
  NoResults,
  dataAccess,
  ...props
}: Props) => {
  return (
    <Query {...props}>
      {({ fetching, error, data }) => {
        if (fetching) {
          return <Loading />
        }

        if (error) {
          return <Error error={error} />
        }

        if (!data) {
          return <div />
        }

        const accessedData = dataAccess ? get(dataAccess as any, data) : data

        if (
          NoResults &&
          Array.isArray(accessedData) &&
          accessedData.length === 0
        ) {
          return <NoResults />
        }

        return <Success data={accessedData} />
      }}
    </Query>
  )
}

SimpleQuery.defaultProps = {
  Loading: () => <LoadingSpinner $size="md" $color="primary" $type="dots" />,
  Error: ({ error }: ErrorProps) => (
    <ErrorMessage>{error.message}</ErrorMessage>
  ),
}

export default SimpleQuery
