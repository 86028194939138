import React, { useState } from 'react'
import Container from 'components/Container'
import Title from 'components/Title'
import { ReactComponent as IconHouse } from 'assets/icons/icon-house.svg'
import { ReactComponent as IconCar } from 'assets/icons/icon-car.svg'
import { ReactComponent as IconCreditCard } from 'assets/icons/icon-credit-card.svg'
import { ReactComponent as IconLoan } from 'assets/icons/icon-loan.svg'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'
import cc from 'classcat'
import { Navigator, useStepper } from 'Stepper'

function Reason() {
  const { getDefault } = useStepper()
  const [selectedOption, setSelectedOption] = useState(
    getDefault('reason') || '',
  )

  const OPTIONS = [
    {
      id: 'home',
      Icon: IconHouse,
      label: 'Buy a House',
    },
    {
      id: 'car',
      Icon: IconCar,
      label: 'Buy a Car',
    },
    {
      id: 'credit-card',
      Icon: IconCreditCard,
      label: 'Credit Cards',
    },
    {
      id: 'loan',
      Icon: IconLoan,
      label: 'Loans',
    },
  ]
  return (
    <Container>
      <div className="max-w-2xl mx-auto text-center">
        <Title
          title={'Why do you want to fix your credit?'}
          subtitle="Select the objective for which you want to repair your credit."
        />
        <ul className="grid-cols-2 grid rows-2 gap-4">
          {OPTIONS.map(({ id, Icon, label }) => (
            <li key={`option-${id}`}>
              <SquareBoxOption
                Icon={Icon}
                $selected={selectedOption === id}
                onClick={() => {
                  setSelectedOption(id)
                }}
                label={label}
              />
            </li>
          ))}
        </ul>

        <Navigator
          $disableContinue={selectedOption.length === 0}
          saveDataHandler={() => selectedOption}
          $continueText="Start"
        />
      </div>
    </Container>
  )
}

const SquareBoxOption = ({
  Icon,
  $selected,
  onClick,
  label,
}: {
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
  $selected: boolean
  onClick: () => void
  label: string
}) => {
  return (
    <div
      className="bg-white shadow-lg rounded-lg h-44 relative overflow-hidden flex flex-col justify-center items-center text-sm cursor-pointer"
      onClick={() => onClick()}
    >
      {$selected && (
        <div className="absolute top-2.5 bg-primary rounded-full p-1.5">
          <IconCheck width="10" className="fill-white" />
        </div>
      )}
      <Icon width="50" />
      <div className={cc(['pt-2.5', { 'font-bold': $selected }])}>{label}</div>

      {$selected && <div className="absolute bottom-0 w-full h-2 bg-primary" />}
    </div>
  )
}

export default Reason
