import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import SectionLockedPopup from 'components/SectionLockedPopup'
import { useToast } from 'components/ToastMessage'
import { useGetMissingStepsQuery } from 'generated/graphql'
import React, { useEffect } from 'react'

function SectionLocked({ children }: { children: React.ReactNode }) {
  const [{ data, error, fetching }] = useGetMissingStepsQuery()
  const toast = useToast()

  useEffect(() => {
    let allStepsCompleted = false
    if (data?.checkMissingSteps && !fetching) {
      const steps = data.checkMissingSteps.filter(
        (step) => step?.key !== 'letters',
      )
      for (const step of steps) {
        if (step?.completed) {
          allStepsCompleted = true
        } else {
          allStepsCompleted = false
          break
        }
      }
      !allStepsCompleted &&
        toast.notify({
          type: 'warning',
          preventAutoClose: true,
          preventClose: true,
          message: SectionLockedPopup,
        })
    }
  }, [data])

  if (fetching) {
    return <Loading />
  }

  if (error?.message) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data || !data.checkMissingSteps || !data.checkMissingSteps.length) {
    return null
  }
  return <>{children}</>
}

export default SectionLocked
