import {
  Document,
  Page,
  StyleSheet,
  Image,
  View,
  Text,
  Font,
} from '@react-pdf/renderer'
import ConvertHtmlToPDF from 'components/ConvertHtmlToPDF'
import { bureausVariables, UserInformationType } from 'globalConstants'
import ArialRegular from 'assets/fonts/arial.ttf'
import ArialBold from 'assets/fonts/arialBold.ttf'
import { capitalize, get } from 'lodash'
import getFullName from 'utils/getFullName'

// Create styles
const styles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: 64,
  },
  section: {
    flexGrow: 1,
  },
  imageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
  },
  image: {
    maxHeight: '220px',
    margin: '12px 0',
  },
  folderContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Arial',
  },
  folderCenterInfo: {
    marginTop: 12,
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    textAlign: 'center',
  },
  folderUserInfo: { padding: 44 },
  /*
    Note: text align doesn't work correctly on final PDF so it requires flex center to work correctly
  */
  textCenter: {
    display: 'flex',
    alignSelf: 'center',
    lineHeight: 1.4,
    fontSize: 12,
  },
  bold: { fontWeight: 'bold' },
  folderText: { margin: '2px 0 0 0', fontSize: 12 },
})

Font.register({
  family: 'Arial',
  fonts: [
    {
      src: ArialRegular,
    },
    {
      src: ArialBold,
      fontWeight: 'bold',
    },
  ],
})

const PDFDocument = ({
  htmlDocuments,
  userInformation,
  selectedBureaus,
}: {
  htmlDocuments: string[]
  userInformation: UserInformationType
  selectedBureaus: string[]
}) => {
  return (
    <Document>
      {selectedBureaus.map((bureau) => (
        <FolderItem userInformation={userInformation} bureau={bureau} />
      ))}
      {htmlDocuments &&
        htmlDocuments.map((html, i) => (
          <>
            <Page size="A4" style={styles.page}>
              {html
                .replace(/<br>/g, '<C></C>')
                .split('<C></C>')
                .map((line) => (
                  <ConvertHtmlToPDF elementString={line} />
                ))}
            </Page>
            <Page
              style={{ ...styles.page, ...styles.imageContainer }}
              size="A5"
            >
              {userInformation.ssn_picture && (
                <Image src={userInformation.ssn_picture} style={styles.image} />
              )}
              {userInformation.license_picture && (
                <Image
                  src={userInformation.license_picture}
                  style={styles.image}
                />
              )}
            </Page>

            {userInformation?.address_picture && (
              <Page wrap style={styles.page}>
                <Image src={userInformation.address_picture} />
              </Page>
            )}
          </>
        ))}
    </Document>
  )
}

const FolderItem = ({
  userInformation,
  bureau,
}: {
  userInformation: UserInformationType
  bureau: string
}) => {
  return (
    <Page size={{ height: 350, width: 680 }} style={styles.folderContainer}>
      <View style={styles.folderUserInfo}>
        <Text style={{ fontSize: 15, fontWeight: 'bold' }}>
          {getFullName(userInformation.firstName, userInformation.lastName)}
        </Text>
        <Text style={styles.folderText}>
          {capitalize(userInformation.address1)}
        </Text>
        <Text
          style={styles.folderText}
        >{`${userInformation.city}, ${userInformation.state}. ${userInformation.zip_code}`}</Text>
      </View>
      <View style={styles.folderCenterInfo}>
        <Text
          style={{
            ...styles.textCenter,
            fontWeight: 'bold',
            lineHeight: 1.6,
            fontSize: 14,
          }}
        >
          {get(bureausVariables, `${bureau}.RecipientName`)}
        </Text>
        <Text style={styles.textCenter}>
          {get(bureausVariables, `${bureau}.RecipientAddressLine1`)}
        </Text>
        <Text style={styles.textCenter}>
          {get(bureausVariables, `${bureau}.RecipientCityStatePostalCode`)}
        </Text>
      </View>
    </Page>
  )
}

export default PDFDocument
