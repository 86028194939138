import React from 'react'

function ProgresBar({ value }: { value: number }) {
  return (
    <div className="h-2 w-full bg-white overflow-hidden relative">
      <div
        className="absolute h-full left-0 top-0 bg-primary "
        style={{ width: `${value}%`, transition: `all 300ms ease-in-out` }}
      />
    </div>
  )
}

export default ProgresBar
