import { useEffect, useRef, useState } from 'react'
import Checkbox from 'components/Checkbox'
import CreditSimulator from 'components/CreditSimulator'
import config, { DEFAULT_SCORE, MAX_REPAIRABLE_SCORE, MAX_SCORE } from 'config'
import { Navigator, useStepper } from 'Stepper'
import roundDownToNearest10 from 'utils/roundDownToNearest10'
import Container from 'components/Container'
import Title from 'components/Title'

export const simulateScore = (currentScore: number, selectedOptions = []) => {
  const maxBigImpactScore = 700
  const maxBigImpactSelection = 3

  if (currentScore >= maxBigImpactScore - maxBigImpactSelection * 10) {
    const newSimulatedScore = currentScore + selectedOptions.length * 10
    const scoreLimit =
      currentScore >= 700
        ? currentScore > MAX_REPAIRABLE_SCORE
          ? currentScore
          : MAX_REPAIRABLE_SCORE
        : MAX_SCORE

    return newSimulatedScore > scoreLimit ? scoreLimit : newSimulatedScore
  }

  if (selectedOptions.length === config.recommendations.length) {
    return MAX_REPAIRABLE_SCORE
  }

  const diffToMaxBigImpact = roundDownToNearest10(
    (maxBigImpactScore - currentScore) / maxBigImpactSelection,
  )

  const diffSmallImpact = roundDownToNearest10(
    (MAX_REPAIRABLE_SCORE -
      (diffToMaxBigImpact * maxBigImpactSelection + currentScore)) /
      (config.recommendations.length - maxBigImpactSelection),
  )
  const sortedSelectedOptions = selectedOptions
  const newSimulatedScore = sortedSelectedOptions.reduce((acc, _opt, index) => {
    return (
      acc +
      (index < maxBigImpactSelection ? diffToMaxBigImpact : diffSmallImpact)
    )
  }, currentScore)

  return newSimulatedScore > MAX_SCORE ? MAX_SCORE : newSimulatedScore
}

const Recommendations = () => {
  const { getDefault } = useStepper()
  const currentScore = getDefault('currentScore') || DEFAULT_SCORE
  const [selectedOptions, setSelectedOptions] = useState(
    getDefault('recommendations.selectedOptions') || [],
  )
  const [simulatedScore, setSimulatedScore] = useState(currentScore)
  const reason = getDefault('reason')
  const { firstName, lastName } = getDefault('personalInfo') || {}
  const simRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setSimulatedScore(simulateScore(currentScore, selectedOptions))
  }, [currentScore, selectedOptions])

  return (
    <Container className="relative">
      <div className="max-w-2xl mx-auto text-center">
        <Title
          title={
            'Select the recommendations, and you will see how much $ you can save with good credit.'
          }
        />

        <div ref={simRef}>
          <CreditSimulator
            score={simulatedScore}
            loanType={reason}
            person={[firstName, lastName].filter(Boolean).join(' ')}
          />
        </div>
        <ul className="relative z-1 my-2 -mx-2">
          {config.recommendations.map(({ id, title, body }) => (
            <li key={`option-${id}`} className="flex p-3">
              <Checkbox
                $size="lg"
                id={`recommendation-${id}`}
                checked={selectedOptions.includes(id)}
                onChange={() => {
                  if (selectedOptions.includes(id)) {
                    setSelectedOptions(
                      selectedOptions.filter((op: string) => op !== id),
                    )
                  } else {
                    setSelectedOptions([...selectedOptions, id])
                  }
                }}
              />
              <div className="text-left ml-4">
                <strong>{title}</strong>
                <div className="text-xs sm:text-sm">{body}</div>
              </div>
            </li>
          ))}
        </ul>
        <div className='relative z-1"'>
          <Navigator
            saveDataHandler={() => ({ simulatedScore, selectedOptions })}
            $disableContinue={selectedOptions.length === 0}
          />
        </div>
      </div>
    </Container>
  )
}

export default Recommendations
