import cc from 'classcat'
import { Link as RouterLink } from 'react-router-dom'

type Type = 'primary' | 'secondary' | 'logout'

type Props = {
  to: string
  children: React.ReactNode
  className?: string
  active?: boolean
  $type?: Type
  ['data-testid']?: string
}

const classMap: {
  [key: string]: {
    active: string
    notActive: string
  }
} = {
  primary: {
    active: 'text-primary font-medium',
    notActive: 'text-black hover:text-gray-700',
  },
  secondary: {
    active: 'text-primary font-medium ml-6',
    notActive: 'text-gray-600 hover:text-gray-800 ml-6',
  },
  logout: {
    active: 'text-primary font-medium',
    notActive: 'text-gray-600 hover:text-gray-800',
  },
}

const Link = ({ to, children, className, active, $type, ...rest }: Props) => {
  const $className = cc([
    'inline-block text-left',
    $type && classMap[$type][active ? 'active' : 'notActive'],
    className,
  ])

  if (
    typeof to === 'string' &&
    (to.startsWith('http') || to.startsWith('mailto'))
  ) {
    return (
      <a
        {...rest}
        href={to}
        target="_blank"
        rel="noreferrer"
        className={$className}
      >
        {children}
      </a>
    )
  }

  return (
    <RouterLink {...rest} to={to} className={$className}>
      {children}
    </RouterLink>
  )
}

Link.defaultProps = {
  $type: 'primary',
}

export default Link
