import BackgroundImage from 'components/BackgroundImage'
import Container from 'components/Container'
import Title from 'components/Title'
import withContainer from 'hocs/withContainer'
import React, { useState } from 'react'
import { ReactComponent as IconCamera } from 'assets/icons/camera.svg'
import { useAuth } from 'clients/auth.client'
import FilePicker from 'utils/FilePicker'
import Button from 'components/Button'
import {
  GetPersonalDocumentsQuery,
  PersonalDocumentType_Enum,
  useDeletePersonalDocumentMutation,
  useGetPersonalDocumentsQuery,
  // PersonalDocumentType_Enum,
  useUploadPersonalDocumentMutation,
} from 'generated/graphql'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { useToast } from 'components/ToastMessage'
import withAsideMenu from 'hocs/withAsideMenu'
import { Link } from 'react-router-dom'
import PlayVideoButton from 'components/PlayVideoButton'
import { Videos } from 'globalConstants'

type pictureType = {
  base64String?: string
  type?: string
  image?: string
}

type pictureToUpload = pictureType & {
  documentType: string
  oldId: string | undefined
}

const imageResolver = yup.object({
  base64String: yup.string(),
  type: yup.string(),
  image: yup.string(),
})

function PersonalDocuments() {
  const [{ data, error, fetching }] = useGetPersonalDocumentsQuery()

  if (fetching) return <Loading />

  if (error) return <ErrorMessage>{error.message}</ErrorMessage>

  if (!data || !data.PersonalDocument) return null

  return (
    <Container>
      <Form data={data?.PersonalDocument} />
    </Container>
  )
}

const Form = ({
  data,
}: {
  data: GetPersonalDocumentsQuery['PersonalDocument']
}) => {
  //constant style for the bottom image selectors
  const bottomSelectosStyle = 'aspect-w-4 aspect-h-7'
  const [current, setCurrent] = useState(0)
  const [total, setTotal] = useState(0)
  const { isAuthenticated, updateAccount } = useAuth()

  /* INITIAL IMAGES */
  const sscInitialImage = data.find(
    (document) => document.type === PersonalDocumentType_Enum.Ssc,
  )
  const driverLicenseInitialImage = data.find(
    (document) => document.type === PersonalDocumentType_Enum.DriverLicense,
  )
  const addressInitialImage = data.find(
    (document) => document.type === PersonalDocumentType_Enum.Address,
  )
  const address2InitialImage = data.find(
    (document) => document.type === PersonalDocumentType_Enum.Address_2,
  )

  const [, uploadDocumentMutation] = useUploadPersonalDocumentMutation()
  const [, deleteDocument] = useDeletePersonalDocumentMutation()
  const toast = useToast()

  const { setValue, handleSubmit, formState, getValues } = useForm({
    resolver: yupResolver(
      yup.object({
        ssc: imageResolver,
        driver_license: imageResolver,
        proof_address: imageResolver,
        proof_address2: imageResolver,
      }),
    ),
    defaultValues: {
      ssc: {
        base64String: '',
        type: '',
        image: sscInitialImage?.File.url,
      },
      driver_license: {
        base64String: '',
        type: '',
        image: driverLicenseInitialImage?.File.url,
      },
      proof_address: {
        base64String: '',
        type: '',
        image: addressInitialImage?.File.url,
      },
      proof_address2: {
        base64String: '',
        type: '',
        image: address2InitialImage?.File.url,
      },
    },
  })
  const { isSubmitting, isDirty } = formState

  if (!isAuthenticated) {
    return null
  }

  return (
    <div>
      <form
        onSubmit={handleSubmit(async (values) => {
          const images: pictureToUpload[] = [
            {
              ...values.ssc,
              documentType: PersonalDocumentType_Enum.Ssc,
              oldId: sscInitialImage?.id,
            },
            {
              ...values.driver_license,
              documentType: PersonalDocumentType_Enum.DriverLicense,
              oldId: driverLicenseInitialImage?.id,
            },
            {
              ...values.proof_address,
              documentType: PersonalDocumentType_Enum.Address,
              oldId: addressInitialImage?.id,
            },
            {
              ...values.proof_address2,
              documentType: PersonalDocumentType_Enum.Address_2,
              oldId: address2InitialImage?.id,
            },
          ].filter((image) => image.base64String && image.type)

          let currentImage = 0
          let hasErrors = false

          setTotal(images.length)

          let uploadedImages = {
            ssn_picture: '',
            license_picture: '',
            address_picture1: '',
            address_picture2: '',
          }

          for (const image of images) {
            const { base64String, type, documentType, oldId } = image
            setCurrent(currentImage)
            const result = await uploadDocumentMutation({
              documentType: documentType,
              imageBase64: base64String || '',
              imageType: type || '',
            })
            if (oldId) {
              await deleteDocument({ oldDocumentId: oldId })
            }
            currentImage = currentImage + 1

            if (result.error) hasErrors = true
            else {
              const imageUrl =
                result.data?.UploadPersonalDocument?.imageUrl || ''
              switch (documentType) {
                case PersonalDocumentType_Enum.Ssc:
                  uploadedImages.ssn_picture = imageUrl || ''
                  break
                case PersonalDocumentType_Enum.DriverLicense:
                  uploadedImages.license_picture = imageUrl || ''
                  break
                case PersonalDocumentType_Enum.Address:
                  uploadedImages.address_picture1 = imageUrl || ''
                  break
                case PersonalDocumentType_Enum.Address_2:
                  uploadedImages.address_picture2 = imageUrl || ''
                  break
              }
            }
          }

          setTotal(0)
          setCurrent(0)
          if (!hasErrors) {
            toast.notify({
              type: 'success',
              message: 'Your information has been updated!',
            })
            updateAccount({
              ssn_picture: uploadedImages.ssn_picture,
              address_picture1: uploadedImages.address_picture1,
              address_picture2: uploadedImages.address_picture2,
              license_picture: uploadedImages.license_picture,
            })
          } else {
            toast.notify({
              type: 'failure',
              message:
                'There was a problem updating your information please try again later',
            })
          }
        })}
      >
        <div className="max-w-2xl mx-auto">
          <div className="pb-5 pt-5 flex items-center">
            <div className="max-w-settingsTitle">
              <Title
                $align="left"
                title="Personal Documents"
                subtitle="Upload a valid ID, SS card, and an up-to-date Proof of address."
              />
            </div>
            <div className="ml-auto">
              <PlayVideoButton video={Videos.DOCUMENTS} />
            </div>
          </div>
          <div className="space-y-8">
            <div>
              <div className="mb-2">
                Upload your driver License or identification Card
              </div>
              <PictureSelector
                onChange={async (image) => {
                  setValue(
                    'driver_license',
                    {
                      base64String: image.base64String || '',
                      image: image.image || '',
                      type: image.type || '',
                    },
                    { shouldDirty: true },
                  )
                }}
                initialImage={getValues('driver_license').image}
              />
            </div>
            <div>
              <div className="mb-2">Upload your Social Security Card </div>
              <PictureSelector
                onChange={(image) => {
                  setValue(
                    'ssc',
                    {
                      base64String: image.base64String || '',
                      image: image.image || '',
                      type: image.type || '',
                    },
                    { shouldDirty: true },
                  )
                }}
                initialImage={getValues('ssc').image}
              />
            </div>
            <div>
              <div className="mb-2">
                Upload Two Proof of address for verification
              </div>
              <div className="flex gap-5">
                <div className="w-full">
                  <PictureSelector
                    className={bottomSelectosStyle}
                    onChange={(image) => {
                      setValue(
                        'proof_address',
                        {
                          base64String: image.base64String || '',
                          image: image.image || '',
                          type: image.type || '',
                        },
                        { shouldDirty: true },
                      )
                    }}
                    initialImage={getValues('proof_address').image}
                  />
                </div>
                <div className="w-full">
                  <PictureSelector
                    className={bottomSelectosStyle}
                    onChange={(image) => {
                      setValue(
                        'proof_address2',
                        {
                          base64String: image.base64String || '',
                          image: image.image || '',
                          type: image.type || '',
                        },
                        { shouldDirty: true },
                      )
                    }}
                    initialImage={getValues('proof_address2').image}
                  />
                </div>
              </div>
            </div>
            {total > 0 && (
              <div className="text-center">{`Uploading your images ${current}/${total}`}</div>
            )}
            <Button $fluid loading={isSubmitting} disabled={!isDirty}>
              Save
            </Button>
            <Link to="/credit-items">
              <Button $fluid className="mt-3">
                Continue to next Step
              </Button>
            </Link>
          </div>
        </div>
      </form>
    </div>
  )
}

const PictureSelector = ({
  className,
  onChange,
  initialImage,
}: {
  className?: string
  initialImage?: string
  onChange: (picture: pictureType) => void
}) => {
  const [picture, setPicture] = useState(initialImage || '')
  const { isAuthenticated } = useAuth()
  const addCoverHandler = async () => {
    if (!isAuthenticated) {
      return
    }

    const { base64String, mime } = await FilePicker('image/*')
    const imageRaw = `data:${mime};base64,${base64String}`
    setPicture(imageRaw)
    onChange({ base64String, type: mime, image: imageRaw })
  }

  return (
    <div className={className || 'aspect-w-16 aspect-h-7'}>
      <BackgroundImage
        image={picture}
        loadHandler={false}
        className="flex items-end justify-end filter  overflow-hidden rounded-xl border border-gray-500"
      >
        <div className="p-4">
          <button
            type="button"
            className="bg-black rounded-full bg-opacity-60 p-2"
            onClick={() => {
              addCoverHandler()
            }}
          >
            <IconCamera height="17" className="fill-white" />
          </button>
        </div>
      </BackgroundImage>
    </div>
  )
}

export default withContainer(withAsideMenu(PersonalDocuments))
