import React from 'react'
import ReactPlayer from 'react-player'

function VideoPlayer({ url, playing }: { url: string; playing?: boolean }) {
  return (
    <div className="h-48 aspect-w-16 aspect-h-9 max-w-videoPlayer relative">
      <ReactPlayer
        url={url}
        width="100%"
        height="100%"
        controls
        className="react-player"
        playing={playing}
      />
    </div>
  )
}

export default VideoPlayer
