import { View, Text, Font } from '@react-pdf/renderer'
import ArialRegular from 'assets/fonts/arial.ttf'
import ArialBold from 'assets/fonts/arialBold.ttf'

const getTextDecoration = (value: string) => {
  const hasUnderline = value.includes('<ins>')
  const hasLinethrough = value.includes('<del>')

  return [hasLinethrough && 'line-through', hasUnderline && 'underline']
    .filter(Boolean)
    .join(' ') as
    | 'line-through'
    | 'underline'
    | 'line-through underline'
    | 'underline line-through'
}

const getElementStyle = (element: HTMLDivElement) => {
  return element.getElementsByTagName('span')[0]
}

const ConvertHtmlToPDF = ({ elementString }: { elementString: string }) => {
  let div = document.createElement('div')
  div.innerHTML = elementString.replace(/&nbsp;/g, '')
  const text = div.textContent || div.innerText
  Font.register({
    family: 'Arial',
    fonts: [
      {
        src: ArialRegular,
      },
      {
        src: ArialBold,
        fontWeight: 'bold',
      },
    ],
  })
  const span = getElementStyle(div)

  return (
    <View
      style={{
        fontFamily: 'Arial',
        padding: text.trim() ? 0 : 10,
        fontWeight: elementString.includes('<strong>') ? 'bold' : 'normal',
        fontStyle: elementString.includes('<em>') ? 'italic' : 'normal',
        textDecoration: getTextDecoration(elementString) || 'none',
        color: span ? span.style.color : '',
        fontSize: span ? span.style.fontSize : undefined,
      }}
    >
      <Text>{text.trim()}</Text>
    </View>
  )
}

export default ConvertHtmlToPDF
