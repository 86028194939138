import CircularSlider from '@fseehawer/react-circular-slider'
import { MAX_SCORE, MIN_SCORE, DEFAULT_SCORE } from 'config'
import { useCallback } from 'react'
import getStatus from 'utils/getStatus'
import patternMatching from 'utils/patternMatching'
import { ReactComponent as WhiteDot } from 'assets/white_dot.svg'
import { colors } from 'styles'

const ScoreWheel = ({
  score,
  onChange,
  disabled,
  maxValue,
  minValue,
  label,
  $size,
  $trackColor,
}: {
  score: number
  onChange?: (value: number) => void
  disabled?: boolean
  maxValue?: number
  minValue?: number
  label?: string
  $size: 'small' | 'large' | 'extra_large' | 'medium'
  $trackColor: string
}) => {
  const maxRange = maxValue || MAX_SCORE
  const minRange = minValue || MIN_SCORE
  const status = getStatus(score)
  const scoreRange = useCallback(
    () =>
      Array((maxRange - minRange) / 10 + 1)
        .fill(10)
        .map((v, i) => v * i + minRange),
    [minRange, maxRange],
  )

  return (
    <>
      <CircularSlider
        data={scoreRange()}
        dataIndex={scoreRange().findIndex((value) => value === score)}
        width={patternMatching([
          ['small', 80],
          ['medium', 120],
          ['large', 180],
          ['extra_large', 280],
        ])($size)}
        knobPosition="bottom"
        knobColor={status.colors[1]}
        knobSize={patternMatching([
          ['small', 20],
          ['medium', 20],
          ['large', 25],
          ['extra_large', 65],
        ])($size)}
        hideKnob={disabled}
        progressColorFrom={status.colors[0]}
        progressColorTo={status.colors[1]}
        progressSize={patternMatching([
          ['small', 5],
          ['medium', 6],
          ['large', 10],
          ['extra_large', 20],
        ])($size)}
        trackColor={$trackColor}
        trackSize={patternMatching([
          ['small', 7],
          ['medium', 6],
          ['large', 20],
          ['extra_large', 20],
        ])($size)}
        label={label || status.label}
        labelBottom
        valueColo
        labelColor={status.colors[1]}
        labelFontSize={patternMatching([
          ['small', '0.5rem'],
          ['medium', '0.6rem'],
          ['large', '0.6rem'],
          ['extra_large', '1rem'],
        ])($size)}
        valueFontSize={patternMatching([
          ['small', '1.2rem'],
          ['medium', '2rem'],
          ['large', '3.2rem'],
          ['extra_large', '5rem'],
        ])($size)}
        onChange={(value: number) => {
          onChange?.(value)
        }}
        knobDraggable={!disabled}
      >
        <WhiteDot />
      </CircularSlider>
    </>
  )
}

ScoreWheel.defaultProps = {
  score: DEFAULT_SCORE,
  $size: 'extra_large',
  $trackColor: colors.white,
}

export default ScoreWheel
