import { CreditItemStatus_Enum } from 'generated/graphql'
import React from 'react'
import friendlyStringFormatter from 'utils/friendlyStringFormatter'
import cc from 'classcat'

function CreditItemStatus({ status }: { status: string }) {
  return (
    <div
      className={cc([
        'mr-1 font-bold',
        {
          'text-yellow-400': status === CreditItemStatus_Enum.InProgress,
        },
        {
          'text-green-500':
            status === CreditItemStatus_Enum.AccountFixed ||
            status === CreditItemStatus_Enum.AccountDeleted,
        },
        {
          'text-red-600': status === CreditItemStatus_Enum.Canceled,
        },
      ])}
    >
      {status
        .split('_')
        .map((value) => friendlyStringFormatter(value))
        .join(' ')}
    </div>
  )
}

export default CreditItemStatus
