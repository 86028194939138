const calculateLoan = (principal: number, apr: number, months: number) => {
  const percentageRate = apr / 1200
  const monthlyPayment =
    (principal * percentageRate) /
    (1 - Math.pow(1 + percentageRate, months * -1))

  return Math.round(monthlyPayment)
}

export default calculateLoan
