import React from 'react'
import Button from 'components/Button'
import Container from 'components/Container'
import Title from 'components/Title'
import withContainer from 'hocs/withContainer'
import { useNavigate, useParams } from 'react-router-dom'
import Collapsable from 'components/Collapsable'
import LetterForm from 'components/LetterForm/index'
import {
  Bureaus_Enum,
  useGetCreditItemByIdQuery,
  useGetLetterByIdQuery,
} from 'generated/graphql'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-payment.svg'
import SectionLocked from 'components/SectionLocked'

const today = new Date().toISOString()

function LetterPage() {
  const { itemID, letterID } = useParams<{ itemID: string; letterID: string }>()
  const navigation = useNavigate()
  const [{ data, fetching, error }] = useGetLetterByIdQuery({
    variables: { id: letterID },
  })
  const [{ data: itemData, fetching: fetchingItem, error: errorItem }] =
    useGetCreditItemByIdQuery({ variables: { id: itemID, today } })

  if (fetching || fetchingItem) return <Loading />

  if (error || errorItem)
    return (
      <ErrorMessage>{error?.message || errorItem?.message || ''}</ErrorMessage>
    )

  if (
    !data ||
    !data.Letter_by_pk ||
    !itemData ||
    !itemData.CreditItem_by_pk ||
    !data.Letter_by_pk.CreditItem
  )
    return null

  return (
    <SectionLocked>
      <Container>
        <div className="pb-5 pt-5 flex gap-5">
          <button
            onClick={() => {
              navigation('/letters')
            }}
          >
            <IconArrowRight className="w-4 transform rotate-180 fill-gray-600" />
          </button>
          <div>
            <Title
              $align="left"
              title="Dispute Letters"
              subtitle="Choose the letter you want to print"
            />
          </div>
        </div>
        <div className="bg-white w-full p-4 border-gray-600 border rounded-md">
          <Collapsable
            trigger={() => (
              <Title title={itemData.CreditItem_by_pk?.account_name || ''} />
            )}
            initialValue={true}
          >
            <LetterForm
              id={letterID}
              accountName={data.Letter_by_pk.CreditItem.account_name}
              accountNumber={data.Letter_by_pk.CreditItem.account_number}
              accountBalance={data.Letter_by_pk.CreditItem.total}
              accountCategory={data.Letter_by_pk.CreditItem.category}
              initialValues={{
                name: data.Letter_by_pk.name,
                rawHtml: data.Letter_by_pk.raw_html,
                equifax: data.Letter_by_pk.LetterBureaus.find(
                  (bureau) => bureau.value === Bureaus_Enum.Equifax,
                )?.value,
                trans_union: data.Letter_by_pk.LetterBureaus.find(
                  (bureau) => bureau.value === Bureaus_Enum.TransUnion,
                )?.value,
                experian: data.Letter_by_pk.LetterBureaus.find(
                  (bureau) => bureau.value === Bureaus_Enum.Experian,
                )?.value,
                savedAs: data.Letter_by_pk.type || '',
                extraFields: data.Letter_by_pk.extra_fields,
              }}
              allowNewLetter={
                itemData.CreditItem_by_pk.Letters_aggregate.aggregate?.count ===
                0
              }
              itemID={itemData.CreditItem_by_pk.id}
            />
          </Collapsable>
        </div>
        <div className="mt-5">
          <Button
            $type="tertiary"
            $fluid
            onClick={() => navigation('/letters')}
          >
            Go back to letters
          </Button>
        </div>
      </Container>
    </SectionLocked>
  )
}

export default withContainer(LetterPage)
