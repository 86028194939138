import Reason from './steps/Reason'
import Stepper, { Step } from 'Stepper'
import CurrentScore from './steps/CurrentScore'
import CurrentProblems from './steps/CurrentProblems'
import Recommendations from './steps/Recommendations'
import GettingStarted from './steps/GettingStarted'
import FinalStep from './steps/FinalStep'
import Container from 'components/Container'

function CreditSimulator({ LastStep }: { LastStep: any }) {
  return (
    <Container>
      <Stepper
        // debug={
        //   process.env.REACT_APP_DEBUGGER === 'true' ||
        //   process.env.NODE_ENV === 'development'
        // }
        debug={false}
        $progressBar
        $progressBarFrom={0}
        startFrom={0}
        initialData={{
          recommendations: {
            reason: 'home',
            simulatedScore: 720,
            selectedOptions: [
              'collection',
              'late-payments',
              'inquiries',
              'authorized-user',
              'secured-credit-card',
            ],
          },
        }}
      >
        {/* 0 */}
        <Step id="reason" timeToComplete={30}>
          <Reason />
        </Step>

        {/* 1 */}
        <Step id="currentScore" timeToComplete={30}>
          <CurrentScore />
        </Step>

        {/* 2 */}
        <Step id="currentProblems" timeToComplete={30}>
          <CurrentProblems />
        </Step>

        {/* 3 */}
        <Step id="recommendations" timeToComplete={6}>
          <Recommendations />
        </Step>

        {/* 4 */}
        <Step id="gettingStarted" timeToComplete={0} omitBack>
          <GettingStarted />
        </Step>

        {/* 5 */}
        <Step id="finalStep" timeToComplete={0} omitBack>
          <LastStep />
        </Step>
      </Stepper>
    </Container>
  )
}

CreditSimulator.defaultProps = {
  LastStep: FinalStep,
}

export default CreditSimulator
