import { colors } from 'styles'

type Account = any

export const ACCOUNT: {
  storageKey: string
  defaultData: Account
} = {
  storageKey: 'loggedInAccount',
  defaultData: {
    id: '',
    type: null,
  },
}

export const SCORE_STATUS = {
  poor: {
    id: 'poor',
    label: 'POOR',
    colors: [colors.red, colors.red],
  },
  decent: {
    id: 'decent',
    label: 'FAIR',
    colors: [colors.orange, colors.orange],
  },
  good: {
    id: 'good',
    label: 'GOOD',
    colors: [colors.lightGreen, colors.lightGreen],
  },
  excellent: {
    id: 'excellent',
    label: 'EXCELLENT',
    colors: [colors.green, colors.green],
  },
}

export const DEFAULT_SCORE = 350

export const MIN_SCORE = 300
export const MAX_SCORE = 850
export const MAX_REPAIRABLE_SCORE = 720

export const INTEREST_PER_STATUS = {
  home: {
    poor: 6.5,
    decent: 5.5,
    good: 4.2,
    excellent: 3.5,
  },
  car: {
    poor: 15,
    decent: 10,
    good: 4,
    excellent: 2.9,
  },
  'credit-card': {
    poor: 30,
    decent: 25,
    good: 21,
    excellent: 18,
  },
  loan: {
    poor: 20,
    decent: 15,
    good: 10,
    excellent: 5,
  },
}

export const LOAN_TYPES = {
  home: {
    img: `http://www.creditrocket.me/wp-content/uploads/2022/04/img-house.jpg`,
    cost: 250000,
    months: 30 * 12,
  },
  car: {
    img: `http://www.creditrocket.me/wp-content/uploads/2022/04/img-car.jpg`,
    cost: 40000,
    months: 60,
  },
  'credit-card': {
    img: 'http://www.creditrocket.me/wp-content/uploads/2022/04/img-cards.jpg',
    cost: 10000,
    months: 12,
  },
  loan: {
    img: 'http://www.creditrocket.me/wp-content/uploads/2022/04/img-loans.jpg',
    cost: 20000,
    months: 36,
  },
}

const config = {
  api:
    process.env.NODE_ENV === 'production'
      ? 'https://backend.creditrocket.me'
      : 'http://localhost:9000',
  sensitiveData: ['ssn'],
  plans: [
    {
      id: 'basic-biweekly',
      name: ['Plan de Reparación', 'de Crédito Ilimitado'],
      description: [
        'Expertos con 10+ años de experiencia',
        'Eliminar cuentas negativas',
        'Disputas ilimitadas',
        'Servicio garantizado',
        'Sin contrato',
        'Portal Personalizado 24/7',
      ],
      price: {
        amount: 4999,
        period: 'Quincenal',
      },
      image:
        'http://www.creditrocket.me/wp-content/uploads/2022/06/cr-plan-basico.jpg',
      strikedPrice: 69,
      active: true,
    },
    {
      id: 'basic-monthly',
      name: ['Plan de Reparación', 'de Crédito Ilimitado'],
      description: [
        'Expertos con 10+ años de experiencia',
        'Eliminar cuentas negativas',
        'Disputas ilimitadas',
        'Servicio garantizado',
        'Sin contrato',
        'Portal Personalizado 24/7',
      ],
      image:
        'http://www.creditrocket.me/wp-content/uploads/2022/06/cr-plan-basico.jpg',
      strikedPrice: 138,
      price: {
        amount: 9999,
        period: 'Mensual',
      },
      active: true,
    },
  ],
  recommendations: [
    {
      id: 'collection',
      title: 'Delete accounts in the collection',
      body: 'These are the accounts that are in loss or that were closed with negative stains.',
      bodyFuture: 'Accounts on collection deleted',
      weight: 1,
    },
    {
      id: 'late-payments',
      title: 'Eliminate late payments',
      body: 'These are the accounts that are in loss or that were closed with negative stains.',
      bodyFuture: 'Late payments deleted',
      weight: 1,
    },
    {
      id: 'inquiries',
      title: 'Delete Inquiries',
      body: 'Every time you apply for a loan, they inquire. Multiple inquiries affect your credit.',
      bodyFuture: 'Inquiries deleted',
      weight: 1,
    },
    {
      id: 'authorized-user',
      title: 'Keep your credit cards below 30%',
      body: 'If you have $1,000 in credit cards at the end of the month, your account should reflect a balance of less than $300.',
      bodyFuture: 'Using less of 30% on my credit cards',
      weight: 2,
    },
    {
      id: 'secured-credit-card',
      title: 'Get a secured card or be added as an authorized user',
      body: 'A secured credit card is one that you can open using your own money. You can ask a family member to add you as an authorized user on their cards, which will reflect that history.',
      bodyFuture: 'New card secured and/or user authorized',
      weight: 2,
    },
  ],
}

export default config
