import getStatus from 'utils/getStatus'

const Highlight = ({
  children,
  score,
}: {
  children: React.ReactNode
  score: number
}) => {
  const status = getStatus(score)
  return (
    <div style={{ color: status.colors[1] }}>
      <div>{children}</div>
    </div>
  )
}

export default Highlight
