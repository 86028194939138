import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  json: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** columns and relationships of "AccountStatus" */
export type AccountStatus = {
  __typename?: 'AccountStatus';
  value: Scalars['String'];
};

/** aggregated selection of "AccountStatus" */
export type AccountStatus_Aggregate = {
  __typename?: 'AccountStatus_aggregate';
  aggregate?: Maybe<AccountStatus_Aggregate_Fields>;
  nodes: Array<AccountStatus>;
};

/** aggregate fields of "AccountStatus" */
export type AccountStatus_Aggregate_Fields = {
  __typename?: 'AccountStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<AccountStatus_Max_Fields>;
  min?: Maybe<AccountStatus_Min_Fields>;
};


/** aggregate fields of "AccountStatus" */
export type AccountStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<AccountStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "AccountStatus". All fields are combined with a logical 'AND'. */
export type AccountStatus_Bool_Exp = {
  _and?: InputMaybe<Array<AccountStatus_Bool_Exp>>;
  _not?: InputMaybe<AccountStatus_Bool_Exp>;
  _or?: InputMaybe<Array<AccountStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "AccountStatus" */
export enum AccountStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  AccountStatusPkey = 'AccountStatus_pkey'
}

export enum AccountStatus_Enum {
  Closed = 'closed',
  Open = 'open'
}

/** Boolean expression to compare columns of type "AccountStatus_enum". All fields are combined with logical 'AND'. */
export type AccountStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AccountStatus_Enum>;
  _in?: InputMaybe<Array<AccountStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<AccountStatus_Enum>;
  _nin?: InputMaybe<Array<AccountStatus_Enum>>;
};

/** input type for inserting data into table "AccountStatus" */
export type AccountStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type AccountStatus_Max_Fields = {
  __typename?: 'AccountStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type AccountStatus_Min_Fields = {
  __typename?: 'AccountStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "AccountStatus" */
export type AccountStatus_Mutation_Response = {
  __typename?: 'AccountStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<AccountStatus>;
};

/** on_conflict condition type for table "AccountStatus" */
export type AccountStatus_On_Conflict = {
  constraint: AccountStatus_Constraint;
  update_columns?: Array<AccountStatus_Update_Column>;
  where?: InputMaybe<AccountStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "AccountStatus". */
export type AccountStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: AccountStatus */
export type AccountStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "AccountStatus" */
export enum AccountStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "AccountStatus" */
export type AccountStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "AccountStatus" */
export enum AccountStatus_Update_Column {
  /** column name */
  Value = 'value'
}

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** columns and relationships of "Bureaus" */
export type Bureaus = {
  __typename?: 'Bureaus';
  value: Scalars['String'];
};

/** aggregated selection of "Bureaus" */
export type Bureaus_Aggregate = {
  __typename?: 'Bureaus_aggregate';
  aggregate?: Maybe<Bureaus_Aggregate_Fields>;
  nodes: Array<Bureaus>;
};

/** aggregate fields of "Bureaus" */
export type Bureaus_Aggregate_Fields = {
  __typename?: 'Bureaus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Bureaus_Max_Fields>;
  min?: Maybe<Bureaus_Min_Fields>;
};


/** aggregate fields of "Bureaus" */
export type Bureaus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Bureaus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Bureaus". All fields are combined with a logical 'AND'. */
export type Bureaus_Bool_Exp = {
  _and?: InputMaybe<Array<Bureaus_Bool_Exp>>;
  _not?: InputMaybe<Bureaus_Bool_Exp>;
  _or?: InputMaybe<Array<Bureaus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Bureaus" */
export enum Bureaus_Constraint {
  /** unique or primary key constraint on columns "value" */
  BureausPkey = 'Bureaus_pkey'
}

export enum Bureaus_Enum {
  Equifax = 'equifax',
  Experian = 'experian',
  TransUnion = 'trans_union'
}

/** Boolean expression to compare columns of type "Bureaus_enum". All fields are combined with logical 'AND'. */
export type Bureaus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Bureaus_Enum>;
  _in?: InputMaybe<Array<Bureaus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Bureaus_Enum>;
  _nin?: InputMaybe<Array<Bureaus_Enum>>;
};

/** input type for inserting data into table "Bureaus" */
export type Bureaus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bureaus_Max_Fields = {
  __typename?: 'Bureaus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Bureaus_Min_Fields = {
  __typename?: 'Bureaus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Bureaus" */
export type Bureaus_Mutation_Response = {
  __typename?: 'Bureaus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Bureaus>;
};

/** on_conflict condition type for table "Bureaus" */
export type Bureaus_On_Conflict = {
  constraint: Bureaus_Constraint;
  update_columns?: Array<Bureaus_Update_Column>;
  where?: InputMaybe<Bureaus_Bool_Exp>;
};

/** Ordering options when selecting data from "Bureaus". */
export type Bureaus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Bureaus */
export type Bureaus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "Bureaus" */
export enum Bureaus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Bureaus" */
export type Bureaus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Bureaus" */
export enum Bureaus_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "Categories" */
export type Categories = {
  __typename?: 'Categories';
  value: Scalars['String'];
};

/** aggregated selection of "Categories" */
export type Categories_Aggregate = {
  __typename?: 'Categories_aggregate';
  aggregate?: Maybe<Categories_Aggregate_Fields>;
  nodes: Array<Categories>;
};

/** aggregate fields of "Categories" */
export type Categories_Aggregate_Fields = {
  __typename?: 'Categories_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Categories_Max_Fields>;
  min?: Maybe<Categories_Min_Fields>;
};


/** aggregate fields of "Categories" */
export type Categories_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Categories_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "Categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>;
  _not?: InputMaybe<Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "value" */
  CategoriesPkey = 'Categories_pkey'
}

export enum Categories_Enum {
  ChargeOff = 'charge_off',
  Collections = 'collections',
  Foreclosure = 'foreclosure',
  LatePayments = 'late_payments',
  MixedReasons = 'mixed_reasons',
  Repossession = 'repossession',
  UnknownAccounts = 'unknown_accounts'
}

/** Boolean expression to compare columns of type "Categories_enum". All fields are combined with logical 'AND'. */
export type Categories_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Categories_Enum>;
  _in?: InputMaybe<Array<Categories_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Categories_Enum>;
  _nin?: InputMaybe<Array<Categories_Enum>>;
};

/** input type for inserting data into table "Categories" */
export type Categories_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Categories_Max_Fields = {
  __typename?: 'Categories_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Categories_Min_Fields = {
  __typename?: 'Categories_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "Categories" */
export type Categories_Mutation_Response = {
  __typename?: 'Categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** on_conflict condition type for table "Categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "Categories". */
export type Categories_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Categories */
export type Categories_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "Categories" */
export enum Categories_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "Categories" */
export type Categories_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "Categories" */
export enum Categories_Update_Column {
  /** column name */
  Value = 'value'
}

export type CitiesOutput = {
  __typename?: 'CitiesOutput';
  country_code: Scalars['String'];
  name: Scalars['String'];
  state_code: Scalars['String'];
};

/** columns and relationships of "CreditItem" */
export type CreditItem = {
  __typename?: 'CreditItem';
  /** An array relationship */
  ItemBureaus: Array<ItemBureau>;
  /** An aggregate relationship */
  ItemBureaus_aggregate: ItemBureau_Aggregate;
  /** An array relationship */
  Letters: Array<Letter>;
  /** An aggregate relationship */
  Letters_aggregate: Letter_Aggregate;
  /** An object relationship */
  User?: Maybe<User>;
  account_name: Scalars['String'];
  account_number: Scalars['String'];
  account_status: AccountStatus_Enum;
  category: Categories_Enum;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  dispute_reason?: Maybe<Scalars['String']>;
  dispute_round?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  round_expired_at?: Maybe<Scalars['timestamptz']>;
  status: CreditItemStatus_Enum;
  total?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "CreditItem" */
export type CreditItemItemBureausArgs = {
  distinct_on?: InputMaybe<Array<ItemBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemBureau_Order_By>>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};


/** columns and relationships of "CreditItem" */
export type CreditItemItemBureaus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ItemBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemBureau_Order_By>>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};


/** columns and relationships of "CreditItem" */
export type CreditItemLettersArgs = {
  distinct_on?: InputMaybe<Array<Letter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letter_Order_By>>;
  where?: InputMaybe<Letter_Bool_Exp>;
};


/** columns and relationships of "CreditItem" */
export type CreditItemLetters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Letter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letter_Order_By>>;
  where?: InputMaybe<Letter_Bool_Exp>;
};

/** columns and relationships of "CreditItemStatus" */
export type CreditItemStatus = {
  __typename?: 'CreditItemStatus';
  value: Scalars['String'];
};

/** aggregated selection of "CreditItemStatus" */
export type CreditItemStatus_Aggregate = {
  __typename?: 'CreditItemStatus_aggregate';
  aggregate?: Maybe<CreditItemStatus_Aggregate_Fields>;
  nodes: Array<CreditItemStatus>;
};

/** aggregate fields of "CreditItemStatus" */
export type CreditItemStatus_Aggregate_Fields = {
  __typename?: 'CreditItemStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<CreditItemStatus_Max_Fields>;
  min?: Maybe<CreditItemStatus_Min_Fields>;
};


/** aggregate fields of "CreditItemStatus" */
export type CreditItemStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditItemStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "CreditItemStatus". All fields are combined with a logical 'AND'. */
export type CreditItemStatus_Bool_Exp = {
  _and?: InputMaybe<Array<CreditItemStatus_Bool_Exp>>;
  _not?: InputMaybe<CreditItemStatus_Bool_Exp>;
  _or?: InputMaybe<Array<CreditItemStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditItemStatus" */
export enum CreditItemStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  CreditItemStatusPkey = 'CreditItemStatus_pkey'
}

export enum CreditItemStatus_Enum {
  AccountDeleted = 'account_deleted',
  AccountFixed = 'account_fixed',
  Canceled = 'canceled',
  InProgress = 'in_progress'
}

/** Boolean expression to compare columns of type "CreditItemStatus_enum". All fields are combined with logical 'AND'. */
export type CreditItemStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<CreditItemStatus_Enum>;
  _in?: InputMaybe<Array<CreditItemStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<CreditItemStatus_Enum>;
  _nin?: InputMaybe<Array<CreditItemStatus_Enum>>;
};

/** input type for inserting data into table "CreditItemStatus" */
export type CreditItemStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type CreditItemStatus_Max_Fields = {
  __typename?: 'CreditItemStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type CreditItemStatus_Min_Fields = {
  __typename?: 'CreditItemStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "CreditItemStatus" */
export type CreditItemStatus_Mutation_Response = {
  __typename?: 'CreditItemStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditItemStatus>;
};

/** on_conflict condition type for table "CreditItemStatus" */
export type CreditItemStatus_On_Conflict = {
  constraint: CreditItemStatus_Constraint;
  update_columns?: Array<CreditItemStatus_Update_Column>;
  where?: InputMaybe<CreditItemStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditItemStatus". */
export type CreditItemStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditItemStatus */
export type CreditItemStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "CreditItemStatus" */
export enum CreditItemStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CreditItemStatus" */
export type CreditItemStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "CreditItemStatus" */
export enum CreditItemStatus_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "CreditItem" */
export type CreditItem_Aggregate = {
  __typename?: 'CreditItem_aggregate';
  aggregate?: Maybe<CreditItem_Aggregate_Fields>;
  nodes: Array<CreditItem>;
};

/** aggregate fields of "CreditItem" */
export type CreditItem_Aggregate_Fields = {
  __typename?: 'CreditItem_aggregate_fields';
  avg?: Maybe<CreditItem_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CreditItem_Max_Fields>;
  min?: Maybe<CreditItem_Min_Fields>;
  stddev?: Maybe<CreditItem_Stddev_Fields>;
  stddev_pop?: Maybe<CreditItem_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CreditItem_Stddev_Samp_Fields>;
  sum?: Maybe<CreditItem_Sum_Fields>;
  var_pop?: Maybe<CreditItem_Var_Pop_Fields>;
  var_samp?: Maybe<CreditItem_Var_Samp_Fields>;
  variance?: Maybe<CreditItem_Variance_Fields>;
};


/** aggregate fields of "CreditItem" */
export type CreditItem_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditItem_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CreditItem_Avg_Fields = {
  __typename?: 'CreditItem_avg_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CreditItem". All fields are combined with a logical 'AND'. */
export type CreditItem_Bool_Exp = {
  ItemBureaus?: InputMaybe<ItemBureau_Bool_Exp>;
  Letters?: InputMaybe<Letter_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<CreditItem_Bool_Exp>>;
  _not?: InputMaybe<CreditItem_Bool_Exp>;
  _or?: InputMaybe<Array<CreditItem_Bool_Exp>>;
  account_name?: InputMaybe<String_Comparison_Exp>;
  account_number?: InputMaybe<String_Comparison_Exp>;
  account_status?: InputMaybe<AccountStatus_Enum_Comparison_Exp>;
  category?: InputMaybe<Categories_Enum_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dispute_reason?: InputMaybe<String_Comparison_Exp>;
  dispute_round?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  round_expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  status?: InputMaybe<CreditItemStatus_Enum_Comparison_Exp>;
  total?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditItem" */
export enum CreditItem_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditItemPkey = 'CreditItem_pkey'
}

/** input type for incrementing numeric columns in table "CreditItem" */
export type CreditItem_Inc_Input = {
  dispute_round?: InputMaybe<Scalars['numeric']>;
  total?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditItem" */
export type CreditItem_Insert_Input = {
  ItemBureaus?: InputMaybe<ItemBureau_Arr_Rel_Insert_Input>;
  Letters?: InputMaybe<Letter_Arr_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  account_name?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  account_status?: InputMaybe<AccountStatus_Enum>;
  category?: InputMaybe<Categories_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dispute_reason?: InputMaybe<Scalars['String']>;
  dispute_round?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  round_expired_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<CreditItemStatus_Enum>;
  total?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type CreditItem_Max_Fields = {
  __typename?: 'CreditItem_max_fields';
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispute_reason?: Maybe<Scalars['String']>;
  dispute_round?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  round_expired_at?: Maybe<Scalars['timestamptz']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type CreditItem_Min_Fields = {
  __typename?: 'CreditItem_min_fields';
  account_name?: Maybe<Scalars['String']>;
  account_number?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispute_reason?: Maybe<Scalars['String']>;
  dispute_round?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  round_expired_at?: Maybe<Scalars['timestamptz']>;
  total?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "CreditItem" */
export type CreditItem_Mutation_Response = {
  __typename?: 'CreditItem_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditItem>;
};

/** input type for inserting object relation for remote table "CreditItem" */
export type CreditItem_Obj_Rel_Insert_Input = {
  data: CreditItem_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<CreditItem_On_Conflict>;
};

/** on_conflict condition type for table "CreditItem" */
export type CreditItem_On_Conflict = {
  constraint: CreditItem_Constraint;
  update_columns?: Array<CreditItem_Update_Column>;
  where?: InputMaybe<CreditItem_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditItem". */
export type CreditItem_Order_By = {
  ItemBureaus_aggregate?: InputMaybe<ItemBureau_Aggregate_Order_By>;
  Letters_aggregate?: InputMaybe<Letter_Aggregate_Order_By>;
  User?: InputMaybe<User_Order_By>;
  account_name?: InputMaybe<Order_By>;
  account_number?: InputMaybe<Order_By>;
  account_status?: InputMaybe<Order_By>;
  category?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dispute_reason?: InputMaybe<Order_By>;
  dispute_round?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  round_expired_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  total?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditItem */
export type CreditItem_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditItem" */
export enum CreditItem_Select_Column {
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  AccountStatus = 'account_status',
  /** column name */
  Category = 'category',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisputeReason = 'dispute_reason',
  /** column name */
  DisputeRound = 'dispute_round',
  /** column name */
  Id = 'id',
  /** column name */
  RoundExpiredAt = 'round_expired_at',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "CreditItem" */
export type CreditItem_Set_Input = {
  account_name?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  account_status?: InputMaybe<AccountStatus_Enum>;
  category?: InputMaybe<Categories_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dispute_reason?: InputMaybe<Scalars['String']>;
  dispute_round?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  round_expired_at?: InputMaybe<Scalars['timestamptz']>;
  status?: InputMaybe<CreditItemStatus_Enum>;
  total?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type CreditItem_Stddev_Fields = {
  __typename?: 'CreditItem_stddev_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CreditItem_Stddev_Pop_Fields = {
  __typename?: 'CreditItem_stddev_pop_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CreditItem_Stddev_Samp_Fields = {
  __typename?: 'CreditItem_stddev_samp_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CreditItem_Sum_Fields = {
  __typename?: 'CreditItem_sum_fields';
  dispute_round?: Maybe<Scalars['numeric']>;
  total?: Maybe<Scalars['numeric']>;
};

/** update columns of table "CreditItem" */
export enum CreditItem_Update_Column {
  /** column name */
  AccountName = 'account_name',
  /** column name */
  AccountNumber = 'account_number',
  /** column name */
  AccountStatus = 'account_status',
  /** column name */
  Category = 'category',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisputeReason = 'dispute_reason',
  /** column name */
  DisputeRound = 'dispute_round',
  /** column name */
  Id = 'id',
  /** column name */
  RoundExpiredAt = 'round_expired_at',
  /** column name */
  Status = 'status',
  /** column name */
  Total = 'total',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type CreditItem_Var_Pop_Fields = {
  __typename?: 'CreditItem_var_pop_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CreditItem_Var_Samp_Fields = {
  __typename?: 'CreditItem_var_samp_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CreditItem_Variance_Fields = {
  __typename?: 'CreditItem_variance_fields';
  dispute_round?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "CreditScore" */
export type CreditScore = {
  __typename?: 'CreditScore';
  bureau: Bureaus_Enum;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
  value: Scalars['numeric'];
};

/** aggregated selection of "CreditScore" */
export type CreditScore_Aggregate = {
  __typename?: 'CreditScore_aggregate';
  aggregate?: Maybe<CreditScore_Aggregate_Fields>;
  nodes: Array<CreditScore>;
};

/** aggregate fields of "CreditScore" */
export type CreditScore_Aggregate_Fields = {
  __typename?: 'CreditScore_aggregate_fields';
  avg?: Maybe<CreditScore_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<CreditScore_Max_Fields>;
  min?: Maybe<CreditScore_Min_Fields>;
  stddev?: Maybe<CreditScore_Stddev_Fields>;
  stddev_pop?: Maybe<CreditScore_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<CreditScore_Stddev_Samp_Fields>;
  sum?: Maybe<CreditScore_Sum_Fields>;
  var_pop?: Maybe<CreditScore_Var_Pop_Fields>;
  var_samp?: Maybe<CreditScore_Var_Samp_Fields>;
  variance?: Maybe<CreditScore_Variance_Fields>;
};


/** aggregate fields of "CreditScore" */
export type CreditScore_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<CreditScore_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type CreditScore_Avg_Fields = {
  __typename?: 'CreditScore_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "CreditScore". All fields are combined with a logical 'AND'. */
export type CreditScore_Bool_Exp = {
  _and?: InputMaybe<Array<CreditScore_Bool_Exp>>;
  _not?: InputMaybe<CreditScore_Bool_Exp>;
  _or?: InputMaybe<Array<CreditScore_Bool_Exp>>;
  bureau?: InputMaybe<Bureaus_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "CreditScore" */
export enum CreditScore_Constraint {
  /** unique or primary key constraint on columns "id" */
  CreditScorePkey = 'CreditScore_pkey'
}

/** input type for incrementing numeric columns in table "CreditScore" */
export type CreditScore_Inc_Input = {
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "CreditScore" */
export type CreditScore_Insert_Input = {
  bureau?: InputMaybe<Bureaus_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type CreditScore_Max_Fields = {
  __typename?: 'CreditScore_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['numeric']>;
};

/** aggregate min on columns */
export type CreditScore_Min_Fields = {
  __typename?: 'CreditScore_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['numeric']>;
};

/** response of any mutation on the table "CreditScore" */
export type CreditScore_Mutation_Response = {
  __typename?: 'CreditScore_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<CreditScore>;
};

/** on_conflict condition type for table "CreditScore" */
export type CreditScore_On_Conflict = {
  constraint: CreditScore_Constraint;
  update_columns?: Array<CreditScore_Update_Column>;
  where?: InputMaybe<CreditScore_Bool_Exp>;
};

/** Ordering options when selecting data from "CreditScore". */
export type CreditScore_Order_By = {
  bureau?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: CreditScore */
export type CreditScore_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "CreditScore" */
export enum CreditScore_Select_Column {
  /** column name */
  Bureau = 'bureau',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "CreditScore" */
export type CreditScore_Set_Input = {
  bureau?: InputMaybe<Bureaus_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type CreditScore_Stddev_Fields = {
  __typename?: 'CreditScore_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type CreditScore_Stddev_Pop_Fields = {
  __typename?: 'CreditScore_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type CreditScore_Stddev_Samp_Fields = {
  __typename?: 'CreditScore_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type CreditScore_Sum_Fields = {
  __typename?: 'CreditScore_sum_fields';
  value?: Maybe<Scalars['numeric']>;
};

/** update columns of table "CreditScore" */
export enum CreditScore_Update_Column {
  /** column name */
  Bureau = 'bureau',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type CreditScore_Var_Pop_Fields = {
  __typename?: 'CreditScore_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type CreditScore_Var_Samp_Fields = {
  __typename?: 'CreditScore_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type CreditScore_Variance_Fields = {
  __typename?: 'CreditScore_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "File" */
export type File = {
  __typename?: 'File';
  created_at: Scalars['timestamptz'];
  file_type: File_Type_Enum;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

export type FileOutput = {
  __typename?: 'FileOutput';
  fileId: Scalars['bigint'];
  fileType: Scalars['String'];
  imageUrl: Scalars['String'];
};

/** columns and relationships of "File_Type" */
export type File_Type = {
  __typename?: 'File_Type';
  value: Scalars['String'];
};

/** aggregated selection of "File_Type" */
export type File_Type_Aggregate = {
  __typename?: 'File_Type_aggregate';
  aggregate?: Maybe<File_Type_Aggregate_Fields>;
  nodes: Array<File_Type>;
};

/** aggregate fields of "File_Type" */
export type File_Type_Aggregate_Fields = {
  __typename?: 'File_Type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Type_Max_Fields>;
  min?: Maybe<File_Type_Min_Fields>;
};


/** aggregate fields of "File_Type" */
export type File_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "File_Type". All fields are combined with a logical 'AND'. */
export type File_Type_Bool_Exp = {
  _and?: InputMaybe<Array<File_Type_Bool_Exp>>;
  _not?: InputMaybe<File_Type_Bool_Exp>;
  _or?: InputMaybe<Array<File_Type_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "File_Type" */
export enum File_Type_Constraint {
  /** unique or primary key constraint on columns "value" */
  FileTypePkey = 'File_Type_pkey'
}

export enum File_Type_Enum {
  Document = 'document',
  Image = 'image',
  Video = 'video'
}

/** Boolean expression to compare columns of type "File_Type_enum". All fields are combined with logical 'AND'. */
export type File_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<File_Type_Enum>;
  _in?: InputMaybe<Array<File_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<File_Type_Enum>;
  _nin?: InputMaybe<Array<File_Type_Enum>>;
};

/** input type for inserting data into table "File_Type" */
export type File_Type_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Type_Max_Fields = {
  __typename?: 'File_Type_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Type_Min_Fields = {
  __typename?: 'File_Type_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "File_Type" */
export type File_Type_Mutation_Response = {
  __typename?: 'File_Type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Type>;
};

/** on_conflict condition type for table "File_Type" */
export type File_Type_On_Conflict = {
  constraint: File_Type_Constraint;
  update_columns?: Array<File_Type_Update_Column>;
  where?: InputMaybe<File_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "File_Type". */
export type File_Type_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: File_Type */
export type File_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "File_Type" */
export enum File_Type_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "File_Type" */
export type File_Type_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "File_Type" */
export enum File_Type_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "File" */
export type File_Aggregate = {
  __typename?: 'File_aggregate';
  aggregate?: Maybe<File_Aggregate_Fields>;
  nodes: Array<File>;
};

/** aggregate fields of "File" */
export type File_Aggregate_Fields = {
  __typename?: 'File_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Max_Fields>;
  min?: Maybe<File_Min_Fields>;
};


/** aggregate fields of "File" */
export type File_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "File". All fields are combined with a logical 'AND'. */
export type File_Bool_Exp = {
  _and?: InputMaybe<Array<File_Bool_Exp>>;
  _not?: InputMaybe<File_Bool_Exp>;
  _or?: InputMaybe<Array<File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_type?: InputMaybe<File_Type_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "File" */
export enum File_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilePkey = 'File_pkey',
  /** unique or primary key constraint on columns "url" */
  FileUrlKey = 'File_url_key'
}

/** input type for inserting data into table "File" */
export type File_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<File_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Max_Fields = {
  __typename?: 'File_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Min_Fields = {
  __typename?: 'File_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  url?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "File" */
export type File_Mutation_Response = {
  __typename?: 'File_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File>;
};

/** input type for inserting object relation for remote table "File" */
export type File_Obj_Rel_Insert_Input = {
  data: File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_On_Conflict>;
};

/** on_conflict condition type for table "File" */
export type File_On_Conflict = {
  constraint: File_Constraint;
  update_columns?: Array<File_Update_Column>;
  where?: InputMaybe<File_Bool_Exp>;
};

/** Ordering options when selecting data from "File". */
export type File_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: File */
export type File_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "File" */
export enum File_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "File" */
export type File_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_type?: InputMaybe<File_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "File" */
export enum File_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** columns and relationships of "ItemBureau" */
export type ItemBureau = {
  __typename?: 'ItemBureau';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  value: Bureaus_Enum;
};

/** aggregated selection of "ItemBureau" */
export type ItemBureau_Aggregate = {
  __typename?: 'ItemBureau_aggregate';
  aggregate?: Maybe<ItemBureau_Aggregate_Fields>;
  nodes: Array<ItemBureau>;
};

/** aggregate fields of "ItemBureau" */
export type ItemBureau_Aggregate_Fields = {
  __typename?: 'ItemBureau_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<ItemBureau_Max_Fields>;
  min?: Maybe<ItemBureau_Min_Fields>;
};


/** aggregate fields of "ItemBureau" */
export type ItemBureau_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<ItemBureau_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ItemBureau" */
export type ItemBureau_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<ItemBureau_Max_Order_By>;
  min?: InputMaybe<ItemBureau_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ItemBureau" */
export type ItemBureau_Arr_Rel_Insert_Input = {
  data: Array<ItemBureau_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<ItemBureau_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ItemBureau". All fields are combined with a logical 'AND'. */
export type ItemBureau_Bool_Exp = {
  _and?: InputMaybe<Array<ItemBureau_Bool_Exp>>;
  _not?: InputMaybe<ItemBureau_Bool_Exp>;
  _or?: InputMaybe<Array<ItemBureau_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Bureaus_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "ItemBureau" */
export enum ItemBureau_Constraint {
  /** unique or primary key constraint on columns "id" */
  ItemBureauPkey = 'ItemBureau_pkey'
}

/** input type for inserting data into table "ItemBureau" */
export type ItemBureau_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Bureaus_Enum>;
};

/** aggregate max on columns */
export type ItemBureau_Max_Fields = {
  __typename?: 'ItemBureau_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ItemBureau" */
export type ItemBureau_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type ItemBureau_Min_Fields = {
  __typename?: 'ItemBureau_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ItemBureau" */
export type ItemBureau_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ItemBureau" */
export type ItemBureau_Mutation_Response = {
  __typename?: 'ItemBureau_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<ItemBureau>;
};

/** on_conflict condition type for table "ItemBureau" */
export type ItemBureau_On_Conflict = {
  constraint: ItemBureau_Constraint;
  update_columns?: Array<ItemBureau_Update_Column>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};

/** Ordering options when selecting data from "ItemBureau". */
export type ItemBureau_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ItemBureau */
export type ItemBureau_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ItemBureau" */
export enum ItemBureau_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "ItemBureau" */
export type ItemBureau_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Bureaus_Enum>;
};

/** update columns of table "ItemBureau" */
export enum ItemBureau_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "Letter" */
export type Letter = {
  __typename?: 'Letter';
  /** An object relationship */
  CreditItem?: Maybe<CreditItem>;
  /** An array relationship */
  LetterBureaus: Array<LetterBureau>;
  /** An aggregate relationship */
  LetterBureaus_aggregate: LetterBureau_Aggregate;
  /** An array relationship */
  LetterGroups: Array<LetterGroup>;
  /** An aggregate relationship */
  LetterGroups_aggregate: LetterGroup_Aggregate;
  created_at: Scalars['timestamptz'];
  extra_fields?: Maybe<Scalars['jsonb']>;
  group_number?: Maybe<Scalars['numeric']>;
  id: Scalars['uuid'];
  item_id?: Maybe<Scalars['uuid']>;
  item_round: Scalars['numeric'];
  name: Scalars['String'];
  raw_html: Scalars['String'];
  status: LetterStatus_Enum;
  type?: Maybe<LetterType_Enum>;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  waiting_expired_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "Letter" */
export type LetterLetterBureausArgs = {
  distinct_on?: InputMaybe<Array<LetterBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterBureau_Order_By>>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};


/** columns and relationships of "Letter" */
export type LetterLetterBureaus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterBureau_Order_By>>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};


/** columns and relationships of "Letter" */
export type LetterLetterGroupsArgs = {
  distinct_on?: InputMaybe<Array<LetterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterGroup_Order_By>>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};


/** columns and relationships of "Letter" */
export type LetterLetterGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterGroup_Order_By>>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};


/** columns and relationships of "Letter" */
export type LetterExtra_FieldsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "LetterBureau" */
export type LetterBureau = {
  __typename?: 'LetterBureau';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  letter_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  value: Bureaus_Enum;
};

/** aggregated selection of "LetterBureau" */
export type LetterBureau_Aggregate = {
  __typename?: 'LetterBureau_aggregate';
  aggregate?: Maybe<LetterBureau_Aggregate_Fields>;
  nodes: Array<LetterBureau>;
};

/** aggregate fields of "LetterBureau" */
export type LetterBureau_Aggregate_Fields = {
  __typename?: 'LetterBureau_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LetterBureau_Max_Fields>;
  min?: Maybe<LetterBureau_Min_Fields>;
};


/** aggregate fields of "LetterBureau" */
export type LetterBureau_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LetterBureau_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "LetterBureau" */
export type LetterBureau_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LetterBureau_Max_Order_By>;
  min?: InputMaybe<LetterBureau_Min_Order_By>;
};

/** input type for inserting array relation for remote table "LetterBureau" */
export type LetterBureau_Arr_Rel_Insert_Input = {
  data: Array<LetterBureau_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LetterBureau_On_Conflict>;
};

/** Boolean expression to filter rows from the table "LetterBureau". All fields are combined with a logical 'AND'. */
export type LetterBureau_Bool_Exp = {
  _and?: InputMaybe<Array<LetterBureau_Bool_Exp>>;
  _not?: InputMaybe<LetterBureau_Bool_Exp>;
  _or?: InputMaybe<Array<LetterBureau_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  letter_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  value?: InputMaybe<Bureaus_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "LetterBureau" */
export enum LetterBureau_Constraint {
  /** unique or primary key constraint on columns "id" */
  LetterBureauPkey = 'LetterBureau_pkey'
}

/** input type for inserting data into table "LetterBureau" */
export type LetterBureau_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  letter_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Bureaus_Enum>;
};

/** aggregate max on columns */
export type LetterBureau_Max_Fields = {
  __typename?: 'LetterBureau_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  letter_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "LetterBureau" */
export type LetterBureau_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  letter_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LetterBureau_Min_Fields = {
  __typename?: 'LetterBureau_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  letter_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "LetterBureau" */
export type LetterBureau_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  letter_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "LetterBureau" */
export type LetterBureau_Mutation_Response = {
  __typename?: 'LetterBureau_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LetterBureau>;
};

/** on_conflict condition type for table "LetterBureau" */
export type LetterBureau_On_Conflict = {
  constraint: LetterBureau_Constraint;
  update_columns?: Array<LetterBureau_Update_Column>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};

/** Ordering options when selecting data from "LetterBureau". */
export type LetterBureau_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  letter_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: LetterBureau */
export type LetterBureau_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "LetterBureau" */
export enum LetterBureau_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "LetterBureau" */
export type LetterBureau_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  letter_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  value?: InputMaybe<Bureaus_Enum>;
};

/** update columns of table "LetterBureau" */
export enum LetterBureau_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "LetterGroup" */
export type LetterGroup = {
  __typename?: 'LetterGroup';
  /** An object relationship */
  CreditItem: CreditItem;
  /** An object relationship */
  Letter: Letter;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  item_id: Scalars['uuid'];
  letter_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
};

export type LetterGroupOutput = {
  __typename?: 'LetterGroupOutput';
  id: Scalars['String'];
};

export type LetterGroupUpdateOutput = {
  __typename?: 'LetterGroupUpdateOutput';
  id: Scalars['String'];
};

/** aggregated selection of "LetterGroup" */
export type LetterGroup_Aggregate = {
  __typename?: 'LetterGroup_aggregate';
  aggregate?: Maybe<LetterGroup_Aggregate_Fields>;
  nodes: Array<LetterGroup>;
};

/** aggregate fields of "LetterGroup" */
export type LetterGroup_Aggregate_Fields = {
  __typename?: 'LetterGroup_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LetterGroup_Max_Fields>;
  min?: Maybe<LetterGroup_Min_Fields>;
};


/** aggregate fields of "LetterGroup" */
export type LetterGroup_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LetterGroup_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "LetterGroup" */
export type LetterGroup_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<LetterGroup_Max_Order_By>;
  min?: InputMaybe<LetterGroup_Min_Order_By>;
};

/** input type for inserting array relation for remote table "LetterGroup" */
export type LetterGroup_Arr_Rel_Insert_Input = {
  data: Array<LetterGroup_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<LetterGroup_On_Conflict>;
};

/** Boolean expression to filter rows from the table "LetterGroup". All fields are combined with a logical 'AND'. */
export type LetterGroup_Bool_Exp = {
  CreditItem?: InputMaybe<CreditItem_Bool_Exp>;
  Letter?: InputMaybe<Letter_Bool_Exp>;
  _and?: InputMaybe<Array<LetterGroup_Bool_Exp>>;
  _not?: InputMaybe<LetterGroup_Bool_Exp>;
  _or?: InputMaybe<Array<LetterGroup_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  letter_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "LetterGroup" */
export enum LetterGroup_Constraint {
  /** unique or primary key constraint on columns "id" */
  LetterGroupPkey = 'LetterGroup_pkey'
}

/** input type for inserting data into table "LetterGroup" */
export type LetterGroup_Insert_Input = {
  CreditItem?: InputMaybe<CreditItem_Obj_Rel_Insert_Input>;
  Letter?: InputMaybe<Letter_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  letter_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type LetterGroup_Max_Fields = {
  __typename?: 'LetterGroup_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  letter_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "LetterGroup" */
export type LetterGroup_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  letter_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type LetterGroup_Min_Fields = {
  __typename?: 'LetterGroup_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  letter_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "LetterGroup" */
export type LetterGroup_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  letter_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "LetterGroup" */
export type LetterGroup_Mutation_Response = {
  __typename?: 'LetterGroup_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LetterGroup>;
};

/** on_conflict condition type for table "LetterGroup" */
export type LetterGroup_On_Conflict = {
  constraint: LetterGroup_Constraint;
  update_columns?: Array<LetterGroup_Update_Column>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};

/** Ordering options when selecting data from "LetterGroup". */
export type LetterGroup_Order_By = {
  CreditItem?: InputMaybe<CreditItem_Order_By>;
  Letter?: InputMaybe<Letter_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  letter_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: LetterGroup */
export type LetterGroup_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "LetterGroup" */
export enum LetterGroup_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "LetterGroup" */
export type LetterGroup_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  letter_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "LetterGroup" */
export enum LetterGroup_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "LetterStatus" */
export type LetterStatus = {
  __typename?: 'LetterStatus';
  value: Scalars['String'];
};

/** aggregated selection of "LetterStatus" */
export type LetterStatus_Aggregate = {
  __typename?: 'LetterStatus_aggregate';
  aggregate?: Maybe<LetterStatus_Aggregate_Fields>;
  nodes: Array<LetterStatus>;
};

/** aggregate fields of "LetterStatus" */
export type LetterStatus_Aggregate_Fields = {
  __typename?: 'LetterStatus_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LetterStatus_Max_Fields>;
  min?: Maybe<LetterStatus_Min_Fields>;
};


/** aggregate fields of "LetterStatus" */
export type LetterStatus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LetterStatus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "LetterStatus". All fields are combined with a logical 'AND'. */
export type LetterStatus_Bool_Exp = {
  _and?: InputMaybe<Array<LetterStatus_Bool_Exp>>;
  _not?: InputMaybe<LetterStatus_Bool_Exp>;
  _or?: InputMaybe<Array<LetterStatus_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "LetterStatus" */
export enum LetterStatus_Constraint {
  /** unique or primary key constraint on columns "value" */
  LetterStatusPkey = 'LetterStatus_pkey'
}

export enum LetterStatus_Enum {
  SavedDownloaded = 'saved_downloaded',
  SavedDraft = 'saved_draft'
}

/** Boolean expression to compare columns of type "LetterStatus_enum". All fields are combined with logical 'AND'. */
export type LetterStatus_Enum_Comparison_Exp = {
  _eq?: InputMaybe<LetterStatus_Enum>;
  _in?: InputMaybe<Array<LetterStatus_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LetterStatus_Enum>;
  _nin?: InputMaybe<Array<LetterStatus_Enum>>;
};

/** input type for inserting data into table "LetterStatus" */
export type LetterStatus_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LetterStatus_Max_Fields = {
  __typename?: 'LetterStatus_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LetterStatus_Min_Fields = {
  __typename?: 'LetterStatus_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "LetterStatus" */
export type LetterStatus_Mutation_Response = {
  __typename?: 'LetterStatus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LetterStatus>;
};

/** on_conflict condition type for table "LetterStatus" */
export type LetterStatus_On_Conflict = {
  constraint: LetterStatus_Constraint;
  update_columns?: Array<LetterStatus_Update_Column>;
  where?: InputMaybe<LetterStatus_Bool_Exp>;
};

/** Ordering options when selecting data from "LetterStatus". */
export type LetterStatus_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: LetterStatus */
export type LetterStatus_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "LetterStatus" */
export enum LetterStatus_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "LetterStatus" */
export type LetterStatus_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "LetterStatus" */
export enum LetterStatus_Update_Column {
  /** column name */
  Value = 'value'
}

/** columns and relationships of "LetterType" */
export type LetterType = {
  __typename?: 'LetterType';
  value: Scalars['String'];
};

/** aggregated selection of "LetterType" */
export type LetterType_Aggregate = {
  __typename?: 'LetterType_aggregate';
  aggregate?: Maybe<LetterType_Aggregate_Fields>;
  nodes: Array<LetterType>;
};

/** aggregate fields of "LetterType" */
export type LetterType_Aggregate_Fields = {
  __typename?: 'LetterType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<LetterType_Max_Fields>;
  min?: Maybe<LetterType_Min_Fields>;
};


/** aggregate fields of "LetterType" */
export type LetterType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<LetterType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "LetterType". All fields are combined with a logical 'AND'. */
export type LetterType_Bool_Exp = {
  _and?: InputMaybe<Array<LetterType_Bool_Exp>>;
  _not?: InputMaybe<LetterType_Bool_Exp>;
  _or?: InputMaybe<Array<LetterType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "LetterType" */
export enum LetterType_Constraint {
  /** unique or primary key constraint on columns "value" */
  LetterTypePkey = 'LetterType_pkey'
}

export enum LetterType_Enum {
  Draft = 'draft',
  Letter = 'letter',
  LetterGroup = 'letter_group'
}

/** Boolean expression to compare columns of type "LetterType_enum". All fields are combined with logical 'AND'. */
export type LetterType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<LetterType_Enum>;
  _in?: InputMaybe<Array<LetterType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<LetterType_Enum>;
  _nin?: InputMaybe<Array<LetterType_Enum>>;
};

/** input type for inserting data into table "LetterType" */
export type LetterType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type LetterType_Max_Fields = {
  __typename?: 'LetterType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type LetterType_Min_Fields = {
  __typename?: 'LetterType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "LetterType" */
export type LetterType_Mutation_Response = {
  __typename?: 'LetterType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<LetterType>;
};

/** on_conflict condition type for table "LetterType" */
export type LetterType_On_Conflict = {
  constraint: LetterType_Constraint;
  update_columns?: Array<LetterType_Update_Column>;
  where?: InputMaybe<LetterType_Bool_Exp>;
};

/** Ordering options when selecting data from "LetterType". */
export type LetterType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: LetterType */
export type LetterType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "LetterType" */
export enum LetterType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "LetterType" */
export type LetterType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "LetterType" */
export enum LetterType_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "Letter" */
export type Letter_Aggregate = {
  __typename?: 'Letter_aggregate';
  aggregate?: Maybe<Letter_Aggregate_Fields>;
  nodes: Array<Letter>;
};

/** aggregate fields of "Letter" */
export type Letter_Aggregate_Fields = {
  __typename?: 'Letter_aggregate_fields';
  avg?: Maybe<Letter_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Letter_Max_Fields>;
  min?: Maybe<Letter_Min_Fields>;
  stddev?: Maybe<Letter_Stddev_Fields>;
  stddev_pop?: Maybe<Letter_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Letter_Stddev_Samp_Fields>;
  sum?: Maybe<Letter_Sum_Fields>;
  var_pop?: Maybe<Letter_Var_Pop_Fields>;
  var_samp?: Maybe<Letter_Var_Samp_Fields>;
  variance?: Maybe<Letter_Variance_Fields>;
};


/** aggregate fields of "Letter" */
export type Letter_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Letter_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Letter" */
export type Letter_Aggregate_Order_By = {
  avg?: InputMaybe<Letter_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Letter_Max_Order_By>;
  min?: InputMaybe<Letter_Min_Order_By>;
  stddev?: InputMaybe<Letter_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Letter_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Letter_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Letter_Sum_Order_By>;
  var_pop?: InputMaybe<Letter_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Letter_Var_Samp_Order_By>;
  variance?: InputMaybe<Letter_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Letter_Append_Input = {
  extra_fields?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "Letter" */
export type Letter_Arr_Rel_Insert_Input = {
  data: Array<Letter_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Letter_On_Conflict>;
};

/** aggregate avg on columns */
export type Letter_Avg_Fields = {
  __typename?: 'Letter_avg_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Letter" */
export type Letter_Avg_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Letter". All fields are combined with a logical 'AND'. */
export type Letter_Bool_Exp = {
  CreditItem?: InputMaybe<CreditItem_Bool_Exp>;
  LetterBureaus?: InputMaybe<LetterBureau_Bool_Exp>;
  LetterGroups?: InputMaybe<LetterGroup_Bool_Exp>;
  _and?: InputMaybe<Array<Letter_Bool_Exp>>;
  _not?: InputMaybe<Letter_Bool_Exp>;
  _or?: InputMaybe<Array<Letter_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  extra_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  group_number?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  item_id?: InputMaybe<Uuid_Comparison_Exp>;
  item_round?: InputMaybe<Numeric_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  raw_html?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<LetterStatus_Enum_Comparison_Exp>;
  type?: InputMaybe<LetterType_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  waiting_expired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "Letter" */
export enum Letter_Constraint {
  /** unique or primary key constraint on columns "id" */
  LetterPkey = 'Letter_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Letter_Delete_At_Path_Input = {
  extra_fields?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Letter_Delete_Elem_Input = {
  extra_fields?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Letter_Delete_Key_Input = {
  extra_fields?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "Letter" */
export type Letter_Inc_Input = {
  group_number?: InputMaybe<Scalars['numeric']>;
  item_round?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "Letter" */
export type Letter_Insert_Input = {
  CreditItem?: InputMaybe<CreditItem_Obj_Rel_Insert_Input>;
  LetterBureaus?: InputMaybe<LetterBureau_Arr_Rel_Insert_Input>;
  LetterGroups?: InputMaybe<LetterGroup_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  extra_fields?: InputMaybe<Scalars['jsonb']>;
  group_number?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_round?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  raw_html?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LetterStatus_Enum>;
  type?: InputMaybe<LetterType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  waiting_expired_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Letter_Max_Fields = {
  __typename?: 'Letter_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_number?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_round?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  raw_html?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  waiting_expired_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "Letter" */
export type Letter_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  raw_html?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  waiting_expired_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Letter_Min_Fields = {
  __typename?: 'Letter_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_number?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['uuid']>;
  item_id?: Maybe<Scalars['uuid']>;
  item_round?: Maybe<Scalars['numeric']>;
  name?: Maybe<Scalars['String']>;
  raw_html?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  waiting_expired_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "Letter" */
export type Letter_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  raw_html?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  waiting_expired_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "Letter" */
export type Letter_Mutation_Response = {
  __typename?: 'Letter_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Letter>;
};

/** input type for inserting object relation for remote table "Letter" */
export type Letter_Obj_Rel_Insert_Input = {
  data: Letter_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Letter_On_Conflict>;
};

/** on_conflict condition type for table "Letter" */
export type Letter_On_Conflict = {
  constraint: Letter_Constraint;
  update_columns?: Array<Letter_Update_Column>;
  where?: InputMaybe<Letter_Bool_Exp>;
};

/** Ordering options when selecting data from "Letter". */
export type Letter_Order_By = {
  CreditItem?: InputMaybe<CreditItem_Order_By>;
  LetterBureaus_aggregate?: InputMaybe<LetterBureau_Aggregate_Order_By>;
  LetterGroups_aggregate?: InputMaybe<LetterGroup_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  extra_fields?: InputMaybe<Order_By>;
  group_number?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  item_id?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  raw_html?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  waiting_expired_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Letter */
export type Letter_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Letter_Prepend_Input = {
  extra_fields?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Letter" */
export enum Letter_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtraFields = 'extra_fields',
  /** column name */
  GroupNumber = 'group_number',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemRound = 'item_round',
  /** column name */
  Name = 'name',
  /** column name */
  RawHtml = 'raw_html',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WaitingExpiredAt = 'waiting_expired_at'
}

/** input type for updating data in table "Letter" */
export type Letter_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  extra_fields?: InputMaybe<Scalars['jsonb']>;
  group_number?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['uuid']>;
  item_id?: InputMaybe<Scalars['uuid']>;
  item_round?: InputMaybe<Scalars['numeric']>;
  name?: InputMaybe<Scalars['String']>;
  raw_html?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LetterStatus_Enum>;
  type?: InputMaybe<LetterType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  waiting_expired_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Letter_Stddev_Fields = {
  __typename?: 'Letter_stddev_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Letter" */
export type Letter_Stddev_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Letter_Stddev_Pop_Fields = {
  __typename?: 'Letter_stddev_pop_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Letter" */
export type Letter_Stddev_Pop_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Letter_Stddev_Samp_Fields = {
  __typename?: 'Letter_stddev_samp_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Letter" */
export type Letter_Stddev_Samp_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Letter_Sum_Fields = {
  __typename?: 'Letter_sum_fields';
  group_number?: Maybe<Scalars['numeric']>;
  item_round?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "Letter" */
export type Letter_Sum_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** update columns of table "Letter" */
export enum Letter_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExtraFields = 'extra_fields',
  /** column name */
  GroupNumber = 'group_number',
  /** column name */
  Id = 'id',
  /** column name */
  ItemId = 'item_id',
  /** column name */
  ItemRound = 'item_round',
  /** column name */
  Name = 'name',
  /** column name */
  RawHtml = 'raw_html',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  WaitingExpiredAt = 'waiting_expired_at'
}

/** aggregate var_pop on columns */
export type Letter_Var_Pop_Fields = {
  __typename?: 'Letter_var_pop_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Letter" */
export type Letter_Var_Pop_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Letter_Var_Samp_Fields = {
  __typename?: 'Letter_var_samp_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Letter" */
export type Letter_Var_Samp_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Letter_Variance_Fields = {
  __typename?: 'Letter_variance_fields';
  group_number?: Maybe<Scalars['Float']>;
  item_round?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Letter" */
export type Letter_Variance_Order_By = {
  group_number?: InputMaybe<Order_By>;
  item_round?: InputMaybe<Order_By>;
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  active: Scalars['Boolean'];
  address1: Scalars['String'];
  address2: Scalars['String'];
  address_picture1: Scalars['String'];
  address_picture2: Scalars['String'];
  birth_date?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  license_picture: Scalars['String'];
  picture?: Maybe<Scalars['String']>;
  ssn: Scalars['String'];
  ssn_picture: Scalars['String'];
  state: Scalars['String'];
  token: Scalars['String'];
  zip_code: Scalars['String'];
};

/** columns and relationships of "Notification" */
export type Notification = {
  __typename?: 'Notification';
  /** An object relationship */
  User: User;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  message: Scalars['String'];
  metadata: Scalars['jsonb'];
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "Notification" */
export type NotificationMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "Notification" */
export type Notification_Aggregate = {
  __typename?: 'Notification_aggregate';
  aggregate?: Maybe<Notification_Aggregate_Fields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "Notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'Notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Max_Fields>;
  min?: Maybe<Notification_Min_Fields>;
};


/** aggregate fields of "Notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Notification_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "Notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  User?: InputMaybe<User_Bool_Exp>;
  _and?: InputMaybe<Array<Notification_Bool_Exp>>;
  _not?: InputMaybe<Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'Notification_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Notification_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Notification_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Notification_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "Notification" */
export type Notification_Insert_Input = {
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  seen?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'Notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'Notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  seen?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "Notification" */
export type Notification_Mutation_Response = {
  __typename?: 'Notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** on_conflict condition type for table "Notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns?: Array<Notification_Update_Column>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "Notification". */
export type Notification_Order_By = {
  User?: InputMaybe<User_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  seen?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Notification_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "Notification" */
export enum Notification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Seen = 'seen',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "Notification" */
export type Notification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  seen?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "Notification" */
export enum Notification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Seen = 'seen',
  /** column name */
  Title = 'title',
  /** column name */
  UserId = 'user_id'
}

export type PasswordResetOutput = {
  __typename?: 'PasswordResetOutput';
  success: Scalars['Boolean'];
};

/** columns and relationships of "Payment" */
export type Payment = {
  __typename?: 'Payment';
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['String'];
  expires_at: Scalars['Int'];
  id: Scalars['uuid'];
  invoice_id: Scalars['String'];
  payment_link_id?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subscription_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

export type PaymentLinkOutput = {
  __typename?: 'PaymentLinkOutput';
  url: Scalars['String'];
};

export type PaymentPlansOutput = {
  __typename?: 'PaymentPlansOutput';
  data: Scalars['jsonb'];
};

export type PaymentStatusOutput = {
  __typename?: 'PaymentStatusOutput';
  status: Scalars['String'];
};

/** aggregated selection of "Payment" */
export type Payment_Aggregate = {
  __typename?: 'Payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
};

/** aggregate fields of "Payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'Payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
};


/** aggregate fields of "Payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'Payment_avg_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "Payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Bool_Exp>>;
  _not?: InputMaybe<Payment_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  expires_at?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  payment_link_id?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "Payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentPkey = 'Payment_pkey'
}

/** input type for incrementing numeric columns in table "Payment" */
export type Payment_Inc_Input = {
  expires_at?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "Payment" */
export type Payment_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  payment_link_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'Payment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  payment_link_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'Payment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  payment_link_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "Payment" */
export type Payment_Mutation_Response = {
  __typename?: 'Payment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment>;
};

/** on_conflict condition type for table "Payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint;
  update_columns?: Array<Payment_Update_Column>;
  where?: InputMaybe<Payment_Bool_Exp>;
};

/** Ordering options when selecting data from "Payment". */
export type Payment_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  payment_link_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: Payment */
export type Payment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "Payment" */
export enum Payment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  PaymentLinkId = 'payment_link_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "Payment" */
export type Payment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  expires_at?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  payment_link_id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'Payment_stddev_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'Payment_stddev_pop_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'Payment_stddev_samp_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'Payment_sum_fields';
  expires_at?: Maybe<Scalars['Int']>;
};

/** update columns of table "Payment" */
export enum Payment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  PaymentLinkId = 'payment_link_id',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'Payment_var_pop_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'Payment_var_samp_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'Payment_variance_fields';
  expires_at?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "PersonalDocument" */
export type PersonalDocument = {
  __typename?: 'PersonalDocument';
  /** An object relationship */
  File: File;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id: Scalars['uuid'];
  id: Scalars['uuid'];
  type: PersonalDocumentType_Enum;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "PersonalDocumentType" */
export type PersonalDocumentType = {
  __typename?: 'PersonalDocumentType';
  value: Scalars['String'];
};

/** aggregated selection of "PersonalDocumentType" */
export type PersonalDocumentType_Aggregate = {
  __typename?: 'PersonalDocumentType_aggregate';
  aggregate?: Maybe<PersonalDocumentType_Aggregate_Fields>;
  nodes: Array<PersonalDocumentType>;
};

/** aggregate fields of "PersonalDocumentType" */
export type PersonalDocumentType_Aggregate_Fields = {
  __typename?: 'PersonalDocumentType_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PersonalDocumentType_Max_Fields>;
  min?: Maybe<PersonalDocumentType_Min_Fields>;
};


/** aggregate fields of "PersonalDocumentType" */
export type PersonalDocumentType_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PersonalDocumentType_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "PersonalDocumentType". All fields are combined with a logical 'AND'. */
export type PersonalDocumentType_Bool_Exp = {
  _and?: InputMaybe<Array<PersonalDocumentType_Bool_Exp>>;
  _not?: InputMaybe<PersonalDocumentType_Bool_Exp>;
  _or?: InputMaybe<Array<PersonalDocumentType_Bool_Exp>>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PersonalDocumentType" */
export enum PersonalDocumentType_Constraint {
  /** unique or primary key constraint on columns "value" */
  PersonalDocumentTypePkey = 'PersonalDocumentType_pkey'
}

export enum PersonalDocumentType_Enum {
  Address = 'address',
  Address_2 = 'address_2',
  DriverLicense = 'driver_license',
  Ssc = 'ssc'
}

/** Boolean expression to compare columns of type "PersonalDocumentType_enum". All fields are combined with logical 'AND'. */
export type PersonalDocumentType_Enum_Comparison_Exp = {
  _eq?: InputMaybe<PersonalDocumentType_Enum>;
  _in?: InputMaybe<Array<PersonalDocumentType_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<PersonalDocumentType_Enum>;
  _nin?: InputMaybe<Array<PersonalDocumentType_Enum>>;
};

/** input type for inserting data into table "PersonalDocumentType" */
export type PersonalDocumentType_Insert_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PersonalDocumentType_Max_Fields = {
  __typename?: 'PersonalDocumentType_max_fields';
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type PersonalDocumentType_Min_Fields = {
  __typename?: 'PersonalDocumentType_min_fields';
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "PersonalDocumentType" */
export type PersonalDocumentType_Mutation_Response = {
  __typename?: 'PersonalDocumentType_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PersonalDocumentType>;
};

/** on_conflict condition type for table "PersonalDocumentType" */
export type PersonalDocumentType_On_Conflict = {
  constraint: PersonalDocumentType_Constraint;
  update_columns?: Array<PersonalDocumentType_Update_Column>;
  where?: InputMaybe<PersonalDocumentType_Bool_Exp>;
};

/** Ordering options when selecting data from "PersonalDocumentType". */
export type PersonalDocumentType_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PersonalDocumentType */
export type PersonalDocumentType_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "PersonalDocumentType" */
export enum PersonalDocumentType_Select_Column {
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "PersonalDocumentType" */
export type PersonalDocumentType_Set_Input = {
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "PersonalDocumentType" */
export enum PersonalDocumentType_Update_Column {
  /** column name */
  Value = 'value'
}

/** aggregated selection of "PersonalDocument" */
export type PersonalDocument_Aggregate = {
  __typename?: 'PersonalDocument_aggregate';
  aggregate?: Maybe<PersonalDocument_Aggregate_Fields>;
  nodes: Array<PersonalDocument>;
};

/** aggregate fields of "PersonalDocument" */
export type PersonalDocument_Aggregate_Fields = {
  __typename?: 'PersonalDocument_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<PersonalDocument_Max_Fields>;
  min?: Maybe<PersonalDocument_Min_Fields>;
};


/** aggregate fields of "PersonalDocument" */
export type PersonalDocument_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PersonalDocument" */
export type PersonalDocument_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<PersonalDocument_Max_Order_By>;
  min?: InputMaybe<PersonalDocument_Min_Order_By>;
};

/** input type for inserting array relation for remote table "PersonalDocument" */
export type PersonalDocument_Arr_Rel_Insert_Input = {
  data: Array<PersonalDocument_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<PersonalDocument_On_Conflict>;
};

/** Boolean expression to filter rows from the table "PersonalDocument". All fields are combined with a logical 'AND'. */
export type PersonalDocument_Bool_Exp = {
  File?: InputMaybe<File_Bool_Exp>;
  _and?: InputMaybe<Array<PersonalDocument_Bool_Exp>>;
  _not?: InputMaybe<PersonalDocument_Bool_Exp>;
  _or?: InputMaybe<Array<PersonalDocument_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<PersonalDocumentType_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "PersonalDocument" */
export enum PersonalDocument_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonaDocumentPkey = 'PersonaDocument_pkey'
}

/** input type for inserting data into table "PersonalDocument" */
export type PersonalDocument_Insert_Input = {
  File?: InputMaybe<File_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<PersonalDocumentType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type PersonalDocument_Max_Fields = {
  __typename?: 'PersonalDocument_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "PersonalDocument" */
export type PersonalDocument_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type PersonalDocument_Min_Fields = {
  __typename?: 'PersonalDocument_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "PersonalDocument" */
export type PersonalDocument_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "PersonalDocument" */
export type PersonalDocument_Mutation_Response = {
  __typename?: 'PersonalDocument_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<PersonalDocument>;
};

/** on_conflict condition type for table "PersonalDocument" */
export type PersonalDocument_On_Conflict = {
  constraint: PersonalDocument_Constraint;
  update_columns?: Array<PersonalDocument_Update_Column>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};

/** Ordering options when selecting data from "PersonalDocument". */
export type PersonalDocument_Order_By = {
  File?: InputMaybe<File_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: PersonalDocument */
export type PersonalDocument_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "PersonalDocument" */
export enum PersonalDocument_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "PersonalDocument" */
export type PersonalDocument_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<PersonalDocumentType_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "PersonalDocument" */
export enum PersonalDocument_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type PersonalImageFileOutput = {
  __typename?: 'PersonalImageFileOutput';
  documentId: Scalars['uuid'];
  documentType: Scalars['String'];
  fileId: Scalars['uuid'];
  fileType: Scalars['String'];
  imageUrl: Scalars['String'];
};

export type RequestPasswordResetOutput = {
  __typename?: 'RequestPasswordResetOutput';
  success: Scalars['Boolean'];
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  active: Scalars['Boolean'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  token: Scalars['String'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  /** An array relationship */
  PersonalDocuments: Array<PersonalDocument>;
  /** An aggregate relationship */
  PersonalDocuments_aggregate: PersonalDocument_Aggregate;
  active: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  email: Scalars['String'];
  id: Scalars['uuid'];
  password: Scalars['String'];
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "User" */
export type UserPersonalDocumentsArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocument_Order_By>>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserPersonalDocuments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocument_Order_By>>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};

/** columns and relationships of "UserAddress" */
export type UserAddress = {
  __typename?: 'UserAddress';
  /** An object relationship */
  UserProfile?: Maybe<UserProfile>;
  address_line_1: Scalars['String'];
  address_line_2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  profile_id?: Maybe<Scalars['uuid']>;
  state: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  zip_code: Scalars['String'];
};

/** aggregated selection of "UserAddress" */
export type UserAddress_Aggregate = {
  __typename?: 'UserAddress_aggregate';
  aggregate?: Maybe<UserAddress_Aggregate_Fields>;
  nodes: Array<UserAddress>;
};

/** aggregate fields of "UserAddress" */
export type UserAddress_Aggregate_Fields = {
  __typename?: 'UserAddress_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserAddress_Max_Fields>;
  min?: Maybe<UserAddress_Min_Fields>;
};


/** aggregate fields of "UserAddress" */
export type UserAddress_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserAddress_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "UserAddress". All fields are combined with a logical 'AND'. */
export type UserAddress_Bool_Exp = {
  UserProfile?: InputMaybe<UserProfile_Bool_Exp>;
  _and?: InputMaybe<Array<UserAddress_Bool_Exp>>;
  _not?: InputMaybe<UserAddress_Bool_Exp>;
  _or?: InputMaybe<Array<UserAddress_Bool_Exp>>;
  address_line_1?: InputMaybe<String_Comparison_Exp>;
  address_line_2?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zip_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserAddress" */
export enum UserAddress_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserAdressPkey = 'UserAdress_pkey'
}

/** input type for inserting data into table "UserAddress" */
export type UserAddress_Insert_Input = {
  UserProfile?: InputMaybe<UserProfile_Obj_Rel_Insert_Input>;
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  profile_id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type UserAddress_Max_Fields = {
  __typename?: 'UserAddress_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  profile_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type UserAddress_Min_Fields = {
  __typename?: 'UserAddress_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  profile_id?: Maybe<Scalars['uuid']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zip_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "UserAddress" */
export type UserAddress_Mutation_Response = {
  __typename?: 'UserAddress_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserAddress>;
};

/** input type for inserting object relation for remote table "UserAddress" */
export type UserAddress_Obj_Rel_Insert_Input = {
  data: UserAddress_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserAddress_On_Conflict>;
};

/** on_conflict condition type for table "UserAddress" */
export type UserAddress_On_Conflict = {
  constraint: UserAddress_Constraint;
  update_columns?: Array<UserAddress_Update_Column>;
  where?: InputMaybe<UserAddress_Bool_Exp>;
};

/** Ordering options when selecting data from "UserAddress". */
export type UserAddress_Order_By = {
  UserProfile?: InputMaybe<UserProfile_Order_By>;
  address_line_1?: InputMaybe<Order_By>;
  address_line_2?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  profile_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zip_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserAddress */
export type UserAddress_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "UserAddress" */
export enum UserAddress_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** input type for updating data in table "UserAddress" */
export type UserAddress_Set_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  profile_id?: InputMaybe<Scalars['uuid']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zip_code?: InputMaybe<Scalars['String']>;
};

/** update columns of table "UserAddress" */
export enum UserAddress_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZipCode = 'zip_code'
}

/** columns and relationships of "UserProfile" */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** An object relationship */
  ProfilePicture?: Maybe<File>;
  /** An object relationship */
  User: User;
  /** An object relationship */
  UserAddress?: Maybe<UserAddress>;
  address_id?: Maybe<Scalars['uuid']>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name: Scalars['String'];
  id: Scalars['uuid'];
  last_name: Scalars['String'];
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  ssn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "UserProfile" */
export type UserProfile_Aggregate = {
  __typename?: 'UserProfile_aggregate';
  aggregate?: Maybe<UserProfile_Aggregate_Fields>;
  nodes: Array<UserProfile>;
};

/** aggregate fields of "UserProfile" */
export type UserProfile_Aggregate_Fields = {
  __typename?: 'UserProfile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<UserProfile_Max_Fields>;
  min?: Maybe<UserProfile_Min_Fields>;
};


/** aggregate fields of "UserProfile" */
export type UserProfile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<UserProfile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "UserProfile". All fields are combined with a logical 'AND'. */
export type UserProfile_Bool_Exp = {
  ProfilePicture?: InputMaybe<File_Bool_Exp>;
  User?: InputMaybe<User_Bool_Exp>;
  UserAddress?: InputMaybe<UserAddress_Bool_Exp>;
  _and?: InputMaybe<Array<UserProfile_Bool_Exp>>;
  _not?: InputMaybe<UserProfile_Bool_Exp>;
  _or?: InputMaybe<Array<UserProfile_Bool_Exp>>;
  address_id?: InputMaybe<Uuid_Comparison_Exp>;
  birth_date?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  middle_name?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  profile_picture_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  ssn?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserProfile" */
export enum UserProfile_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserProfilePkey = 'UserProfile_pkey'
}

/** input type for inserting data into table "UserProfile" */
export type UserProfile_Insert_Input = {
  ProfilePicture?: InputMaybe<File_Obj_Rel_Insert_Input>;
  User?: InputMaybe<User_Obj_Rel_Insert_Input>;
  UserAddress?: InputMaybe<UserAddress_Obj_Rel_Insert_Input>;
  address_id?: InputMaybe<Scalars['uuid']>;
  birth_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  ssn?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type UserProfile_Max_Fields = {
  __typename?: 'UserProfile_max_fields';
  address_id?: Maybe<Scalars['uuid']>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  ssn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type UserProfile_Min_Fields = {
  __typename?: 'UserProfile_min_fields';
  address_id?: Maybe<Scalars['uuid']>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_name?: Maybe<Scalars['String']>;
  middle_name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  profile_picture_file_id?: Maybe<Scalars['uuid']>;
  ssn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "UserProfile" */
export type UserProfile_Mutation_Response = {
  __typename?: 'UserProfile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<UserProfile>;
};

/** input type for inserting object relation for remote table "UserProfile" */
export type UserProfile_Obj_Rel_Insert_Input = {
  data: UserProfile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<UserProfile_On_Conflict>;
};

/** on_conflict condition type for table "UserProfile" */
export type UserProfile_On_Conflict = {
  constraint: UserProfile_Constraint;
  update_columns?: Array<UserProfile_Update_Column>;
  where?: InputMaybe<UserProfile_Bool_Exp>;
};

/** Ordering options when selecting data from "UserProfile". */
export type UserProfile_Order_By = {
  ProfilePicture?: InputMaybe<File_Order_By>;
  User?: InputMaybe<User_Order_By>;
  UserAddress?: InputMaybe<UserAddress_Order_By>;
  address_id?: InputMaybe<Order_By>;
  birth_date?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  middle_name?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  profile_picture_file_id?: InputMaybe<Order_By>;
  ssn?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: UserProfile */
export type UserProfile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "UserProfile" */
export enum UserProfile_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Ssn = 'ssn',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "UserProfile" */
export type UserProfile_Set_Input = {
  address_id?: InputMaybe<Scalars['uuid']>;
  birth_date?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_name?: InputMaybe<Scalars['String']>;
  middle_name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  profile_picture_file_id?: InputMaybe<Scalars['uuid']>;
  ssn?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "UserProfile" */
export enum UserProfile_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastName = 'last_name',
  /** column name */
  MiddleName = 'middle_name',
  /** column name */
  Phone = 'phone',
  /** column name */
  ProfilePictureFileId = 'profile_picture_file_id',
  /** column name */
  Ssn = 'ssn',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregated selection of "User" */
export type User_Aggregate = {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type User_Aggregate_Fields = {
  __typename?: 'User_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
};


/** aggregate fields of "User" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  PersonalDocuments?: InputMaybe<PersonalDocument_Bool_Exp>;
  _and?: InputMaybe<Array<User_Bool_Exp>>;
  _not?: InputMaybe<User_Bool_Exp>;
  _or?: InputMaybe<Array<User_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "User" */
export enum User_Constraint {
  /** unique or primary key constraint on columns "email" */
  WuiUserEmailKey = 'WUI_User_email_key',
  /** unique or primary key constraint on columns "id" */
  WuiUserPkey = 'WUI_User_pkey'
}

/** input type for inserting data into table "User" */
export type User_Insert_Input = {
  PersonalDocuments?: InputMaybe<PersonalDocument_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'User_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'User_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  password?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "User" */
export type User_Mutation_Response = {
  __typename?: 'User_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_On_Conflict>;
};

/** on_conflict condition type for table "User" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns?: Array<User_Update_Column>;
  where?: InputMaybe<User_Bool_Exp>;
};

/** Ordering options when selecting data from "User". */
export type User_Order_By = {
  PersonalDocuments_aggregate?: InputMaybe<PersonalDocument_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: User */
export type User_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "User" */
export enum User_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "User" */
export type User_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  password?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "User" */
export enum User_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Password = 'password',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

export type MisingActionOutput = {
  __typename?: 'misingActionOutput';
  buttonLink: Scalars['String'];
  buttonText: Scalars['String'];
  completed?: Maybe<Scalars['Boolean']>;
  key: Scalars['String'];
  subtitle: Scalars['String'];
  title: Scalars['String'];
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  CreateLetterGroup?: Maybe<LetterGroupOutput>;
  UpdateLetterGroup?: Maybe<LetterGroupUpdateOutput>;
  UploadPersonalDocument?: Maybe<PersonalImageFileOutput>;
  UploadUserProfilePicture?: Maybe<FileOutput>;
  /** delete data from the table: "AccountStatus" */
  delete_AccountStatus?: Maybe<AccountStatus_Mutation_Response>;
  /** delete single row from the table: "AccountStatus" */
  delete_AccountStatus_by_pk?: Maybe<AccountStatus>;
  /** delete data from the table: "Bureaus" */
  delete_Bureaus?: Maybe<Bureaus_Mutation_Response>;
  /** delete single row from the table: "Bureaus" */
  delete_Bureaus_by_pk?: Maybe<Bureaus>;
  /** delete data from the table: "Categories" */
  delete_Categories?: Maybe<Categories_Mutation_Response>;
  /** delete single row from the table: "Categories" */
  delete_Categories_by_pk?: Maybe<Categories>;
  /** delete data from the table: "CreditItem" */
  delete_CreditItem?: Maybe<CreditItem_Mutation_Response>;
  /** delete data from the table: "CreditItemStatus" */
  delete_CreditItemStatus?: Maybe<CreditItemStatus_Mutation_Response>;
  /** delete single row from the table: "CreditItemStatus" */
  delete_CreditItemStatus_by_pk?: Maybe<CreditItemStatus>;
  /** delete single row from the table: "CreditItem" */
  delete_CreditItem_by_pk?: Maybe<CreditItem>;
  /** delete data from the table: "CreditScore" */
  delete_CreditScore?: Maybe<CreditScore_Mutation_Response>;
  /** delete single row from the table: "CreditScore" */
  delete_CreditScore_by_pk?: Maybe<CreditScore>;
  /** delete data from the table: "File" */
  delete_File?: Maybe<File_Mutation_Response>;
  /** delete data from the table: "File_Type" */
  delete_File_Type?: Maybe<File_Type_Mutation_Response>;
  /** delete single row from the table: "File_Type" */
  delete_File_Type_by_pk?: Maybe<File_Type>;
  /** delete single row from the table: "File" */
  delete_File_by_pk?: Maybe<File>;
  /** delete data from the table: "ItemBureau" */
  delete_ItemBureau?: Maybe<ItemBureau_Mutation_Response>;
  /** delete single row from the table: "ItemBureau" */
  delete_ItemBureau_by_pk?: Maybe<ItemBureau>;
  /** delete data from the table: "Letter" */
  delete_Letter?: Maybe<Letter_Mutation_Response>;
  /** delete data from the table: "LetterBureau" */
  delete_LetterBureau?: Maybe<LetterBureau_Mutation_Response>;
  /** delete single row from the table: "LetterBureau" */
  delete_LetterBureau_by_pk?: Maybe<LetterBureau>;
  /** delete data from the table: "LetterGroup" */
  delete_LetterGroup?: Maybe<LetterGroup_Mutation_Response>;
  /** delete single row from the table: "LetterGroup" */
  delete_LetterGroup_by_pk?: Maybe<LetterGroup>;
  /** delete data from the table: "LetterStatus" */
  delete_LetterStatus?: Maybe<LetterStatus_Mutation_Response>;
  /** delete single row from the table: "LetterStatus" */
  delete_LetterStatus_by_pk?: Maybe<LetterStatus>;
  /** delete data from the table: "LetterType" */
  delete_LetterType?: Maybe<LetterType_Mutation_Response>;
  /** delete single row from the table: "LetterType" */
  delete_LetterType_by_pk?: Maybe<LetterType>;
  /** delete single row from the table: "Letter" */
  delete_Letter_by_pk?: Maybe<Letter>;
  /** delete data from the table: "Notification" */
  delete_Notification?: Maybe<Notification_Mutation_Response>;
  /** delete single row from the table: "Notification" */
  delete_Notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "Payment" */
  delete_Payment?: Maybe<Payment_Mutation_Response>;
  /** delete single row from the table: "Payment" */
  delete_Payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "PersonalDocument" */
  delete_PersonalDocument?: Maybe<PersonalDocument_Mutation_Response>;
  /** delete data from the table: "PersonalDocumentType" */
  delete_PersonalDocumentType?: Maybe<PersonalDocumentType_Mutation_Response>;
  /** delete single row from the table: "PersonalDocumentType" */
  delete_PersonalDocumentType_by_pk?: Maybe<PersonalDocumentType>;
  /** delete single row from the table: "PersonalDocument" */
  delete_PersonalDocument_by_pk?: Maybe<PersonalDocument>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "UserAddress" */
  delete_UserAddress?: Maybe<UserAddress_Mutation_Response>;
  /** delete single row from the table: "UserAddress" */
  delete_UserAddress_by_pk?: Maybe<UserAddress>;
  /** delete data from the table: "UserProfile" */
  delete_UserProfile?: Maybe<UserProfile_Mutation_Response>;
  /** delete single row from the table: "UserProfile" */
  delete_UserProfile_by_pk?: Maybe<UserProfile>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** insert data into the table: "AccountStatus" */
  insert_AccountStatus?: Maybe<AccountStatus_Mutation_Response>;
  /** insert a single row into the table: "AccountStatus" */
  insert_AccountStatus_one?: Maybe<AccountStatus>;
  /** insert data into the table: "Bureaus" */
  insert_Bureaus?: Maybe<Bureaus_Mutation_Response>;
  /** insert a single row into the table: "Bureaus" */
  insert_Bureaus_one?: Maybe<Bureaus>;
  /** insert data into the table: "Categories" */
  insert_Categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "Categories" */
  insert_Categories_one?: Maybe<Categories>;
  /** insert data into the table: "CreditItem" */
  insert_CreditItem?: Maybe<CreditItem_Mutation_Response>;
  /** insert data into the table: "CreditItemStatus" */
  insert_CreditItemStatus?: Maybe<CreditItemStatus_Mutation_Response>;
  /** insert a single row into the table: "CreditItemStatus" */
  insert_CreditItemStatus_one?: Maybe<CreditItemStatus>;
  /** insert a single row into the table: "CreditItem" */
  insert_CreditItem_one?: Maybe<CreditItem>;
  /** insert data into the table: "CreditScore" */
  insert_CreditScore?: Maybe<CreditScore_Mutation_Response>;
  /** insert a single row into the table: "CreditScore" */
  insert_CreditScore_one?: Maybe<CreditScore>;
  /** insert data into the table: "File" */
  insert_File?: Maybe<File_Mutation_Response>;
  /** insert data into the table: "File_Type" */
  insert_File_Type?: Maybe<File_Type_Mutation_Response>;
  /** insert a single row into the table: "File_Type" */
  insert_File_Type_one?: Maybe<File_Type>;
  /** insert a single row into the table: "File" */
  insert_File_one?: Maybe<File>;
  /** insert data into the table: "ItemBureau" */
  insert_ItemBureau?: Maybe<ItemBureau_Mutation_Response>;
  /** insert a single row into the table: "ItemBureau" */
  insert_ItemBureau_one?: Maybe<ItemBureau>;
  /** insert data into the table: "Letter" */
  insert_Letter?: Maybe<Letter_Mutation_Response>;
  /** insert data into the table: "LetterBureau" */
  insert_LetterBureau?: Maybe<LetterBureau_Mutation_Response>;
  /** insert a single row into the table: "LetterBureau" */
  insert_LetterBureau_one?: Maybe<LetterBureau>;
  /** insert data into the table: "LetterGroup" */
  insert_LetterGroup?: Maybe<LetterGroup_Mutation_Response>;
  /** insert a single row into the table: "LetterGroup" */
  insert_LetterGroup_one?: Maybe<LetterGroup>;
  /** insert data into the table: "LetterStatus" */
  insert_LetterStatus?: Maybe<LetterStatus_Mutation_Response>;
  /** insert a single row into the table: "LetterStatus" */
  insert_LetterStatus_one?: Maybe<LetterStatus>;
  /** insert data into the table: "LetterType" */
  insert_LetterType?: Maybe<LetterType_Mutation_Response>;
  /** insert a single row into the table: "LetterType" */
  insert_LetterType_one?: Maybe<LetterType>;
  /** insert a single row into the table: "Letter" */
  insert_Letter_one?: Maybe<Letter>;
  /** insert data into the table: "Notification" */
  insert_Notification?: Maybe<Notification_Mutation_Response>;
  /** insert a single row into the table: "Notification" */
  insert_Notification_one?: Maybe<Notification>;
  /** insert data into the table: "Payment" */
  insert_Payment?: Maybe<Payment_Mutation_Response>;
  /** insert a single row into the table: "Payment" */
  insert_Payment_one?: Maybe<Payment>;
  /** insert data into the table: "PersonalDocument" */
  insert_PersonalDocument?: Maybe<PersonalDocument_Mutation_Response>;
  /** insert data into the table: "PersonalDocumentType" */
  insert_PersonalDocumentType?: Maybe<PersonalDocumentType_Mutation_Response>;
  /** insert a single row into the table: "PersonalDocumentType" */
  insert_PersonalDocumentType_one?: Maybe<PersonalDocumentType>;
  /** insert a single row into the table: "PersonalDocument" */
  insert_PersonalDocument_one?: Maybe<PersonalDocument>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "UserAddress" */
  insert_UserAddress?: Maybe<UserAddress_Mutation_Response>;
  /** insert a single row into the table: "UserAddress" */
  insert_UserAddress_one?: Maybe<UserAddress>;
  /** insert data into the table: "UserProfile" */
  insert_UserProfile?: Maybe<UserProfile_Mutation_Response>;
  /** insert a single row into the table: "UserProfile" */
  insert_UserProfile_one?: Maybe<UserProfile>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  login?: Maybe<LoginOutput>;
  passwordReset?: Maybe<PasswordResetOutput>;
  paymentLink?: Maybe<PaymentLinkOutput>;
  requestPasswordReset?: Maybe<RequestPasswordResetOutput>;
  signup?: Maybe<SignupOutput>;
  /** update data of the table: "AccountStatus" */
  update_AccountStatus?: Maybe<AccountStatus_Mutation_Response>;
  /** update single row of the table: "AccountStatus" */
  update_AccountStatus_by_pk?: Maybe<AccountStatus>;
  /** update data of the table: "Bureaus" */
  update_Bureaus?: Maybe<Bureaus_Mutation_Response>;
  /** update single row of the table: "Bureaus" */
  update_Bureaus_by_pk?: Maybe<Bureaus>;
  /** update data of the table: "Categories" */
  update_Categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "Categories" */
  update_Categories_by_pk?: Maybe<Categories>;
  /** update data of the table: "CreditItem" */
  update_CreditItem?: Maybe<CreditItem_Mutation_Response>;
  /** update data of the table: "CreditItemStatus" */
  update_CreditItemStatus?: Maybe<CreditItemStatus_Mutation_Response>;
  /** update single row of the table: "CreditItemStatus" */
  update_CreditItemStatus_by_pk?: Maybe<CreditItemStatus>;
  /** update single row of the table: "CreditItem" */
  update_CreditItem_by_pk?: Maybe<CreditItem>;
  /** update data of the table: "CreditScore" */
  update_CreditScore?: Maybe<CreditScore_Mutation_Response>;
  /** update single row of the table: "CreditScore" */
  update_CreditScore_by_pk?: Maybe<CreditScore>;
  /** update data of the table: "File" */
  update_File?: Maybe<File_Mutation_Response>;
  /** update data of the table: "File_Type" */
  update_File_Type?: Maybe<File_Type_Mutation_Response>;
  /** update single row of the table: "File_Type" */
  update_File_Type_by_pk?: Maybe<File_Type>;
  /** update single row of the table: "File" */
  update_File_by_pk?: Maybe<File>;
  /** update data of the table: "ItemBureau" */
  update_ItemBureau?: Maybe<ItemBureau_Mutation_Response>;
  /** update single row of the table: "ItemBureau" */
  update_ItemBureau_by_pk?: Maybe<ItemBureau>;
  /** update data of the table: "Letter" */
  update_Letter?: Maybe<Letter_Mutation_Response>;
  /** update data of the table: "LetterBureau" */
  update_LetterBureau?: Maybe<LetterBureau_Mutation_Response>;
  /** update single row of the table: "LetterBureau" */
  update_LetterBureau_by_pk?: Maybe<LetterBureau>;
  /** update data of the table: "LetterGroup" */
  update_LetterGroup?: Maybe<LetterGroup_Mutation_Response>;
  /** update single row of the table: "LetterGroup" */
  update_LetterGroup_by_pk?: Maybe<LetterGroup>;
  /** update data of the table: "LetterStatus" */
  update_LetterStatus?: Maybe<LetterStatus_Mutation_Response>;
  /** update single row of the table: "LetterStatus" */
  update_LetterStatus_by_pk?: Maybe<LetterStatus>;
  /** update data of the table: "LetterType" */
  update_LetterType?: Maybe<LetterType_Mutation_Response>;
  /** update single row of the table: "LetterType" */
  update_LetterType_by_pk?: Maybe<LetterType>;
  /** update single row of the table: "Letter" */
  update_Letter_by_pk?: Maybe<Letter>;
  /** update data of the table: "Notification" */
  update_Notification?: Maybe<Notification_Mutation_Response>;
  /** update single row of the table: "Notification" */
  update_Notification_by_pk?: Maybe<Notification>;
  /** update data of the table: "Payment" */
  update_Payment?: Maybe<Payment_Mutation_Response>;
  /** update single row of the table: "Payment" */
  update_Payment_by_pk?: Maybe<Payment>;
  /** update data of the table: "PersonalDocument" */
  update_PersonalDocument?: Maybe<PersonalDocument_Mutation_Response>;
  /** update data of the table: "PersonalDocumentType" */
  update_PersonalDocumentType?: Maybe<PersonalDocumentType_Mutation_Response>;
  /** update single row of the table: "PersonalDocumentType" */
  update_PersonalDocumentType_by_pk?: Maybe<PersonalDocumentType>;
  /** update single row of the table: "PersonalDocument" */
  update_PersonalDocument_by_pk?: Maybe<PersonalDocument>;
  /** update data of the table: "User" */
  update_User?: Maybe<User_Mutation_Response>;
  /** update data of the table: "UserAddress" */
  update_UserAddress?: Maybe<UserAddress_Mutation_Response>;
  /** update single row of the table: "UserAddress" */
  update_UserAddress_by_pk?: Maybe<UserAddress>;
  /** update data of the table: "UserProfile" */
  update_UserProfile?: Maybe<UserProfile_Mutation_Response>;
  /** update single row of the table: "UserProfile" */
  update_UserProfile_by_pk?: Maybe<UserProfile>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
};


/** mutation root */
export type Mutation_RootCreateLetterGroupArgs = {
  bureaus: Scalars['json'];
  items: Scalars['json'];
  letter_type: Scalars['String'];
  raw_html: Scalars['String'];
  selected_letter_name: Scalars['String'];
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdateLetterGroupArgs = {
  bureaus: Scalars['json'];
  group_id: Scalars['uuid'];
  items: Scalars['json'];
  letter_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootUploadPersonalDocumentArgs = {
  documentType: Scalars['String'];
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUploadUserProfilePictureArgs = {
  imageBase64: Scalars['String'];
  imageType: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AccountStatusArgs = {
  where: AccountStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_AccountStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_BureausArgs = {
  where: Bureaus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bureaus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CategoriesArgs = {
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Categories_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CreditItemArgs = {
  where: CreditItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CreditItemStatusArgs = {
  where: CreditItemStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CreditItemStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CreditItem_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CreditScoreArgs = {
  where: CreditScore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_CreditScore_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FileArgs = {
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_TypeArgs = {
  where: File_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_File_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ItemBureauArgs = {
  where: ItemBureau_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ItemBureau_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LetterArgs = {
  where: Letter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LetterBureauArgs = {
  where: LetterBureau_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LetterBureau_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LetterGroupArgs = {
  where: LetterGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LetterGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LetterStatusArgs = {
  where: LetterStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LetterStatus_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LetterTypeArgs = {
  where: LetterType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_LetterType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Letter_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PersonalDocumentArgs = {
  where: PersonalDocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PersonalDocumentTypeArgs = {
  where: PersonalDocumentType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PersonalDocumentType_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PersonalDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserAddressArgs = {
  where: UserAddress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UserProfileArgs = {
  where: UserProfile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_AccountStatusArgs = {
  objects: Array<AccountStatus_Insert_Input>;
  on_conflict?: InputMaybe<AccountStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccountStatus_OneArgs = {
  object: AccountStatus_Insert_Input;
  on_conflict?: InputMaybe<AccountStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BureausArgs = {
  objects: Array<Bureaus_Insert_Input>;
  on_conflict?: InputMaybe<Bureaus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bureaus_OneArgs = {
  object: Bureaus_Insert_Input;
  on_conflict?: InputMaybe<Bureaus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CreditItemArgs = {
  objects: Array<CreditItem_Insert_Input>;
  on_conflict?: InputMaybe<CreditItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CreditItemStatusArgs = {
  objects: Array<CreditItemStatus_Insert_Input>;
  on_conflict?: InputMaybe<CreditItemStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CreditItemStatus_OneArgs = {
  object: CreditItemStatus_Insert_Input;
  on_conflict?: InputMaybe<CreditItemStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CreditItem_OneArgs = {
  object: CreditItem_Insert_Input;
  on_conflict?: InputMaybe<CreditItem_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CreditScoreArgs = {
  objects: Array<CreditScore_Insert_Input>;
  on_conflict?: InputMaybe<CreditScore_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CreditScore_OneArgs = {
  object: CreditScore_Insert_Input;
  on_conflict?: InputMaybe<CreditScore_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FileArgs = {
  objects: Array<File_Insert_Input>;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_TypeArgs = {
  objects: Array<File_Type_Insert_Input>;
  on_conflict?: InputMaybe<File_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Type_OneArgs = {
  object: File_Type_Insert_Input;
  on_conflict?: InputMaybe<File_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_OneArgs = {
  object: File_Insert_Input;
  on_conflict?: InputMaybe<File_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItemBureauArgs = {
  objects: Array<ItemBureau_Insert_Input>;
  on_conflict?: InputMaybe<ItemBureau_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ItemBureau_OneArgs = {
  object: ItemBureau_Insert_Input;
  on_conflict?: InputMaybe<ItemBureau_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterArgs = {
  objects: Array<Letter_Insert_Input>;
  on_conflict?: InputMaybe<Letter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterBureauArgs = {
  objects: Array<LetterBureau_Insert_Input>;
  on_conflict?: InputMaybe<LetterBureau_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterBureau_OneArgs = {
  object: LetterBureau_Insert_Input;
  on_conflict?: InputMaybe<LetterBureau_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterGroupArgs = {
  objects: Array<LetterGroup_Insert_Input>;
  on_conflict?: InputMaybe<LetterGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterGroup_OneArgs = {
  object: LetterGroup_Insert_Input;
  on_conflict?: InputMaybe<LetterGroup_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterStatusArgs = {
  objects: Array<LetterStatus_Insert_Input>;
  on_conflict?: InputMaybe<LetterStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterStatus_OneArgs = {
  object: LetterStatus_Insert_Input;
  on_conflict?: InputMaybe<LetterStatus_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterTypeArgs = {
  objects: Array<LetterType_Insert_Input>;
  on_conflict?: InputMaybe<LetterType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LetterType_OneArgs = {
  object: LetterType_Insert_Input;
  on_conflict?: InputMaybe<LetterType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_OneArgs = {
  object: Letter_Insert_Input;
  on_conflict?: InputMaybe<Letter_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: InputMaybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonalDocumentArgs = {
  objects: Array<PersonalDocument_Insert_Input>;
  on_conflict?: InputMaybe<PersonalDocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonalDocumentTypeArgs = {
  objects: Array<PersonalDocumentType_Insert_Input>;
  on_conflict?: InputMaybe<PersonalDocumentType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonalDocumentType_OneArgs = {
  object: PersonalDocumentType_Insert_Input;
  on_conflict?: InputMaybe<PersonalDocumentType_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PersonalDocument_OneArgs = {
  object: PersonalDocument_Insert_Input;
  on_conflict?: InputMaybe<PersonalDocument_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAddressArgs = {
  objects: Array<UserAddress_Insert_Input>;
  on_conflict?: InputMaybe<UserAddress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserAddress_OneArgs = {
  object: UserAddress_Insert_Input;
  on_conflict?: InputMaybe<UserAddress_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserProfileArgs = {
  objects: Array<UserProfile_Insert_Input>;
  on_conflict?: InputMaybe<UserProfile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserProfile_OneArgs = {
  object: UserProfile_Insert_Input;
  on_conflict?: InputMaybe<UserProfile_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: InputMaybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPasswordResetArgs = {
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  token: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPaymentLinkArgs = {
  discounts?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  priceId: Scalars['String'];
  productId: Scalars['String'];
};


/** mutation root */
export type Mutation_RootRequestPasswordResetArgs = {
  email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_AccountStatusArgs = {
  _set?: InputMaybe<AccountStatus_Set_Input>;
  where: AccountStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_AccountStatus_By_PkArgs = {
  _set?: InputMaybe<AccountStatus_Set_Input>;
  pk_columns: AccountStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BureausArgs = {
  _set?: InputMaybe<Bureaus_Set_Input>;
  where: Bureaus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bureaus_By_PkArgs = {
  _set?: InputMaybe<Bureaus_Set_Input>;
  pk_columns: Bureaus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CreditItemArgs = {
  _inc?: InputMaybe<CreditItem_Inc_Input>;
  _set?: InputMaybe<CreditItem_Set_Input>;
  where: CreditItem_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CreditItemStatusArgs = {
  _set?: InputMaybe<CreditItemStatus_Set_Input>;
  where: CreditItemStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CreditItemStatus_By_PkArgs = {
  _set?: InputMaybe<CreditItemStatus_Set_Input>;
  pk_columns: CreditItemStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CreditItem_By_PkArgs = {
  _inc?: InputMaybe<CreditItem_Inc_Input>;
  _set?: InputMaybe<CreditItem_Set_Input>;
  pk_columns: CreditItem_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CreditScoreArgs = {
  _inc?: InputMaybe<CreditScore_Inc_Input>;
  _set?: InputMaybe<CreditScore_Set_Input>;
  where: CreditScore_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_CreditScore_By_PkArgs = {
  _inc?: InputMaybe<CreditScore_Inc_Input>;
  _set?: InputMaybe<CreditScore_Set_Input>;
  pk_columns: CreditScore_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FileArgs = {
  _set?: InputMaybe<File_Set_Input>;
  where: File_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_TypeArgs = {
  _set?: InputMaybe<File_Type_Set_Input>;
  where: File_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Type_By_PkArgs = {
  _set?: InputMaybe<File_Type_Set_Input>;
  pk_columns: File_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_By_PkArgs = {
  _set?: InputMaybe<File_Set_Input>;
  pk_columns: File_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ItemBureauArgs = {
  _set?: InputMaybe<ItemBureau_Set_Input>;
  where: ItemBureau_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ItemBureau_By_PkArgs = {
  _set?: InputMaybe<ItemBureau_Set_Input>;
  pk_columns: ItemBureau_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LetterArgs = {
  _append?: InputMaybe<Letter_Append_Input>;
  _delete_at_path?: InputMaybe<Letter_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Letter_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Letter_Delete_Key_Input>;
  _inc?: InputMaybe<Letter_Inc_Input>;
  _prepend?: InputMaybe<Letter_Prepend_Input>;
  _set?: InputMaybe<Letter_Set_Input>;
  where: Letter_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LetterBureauArgs = {
  _set?: InputMaybe<LetterBureau_Set_Input>;
  where: LetterBureau_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LetterBureau_By_PkArgs = {
  _set?: InputMaybe<LetterBureau_Set_Input>;
  pk_columns: LetterBureau_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LetterGroupArgs = {
  _set?: InputMaybe<LetterGroup_Set_Input>;
  where: LetterGroup_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LetterGroup_By_PkArgs = {
  _set?: InputMaybe<LetterGroup_Set_Input>;
  pk_columns: LetterGroup_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LetterStatusArgs = {
  _set?: InputMaybe<LetterStatus_Set_Input>;
  where: LetterStatus_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LetterStatus_By_PkArgs = {
  _set?: InputMaybe<LetterStatus_Set_Input>;
  pk_columns: LetterStatus_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LetterTypeArgs = {
  _set?: InputMaybe<LetterType_Set_Input>;
  where: LetterType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_LetterType_By_PkArgs = {
  _set?: InputMaybe<LetterType_Set_Input>;
  pk_columns: LetterType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_By_PkArgs = {
  _append?: InputMaybe<Letter_Append_Input>;
  _delete_at_path?: InputMaybe<Letter_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Letter_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Letter_Delete_Key_Input>;
  _inc?: InputMaybe<Letter_Inc_Input>;
  _prepend?: InputMaybe<Letter_Prepend_Input>;
  _set?: InputMaybe<Letter_Set_Input>;
  pk_columns: Letter_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _append?: InputMaybe<Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  where: Notification_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _append?: InputMaybe<Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Notification_Delete_Key_Input>;
  _prepend?: InputMaybe<Notification_Prepend_Input>;
  _set?: InputMaybe<Notification_Set_Input>;
  pk_columns: Notification_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _inc?: InputMaybe<Payment_Inc_Input>;
  _set?: InputMaybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _inc?: InputMaybe<Payment_Inc_Input>;
  _set?: InputMaybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PersonalDocumentArgs = {
  _set?: InputMaybe<PersonalDocument_Set_Input>;
  where: PersonalDocument_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PersonalDocumentTypeArgs = {
  _set?: InputMaybe<PersonalDocumentType_Set_Input>;
  where: PersonalDocumentType_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PersonalDocumentType_By_PkArgs = {
  _set?: InputMaybe<PersonalDocumentType_Set_Input>;
  pk_columns: PersonalDocumentType_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PersonalDocument_By_PkArgs = {
  _set?: InputMaybe<PersonalDocument_Set_Input>;
  pk_columns: PersonalDocument_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _set?: InputMaybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserAddressArgs = {
  _set?: InputMaybe<UserAddress_Set_Input>;
  where: UserAddress_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserAddress_By_PkArgs = {
  _set?: InputMaybe<UserAddress_Set_Input>;
  pk_columns: UserAddress_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserProfileArgs = {
  _set?: InputMaybe<UserProfile_Set_Input>;
  where: UserProfile_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserProfile_By_PkArgs = {
  _set?: InputMaybe<UserProfile_Set_Input>;
  pk_columns: UserProfile_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _set?: InputMaybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "AccountStatus" */
  AccountStatus: Array<AccountStatus>;
  /** fetch aggregated fields from the table: "AccountStatus" */
  AccountStatus_aggregate: AccountStatus_Aggregate;
  /** fetch data from the table: "AccountStatus" using primary key columns */
  AccountStatus_by_pk?: Maybe<AccountStatus>;
  /** fetch data from the table: "Bureaus" */
  Bureaus: Array<Bureaus>;
  /** fetch aggregated fields from the table: "Bureaus" */
  Bureaus_aggregate: Bureaus_Aggregate;
  /** fetch data from the table: "Bureaus" using primary key columns */
  Bureaus_by_pk?: Maybe<Bureaus>;
  /** fetch data from the table: "Categories" */
  Categories: Array<Categories>;
  /** fetch aggregated fields from the table: "Categories" */
  Categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "Categories" using primary key columns */
  Categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table: "CreditItem" */
  CreditItem: Array<CreditItem>;
  /** fetch data from the table: "CreditItemStatus" */
  CreditItemStatus: Array<CreditItemStatus>;
  /** fetch aggregated fields from the table: "CreditItemStatus" */
  CreditItemStatus_aggregate: CreditItemStatus_Aggregate;
  /** fetch data from the table: "CreditItemStatus" using primary key columns */
  CreditItemStatus_by_pk?: Maybe<CreditItemStatus>;
  /** fetch aggregated fields from the table: "CreditItem" */
  CreditItem_aggregate: CreditItem_Aggregate;
  /** fetch data from the table: "CreditItem" using primary key columns */
  CreditItem_by_pk?: Maybe<CreditItem>;
  /** fetch data from the table: "CreditScore" */
  CreditScore: Array<CreditScore>;
  /** fetch aggregated fields from the table: "CreditScore" */
  CreditScore_aggregate: CreditScore_Aggregate;
  /** fetch data from the table: "CreditScore" using primary key columns */
  CreditScore_by_pk?: Maybe<CreditScore>;
  /** fetch data from the table: "File" */
  File: Array<File>;
  /** fetch data from the table: "File_Type" */
  File_Type: Array<File_Type>;
  /** fetch aggregated fields from the table: "File_Type" */
  File_Type_aggregate: File_Type_Aggregate;
  /** fetch data from the table: "File_Type" using primary key columns */
  File_Type_by_pk?: Maybe<File_Type>;
  /** fetch aggregated fields from the table: "File" */
  File_aggregate: File_Aggregate;
  /** fetch data from the table: "File" using primary key columns */
  File_by_pk?: Maybe<File>;
  /** fetch data from the table: "ItemBureau" */
  ItemBureau: Array<ItemBureau>;
  /** fetch aggregated fields from the table: "ItemBureau" */
  ItemBureau_aggregate: ItemBureau_Aggregate;
  /** fetch data from the table: "ItemBureau" using primary key columns */
  ItemBureau_by_pk?: Maybe<ItemBureau>;
  /** fetch data from the table: "Letter" */
  Letter: Array<Letter>;
  /** fetch data from the table: "LetterBureau" */
  LetterBureau: Array<LetterBureau>;
  /** fetch aggregated fields from the table: "LetterBureau" */
  LetterBureau_aggregate: LetterBureau_Aggregate;
  /** fetch data from the table: "LetterBureau" using primary key columns */
  LetterBureau_by_pk?: Maybe<LetterBureau>;
  /** fetch data from the table: "LetterGroup" */
  LetterGroup: Array<LetterGroup>;
  /** fetch aggregated fields from the table: "LetterGroup" */
  LetterGroup_aggregate: LetterGroup_Aggregate;
  /** fetch data from the table: "LetterGroup" using primary key columns */
  LetterGroup_by_pk?: Maybe<LetterGroup>;
  /** fetch data from the table: "LetterStatus" */
  LetterStatus: Array<LetterStatus>;
  /** fetch aggregated fields from the table: "LetterStatus" */
  LetterStatus_aggregate: LetterStatus_Aggregate;
  /** fetch data from the table: "LetterStatus" using primary key columns */
  LetterStatus_by_pk?: Maybe<LetterStatus>;
  /** fetch data from the table: "LetterType" */
  LetterType: Array<LetterType>;
  /** fetch aggregated fields from the table: "LetterType" */
  LetterType_aggregate: LetterType_Aggregate;
  /** fetch data from the table: "LetterType" using primary key columns */
  LetterType_by_pk?: Maybe<LetterType>;
  /** fetch aggregated fields from the table: "Letter" */
  Letter_aggregate: Letter_Aggregate;
  /** fetch data from the table: "Letter" using primary key columns */
  Letter_by_pk?: Maybe<Letter>;
  /** fetch data from the table: "Notification" */
  Notification: Array<Notification>;
  /** fetch aggregated fields from the table: "Notification" */
  Notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "Notification" using primary key columns */
  Notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "Payment" */
  Payment: Array<Payment>;
  /** fetch aggregated fields from the table: "Payment" */
  Payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "Payment" using primary key columns */
  Payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "PersonalDocument" */
  PersonalDocument: Array<PersonalDocument>;
  /** fetch data from the table: "PersonalDocumentType" */
  PersonalDocumentType: Array<PersonalDocumentType>;
  /** fetch aggregated fields from the table: "PersonalDocumentType" */
  PersonalDocumentType_aggregate: PersonalDocumentType_Aggregate;
  /** fetch data from the table: "PersonalDocumentType" using primary key columns */
  PersonalDocumentType_by_pk?: Maybe<PersonalDocumentType>;
  /** fetch aggregated fields from the table: "PersonalDocument" */
  PersonalDocument_aggregate: PersonalDocument_Aggregate;
  /** fetch data from the table: "PersonalDocument" using primary key columns */
  PersonalDocument_by_pk?: Maybe<PersonalDocument>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserAddress" */
  UserAddress: Array<UserAddress>;
  /** fetch aggregated fields from the table: "UserAddress" */
  UserAddress_aggregate: UserAddress_Aggregate;
  /** fetch data from the table: "UserAddress" using primary key columns */
  UserAddress_by_pk?: Maybe<UserAddress>;
  /** fetch data from the table: "UserProfile" */
  UserProfile: Array<UserProfile>;
  /** fetch aggregated fields from the table: "UserProfile" */
  UserProfile_aggregate: UserProfile_Aggregate;
  /** fetch data from the table: "UserProfile" using primary key columns */
  UserProfile_by_pk?: Maybe<UserProfile>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  checkMissingSteps: Array<Maybe<MisingActionOutput>>;
  getCitiesByState?: Maybe<Array<CitiesOutput>>;
  paymentPlans: PaymentPlansOutput;
  paymentStatus?: Maybe<PaymentStatusOutput>;
};


export type Query_RootAccountStatusArgs = {
  distinct_on?: InputMaybe<Array<AccountStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountStatus_Order_By>>;
  where?: InputMaybe<AccountStatus_Bool_Exp>;
};


export type Query_RootAccountStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccountStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountStatus_Order_By>>;
  where?: InputMaybe<AccountStatus_Bool_Exp>;
};


export type Query_RootAccountStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootBureausArgs = {
  distinct_on?: InputMaybe<Array<Bureaus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bureaus_Order_By>>;
  where?: InputMaybe<Bureaus_Bool_Exp>;
};


export type Query_RootBureaus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bureaus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bureaus_Order_By>>;
  where?: InputMaybe<Bureaus_Bool_Exp>;
};


export type Query_RootBureaus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Query_RootCategories_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCreditItemArgs = {
  distinct_on?: InputMaybe<Array<CreditItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItem_Order_By>>;
  where?: InputMaybe<CreditItem_Bool_Exp>;
};


export type Query_RootCreditItemStatusArgs = {
  distinct_on?: InputMaybe<Array<CreditItemStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItemStatus_Order_By>>;
  where?: InputMaybe<CreditItemStatus_Bool_Exp>;
};


export type Query_RootCreditItemStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditItemStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItemStatus_Order_By>>;
  where?: InputMaybe<CreditItemStatus_Bool_Exp>;
};


export type Query_RootCreditItemStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCreditItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItem_Order_By>>;
  where?: InputMaybe<CreditItem_Bool_Exp>;
};


export type Query_RootCreditItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCreditScoreArgs = {
  distinct_on?: InputMaybe<Array<CreditScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditScore_Order_By>>;
  where?: InputMaybe<CreditScore_Bool_Exp>;
};


export type Query_RootCreditScore_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditScore_Order_By>>;
  where?: InputMaybe<CreditScore_Bool_Exp>;
};


export type Query_RootCreditScore_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_TypeArgs = {
  distinct_on?: InputMaybe<Array<File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Type_Order_By>>;
  where?: InputMaybe<File_Type_Bool_Exp>;
};


export type Query_RootFile_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Type_Order_By>>;
  where?: InputMaybe<File_Type_Bool_Exp>;
};


export type Query_RootFile_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Query_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootItemBureauArgs = {
  distinct_on?: InputMaybe<Array<ItemBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemBureau_Order_By>>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};


export type Query_RootItemBureau_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ItemBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemBureau_Order_By>>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};


export type Query_RootItemBureau_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLetterArgs = {
  distinct_on?: InputMaybe<Array<Letter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letter_Order_By>>;
  where?: InputMaybe<Letter_Bool_Exp>;
};


export type Query_RootLetterBureauArgs = {
  distinct_on?: InputMaybe<Array<LetterBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterBureau_Order_By>>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};


export type Query_RootLetterBureau_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterBureau_Order_By>>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};


export type Query_RootLetterBureau_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLetterGroupArgs = {
  distinct_on?: InputMaybe<Array<LetterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterGroup_Order_By>>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};


export type Query_RootLetterGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterGroup_Order_By>>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};


export type Query_RootLetterGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLetterStatusArgs = {
  distinct_on?: InputMaybe<Array<LetterStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterStatus_Order_By>>;
  where?: InputMaybe<LetterStatus_Bool_Exp>;
};


export type Query_RootLetterStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterStatus_Order_By>>;
  where?: InputMaybe<LetterStatus_Bool_Exp>;
};


export type Query_RootLetterStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLetterTypeArgs = {
  distinct_on?: InputMaybe<Array<LetterType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterType_Order_By>>;
  where?: InputMaybe<LetterType_Bool_Exp>;
};


export type Query_RootLetterType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterType_Order_By>>;
  where?: InputMaybe<LetterType_Bool_Exp>;
};


export type Query_RootLetterType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootLetter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Letter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letter_Order_By>>;
  where?: InputMaybe<Letter_Bool_Exp>;
};


export type Query_RootLetter_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Query_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPersonalDocumentArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocument_Order_By>>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};


export type Query_RootPersonalDocumentTypeArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocumentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocumentType_Order_By>>;
  where?: InputMaybe<PersonalDocumentType_Bool_Exp>;
};


export type Query_RootPersonalDocumentType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocumentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocumentType_Order_By>>;
  where?: InputMaybe<PersonalDocumentType_Bool_Exp>;
};


export type Query_RootPersonalDocumentType_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootPersonalDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocument_Order_By>>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};


export type Query_RootPersonalDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUserAddressArgs = {
  distinct_on?: InputMaybe<Array<UserAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddress_Order_By>>;
  where?: InputMaybe<UserAddress_Bool_Exp>;
};


export type Query_RootUserAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddress_Order_By>>;
  where?: InputMaybe<UserAddress_Bool_Exp>;
};


export type Query_RootUserAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUserProfileArgs = {
  distinct_on?: InputMaybe<Array<UserProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProfile_Order_By>>;
  where?: InputMaybe<UserProfile_Bool_Exp>;
};


export type Query_RootUserProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProfile_Order_By>>;
  where?: InputMaybe<UserProfile_Bool_Exp>;
};


export type Query_RootUserProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Query_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetCitiesByStateArgs = {
  countryCode: Scalars['String'];
  stateCode: Scalars['String'];
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "AccountStatus" */
  AccountStatus: Array<AccountStatus>;
  /** fetch aggregated fields from the table: "AccountStatus" */
  AccountStatus_aggregate: AccountStatus_Aggregate;
  /** fetch data from the table: "AccountStatus" using primary key columns */
  AccountStatus_by_pk?: Maybe<AccountStatus>;
  /** fetch data from the table: "Bureaus" */
  Bureaus: Array<Bureaus>;
  /** fetch aggregated fields from the table: "Bureaus" */
  Bureaus_aggregate: Bureaus_Aggregate;
  /** fetch data from the table: "Bureaus" using primary key columns */
  Bureaus_by_pk?: Maybe<Bureaus>;
  /** fetch data from the table: "Categories" */
  Categories: Array<Categories>;
  /** fetch aggregated fields from the table: "Categories" */
  Categories_aggregate: Categories_Aggregate;
  /** fetch data from the table: "Categories" using primary key columns */
  Categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table: "CreditItem" */
  CreditItem: Array<CreditItem>;
  /** fetch data from the table: "CreditItemStatus" */
  CreditItemStatus: Array<CreditItemStatus>;
  /** fetch aggregated fields from the table: "CreditItemStatus" */
  CreditItemStatus_aggregate: CreditItemStatus_Aggregate;
  /** fetch data from the table: "CreditItemStatus" using primary key columns */
  CreditItemStatus_by_pk?: Maybe<CreditItemStatus>;
  /** fetch aggregated fields from the table: "CreditItem" */
  CreditItem_aggregate: CreditItem_Aggregate;
  /** fetch data from the table: "CreditItem" using primary key columns */
  CreditItem_by_pk?: Maybe<CreditItem>;
  /** fetch data from the table: "CreditScore" */
  CreditScore: Array<CreditScore>;
  /** fetch aggregated fields from the table: "CreditScore" */
  CreditScore_aggregate: CreditScore_Aggregate;
  /** fetch data from the table: "CreditScore" using primary key columns */
  CreditScore_by_pk?: Maybe<CreditScore>;
  /** fetch data from the table: "File" */
  File: Array<File>;
  /** fetch data from the table: "File_Type" */
  File_Type: Array<File_Type>;
  /** fetch aggregated fields from the table: "File_Type" */
  File_Type_aggregate: File_Type_Aggregate;
  /** fetch data from the table: "File_Type" using primary key columns */
  File_Type_by_pk?: Maybe<File_Type>;
  /** fetch aggregated fields from the table: "File" */
  File_aggregate: File_Aggregate;
  /** fetch data from the table: "File" using primary key columns */
  File_by_pk?: Maybe<File>;
  /** fetch data from the table: "ItemBureau" */
  ItemBureau: Array<ItemBureau>;
  /** fetch aggregated fields from the table: "ItemBureau" */
  ItemBureau_aggregate: ItemBureau_Aggregate;
  /** fetch data from the table: "ItemBureau" using primary key columns */
  ItemBureau_by_pk?: Maybe<ItemBureau>;
  /** fetch data from the table: "Letter" */
  Letter: Array<Letter>;
  /** fetch data from the table: "LetterBureau" */
  LetterBureau: Array<LetterBureau>;
  /** fetch aggregated fields from the table: "LetterBureau" */
  LetterBureau_aggregate: LetterBureau_Aggregate;
  /** fetch data from the table: "LetterBureau" using primary key columns */
  LetterBureau_by_pk?: Maybe<LetterBureau>;
  /** fetch data from the table: "LetterGroup" */
  LetterGroup: Array<LetterGroup>;
  /** fetch aggregated fields from the table: "LetterGroup" */
  LetterGroup_aggregate: LetterGroup_Aggregate;
  /** fetch data from the table: "LetterGroup" using primary key columns */
  LetterGroup_by_pk?: Maybe<LetterGroup>;
  /** fetch data from the table: "LetterStatus" */
  LetterStatus: Array<LetterStatus>;
  /** fetch aggregated fields from the table: "LetterStatus" */
  LetterStatus_aggregate: LetterStatus_Aggregate;
  /** fetch data from the table: "LetterStatus" using primary key columns */
  LetterStatus_by_pk?: Maybe<LetterStatus>;
  /** fetch data from the table: "LetterType" */
  LetterType: Array<LetterType>;
  /** fetch aggregated fields from the table: "LetterType" */
  LetterType_aggregate: LetterType_Aggregate;
  /** fetch data from the table: "LetterType" using primary key columns */
  LetterType_by_pk?: Maybe<LetterType>;
  /** fetch aggregated fields from the table: "Letter" */
  Letter_aggregate: Letter_Aggregate;
  /** fetch data from the table: "Letter" using primary key columns */
  Letter_by_pk?: Maybe<Letter>;
  /** fetch data from the table: "Notification" */
  Notification: Array<Notification>;
  /** fetch aggregated fields from the table: "Notification" */
  Notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "Notification" using primary key columns */
  Notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "Payment" */
  Payment: Array<Payment>;
  /** fetch aggregated fields from the table: "Payment" */
  Payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "Payment" using primary key columns */
  Payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "PersonalDocument" */
  PersonalDocument: Array<PersonalDocument>;
  /** fetch data from the table: "PersonalDocumentType" */
  PersonalDocumentType: Array<PersonalDocumentType>;
  /** fetch aggregated fields from the table: "PersonalDocumentType" */
  PersonalDocumentType_aggregate: PersonalDocumentType_Aggregate;
  /** fetch data from the table: "PersonalDocumentType" using primary key columns */
  PersonalDocumentType_by_pk?: Maybe<PersonalDocumentType>;
  /** fetch aggregated fields from the table: "PersonalDocument" */
  PersonalDocument_aggregate: PersonalDocument_Aggregate;
  /** fetch data from the table: "PersonalDocument" using primary key columns */
  PersonalDocument_by_pk?: Maybe<PersonalDocument>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserAddress" */
  UserAddress: Array<UserAddress>;
  /** fetch aggregated fields from the table: "UserAddress" */
  UserAddress_aggregate: UserAddress_Aggregate;
  /** fetch data from the table: "UserAddress" using primary key columns */
  UserAddress_by_pk?: Maybe<UserAddress>;
  /** fetch data from the table: "UserProfile" */
  UserProfile: Array<UserProfile>;
  /** fetch aggregated fields from the table: "UserProfile" */
  UserProfile_aggregate: UserProfile_Aggregate;
  /** fetch data from the table: "UserProfile" using primary key columns */
  UserProfile_by_pk?: Maybe<UserProfile>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
};


export type Subscription_RootAccountStatusArgs = {
  distinct_on?: InputMaybe<Array<AccountStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountStatus_Order_By>>;
  where?: InputMaybe<AccountStatus_Bool_Exp>;
};


export type Subscription_RootAccountStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<AccountStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<AccountStatus_Order_By>>;
  where?: InputMaybe<AccountStatus_Bool_Exp>;
};


export type Subscription_RootAccountStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootBureausArgs = {
  distinct_on?: InputMaybe<Array<Bureaus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bureaus_Order_By>>;
  where?: InputMaybe<Bureaus_Bool_Exp>;
};


export type Subscription_RootBureaus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Bureaus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bureaus_Order_By>>;
  where?: InputMaybe<Bureaus_Bool_Exp>;
};


export type Subscription_RootBureaus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};


export type Subscription_RootCategories_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCreditItemArgs = {
  distinct_on?: InputMaybe<Array<CreditItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItem_Order_By>>;
  where?: InputMaybe<CreditItem_Bool_Exp>;
};


export type Subscription_RootCreditItemStatusArgs = {
  distinct_on?: InputMaybe<Array<CreditItemStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItemStatus_Order_By>>;
  where?: InputMaybe<CreditItemStatus_Bool_Exp>;
};


export type Subscription_RootCreditItemStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditItemStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItemStatus_Order_By>>;
  where?: InputMaybe<CreditItemStatus_Bool_Exp>;
};


export type Subscription_RootCreditItemStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCreditItem_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditItem_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditItem_Order_By>>;
  where?: InputMaybe<CreditItem_Bool_Exp>;
};


export type Subscription_RootCreditItem_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCreditScoreArgs = {
  distinct_on?: InputMaybe<Array<CreditScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditScore_Order_By>>;
  where?: InputMaybe<CreditScore_Bool_Exp>;
};


export type Subscription_RootCreditScore_AggregateArgs = {
  distinct_on?: InputMaybe<Array<CreditScore_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<CreditScore_Order_By>>;
  where?: InputMaybe<CreditScore_Bool_Exp>;
};


export type Subscription_RootCreditScore_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFileArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_TypeArgs = {
  distinct_on?: InputMaybe<Array<File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Type_Order_By>>;
  where?: InputMaybe<File_Type_Bool_Exp>;
};


export type Subscription_RootFile_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Type_Order_By>>;
  where?: InputMaybe<File_Type_Bool_Exp>;
};


export type Subscription_RootFile_Type_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootFile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Order_By>>;
  where?: InputMaybe<File_Bool_Exp>;
};


export type Subscription_RootFile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootItemBureauArgs = {
  distinct_on?: InputMaybe<Array<ItemBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemBureau_Order_By>>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};


export type Subscription_RootItemBureau_AggregateArgs = {
  distinct_on?: InputMaybe<Array<ItemBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<ItemBureau_Order_By>>;
  where?: InputMaybe<ItemBureau_Bool_Exp>;
};


export type Subscription_RootItemBureau_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLetterArgs = {
  distinct_on?: InputMaybe<Array<Letter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letter_Order_By>>;
  where?: InputMaybe<Letter_Bool_Exp>;
};


export type Subscription_RootLetterBureauArgs = {
  distinct_on?: InputMaybe<Array<LetterBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterBureau_Order_By>>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};


export type Subscription_RootLetterBureau_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterBureau_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterBureau_Order_By>>;
  where?: InputMaybe<LetterBureau_Bool_Exp>;
};


export type Subscription_RootLetterBureau_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLetterGroupArgs = {
  distinct_on?: InputMaybe<Array<LetterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterGroup_Order_By>>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};


export type Subscription_RootLetterGroup_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterGroup_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterGroup_Order_By>>;
  where?: InputMaybe<LetterGroup_Bool_Exp>;
};


export type Subscription_RootLetterGroup_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLetterStatusArgs = {
  distinct_on?: InputMaybe<Array<LetterStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterStatus_Order_By>>;
  where?: InputMaybe<LetterStatus_Bool_Exp>;
};


export type Subscription_RootLetterStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterStatus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterStatus_Order_By>>;
  where?: InputMaybe<LetterStatus_Bool_Exp>;
};


export type Subscription_RootLetterStatus_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLetterTypeArgs = {
  distinct_on?: InputMaybe<Array<LetterType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterType_Order_By>>;
  where?: InputMaybe<LetterType_Bool_Exp>;
};


export type Subscription_RootLetterType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<LetterType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<LetterType_Order_By>>;
  where?: InputMaybe<LetterType_Bool_Exp>;
};


export type Subscription_RootLetterType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootLetter_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Letter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letter_Order_By>>;
  where?: InputMaybe<Letter_Bool_Exp>;
};


export type Subscription_RootLetter_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};


export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPaymentArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Order_By>>;
  where?: InputMaybe<Payment_Bool_Exp>;
};


export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPersonalDocumentArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocument_Order_By>>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};


export type Subscription_RootPersonalDocumentTypeArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocumentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocumentType_Order_By>>;
  where?: InputMaybe<PersonalDocumentType_Bool_Exp>;
};


export type Subscription_RootPersonalDocumentType_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocumentType_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocumentType_Order_By>>;
  where?: InputMaybe<PersonalDocumentType_Bool_Exp>;
};


export type Subscription_RootPersonalDocumentType_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootPersonalDocument_AggregateArgs = {
  distinct_on?: InputMaybe<Array<PersonalDocument_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<PersonalDocument_Order_By>>;
  where?: InputMaybe<PersonalDocument_Bool_Exp>;
};


export type Subscription_RootPersonalDocument_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUserAddressArgs = {
  distinct_on?: InputMaybe<Array<UserAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddress_Order_By>>;
  where?: InputMaybe<UserAddress_Bool_Exp>;
};


export type Subscription_RootUserAddress_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserAddress_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserAddress_Order_By>>;
  where?: InputMaybe<UserAddress_Bool_Exp>;
};


export type Subscription_RootUserAddress_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUserProfileArgs = {
  distinct_on?: InputMaybe<Array<UserProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProfile_Order_By>>;
  where?: InputMaybe<UserProfile_Bool_Exp>;
};


export type Subscription_RootUserProfile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<UserProfile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<UserProfile_Order_By>>;
  where?: InputMaybe<UserProfile_Bool_Exp>;
};


export type Subscription_RootUserProfile_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Order_By>>;
  where?: InputMaybe<User_Bool_Exp>;
};


export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type CreateLetterGroupMutationVariables = Exact<{
  items: Scalars['json'];
  bureaus: Scalars['json'];
  rawHtml: Scalars['String'];
  letterType: Scalars['String'];
  letterName: Scalars['String'];
  status: Scalars['String'];
}>;


export type CreateLetterGroupMutation = { __typename?: 'mutation_root', CreateLetterGroup?: { __typename?: 'LetterGroupOutput', id: string } | null };

export type CreateNewCreditItemMutationVariables = Exact<{
  object: CreditItem_Insert_Input;
}>;


export type CreateNewCreditItemMutation = { __typename?: 'mutation_root', insert_CreditItem_one?: { __typename?: 'CreditItem', account_number: string, account_name: string, account_status: AccountStatus_Enum, category: Categories_Enum, created_at: any, id: any, status: CreditItemStatus_Enum, total?: any | null, ItemBureaus: Array<{ __typename?: 'ItemBureau', id: any, value: Bureaus_Enum }> } | null };

export type CreateNewLetterMutationVariables = Exact<{
  object: Letter_Insert_Input;
}>;


export type CreateNewLetterMutation = { __typename?: 'mutation_root', insert_Letter_one?: { __typename?: 'Letter', id: any } | null };

export type CreatePaymentLinkMutationVariables = Exact<{
  productId: Scalars['String'];
  priceId: Scalars['String'];
  discounts?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type CreatePaymentLinkMutation = { __typename?: 'mutation_root', paymentLink?: { __typename?: 'PaymentLinkOutput', url: string } | null };

export type CreateUserAddressMutationVariables = Exact<{
  object: UserAddress_Insert_Input;
}>;


export type CreateUserAddressMutation = { __typename?: 'mutation_root', insert_UserAddress_one?: { __typename?: 'UserAddress', id: any, address_line_1: string, address_line_2?: string | null, city: string, state: string, zip_code: string } | null };

export type DeleteCreditItemMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCreditItemMutation = { __typename?: 'mutation_root', delete_CreditItem_by_pk?: { __typename?: 'CreditItem', id: any } | null };

export type DeleteLetterMutationVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;


export type DeleteLetterMutation = { __typename?: 'mutation_root', delete_Letter?: { __typename?: 'Letter_mutation_response', returning: Array<{ __typename?: 'Letter', id: any }> } | null };

export type DeletePersonalDocumentMutationVariables = Exact<{
  oldDocumentId?: InputMaybe<Scalars['uuid']>;
}>;


export type DeletePersonalDocumentMutation = { __typename?: 'mutation_root', delete_PersonalDocument?: { __typename?: 'PersonalDocument_mutation_response', returning: Array<{ __typename?: 'PersonalDocument', id: any }> } | null };

export type MarkNotificationAsSeenMutationVariables = Exact<{
  id: Scalars['uuid'];
  seen?: InputMaybe<Scalars['timestamptz']>;
}>;


export type MarkNotificationAsSeenMutation = { __typename?: 'mutation_root', update_Notification_by_pk?: { __typename?: 'Notification', seen?: any | null } | null };

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = { __typename?: 'mutation_root', requestPasswordReset?: { __typename?: 'RequestPasswordResetOutput', success: boolean } | null };

export type UpdateBureausItemsMutationVariables = Exact<{
  object: Array<ItemBureau_Insert_Input> | ItemBureau_Insert_Input;
  itemId?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateBureausItemsMutation = { __typename?: 'mutation_root', delete_ItemBureau?: { __typename?: 'ItemBureau_mutation_response', affected_rows: number } | null, insert_ItemBureau?: { __typename?: 'ItemBureau_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'ItemBureau', id: any }> } | null };

export type UpdateBureausLettersMutationVariables = Exact<{
  object: Array<LetterBureau_Insert_Input> | LetterBureau_Insert_Input;
  letterId?: InputMaybe<Scalars['uuid']>;
}>;


export type UpdateBureausLettersMutation = { __typename?: 'mutation_root', delete_LetterBureau?: { __typename?: 'LetterBureau_mutation_response', affected_rows: number } | null, insert_LetterBureau?: { __typename?: 'LetterBureau_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'LetterBureau', id: any }> } | null };

export type UpdateCreditItemMutationVariables = Exact<{
  id: Scalars['uuid'];
  set: CreditItem_Set_Input;
}>;


export type UpdateCreditItemMutation = { __typename?: 'mutation_root', update_CreditItem_by_pk?: { __typename?: 'CreditItem', id: any } | null };

export type UpdateCreditScoreMutationVariables = Exact<{
  objects: Array<CreditScore_Insert_Input> | CreditScore_Insert_Input;
  whereToDelete?: InputMaybe<Array<CreditScore_Bool_Exp> | CreditScore_Bool_Exp>;
}>;


export type UpdateCreditScoreMutation = { __typename?: 'mutation_root', delete_CreditScore?: { __typename?: 'CreditScore_mutation_response', affected_rows: number } | null, insert_CreditScore?: { __typename?: 'CreditScore_mutation_response', returning: Array<{ __typename?: 'CreditScore', id: any, value: any, bureau: Bureaus_Enum }> } | null };

export type UpdateLetterMutationVariables = Exact<{
  id: Scalars['uuid'];
  set?: InputMaybe<Letter_Set_Input>;
}>;


export type UpdateLetterMutation = { __typename?: 'mutation_root', update_Letter_by_pk?: { __typename?: 'Letter', id: any, item_id?: any | null, name: string, raw_html: string, status: LetterStatus_Enum, type?: LetterType_Enum | null, updated_at: any, user_id?: any | null, created_at: any } | null };

export type UpdateLetterGroupMutationVariables = Exact<{
  items: Scalars['json'];
  bureaus: Scalars['json'];
  groupId: Scalars['uuid'];
  letterId: Scalars['uuid'];
}>;


export type UpdateLetterGroupMutation = { __typename?: 'mutation_root', UpdateLetterGroup?: { __typename?: 'LetterGroupUpdateOutput', id: string } | null };

export type UpodateUserAddressMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
  set: UserAddress_Set_Input;
}>;


export type UpodateUserAddressMutation = { __typename?: 'mutation_root', update_UserAddress?: { __typename?: 'UserAddress_mutation_response', returning: Array<{ __typename?: 'UserAddress', id: any, address_line_1: string, address_line_2?: string | null, city: string, state: string, zip_code: string }> } | null };

export type UpdateUserProfileMutationVariables = Exact<{
  email?: InputMaybe<Scalars['String']>;
  set: UserProfile_Set_Input;
}>;


export type UpdateUserProfileMutation = { __typename?: 'mutation_root', update_UserProfile?: { __typename?: 'UserProfile_mutation_response', returning: Array<{ __typename?: 'UserProfile', id: any, first_name: string, last_name: string, ssn?: string | null, birth_date?: any | null, ProfilePicture?: { __typename?: 'File', id: any, url: string } | null }> } | null };

export type UploadPersonalDocumentMutationVariables = Exact<{
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
  documentType: Scalars['String'];
}>;


export type UploadPersonalDocumentMutation = { __typename?: 'mutation_root', UploadPersonalDocument?: { __typename?: 'PersonalImageFileOutput', documentId: any, fileType: string, fileId: any, imageUrl: string, documentType: string } | null };

export type UploadProfilePictureMutationVariables = Exact<{
  imageType: Scalars['String'];
  imageBase64: Scalars['String'];
}>;


export type UploadProfilePictureMutation = { __typename?: 'mutation_root', UploadUserProfilePicture?: { __typename?: 'FileOutput', imageUrl: string } | null };

export type PasswordResetMutationVariables = Exact<{
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  token: Scalars['String'];
}>;


export type PasswordResetMutation = { __typename?: 'mutation_root', passwordReset?: { __typename?: 'PasswordResetOutput', success: boolean } | null };

export type SignupMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignupMutation = { __typename?: 'mutation_root', signup?: { __typename?: 'SignupOutput', id: string, active: boolean, email: string, firstName: string, lastName: string, token: string } | null };

export type CountCreditItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type CountCreditItemsQuery = { __typename?: 'query_root', unsolvedItems: { __typename?: 'CreditItem_aggregate', aggregate?: { __typename?: 'CreditItem_aggregate_fields', count: number } | null }, sovledItems: { __typename?: 'CreditItem_aggregate', aggregate?: { __typename?: 'CreditItem_aggregate_fields', count: number } | null } };

export type GetallCreditItemsQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type GetallCreditItemsQuery = { __typename?: 'query_root', CreditItem: Array<{ __typename?: 'CreditItem', account_number: string, account_name: string, account_status: AccountStatus_Enum, category: Categories_Enum, created_at: any, updated_at: any, id: any, status: CreditItemStatus_Enum, total?: any | null, ItemBureaus: Array<{ __typename?: 'ItemBureau', id: any, value: Bureaus_Enum }>, Letters: Array<{ __typename?: 'Letter', id: any, name: string, status: LetterStatus_Enum, extra_fields?: any | null, raw_html: string, LetterBureaus: Array<{ __typename?: 'LetterBureau', id: any, value: Bureaus_Enum }> }> }> };

export type GetCitiesByStateQueryVariables = Exact<{
  stateCode: Scalars['String'];
  countryCode: Scalars['String'];
}>;


export type GetCitiesByStateQuery = { __typename?: 'query_root', getCitiesByState?: Array<{ __typename?: 'CitiesOutput', country_code: string, name: string, state_code: string }> | null };

export type GetCreditItemByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
  today: Scalars['timestamptz'];
}>;


export type GetCreditItemByIdQuery = { __typename?: 'query_root', CreditItem_by_pk?: { __typename?: 'CreditItem', account_number: string, account_name: string, account_status: AccountStatus_Enum, category: Categories_Enum, created_at: any, updated_at: any, id: any, status: CreditItemStatus_Enum, total?: any | null, dispute_reason?: string | null, ItemBureaus: Array<{ __typename?: 'ItemBureau', id: any, value: Bureaus_Enum }>, Letters_aggregate: { __typename?: 'Letter_aggregate', aggregate?: { __typename?: 'Letter_aggregate_fields', count: number } | null } } | null };

export type GetCreditItemsQueryVariables = Exact<{
  where?: InputMaybe<CreditItem_Bool_Exp>;
}>;


export type GetCreditItemsQuery = { __typename?: 'query_root', CreditItem: Array<{ __typename?: 'CreditItem', account_number: string, account_name: string, account_status: AccountStatus_Enum, category: Categories_Enum, created_at: any, updated_at: any, id: any, status: CreditItemStatus_Enum, total?: any | null, dispute_round?: any | null, dispute_reason?: string | null, ItemBureaus: Array<{ __typename?: 'ItemBureau', id: any, value: Bureaus_Enum }> }> };

export type GetCreditScoresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditScoresQuery = { __typename?: 'query_root', CreditScore: Array<{ __typename?: 'CreditScore', value: any, id: any, bureau: Bureaus_Enum }> };

export type GetHomePageInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetHomePageInformationQuery = { __typename?: 'query_root', UserProfile: Array<{ __typename?: 'UserProfile', id: any, first_name: string, last_name: string, User: { __typename?: 'User', email: string, id: any }, UserAddress?: { __typename?: 'UserAddress', id: any } | null }>, PersonalDocument: Array<{ __typename?: 'PersonalDocument', id: any, type: PersonalDocumentType_Enum }>, CreditItem: Array<{ __typename?: 'CreditItem', account_name: string, id: any, status: CreditItemStatus_Enum, round_expired_at?: any | null, dispute_round?: any | null, Letters: Array<{ __typename?: 'Letter', id: any, waiting_expired_at?: any | null, type?: LetterType_Enum | null }> }>, CreditScore: Array<{ __typename?: 'CreditScore', value: any, id: any, bureau: Bureaus_Enum }>, CreditScore_aggregate: { __typename?: 'CreditScore_aggregate', aggregate?: { __typename?: 'CreditScore_aggregate_fields', avg?: { __typename?: 'CreditScore_avg_fields', value?: number | null } | null } | null } };

export type GetLetterByIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLetterByIdQuery = { __typename?: 'query_root', Letter_by_pk?: { __typename?: 'Letter', id: any, created_at: any, item_id?: any | null, name: string, raw_html: string, status: LetterStatus_Enum, type?: LetterType_Enum | null, updated_at: any, extra_fields?: any | null, LetterBureaus: Array<{ __typename?: 'LetterBureau', id: any, value: Bureaus_Enum }>, CreditItem?: { __typename?: 'CreditItem', id: any, account_name: string, account_number: string, total?: any | null, category: Categories_Enum } | null } | null };

export type GetLetterGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLetterGroupsQuery = { __typename?: 'query_root', Letter: Array<{ __typename?: 'Letter', id: any, item_id?: any | null, created_at: any, name: string, raw_html: string, status: LetterStatus_Enum, type?: LetterType_Enum | null, updated_at: any, user_id?: any | null, group_number?: any | null, waiting_expired_at?: any | null, LetterGroups: Array<{ __typename?: 'LetterGroup', CreditItem: { __typename?: 'CreditItem', account_name: string, account_number: string, account_status: AccountStatus_Enum, category: Categories_Enum, comment?: string | null, dispute_reason?: string | null, created_at: any, dispute_round?: any | null, id: any, round_expired_at?: any | null, status: CreditItemStatus_Enum, total?: any | null, updated_at: any, ItemBureaus: Array<{ __typename?: 'ItemBureau', id: any, item_id: any, created_at: any, updated_at: any, value: Bureaus_Enum }> } }>, LetterBureaus: Array<{ __typename?: 'LetterBureau', id: any, created_at: any, letter_id: any, updated_at: any, value: Bureaus_Enum }> }> };

export type GetLettersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLettersQuery = { __typename?: 'query_root', Letter: Array<{ __typename?: 'Letter', id: any, name: string, raw_html: string, status: LetterStatus_Enum, type?: LetterType_Enum | null, updated_at: any, created_at: any, LetterBureaus: Array<{ __typename?: 'LetterBureau', id: any, value: Bureaus_Enum }> }> };

export type GetMissingStepsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMissingStepsQuery = { __typename?: 'query_root', checkMissingSteps: Array<{ __typename?: 'misingActionOutput', buttonLink: string, buttonText: string, key: string, completed?: boolean | null, title: string, subtitle: string } | null> };

export type GetPersonalDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPersonalDocumentsQuery = { __typename?: 'query_root', PersonalDocument: Array<{ __typename?: 'PersonalDocument', id: any, type: PersonalDocumentType_Enum, File: { __typename?: 'File', file_type: File_Type_Enum, id: any, url: string } }> };

export type GetUserInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserInformationQuery = { __typename?: 'query_root', UserProfile: Array<{ __typename?: 'UserProfile', id: any, first_name: string, middle_name?: string | null, last_name: string, phone?: string | null, ssn?: string | null, address_id?: any | null, user_id: any, birth_date?: any | null, ProfilePicture?: { __typename?: 'File', file_type: File_Type_Enum, id: any, url: string } | null, User: { __typename?: 'User', email: string, id: any }, UserAddress?: { __typename?: 'UserAddress', id: any, address_line_1: string, address_line_2?: string | null, city: string, state: string, zip_code: string } | null }> };

export type PaymentPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentPlansQuery = { __typename?: 'query_root', paymentPlans: { __typename?: 'PaymentPlansOutput', data: any } };

export type PaymentStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type PaymentStatusQuery = { __typename?: 'query_root', paymentStatus?: { __typename?: 'PaymentStatusOutput', status: string } | null };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = { __typename?: 'mutation_root', login?: { __typename?: 'LoginOutput', id: string, active: boolean, email: string, firstName: string, lastName: string, token: string, picture?: string | null, ssn: string, city: string, state: string, address1: string, address2: string, ssn_picture: string, address_picture1: string, address_picture2: string, license_picture: string, zip_code: string, birth_date?: string | null } | null };

export type CountUnseenNotificationsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CountUnseenNotificationsSubscription = { __typename?: 'subscription_root', Notification_aggregate: { __typename?: 'Notification_aggregate', aggregate?: { __typename?: 'Notification_aggregate_fields', count: number } | null } };

export type GetNotificationsSubscriptionVariables = Exact<{
  offset: Scalars['Int'];
  limit: Scalars['Int'];
}>;


export type GetNotificationsSubscription = { __typename?: 'subscription_root', Notification: Array<{ __typename?: 'Notification', id: any, created_at: any, message: string, metadata: any, seen?: any | null, title?: string | null }> };


export const CreateLetterGroupDocument = gql`
    mutation CreateLetterGroup($items: json!, $bureaus: json!, $rawHtml: String!, $letterType: String!, $letterName: String!, $status: String!) {
  CreateLetterGroup(
    items: $items
    bureaus: $bureaus
    letter_type: $letterType
    raw_html: $rawHtml
    selected_letter_name: $letterName
    status: $status
  ) {
    id
  }
}
    `;

export function useCreateLetterGroupMutation() {
  return Urql.useMutation<CreateLetterGroupMutation, CreateLetterGroupMutationVariables>(CreateLetterGroupDocument);
};
export const CreateNewCreditItemDocument = gql`
    mutation CreateNewCreditItem($object: CreditItem_insert_input!) {
  insert_CreditItem_one(object: $object) {
    account_number
    account_name
    account_status
    category
    created_at
    id
    status
    total
    ItemBureaus {
      id
      value
    }
  }
}
    `;

export function useCreateNewCreditItemMutation() {
  return Urql.useMutation<CreateNewCreditItemMutation, CreateNewCreditItemMutationVariables>(CreateNewCreditItemDocument);
};
export const CreateNewLetterDocument = gql`
    mutation CreateNewLetter($object: Letter_insert_input!) {
  insert_Letter_one(object: $object) {
    id
  }
}
    `;

export function useCreateNewLetterMutation() {
  return Urql.useMutation<CreateNewLetterMutation, CreateNewLetterMutationVariables>(CreateNewLetterDocument);
};
export const CreatePaymentLinkDocument = gql`
    mutation CreatePaymentLink($productId: String!, $priceId: String!, $discounts: [String]) {
  paymentLink(productId: $productId, priceId: $priceId, discounts: $discounts) {
    url
  }
}
    `;

export function useCreatePaymentLinkMutation() {
  return Urql.useMutation<CreatePaymentLinkMutation, CreatePaymentLinkMutationVariables>(CreatePaymentLinkDocument);
};
export const CreateUserAddressDocument = gql`
    mutation CreateUserAddress($object: UserAddress_insert_input!) {
  insert_UserAddress_one(object: $object) {
    id
    address_line_1
    address_line_2
    city
    state
    zip_code
  }
}
    `;

export function useCreateUserAddressMutation() {
  return Urql.useMutation<CreateUserAddressMutation, CreateUserAddressMutationVariables>(CreateUserAddressDocument);
};
export const DeleteCreditItemDocument = gql`
    mutation DeleteCreditItem($id: uuid!) {
  delete_CreditItem_by_pk(id: $id) {
    id
  }
}
    `;

export function useDeleteCreditItemMutation() {
  return Urql.useMutation<DeleteCreditItemMutation, DeleteCreditItemMutationVariables>(DeleteCreditItemDocument);
};
export const DeleteLetterDocument = gql`
    mutation DeleteLetter($id: uuid) {
  delete_Letter(where: {id: {_eq: $id}}) {
    returning {
      id
    }
  }
}
    `;

export function useDeleteLetterMutation() {
  return Urql.useMutation<DeleteLetterMutation, DeleteLetterMutationVariables>(DeleteLetterDocument);
};
export const DeletePersonalDocumentDocument = gql`
    mutation deletePersonalDocument($oldDocumentId: uuid) {
  delete_PersonalDocument(where: {id: {_eq: $oldDocumentId}}) {
    returning {
      id
    }
  }
}
    `;

export function useDeletePersonalDocumentMutation() {
  return Urql.useMutation<DeletePersonalDocumentMutation, DeletePersonalDocumentMutationVariables>(DeletePersonalDocumentDocument);
};
export const MarkNotificationAsSeenDocument = gql`
    mutation MarkNotificationAsSeen($id: uuid!, $seen: timestamptz) {
  update_Notification_by_pk(pk_columns: {id: $id}, _set: {seen: $seen}) {
    seen
  }
}
    `;

export function useMarkNotificationAsSeenMutation() {
  return Urql.useMutation<MarkNotificationAsSeenMutation, MarkNotificationAsSeenMutationVariables>(MarkNotificationAsSeenDocument);
};
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email) {
    success
  }
}
    `;

export function useRequestPasswordResetMutation() {
  return Urql.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument);
};
export const UpdateBureausItemsDocument = gql`
    mutation UpdateBureausItems($object: [ItemBureau_insert_input!]!, $itemId: uuid) {
  delete_ItemBureau(where: {item_id: {_eq: $itemId}}) {
    affected_rows
  }
  insert_ItemBureau(objects: $object) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

export function useUpdateBureausItemsMutation() {
  return Urql.useMutation<UpdateBureausItemsMutation, UpdateBureausItemsMutationVariables>(UpdateBureausItemsDocument);
};
export const UpdateBureausLettersDocument = gql`
    mutation UpdateBureausLetters($object: [LetterBureau_insert_input!]!, $letterId: uuid) {
  delete_LetterBureau(where: {letter_id: {_eq: $letterId}}) {
    affected_rows
  }
  insert_LetterBureau(objects: $object) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

export function useUpdateBureausLettersMutation() {
  return Urql.useMutation<UpdateBureausLettersMutation, UpdateBureausLettersMutationVariables>(UpdateBureausLettersDocument);
};
export const UpdateCreditItemDocument = gql`
    mutation UpdateCreditItem($id: uuid!, $set: CreditItem_set_input!) {
  update_CreditItem_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
  }
}
    `;

export function useUpdateCreditItemMutation() {
  return Urql.useMutation<UpdateCreditItemMutation, UpdateCreditItemMutationVariables>(UpdateCreditItemDocument);
};
export const UpdateCreditScoreDocument = gql`
    mutation UpdateCreditScore($objects: [CreditScore_insert_input!]!, $whereToDelete: [CreditScore_bool_exp!]) {
  delete_CreditScore(where: {_or: $whereToDelete}) {
    affected_rows
  }
  insert_CreditScore(objects: $objects) {
    returning {
      id
      value
      bureau
    }
  }
}
    `;

export function useUpdateCreditScoreMutation() {
  return Urql.useMutation<UpdateCreditScoreMutation, UpdateCreditScoreMutationVariables>(UpdateCreditScoreDocument);
};
export const UpdateLetterDocument = gql`
    mutation UpdateLetter($id: uuid!, $set: Letter_set_input) {
  update_Letter_by_pk(pk_columns: {id: $id}, _set: $set) {
    id
    item_id
    name
    raw_html
    status
    type
    updated_at
    user_id
    created_at
  }
}
    `;

export function useUpdateLetterMutation() {
  return Urql.useMutation<UpdateLetterMutation, UpdateLetterMutationVariables>(UpdateLetterDocument);
};
export const UpdateLetterGroupDocument = gql`
    mutation UpdateLetterGroup($items: json!, $bureaus: json!, $groupId: uuid!, $letterId: uuid!) {
  UpdateLetterGroup(
    letter_id: $letterId
    group_id: $groupId
    items: $items
    bureaus: $bureaus
  ) {
    id
  }
}
    `;

export function useUpdateLetterGroupMutation() {
  return Urql.useMutation<UpdateLetterGroupMutation, UpdateLetterGroupMutationVariables>(UpdateLetterGroupDocument);
};
export const UpodateUserAddressDocument = gql`
    mutation UpodateUserAddress($userId: uuid, $set: UserAddress_set_input!) {
  update_UserAddress(
    where: {UserProfile: {User: {id: {_eq: $userId}}}}
    _set: $set
  ) {
    returning {
      id
      address_line_1
      address_line_2
      city
      state
      zip_code
    }
  }
}
    `;

export function useUpodateUserAddressMutation() {
  return Urql.useMutation<UpodateUserAddressMutation, UpodateUserAddressMutationVariables>(UpodateUserAddressDocument);
};
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($email: String, $set: UserProfile_set_input!) {
  update_UserProfile(where: {User: {email: {_eq: $email}}}, _set: $set) {
    returning {
      id
      first_name
      last_name
      ssn
      birth_date
      ProfilePicture {
        id
        url
      }
    }
  }
}
    `;

export function useUpdateUserProfileMutation() {
  return Urql.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument);
};
export const UploadPersonalDocumentDocument = gql`
    mutation UploadPersonalDocument($imageType: String!, $imageBase64: String!, $documentType: String!) {
  UploadPersonalDocument(
    imageType: $imageType
    imageBase64: $imageBase64
    documentType: $documentType
  ) {
    documentId
    fileType
    fileId
    imageUrl
    documentType
  }
}
    `;

export function useUploadPersonalDocumentMutation() {
  return Urql.useMutation<UploadPersonalDocumentMutation, UploadPersonalDocumentMutationVariables>(UploadPersonalDocumentDocument);
};
export const UploadProfilePictureDocument = gql`
    mutation UploadProfilePicture($imageType: String!, $imageBase64: String!) {
  UploadUserProfilePicture(imageType: $imageType, imageBase64: $imageBase64) {
    imageUrl
  }
}
    `;

export function useUploadProfilePictureMutation() {
  return Urql.useMutation<UploadProfilePictureMutation, UploadProfilePictureMutationVariables>(UploadProfilePictureDocument);
};
export const PasswordResetDocument = gql`
    mutation PasswordReset($password: String!, $passwordConfirmation: String!, $token: String!) {
  passwordReset(
    password: $password
    passwordConfirmation: $passwordConfirmation
    token: $token
  ) {
    success
  }
}
    `;

export function usePasswordResetMutation() {
  return Urql.useMutation<PasswordResetMutation, PasswordResetMutationVariables>(PasswordResetDocument);
};
export const SignupDocument = gql`
    mutation Signup($firstName: String!, $lastName: String!, $email: String!, $password: String!) {
  signup(
    firstName: $firstName
    lastName: $lastName
    email: $email
    password: $password
  ) {
    id
    active
    email
    firstName
    lastName
    token
  }
}
    `;

export function useSignupMutation() {
  return Urql.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument);
};
export const CountCreditItemsDocument = gql`
    query CountCreditItems {
  unsolvedItems: CreditItem_aggregate(
    where: {_and: [{status: {_neq: account_fixed}}, {status: {_neq: account_deleted}}]}
  ) {
    aggregate {
      count(columns: id)
    }
  }
  sovledItems: CreditItem_aggregate(
    where: {_or: [{status: {_eq: account_fixed}}, {status: {_eq: account_deleted}}]}
  ) {
    aggregate {
      count(columns: id)
    }
  }
}
    `;

export function useCountCreditItemsQuery(options?: Omit<Urql.UseQueryArgs<CountCreditItemsQueryVariables>, 'query'>) {
  return Urql.useQuery<CountCreditItemsQuery, CountCreditItemsQueryVariables>({ query: CountCreditItemsDocument, ...options });
};
export const GetallCreditItemsDocument = gql`
    query GetallCreditItems($limit: Int!, $offset: Int!) {
  CreditItem(order_by: {updated_at: desc}, limit: $limit, offset: $offset) {
    account_number
    account_name
    account_status
    category
    created_at
    updated_at
    id
    status
    total
    ItemBureaus {
      id
      value
    }
    Letters(order_by: {created_at: desc, status: asc}) {
      id
      name
      status
      extra_fields
      raw_html
      LetterBureaus {
        id
        value
      }
    }
  }
}
    `;

export function useGetallCreditItemsQuery(options: Omit<Urql.UseQueryArgs<GetallCreditItemsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetallCreditItemsQuery, GetallCreditItemsQueryVariables>({ query: GetallCreditItemsDocument, ...options });
};
export const GetCitiesByStateDocument = gql`
    query getCitiesByState($stateCode: String!, $countryCode: String!) {
  getCitiesByState(countryCode: $countryCode, stateCode: $stateCode) {
    country_code
    name
    state_code
  }
}
    `;

export function useGetCitiesByStateQuery(options: Omit<Urql.UseQueryArgs<GetCitiesByStateQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCitiesByStateQuery, GetCitiesByStateQueryVariables>({ query: GetCitiesByStateDocument, ...options });
};
export const GetCreditItemByIdDocument = gql`
    query GetCreditItemById($id: uuid!, $today: timestamptz!) {
  CreditItem_by_pk(id: $id) {
    account_number
    account_name
    account_status
    category
    created_at
    updated_at
    id
    status
    total
    dispute_reason
    ItemBureaus {
      id
      value
    }
    Letters_aggregate(
      where: {status: {_eq: saved_downloaded}, item_id: {_eq: $id}, waiting_expired_at: {_gte: $today}}
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
}
    `;

export function useGetCreditItemByIdQuery(options: Omit<Urql.UseQueryArgs<GetCreditItemByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreditItemByIdQuery, GetCreditItemByIdQueryVariables>({ query: GetCreditItemByIdDocument, ...options });
};
export const GetCreditItemsDocument = gql`
    query GetCreditItems($where: CreditItem_bool_exp) {
  CreditItem(where: $where, order_by: {updated_at: desc}) {
    account_number
    account_name
    account_status
    category
    created_at
    updated_at
    id
    status
    total
    dispute_round
    ItemBureaus {
      id
      value
    }
    dispute_reason
  }
}
    `;

export function useGetCreditItemsQuery(options?: Omit<Urql.UseQueryArgs<GetCreditItemsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreditItemsQuery, GetCreditItemsQueryVariables>({ query: GetCreditItemsDocument, ...options });
};
export const GetCreditScoresDocument = gql`
    query GetCreditScores {
  CreditScore {
    value
    id
    bureau
  }
}
    `;

export function useGetCreditScoresQuery(options?: Omit<Urql.UseQueryArgs<GetCreditScoresQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCreditScoresQuery, GetCreditScoresQueryVariables>({ query: GetCreditScoresDocument, ...options });
};
export const GetHomePageInformationDocument = gql`
    query GetHomePageInformation {
  UserProfile {
    id
    first_name
    last_name
    User {
      email
      id
    }
    UserAddress {
      id
    }
  }
  PersonalDocument(order_by: {created_at: desc}) {
    id
    type
  }
  CreditItem(order_by: {updated_at: desc}) {
    account_name
    id
    status
    round_expired_at
    dispute_round
    Letters(order_by: {updated_at: desc, status: asc}) {
      id
      waiting_expired_at
      type
    }
  }
  CreditScore {
    value
    id
    bureau
  }
  CreditScore_aggregate {
    aggregate {
      avg {
        value
      }
    }
  }
}
    `;

export function useGetHomePageInformationQuery(options?: Omit<Urql.UseQueryArgs<GetHomePageInformationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetHomePageInformationQuery, GetHomePageInformationQueryVariables>({ query: GetHomePageInformationDocument, ...options });
};
export const GetLetterByIdDocument = gql`
    query GetLetterById($id: uuid!) {
  Letter_by_pk(id: $id) {
    id
    created_at
    item_id
    name
    raw_html
    status
    type
    updated_at
    extra_fields
    LetterBureaus {
      id
      value
    }
    CreditItem {
      id
      account_name
      account_number
      total
      category
    }
  }
}
    `;

export function useGetLetterByIdQuery(options: Omit<Urql.UseQueryArgs<GetLetterByIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLetterByIdQuery, GetLetterByIdQueryVariables>({ query: GetLetterByIdDocument, ...options });
};
export const GetLetterGroupsDocument = gql`
    query GetLetterGroups {
  Letter(where: {type: {_eq: letter_group}}, order_by: {created_at: desc}) {
    id
    item_id
    created_at
    name
    raw_html
    status
    type
    updated_at
    user_id
    group_number
    waiting_expired_at
    LetterGroups {
      CreditItem {
        account_name
        account_number
        account_status
        category
        comment
        dispute_reason
        created_at
        dispute_round
        id
        round_expired_at
        status
        total
        updated_at
        ItemBureaus {
          id
          item_id
          created_at
          updated_at
          value
        }
      }
    }
    LetterBureaus {
      id
      created_at
      letter_id
      updated_at
      value
    }
  }
}
    `;

export function useGetLetterGroupsQuery(options?: Omit<Urql.UseQueryArgs<GetLetterGroupsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLetterGroupsQuery, GetLetterGroupsQueryVariables>({ query: GetLetterGroupsDocument, ...options });
};
export const GetLettersDocument = gql`
    query GetLetters {
  Letter {
    id
    name
    raw_html
    status
    type
    updated_at
    created_at
    LetterBureaus {
      id
      value
    }
  }
}
    `;

export function useGetLettersQuery(options?: Omit<Urql.UseQueryArgs<GetLettersQueryVariables>, 'query'>) {
  return Urql.useQuery<GetLettersQuery, GetLettersQueryVariables>({ query: GetLettersDocument, ...options });
};
export const GetMissingStepsDocument = gql`
    query GetMissingSteps {
  checkMissingSteps {
    buttonLink
    buttonText
    key
    completed
    title
    subtitle
  }
}
    `;

export function useGetMissingStepsQuery(options?: Omit<Urql.UseQueryArgs<GetMissingStepsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetMissingStepsQuery, GetMissingStepsQueryVariables>({ query: GetMissingStepsDocument, ...options });
};
export const GetPersonalDocumentsDocument = gql`
    query GetPersonalDocuments {
  PersonalDocument(order_by: {created_at: desc}) {
    File {
      file_type
      id
      url
    }
    id
    type
  }
}
    `;

export function useGetPersonalDocumentsQuery(options?: Omit<Urql.UseQueryArgs<GetPersonalDocumentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetPersonalDocumentsQuery, GetPersonalDocumentsQueryVariables>({ query: GetPersonalDocumentsDocument, ...options });
};
export const GetUserInformationDocument = gql`
    query GetUserInformation {
  UserProfile {
    id
    first_name
    middle_name
    last_name
    phone
    ssn
    address_id
    user_id
    birth_date
    ProfilePicture {
      file_type
      id
      url
    }
    User {
      email
      id
    }
    UserAddress {
      id
      address_line_1
      address_line_2
      city
      state
      zip_code
    }
  }
}
    `;

export function useGetUserInformationQuery(options?: Omit<Urql.UseQueryArgs<GetUserInformationQueryVariables>, 'query'>) {
  return Urql.useQuery<GetUserInformationQuery, GetUserInformationQueryVariables>({ query: GetUserInformationDocument, ...options });
};
export const PaymentPlansDocument = gql`
    query PaymentPlans {
  paymentPlans {
    data
  }
}
    `;

export function usePaymentPlansQuery(options?: Omit<Urql.UseQueryArgs<PaymentPlansQueryVariables>, 'query'>) {
  return Urql.useQuery<PaymentPlansQuery, PaymentPlansQueryVariables>({ query: PaymentPlansDocument, ...options });
};
export const PaymentStatusDocument = gql`
    query PaymentStatus {
  paymentStatus {
    status
  }
}
    `;

export function usePaymentStatusQuery(options?: Omit<Urql.UseQueryArgs<PaymentStatusQueryVariables>, 'query'>) {
  return Urql.useQuery<PaymentStatusQuery, PaymentStatusQueryVariables>({ query: PaymentStatusDocument, ...options });
};
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    id
    active
    email
    firstName
    lastName
    token
    picture
    ssn
    city
    state
    address1
    address2
    ssn_picture
    address_picture1
    address_picture2
    license_picture
    zip_code
    birth_date
  }
}
    `;

export function useLoginMutation() {
  return Urql.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument);
};
export const CountUnseenNotificationsDocument = gql`
    subscription CountUnseenNotifications {
  Notification_aggregate(where: {seen: {_is_null: true}}) {
    aggregate {
      count
    }
  }
}
    `;

export function useCountUnseenNotificationsSubscription<TData = CountUnseenNotificationsSubscription>(options: Omit<Urql.UseSubscriptionArgs<CountUnseenNotificationsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<CountUnseenNotificationsSubscription, TData>) {
  return Urql.useSubscription<CountUnseenNotificationsSubscription, TData, CountUnseenNotificationsSubscriptionVariables>({ query: CountUnseenNotificationsDocument, ...options }, handler);
};
export const GetNotificationsDocument = gql`
    subscription GetNotifications($offset: Int!, $limit: Int!) {
  Notification(offset: $offset, limit: $limit, order_by: {created_at: desc}) {
    id
    created_at
    message
    metadata
    seen
    title
  }
}
    `;

export function useGetNotificationsSubscription<TData = GetNotificationsSubscription>(options: Omit<Urql.UseSubscriptionArgs<GetNotificationsSubscriptionVariables>, 'query'> = {}, handler?: Urql.SubscriptionHandler<GetNotificationsSubscription, TData>) {
  return Urql.useSubscription<GetNotificationsSubscription, TData, GetNotificationsSubscriptionVariables>({ query: GetNotificationsDocument, ...options }, handler);
};