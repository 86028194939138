import { useContext, createContext, useState } from 'react'
import makeStorage from 'utils/makeStorage'
import { ACCOUNT } from 'config'

export type Account = {
  id: string
  active: boolean
  firstName: string
  lastName: string
  email: string
  ssn?: string
  city?: string
  state?: string
  address1?: string
  address2?: string
  zip_code?: string
  picture?: string
  token: string
  license_picture?: string
  ssn_picture?: string
  address_picture1?: string
  address_picture2?: string
  birth_date?: string
}

export type Profile = Pick<Account, 'id' | 'firstName' | 'lastName'>

type ContextType = {
  login: (account: Account) => void
  logout: () => void
  updateAccount: (account: Partial<Account>) => void
}

type ContextTypeAuthenticated = ContextType & {
  isAuthenticated: true
  account: Account
}

type ContextTypeAnonymous = ContextType & {
  isAuthenticated: false
  account: null
}

const Context = createContext<ContextTypeAuthenticated | ContextTypeAnonymous>({
  account: null,
  isAuthenticated: false,
  login: () => undefined,
  logout: () => undefined,
  updateAccount: () => undefined,
})

export const AccountStorage = makeStorage<Account>(
  ACCOUNT.storageKey,
  ACCOUNT.defaultData,
)

export const useAuth = (): ContextTypeAuthenticated | ContextTypeAnonymous =>
  useContext(Context)

export const AuthProvider = ({ children }: any) => {
  const [account, setAccount] = useState<Account | null>(AccountStorage.get())

  const logout = () => {
    setAccount(null)

    AccountStorage.remove()
  }
  const login = async (account: Account) => {
    AccountStorage.set(account)
    setAccount(account)
  }

  const updateAccount = (newAccount: Partial<Account>) => {
    if (account) {
      login({
        id: newAccount.id || account.id,
        active: newAccount.active || account.active,
        firstName: newAccount.firstName || account.firstName,
        lastName: newAccount.lastName || account.lastName,
        email: newAccount.email || account.email,
        ssn: newAccount.ssn || account.ssn,
        city: newAccount.city || account.city,
        state: newAccount.state || account.state,
        address1: newAccount.address1 || account.address1,
        address2: newAccount.address2 || account.address2,
        zip_code: newAccount.zip_code || account.zip_code,
        picture: newAccount.picture || account.picture,
        token: newAccount.token || account.token,
        license_picture: newAccount.license_picture || account.license_picture,
        ssn_picture: newAccount.ssn_picture || account.ssn_picture,
        address_picture1:
          newAccount.address_picture1 || account.address_picture1,
        address_picture2:
          newAccount.address_picture2 || account.address_picture2,
        birth_date: newAccount.birth_date || account.birth_date,
      })
    }
  }

  return (
    <Context.Provider
      value={
        account
          ? {
              account,
              isAuthenticated: true,
              updateAccount,
              login,
              logout,
            }
          : {
              account: null,
              isAuthenticated: false,
              updateAccount,
              login,
              logout,
            }
      }
    >
      {children}
    </Context.Provider>
  )
}
