type ExtraDocumentVariable = {
  name: string
  value: string
}

export const arrayToObject = (array: ExtraDocumentVariable[]) => {
  let extraVarabiles = {}
  array.forEach((group) => {
    const { name, value } = group
    extraVarabiles = { ...extraVarabiles, [name]: { value } }
  })
  return extraVarabiles
}
