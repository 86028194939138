import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import 'styles/index'
import 'tailwindcss/tailwind.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Integrations } from '@sentry/tracing'
import { getEnv } from 'utils/env'
import * as Sentry from '@sentry/react'
import { AuthProvider } from 'clients/auth.client'
import { ToastProvider } from 'components/ToastMessage'
import GraphqlClientProvider from 'clients/graphql.client'

if (getEnv('SENTRY_DSN', true) && getEnv('SENTRY_RELEASE', true)) {
  Sentry.init({
    dsn: getEnv('SENTRY_DSN', true),
    release: getEnv('SENTRY_RELEASE', true),
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend(event) {
      // We can do something here
      return event
    },
  })
}

if (typeof window !== 'undefined' && typeof document !== 'undefined') {
  if (getEnv('MOUSEFLOW_API_KEY', true)) {
    // Inject "mouseflow"
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(() => {
      // eslint-disable-next-line @typescript-eslint/no-extra-semi
      ;(window as any)._mfq = (window as any)._mfq || []

      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.defer = true
      script.src = `//cdn.mouseflow.com/projects/${getEnv(
        'MOUSEFLOW_API_KEY',
        true,
      )}.js`

      document.getElementsByTagName('head').item(0)?.append(script)
    })()
  }

  if (window.location.href.includes('limited-offer=1')) {
    window.sessionStorage.setItem('special-offer', 'limited-offer')
  }
}

const composeProviders = (
  providers: React.FC<any>[],
  children: React.ReactElement,
): React.ReactElement =>
  providers
    .reverse()
    .reduce((acc, Provider) => <Provider>{acc}</Provider>, children)

const RouterProvider = ({ children }: any) => <Router>{children}</Router>

ReactDOM.render(
  composeProviders(
    [RouterProvider, AuthProvider, GraphqlClientProvider, ToastProvider],
    <App />,
  ),
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
