import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import { useAuth } from 'clients/auth.client'
import { useSignupMutation } from 'generated/graphql'
import ErrorMessage from 'components/ErrorMessage'
import sanitizeData from 'utils/sanitizeData'
import FormCheckbox from 'forms/FormCheckbox'
import { useLocation, useNavigate } from 'react-router-dom'
import qs from 'qs'

const FormSignup = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [signupState, signupMutate] = useSignupMutation()
  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
  } = qs.parse(search ? search.replace('?', '') : '')
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      first_name:
        typeof initialFirstName === 'string' ? initialFirstName : '' || '',
      last_name:
        typeof initialLastName === 'string' ? initialLastName : '' || '',
      email: typeof initialEmail === 'string' ? initialEmail : '' || '',
      password: '',
      termsAndConditions: false,
    },
    resolver: yupResolver(
      yup.object({
        first_name: yup.string().required(),
        last_name: yup.string(),
        email: yup.string().required().email(),
        password: yup.string().required().min(5),
        termsAndConditions: yup
          .boolean()
          .required('To continue please accept our terms and conditions')
          .oneOf([true], 'To continue please accept our terms and conditions'),
      }),
    ),
  })
  const { login } = useAuth()
  const { errors } = formState

  return (
    <div>
      <div>
        {/* Email & Password */}
        <form
          onSubmit={handleSubmit(async (values) => {
            const { first_name, last_name, email, password } = values
            const result = await signupMutate({
              email,
              firstName: first_name,
              lastName: last_name,
              password,
            })
            if (result.data?.signup) {
              const { id, email, firstName, lastName, token, active } =
                result.data.signup
              const account = {
                id,
                active,
                email,
                firstName: sanitizeData(firstName),
                lastName: sanitizeData(lastName),
                token,
              }
              login(account)
              navigate('/')
            }
          })}
        >
          <FormGroup>
            <FormField
              type="first_name"
              placeholder="First Name"
              error={errors.first_name?.message}
              {...register('first_name')}
            />
            <FormField
              type="last_name"
              placeholder="Last Name"
              error={errors.last_name?.message}
              {...register('last_name')}
            />
            <FormField
              {...register('email')}
              type="email"
              placeholder="Email"
              error={errors.email?.message}
            />
            <FormField
              {...register('password')}
              type="password"
              placeholder="Password"
              error={errors.password?.message}
            />
            {/* Terms and Conditions */}
            <div className="flex gap-4 items-center">
              <div className="-auto self-start mt-1">
                <FormCheckbox
                  {...register('termsAndConditions')}
                  id={`terms-and-conditions`}
                />
              </div>
              <p className="text-gray-600">
                By submitting the form and creating an account, you accept the{' '}
                <a
                  className="text-primary font-bold cursor-pointer"
                  href="https://myselfcredit.com/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  terms and conditions
                </a>{' '}
                and{' '}
                <a
                  className="text-primary font-bold cursor-pointer"
                  href="https://myselfcredit.com/privacy-policy"
                  target="_blank"
                  rel="noreferrer"
                >
                  privacy policy.
                </a>
              </p>
            </div>
            {errors.termsAndConditions?.message && (
              <ErrorMessage>{errors.termsAndConditions.message}</ErrorMessage>
            )}
            {signupState.error && (
              <ErrorMessage>
                {signupState.error.message.replace('[GraphQL]', '')}
              </ErrorMessage>
            )}
            <Button $fluid loading={formState.isSubmitting}>
              Signup
            </Button>
          </FormGroup>
        </form>
      </div>
    </div>
  )
}

export default FormSignup
