import { useState } from 'react'
import Button from 'components/Button'
import Title from 'components/Title'
import cc from 'classcat'
import { ReactComponent as IconClose } from 'assets/icons/x.svg'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'

type Props = {
  title: string
  subtitle: string
  buttonText: string
  buttonLink: string
  className?: String
  showCloseButton?: boolean
  name?: string
  stepName?: string
  $isCompleted?: boolean
}

function NoResultTile({
  title,
  subtitle,
  buttonLink,
  buttonText,
  className,
  showCloseButton,
  name,
  stepName,
  $isCompleted,
}: Props) {
  const key = `hide-tile-${name}`
  const [hideTitle, setHideTile] = useState(localStorage.getItem(key))

  if (hideTitle && showCloseButton) {
    return null
  }

  return (
    <div className="relative">
      {showCloseButton && name && (
        <button
          className="absolute top-0 right-0 flex justify-center items-center bg-white rounded-full w-8 h-8 fill-gray-600 m-1"
          onClick={() => {
            localStorage.setItem(key, 'hide')
            setHideTile('hide')
          }}
        >
          <IconClose className="w-4 h-4" />
        </button>
      )}
      <div className={cc(['py-10 text-center', className])}>
        <h2 className="text-gray-600">{stepName}</h2>
        <div className="">
          <Title title={title} subtitle={subtitle} />
        </div>

        {!$isCompleted ? (
          <div style={{ width: '55%', margin: 'auto' }}>
            <Button className="mt-3 w-full" to={buttonLink}>
              {buttonText}
            </Button>
          </div>
        ) : (
          <div className="flex items-center mt-4">
            <div className="bg-green-500 p-2 rounded-full mx-auto">
              <IconCheck className="fill-white" width={30} />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default NoResultTile
