import dayjs from 'dayjs'
import {
  AccountInformationType,
  AccountInstructionType,
  bureausVariables,
  getVariableNameRegex,
  getVariableRegex,
  UserInformationType,
} from 'globalConstants'
import { get } from 'lodash'

const today = dayjs(new Date().toString()).format('DD/MM/YYYY')

const insertInformationToHtml = (
  htmlDocument: string,
  userInformation: UserInformationType,
  bureau: string,
  extraVariables: any,
  accountInformation: AccountInformationType,
  accountsInstructions?: AccountInstructionType[],
) => {
  const variables = {
    CustomerFirstName: { value: userInformation?.firstName },
    CustomerLastName: { value: userInformation?.lastName },
    CustomerSSN: { value: userInformation?.ssn },
    CustomerAddress: {
      value: [
        [userInformation?.address1, userInformation?.address2]
          .filter(Boolean)
          .join(' '),
        [userInformation?.city, userInformation?.state]
          .filter(Boolean)
          .join(' '),
        userInformation?.zip_code,
      ]
        .filter(Boolean)
        .join(', '),
    },
    CustomerDOB: {
      value: dayjs(userInformation?.birthdate).format('DD/MM/YYYY'),
    },
    ItemNameList: { value: accountInformation?.accountName },
    ItemNumberList: { value: accountInformation?.accountNumber },
    ItemBalance: { value: accountInformation.accountBalance },
    RecipientName: { value: get(bureausVariables, `${bureau}.RecipientName`) },
    RecipientAddressLine1: {
      value: get(bureausVariables, `${bureau}.RecipientAddressLine1`),
    },
    RecipientCityStatePostalCode: {
      value: get(bureausVariables, `${bureau}.RecipientCityStatePostalCode`),
    },
    TodaysDate: { value: today },
    ...extraVariables,
  }
  //Todo create function where we can get th vars from the rawhtml value
  const vars = htmlDocument.match(getVariableRegex)
  let result = htmlDocument

  vars?.forEach((variable) => {
    const valuePath = `${variable.match(getVariableNameRegex)?.join('')}.value`
    if (variable === '[ItemsNameAccountsInstructions]') {
      const instructionsText = accountsInstructions
        ?.map(
          (instruction) =>
            `${instruction.accountName} - ${instruction.accountNumber} - ${instruction.accountReason}\n\n`,
        )
        .join('')
      result = result.replace(variable, instructionsText ?? '')
    } else result = result.replace(variable, get(variables, valuePath) ?? '')
  })
  return result
}
export default insertInformationToHtml
