import Collapsable from 'components/Collapsable'
import Container from 'components/Container'
import CreditItemStatus from 'components/CreditItemStatus'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import CreditItemForm from 'forms/CreditItemForm'
import { Bureaus_Enum, useGetCreditItemByIdQuery } from 'generated/graphql'
import withContainer from 'hocs/withContainer'
import React from 'react'
import { useParams } from 'react-router-dom'

const today = new Date().toISOString()

function CreditItem() {
  const { itemID } = useParams<{ itemID: string }>()
  const [{ data, fetching, error }] = useGetCreditItemByIdQuery({
    variables: { id: itemID, today },
  })

  if (fetching) {
    return <Loading />
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  if (!data || !data.CreditItem_by_pk) {
    return null
  }

  const {
    account_name,
    account_number,
    account_status,
    ItemBureaus,
    id,
    category,
    status,
    total,
    dispute_reason,
  } = data.CreditItem_by_pk

  const bureaus = ItemBureaus.map((bureau) => bureau.value)

  return (
    <Container topAndBottom>
      <div className="bg-white p-4 rounded-lg border-2 border-gray-400">
        <Collapsable
          initialValue={true}
          trigger={() => (
            <div className="flex items-center justify-between w-full">
              <div className="text-lg font-bold">{account_name}</div>
              <CreditItemStatus status={status} />
            </div>
          )}
          triggerClass="pr-1"
          $arrowColor="gray"
        >
          <CreditItemForm
            initialValues={{
              id: id,
              accountName: account_name,
              accountNumber: account_number,
              accountStatus: account_status,
              balance: total,
              category: category,
              status,
              equifax: bureaus.find(
                (bureau) => bureau === Bureaus_Enum.Equifax,
              ),
              trans_union: bureaus.find(
                (bureau) => bureau === Bureaus_Enum.TransUnion,
              ),
              experian: bureaus.find(
                (bureau) => bureau === Bureaus_Enum.Experian,
              ),
              dispute_reason: dispute_reason || undefined,
            }}
          />
        </Collapsable>
      </div>
    </Container>
  )
}

export default withContainer(CreditItem)
