import Button from 'components/Button'
import Collapsable from 'components/Collapsable'
import Container from 'components/Container'
import Tabs from 'components/Tabs'
import Title from 'components/Title'
import withContainer from 'hocs/withContainer'
import React, { useState } from 'react'
import {
  AccountStatus_Enum,
  Bureaus_Enum,
  Categories_Enum,
  useGetCreditItemsQuery,
  CreditItemStatus_Enum,
  useCountCreditItemsQuery,
} from 'generated/graphql'
import Loading from 'components/Loading'
import { useNavigate, Link } from 'react-router-dom'
import NoResultMessage from 'components/NoResultMessage'
import CreditItemStatus from 'components/CreditItemStatus'
import PlayVideoButton from 'components/PlayVideoButton'
import withAsideMenu from 'hocs/withAsideMenu'
import { Videos } from 'globalConstants'
import CreditItemForm from 'forms/CreditItemForm'
import CreditItemInformation from 'components/CreditItemInformation'

function CreditItems() {
  const [{ data, fetching }] = useCountCreditItemsQuery()

  if (fetching) return <Loading />

  return (
    <Container topAndBottom>
      <div className="max-w-2xl mx-auto">
        <div className="pb-4 flex items-center">
          <div className="max-w-settingsTitle">
            <Title
              $align="left"
              title="Credit Items"
              subtitle="Create the items you want to dispute from your credit report."
            />
          </div>
          <div className="ml-auto">
            <PlayVideoButton video={Videos.ITEMS} />
          </div>
        </div>
        <Tabs
          items={[
            {
              id: 'unsolved-items',
              Component: UnsolvedItemsTab,
              text: `In-progress (${data?.unsolvedItems.aggregate?.count})`,
            },
            {
              id: 'fixed-items',
              Component: FixedItemsTab,
              text: `Fixed (${data?.sovledItems.aggregate?.count})`,
            },
          ]}
          $style="even"
        />
        <Link to="/letters">
          <Button $fluid>Continue to next Step</Button>
        </Link>
      </div>
    </Container>
  )
}

const FixedItemsTab = () => {
  const [{ data, error, fetching }] = useGetCreditItemsQuery({
    variables: {
      where: {
        _or: [
          { status: { _eq: CreditItemStatus_Enum.AccountFixed } },
          { status: { _eq: CreditItemStatus_Enum.AccountDeleted } },
        ],
      },
    },
    requestPolicy: 'network-only',
  })

  if (fetching) return <Loading />

  if (error?.message) {
    return <ErrorMessage message={error.message} />
  }

  if (!data || !data.CreditItem) {
    return null
  }

  if (data.CreditItem.length === 0)
    return (
      <div className="py-20 bg-white">
        <NoResultMessage message="No solved items found" />
      </div>
    )

  return (
    <div className="my-5 space-y-3">
      {data.CreditItem.map((item, i) => (
        <CreditItem
          key={item.id}
          data={{
            id: item.id,
            accountName: item.account_name,
            accountNumber: item.account_number,
            accountStatus: item.account_status,
            bureaus: item.ItemBureaus.map((bureau) => bureau.value),
            total: item.total,
            category: item.category,
            status: item.status,
            dispute_reason: item.dispute_reason || '',
            dispute_round: item.dispute_round,
          }}
          open={i === 0}
        />
      ))}
    </div>
  )
}

const UnsolvedItemsTab = () => {
  const [showNewItem, setShowNewItem] = useState(false)
  const [{ data, error, fetching }] = useGetCreditItemsQuery({
    variables: {
      where: {
        _and: [
          { status: { _neq: CreditItemStatus_Enum.AccountFixed } },
          { status: { _neq: CreditItemStatus_Enum.AccountDeleted } },
        ],
      },
    },
    requestPolicy: 'network-only',
  })
  if (fetching) return <Loading />

  if (error?.message) {
    return <ErrorMessage message={error.message} />
  }

  if (!data || !data.CreditItem) {
    return null
  }

  const { CreditItem: CreditItems } = data
  const unknownAccounts = CreditItems.filter(
    (item) => item.category === Categories_Enum.UnknownAccounts,
  )
  const latePayments = CreditItems.filter(
    (item) => item.category === Categories_Enum.LatePayments,
  )
  const chargeOff = CreditItems.filter(
    (item) => item.category === Categories_Enum.ChargeOff,
  )
  const collections = CreditItems.filter(
    (item) => item.category === Categories_Enum.Collections,
  )
  const repossession = CreditItems.filter(
    (item) => item.category === Categories_Enum.Repossession,
  )
  const foreclosure = CreditItems.filter(
    (item) => item.category === Categories_Enum.Foreclosure,
  )
  const mixedReasons = CreditItems.filter(
    (item) => item.category === Categories_Enum.MixedReasons,
  )

  return (
    <div className="my-5 ">
      <Button
        $fluid
        $type="secondary"
        onClick={() => {
          setShowNewItem(true)
        }}
      >
        Create new Item
      </Button>
      {showNewItem && (
        <div className="bg-white p-4 rounded-lg border-2 border-gray-400 my-5">
          <CreditItemForm onCancel={() => setShowNewItem(false)} />
        </div>
      )}
      {CreditItems.length === 0 && !showNewItem && (
        <div className="py-20 my-5 bg-white">
          <NoResultMessage message="No unsolved items found" />
        </div>
      )}
      {unknownAccounts.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Unknown Accounts" />
          {unknownAccounts.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}
      {latePayments.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Late Payments" />
          {latePayments.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}
      {chargeOff.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Charge off's" />
          {chargeOff.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}
      {collections.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Collections" />
          {collections.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}
      {repossession.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Repossession" />
          {repossession.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}
      {foreclosure.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Foreclosure" />
          {foreclosure.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}

      {mixedReasons.length > 0 && (
        <div className="my-5 space-y-3">
          <Title title="Mixed Reasons" />
          {mixedReasons.map((item, i) => (
            <CreditItem
              key={item.id}
              data={{
                id: item.id,
                accountName: item.account_name,
                accountNumber: item.account_number,
                accountStatus: item.account_status,
                bureaus: item.ItemBureaus.map((bureau) => bureau.value),
                total: item.total,
                category: item.category,
                status: item.status,
                dispute_reason: item.dispute_reason || '',
                dispute_round: item.dispute_round,
              }}
              open={i === 0}
            />
          ))}
        </div>
      )}
    </div>
  )
}

type CreditItemInformationType = {
  id: string
  accountName: string
  accountNumber: string
  accountStatus: AccountStatus_Enum
  bureaus: Bureaus_Enum[]
  total: number
  category: Categories_Enum
  status: CreditItemStatus_Enum
  dispute_reason?: string
  dispute_round: number
}

const CreditItem = ({
  data,
  open,
}: {
  data: CreditItemInformationType
  open: boolean
}) => {
  const [mode, setMode] = useState<'form' | 'info'>('info')
  const navigation = useNavigate()

  return (
    <div className="bg-white p-4 rounded-lg border-2 border-gray-400">
      <Collapsable
        initialValue={open}
        trigger={() => (
          <div className="flex items-center justify-between w-full">
            <div className="text-lg font-bold">{data.accountName}</div>
            <CreditItemStatus status={data.status} />
          </div>
        )}
        triggerClass="pr-1"
        $arrowColor="gray"
      >
        {mode === 'form' && (
          <CreditItemForm
            initialValues={{
              id: data.id,
              accountName: data.accountName,
              accountNumber: data.accountNumber,
              accountStatus: data.accountStatus,
              balance: data.total,
              category: data.category,
              status: data.status,
              equifax: data.bureaus.find(
                (bureau) => bureau === Bureaus_Enum.Equifax,
              ),
              trans_union: data.bureaus.find(
                (bureau) => bureau === Bureaus_Enum.TransUnion,
              ),
              experian: data.bureaus.find(
                (bureau) => bureau === Bureaus_Enum.Experian,
              ),
              dispute_reason: data.dispute_reason,
            }}
          />
        )}
        {mode === 'info' && (
          <>
            <CreditItemInformation data={data} />
            <Button
              $fluid
              onClick={() => {
                setMode('form')
              }}
              $type="secondary"
            >
              Edit Account
            </Button>
            {data.status !== CreditItemStatus_Enum.AccountFixed && (
              <Button
                $fluid
                onClick={() => {
                  navigation(`/letters/new-letter/${data.id}`)
                }}
                $type="secondary"
                className="mt-5"
              >
                Create Letter
              </Button>
            )}
          </>
        )}
      </Collapsable>
    </div>
  )
}
const ErrorMessage = ({ message }: { message: string }) => {
  if (!message) {
    return null
  }
  return <div className="text-action-fail text-sm pt-1">{message}</div>
}

export default withContainer(withAsideMenu(CreditItems))
