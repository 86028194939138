import React from 'react'
import FormField from 'components/FormField'
import FormGroup from 'components/FormGroup'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import { useRequestPasswordResetMutation } from 'generated/graphql'
import { GENERIC_ERROR_MESSAGE } from 'globalConstants'

const validationSchema = yup.object({
  email: yup.string().required().email(),
})

const FormForgotPassword = ({
  onRequestSent,
}: {
  onRequestSent: () => void
}) => {
  const [requestPassworResetState, requestPasswordReset] =
    useRequestPasswordResetMutation()
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(validationSchema),
  })
  const { errors } = formState

  return (
    <div>
      <div>
        {/* Email & Password */}
        <form
          onSubmit={handleSubmit(async (values) => {
            const { email } = values
            const response = await requestPasswordReset({ email })
            if (!response.error) {
              onRequestSent()
            } else {
              console.error(response.error)
              alert(GENERIC_ERROR_MESSAGE)
            }
          })}
        >
          <FormGroup>
            <FormField
              type="email"
              placeholder="Email"
              error={errors.email?.message}
              {...register('email')}
            />
            {requestPassworResetState.error && (
              <ErrorMessage>
                {requestPassworResetState.error.message}
              </ErrorMessage>
            )}
            <Button
              $fluid
              loading={formState.isSubmitting}
              data-testid="button-login"
            >
              Send Email
            </Button>
          </FormGroup>
        </form>
      </div>
    </div>
  )
}

export default FormForgotPassword
