import Container from 'components/Container'
import withContainer from 'hocs/withContainer'
import * as yup from 'yup'
import React, { useEffect } from 'react'
import FormGroup from 'components/FormGroup'
import FormField from 'components/FormField'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import LogoIcon from 'assets/login-icon.png'
import Button from 'components/Button'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { usePasswordResetMutation } from 'generated/graphql'
import ErrorMessage from 'components/ErrorMessage'
import { useToast } from 'components/ToastMessage'

const validationSchema = yup.object({
  password: yup.string().required().min(5),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

function ResetPassword() {
  const toast = useToast()
  const [resetPasswordState, resetPassword] = usePasswordResetMutation()
  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { password: '', passwordConfirmation: '' },
  })
  const navigation = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { errors, isSubmitting } = formState

  useEffect(() => {
    if (!token) navigation('/')
  }, [])

  if (!token) {
    return null
  }

  return (
    <Container topAndBottom>
      <div className="max-w-xl mx-auto py-10">
        <Container className="flex items-center flex-col">
          <div className="mx-auto p-2">
            <img src={LogoIcon} alt="Logo" className="w-52" />
          </div>
          <form
            onSubmit={handleSubmit(async (values) => {
              const response = await resetPassword({
                password: values.password || '',
                passwordConfirmation: values.passwordConfirmation || '',
                token,
              })
              if (!response.error) {
                toast.notify({
                  type: 'success',
                  message:
                    'Your password was changed successfully! we are redirecting you to the login page',
                  preventAutoClose: true,
                  preventClose: true,
                })
                setTimeout(() => {
                  navigation('/')
                  toast.dismiss(0)
                }, 4500)
              }
            })}
            className="w-full"
          >
            <FormGroup>
              <FormField
                {...register('password')}
                type="password"
                placeholder="Password"
                error={errors.password?.message}
              />
              <FormField
                {...register('passwordConfirmation')}
                type="password"
                placeholder="Confirm Password"
                error={errors.passwordConfirmation?.message}
              />
              {resetPasswordState.error && (
                <ErrorMessage>
                  {resetPasswordState.error.message.replace('[GraphQL]', '')}
                </ErrorMessage>
              )}
              <Button $fluid loading={isSubmitting}>
                Reset password
              </Button>
            </FormGroup>
          </form>
        </Container>
      </div>
    </Container>
  )
}

export default withContainer(ResetPassword)
