import React, { useEffect } from 'react'
import cc from 'classcat'
import ms from 'ms'
import { ReactComponent as IconClose } from 'assets/icons/x.svg'
import { ReactComponent as IconCheck } from 'assets/icons/checkmark.svg'
import { ReactComponent as IconWarning } from 'assets/icons/warning.svg'
import styles from './index.module.scss'

type ContextValue = {
  notify: (toast: Toast) => void
  dismiss: (index: number) => void
}

const Context = React.createContext<ContextValue>({
  notify: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
  dismiss: () => {
    throw new Error(`Cannot use Toast Message outside "ToastProvider"`)
  },
})

type Type = 'info' | 'success' | 'failure' | 'warning' | 'video'

type Toast = {
  type: Type
  message?: React.ReactNode | React.FC
  position?: 'top' | 'button'
  preventAutoClose?: boolean
  title?: string
  preventClose?: Boolean
  onClose?: () => void
}

type ToastProviderProps = {
  children: any
}

export const ToastProvider = ({ children }: ToastProviderProps) => {
  const [state, setState] = React.useState<null | Toast>(null)

  const dismiss = () => {
    setState(null)
  }

  const notify = (toast: Toast) => {
    setState(toast)
  }

  useEffect(() => {
    if (state) {
      if (!state.preventAutoClose) {
        window.setTimeout(() => {
          dismiss()
        }, ms('5s'))
      }
    }
    return () => {
      state?.onClose && state.onClose()
    }
  }, [state])

  return (
    <Context.Provider
      value={{
        notify,
        dismiss,
      }}
    >
      {children}

      {state && (
        <>
          <div
            className="fixed inset-0 z-10 bg-black bg-opacity-50 backdrop-filter backdrop-blur"
            onClick={() => {
              !state.preventClose && dismiss()
            }}
          />
          <div
            className={cc([
              'z-11 w-full  py-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fixed',
              { 'max-w-lg md:px-2 px-8': state.type !== 'video' },
              { 'px-8 max-w-7xl ': state.type === 'video' },
            ])}
          >
            <ToastMessage
              type={state.type}
              message={state.message}
              title={state.title}
              animated
              onClick={() => {
                !state.preventClose && dismiss()
              }}
              preventClose={state.preventClose}
            />
          </div>
        </>
      )}
    </Context.Provider>
  )
}

export const useToast = (): ContextValue => React.useContext(Context)

const titleMap: {
  [key: string]: {
    message: string
    bgColor: string
  }
} = {
  success: {
    message: 'Saved',
    bgColor: 'bg-action-success',
  },
  failure: {
    message: 'Error',
    bgColor: 'bg-action-fail',
  },
  info: {
    message: 'Info',
    bgColor: 'bg-action-success',
  },
  warning: {
    message: 'Warning!',
    bgColor: 'bg-action-warning',
  },
  video: {
    message: '',
    bgColor: '',
  },
}

type ToastMessageProps = {
  type: Type
  message: React.ReactNode | React.FC
  animated?: boolean
  title?: string
  onClick: () => void
  preventClose?: Boolean
}

export const ToastMessage = ({
  type,
  message,
  animated,
  title,
  preventClose,
  onClick,
}: ToastMessageProps) => {
  const getTypeIcon = (type: string) => {
    switch (type) {
      case 'success':
      case 'info':
        return <IconCheck width={28} className="fill-white" />
      case 'failure':
        return <IconClose width={18} className="fill-white" />
      case 'warning':
        return <IconWarning width={32} className="fill-white" />
      default:
        return null
    }
  }
  const MessageComponent = typeof message === 'function' ? message : null
  const messageString = typeof message === 'string' ? message.toString() : null

  return (
    <div
      className={cc([
        'flex flex-col justify-center items-center ',
        {
          [styles.toast]: animated,
        },
      ])}
    >
      <div
        className={cc([
          'rounded-lg shadow-2xl w-full',
          { 'pb-4 bg-white ': type !== 'video' },
          { 'bg-black': type === 'video' },
        ])}
        data-testid="toast-message"
      >
        {/* Header */}
        <div
          className={cc([
            'relative flex flex-col items-center',
            { 'h-16': type !== 'video' },
          ])}
        >
          {type !== 'video' && (
            <div
              className={cc([
                'absolute rounded-full h-14 w-14 flex items-center justify-center border-6 border-white bottom-0 top-5',
                type && titleMap[type]['bgColor'],
              ])}
              onClick={onClick}
            >
              {getTypeIcon(type)}
            </div>
          )}
          {type !== 'video' && (
            <div className="h-12 bg-primary border-b border-gray-500 w-full rounded-t-lg" />
          )}
        </div>

        {/* Body */}
        <div
          className={cc([
            'text-blackf text-center',
            { 'mb-5 mt-4': type !== 'video' },
          ])}
        >
          {MessageComponent ? (
            <MessageComponent />
          ) : (
            <>
              <div className="text-xl text-black font-bold mt-1">
                {title ? title : type && titleMap[type]['message']}
              </div>
              <div className="text-gray-700 my-1 px-2">{messageString}</div>
            </>
          )}
        </div>

        {/* Close */}
        {!preventClose && (
          <button
            className="absolute top-0 md:right-0 right-4 flex justify-center items-center bg-white rounded-full w-8 h-8"
            onClick={onClick}
            data-testid="button-close-toast"
          >
            <IconClose className="w-3 h-3" />
          </button>
        )}
      </div>
    </div>
  )
}

ToastMessage.defaultProps = {
  type: 'info',
}
