import Container from 'components/Container'
import Title from 'components/Title'
import VideoPlayer from 'components/VideoPlayer'
import withContainer from 'hocs/withContainer'

type TutorialType = {
  id: number
  body: string
  video: string
}

const tutorials: TutorialType[] = [
  {
    id: 1,
    body: 'How to complete your profile',
    video: 'https://www.youtube.com/watch?v=lLSu0UHNZhU',
  },
  {
    id: 2,
    body: 'How to ready your credit report',
    video: 'https://www.youtube.com/watch?v=Tish3KkNnLc',
  },
  {
    id: 3,
    body: 'Create your credit items',
    video: 'https://www.youtube.com/watch?v=dQw4w9WgXcQ',
  },
  {
    id: 4,
    body: 'Create your dispute letters',
    video: 'https://www.youtube.com/watch?v=y8Kyi0WNg40',
  },
  {
    id: 5,
    body: 'How to send letters?',
    video: 'https://www.youtube.com/watch?v=lLSu0UHNZhU',
  },
]

function TutorialsPage() {
  return (
    <Container>
      <div className="max-w-2xl mx-auto">
        <div className="pb-5 pt-5">
          <Title
            title="Tutorials"
            subtitle="To obtain better results, we recommend following all these steps carefully."
          />
        </div>
        {tutorials.map(({ id, body, video }) => (
          <TutorialItem body={body} video={video} />
        ))}
      </div>
    </Container>
  )
}

const TutorialItem = ({ body, video }: { body: string; video: string }) => {
  return (
    <div className="mb-10">
      <VideoPlayer url={video} />
      <div>{body}</div>
    </div>
  )
}

export default withContainer(TutorialsPage)
