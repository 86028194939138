import React from 'react'
import { ReactComponent as IconArrowRight } from 'assets/icons/arrow-payment.svg'
import cc from 'classcat'
import useToggle from 'hooks/useToggle'

type Props = {
  children: React.ReactNode
  trigger: React.FC | string
  $arrowColor?: 'black' | 'gray'
  initialValue?: boolean
  triggerClass?: string
  parentClass?: string
}

function Collapsable({
  children,
  trigger,
  $arrowColor,
  initialValue,
  triggerClass,
}: Props) {
  const [isOpen, toggle] = useToggle(initialValue)

  const TriggerComponent = typeof trigger === 'function' ? trigger : null
  const TriggerText = typeof trigger === 'string' ? trigger.toString() : null

  return (
    <div>
      <button
        className={cc(['w-full', triggerClass])}
        onClick={() => {
          toggle()
        }}
        type="button"
        style={{ outline: 'none' }}
      >
        <div className="flex items-center justify-between gap-3">
          {TriggerComponent ? <TriggerComponent /> : TriggerText}
          <IconArrowRight
            className={cc([
              'w-2 transform',
              {
                'rotate-90': !isOpen,
                '-rotate-90': isOpen,
              },
              $arrowColor === 'gray' ? 'fill-gray-600' : '',
            ])}
          />
        </div>
      </button>

      <div>{isOpen && children}</div>
    </div>
  )
}

export default Collapsable
